import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import '../../styles/CandidateDashboard/CandidateDashboard.css';
import categoryIcon from '../../assets/icons/category-2.svg';
import bullseyeIcon from '../../assets/icons/bullseye-2.svg';

function DashboardFooter(props) {
    return (
        <footer className="DashboardFooter">
            <h1>Here's what you can do here!</h1>
            <Grid
                container
                className="things-todo"
                //sx={{ }}
                spacing={1}
            >
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <i className="fa-solid fa-share-nodes"></i>
                        <h2>Networking</h2>
                        <p>
                            Build a strong network of industry experts and like
                            minded people.
                        </p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <img src={categoryIcon} alt="category" />
                        {/* <i className="fa-solid fa-clipboard-check"></i> */}
                        <h2>Events and Competitions</h2>
                        <p>
                            Become a part of exclusive events. Participate in
                            various competitions to win exciting prizes.
                        </p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <img src={bullseyeIcon} alt="bullseye" />
                        {/* <i className="fa-solid fa-icons"></i> */}
                        <h2>2 year headstart at career</h2>
                        <p>
                            Learn all practical skills and standout of the crowd
                            through participating in various live projects and
                            events.
                        </p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <i className="fa-solid fa-users"></i>
                        <h2>Community-based learning</h2>
                        <p>
                            Go beyond traditional learning with peer led
                            engagement to help each other grow.
                        </p>
                    </div>
                </Grid>
            </Grid>
            <div className="footer-social-media-text">
                <p>Join our community and stay connected</p>
                <Link to="/whatsapp" className="whatsapp-btn">
                    <i className="fa-brands fa-whatsapp"></i>
                </Link>
                <Link to="/discord" className="discord-btn">
                    <i className="fa-brands fa-discord"></i>
                </Link>
            </div>
        </footer>
    );
}

export default DashboardFooter;
