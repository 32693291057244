import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import '../../styles/Admin/AdminMentorRequests.css';

function AdminMentorRequests(props) {
    const [candidates, setCandidates] = useState([]);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [currCandidateActionId, setCurrCandidateActionId] = useState('');

    const dispatch = useDispatch();

    // const defaultMentorData = [
    //     { name: 'John Doe', company: 'Google', role: 'UX Designer', email: 'johndoe@preentrepreneur.in' },
    //     { name: 'Jessica Alley', company: 'SpaceX', role: 'DevOps Engineer', email: 'jessicaalley@preentrepreneur.in' },
    //     {
    //         name: 'Micheal De Santa',
    //         company: 'Merryweather Inc.',
    //         role: 'Cyber Security Engineer',
    //         email: 'micheal@preentrepreneur.in'
    //     },
    //     {
    //         name: 'Franklin Clinton',
    //         company: 'Merryweather Inc.',
    //         role: 'Head of IT',
    //         email: 'franklin@preentrepreneur.in'
    //     },
    // ];

    const defaultMentorData = [
        { name: 'Startups'},
        { name: 'Top tech firms'},
        {
            name: 'Global consulting firms'
        },
        {
            name: 'Top global universities'
        },
        {name: 'Top investment banking firms'}
    ];

    useEffect(() => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };

        axios.get('/api/admin/mentorship', config) 
            .then(res => {
                if(res.data.users) setCandidates(res.data.users);
            }).catch(err => console.log(err));
    }, [dialogIsOpen]);

    const handleDialogOpen = userId => {
        if(userId && userId !== '') setCurrCandidateActionId(userId);
        else setCurrCandidateActionId('');
        setDialogIsOpen(!dialogIsOpen);
    }

    const markAsClosed = e => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };
        const payload = {
            userId: currCandidateActionId,
            status: 'closed'
        };

        axios.post('/api/admin/mentorship', payload, config)
            .then(res => {
                if(res.data.userId) {
                    handleDialogOpen();
                    dispatch(
                        handleSnackOpen({
                            message: 'Request Marked as closed',
                            variant: 'success'
                        })
                    );
                }
            }).catch(err => console.log(err));

        
    }

    return (
        <section className='AdminMentorRequests'>
            <h1>Mentor Requests</h1>
            <Grid container spacing={2} sx={{width: '100%'}}>
                {
                    candidates && candidates.length > 0 
                    ? (
                        candidates.map(candidate => {
                            const foundMentor = defaultMentorData.find(mentor => (
                                mentor.name.toLowerCase() === candidate.mentorship.mentorName.toLowerCase()
                            ));
                            return (
                                <Grid item md={6} sm={12} xs={12} key={candidate._id}>
                                    <div className='mentor-request-card'>
                                        <button className='close-request-btn' onClick={() => handleDialogOpen(candidate._id)}>
                                            <i className="fa-solid fa-xmark"></i>
                                        </button>
                                        <span>
                                            <h2>Mentee</h2>
                                            <h3>{candidate.fullName}</h3>
                                            <a href={`mailto:${candidate.email}`}>
                                                <i className="fa-solid fa-envelope"></i> Talk to mentee
                                            </a>
                                        </span>
                                        <span>
                                            <h2>Mentorship Field</h2>
                                            <h3>{candidate.mentorship.mentorName}</h3>
                                            {/* <a href={`mailto:${foundMentor ? foundMentor.email : ''}`}>
                                                <i className="fa-solid fa-envelope"></i> Talk to mentor
                                            </a> */}
                                        </span>
                                    </div>
                                </Grid>
                            );
                        })
                    )
                    : <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center', marginTop: '10%'}}>
                        <p className='no-data-msg'>No mentorship requests 🔍</p>
                    </Grid>
                }
            </Grid>
            <Dialog
                open={dialogIsOpen}
                onClose={() => handleDialogOpen('')}
                fullWidth
            >   
                <DialogTitle>Mark request as closed?</DialogTitle>
                <DialogActions>
                    <div className='dialog-btns'>
                        <button onClick={() => handleDialogOpen('')}>Cancel</button>
                        <button onClick={markAsClosed}>Confirm</button>
                    </div>
                </DialogActions>
            </Dialog>
        </section>
    );
}

export default AdminMentorRequests;