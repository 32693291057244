import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Home/CommunityBanner.css';
import communityIllus from '../../assets/illustrations/home-community-illus.svg';

function CommunityBanner(props) {
    return (
        <section className='CommunityBanner'>
            <div className='CommunityBanner-text'>
                <h1>
                    Become part of our <br />
                    <span>Smart Community</span>
                </h1>
                <button className='community-btn'>
                    <Link to="/discord">Join Community</Link>
                </button>
            </div>
            <div className='CommunityBanner-illus'>
                <img src={communityIllus} alt="community illustration"/>
                <button className='community-btn mobile-view'>
                    <Link to="/discord">Join Community</Link>
                </button>
            </div>
        </section>
    );
}

export default CommunityBanner;