import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import useInputState from '../../hooks/useInputState';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import DetailsModal from './DetailsModal';
import GridViewIcon from '@mui/icons-material/GridView';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
    AFRibbon,
    AFRLeft,
    AFRRight,
    BRemove,
    BResume,
    BShortlist,
    BShortlist1,
    Cleft,
    Container,
    CRhead,
    Cright,
    CRS,
    Date,
    DB1,
    DB2,
    DB3,
    DB4,
    DefineButton,
    EMAILALL,
    Left,
    Middle,
    MSBB,
    MSBody,
    MSBT,
    MSet1,
    MSF,
    MSF1,
    MSF2,
    MSF3,
    MSFoot,
    MSTitle,
    MSTL,
    OPClear,
    Openfilter,
    OPHead,
    OPSet,
    Ribbon,
    Right,
    TabButton,
    TB1,
    TB2,
    TB3,
    TB4,
    Title,
} from './AdminM';
import './AdminM.css';
import '../../styles/Admin/AdminManageJobs.css';
import EmailIcon from '@mui/icons-material/Email';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomDialog, useDialog } from 'react-st-modal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Config from '../../config.json';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    background: '#fff',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    marginTop: '10px',
};

function RatingDialog() {
    const dialog = useDialog();

    const [rating, setRating, resetRating] = useInputState();
    const [comment, setComment, resetComment] = useInputState();

    return (
        <div
            style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
            className="interview-modal"
        >
            <h1>Enter Rating and Comments</h1>
            <input
                type="number"
                value={rating}
                onChange={setRating}
                placeholder="Enter Rating out of 5"
                min={0}
                max={5}
                style={{ width: '50%' }}
            />
            <input
                type="text"
                value={comment}
                onChange={setComment}
                placeholder="Enter Comments"
                size={2}
            />
            <button
                onClick={() => {
                    if (rating && comment)
                        dialog.close({
                            rating,
                            comment,
                        });
                    else alert('Please give comment and rating!');
                }}
            >
                Shortlist
            </button>
        </div>
    );
}

const AdminJobDetails = ({ jobId, closeJob }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState('show');
    const [noshow, setNoshow] = useState('noshow');
    const [container, setContainer] = useState('show');
    const [tab, setTab] = useState('show');
    const [tab1, setTab1] = useState('noshow');
    const [tab2, setTab2] = useState('noshow');
    const [tab3, setTab3] = useState('noshow');
    const [tab4, setTab4] = useState('noshow');
    const [shortl, setShortl] = useState('show');
    const [shortl1, setShortl1] = useState('noshow');
    const [filter, setFilter] = useState('noshow');
    const [currTab, setCurrTab] = useState('all');

    const [jobData, setJobData] = useState({});
    const [degreeFilter, setDegreeFilter, resetDegreeFilter] =
        useInputState('');
    const [skillsFilter, setSkillsFilter, resetSkillsFilter] =
        useInputState('');
    const [
        graduationYearFilter,
        setGraduationYearFilter,
        resetGraduationYearFilter,
    ] = useInputState('');
    const [nameFilter, setNameFilter] = useInputState('');

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleDeleteModalOpen = (e) => {
        setDeleteModalOpen(!deleteModalOpen);
    };
    // Details Modal Data
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: [],
    });

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayPageNav, setDisplayPageNav] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const showPageNav = (e) => {
        setDisplayPageNav(true);
    };

    const hidePageNav = (e) => {
        setDisplayPageNav(false);
    };

    const jobDetails = [
        {
            title: 'Job Title',
            text: jobData && jobData.jobTitle ? jobData.jobTitle : 'N/A',
        },
        {
            title: 'Company',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.companyName
                    : 'N/A',
        },
        {
            title: 'Qualifications',
            text:
                jobData && jobData.qualifications
                    ? jobData.qualifications
                    : 'N/A',
        },
        {
            title: 'Salary',
            text: jobData && jobData.salary ? jobData.salary : 'N/A',
        },
        {
            title: 'Job Role',
            text: jobData && jobData.jobRole ? jobData.jobRole : 'N/A',
        },
        {
            title: 'Job Type',
            text: jobData && jobData.jobType ? jobData.jobType : 'N/A',
        },
        {
            title: 'Number of Openings',
            text: jobData && jobData.numOpenings ? jobData.numOpenings : 'N/A',
        },
        {
            title: 'Job Locations',
            list:
                jobData && jobData.jobLocations ? jobData.jobLocations : 'N/A',
        },
        {
            title: 'Recruiter',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.fullName
                    : 'N/A',
        },
        {
            title: 'Posted At',
            text: jobData && jobData.createdAt ? jobData.createdAt : 'N/A',
            type: 'date',
        },
    ];

    const companyDetails = [
        {
            title: 'Company Name',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.companyName
                    : 'N/A',
        },
        {
            title: 'Recruiter Name',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.fullName
                    : 'N/A',
        },
        {
            title: 'Recruiter Email',
            text:
                jobData && jobData.recruiter ? jobData.recruiter.email : 'N/A',
        },
        {
            title: 'Company Description',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.companyDesc
                    : 'N/A',
        },
        {
            title: 'Company Size',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.companySize
                    : 'N/A',
        },
        {
            title: 'Company Type',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.companyType
                    : 'N/A',
        },
        {
            title: 'Industry',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.industry
                    : 'N/A',
        },
        {
            title: 'Year of Establishment',
            text:
                jobData && jobData.recruiter
                    ? jobData.recruiter.company.yearOfEstd
                    : 'N/A',
        },
    ];

    const handleModalOpen = (e) => {
        if (e.currentTarget.name === 'job') {
            setModalContent({
                title: 'Job Details',
                content: jobDetails,
            });
        } else if (e.currentTarget.name === 'company') {
            setModalContent({
                title: 'Company Details',
                content: companyDetails,
            });
        } else {
            setModalContent({ title: '', content: [] });
        }
        setModalOpen(!modalOpen);
    };

    // Link modal
    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [meetLink, setMeetLink, resetMeetLink] = useInputState('');

    const handleLinkModalOpen = (e) => {
        setLinkModalOpen(!linkModalOpen);
    };

    const [userLinkModalOpen, setUserLinkModalOpen] = useState(false);
    const [resumeModalOpen, setResumeModalOpen] = useState(false);
    const [currResume, setCurrResume] = useState('');
    const [userMeetLink, setUserMeetLink, resetUserMeetLink] =
        useInputState('');
    const [currUserLinkId, setCurrUserLinkId] = useState('');

    const handleUserLinkModalOpen = (e) => {
        setUserLinkModalOpen(true);
        setCurrUserLinkId(e.target.getAttribute('value'));
    };
    const handleUserLinkModalClose = (e) => {
        setUserLinkModalOpen(false);
        setCurrUserLinkId('');
    };

    const handleResumeModalOpen = (e) => {
        setResumeModalOpen(true);
        setCurrResume(e.target.getAttribute('value'));
    };
    const handleResumeModalClose = (e) => {
        setResumeModalOpen(false);
        setCurrResume('');
    };

    const handleLinkSubmit = (e) => {
        if (meetLink !== '') {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const payload = {
                jobId: jobData._id,
                interviewLink: meetLink,
            };
            axios
                .put('/api/admin/interview-link', payload, config)
                .then((res) => {
                    if (res.data.job) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );
                        //setJobData(res.data.job);
                        resetMeetLink();
                        handleLinkModalOpen();
                    }
                })
                .catch((err) => console.dir(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter meet link!',
                    variant: 'warning',
                })
            );
        }
    };

    const handleDeleteJob = (e) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .delete(`/api/admin/job/${jobData._id}`, config)
            .then((res) => {
                if (res.data.msg === 'Job deleted successfully!') {
                    dispatch(
                        handleSnackOpen({
                            message: res.data.msg,
                            variant: 'success',
                        })
                    );
                    handleDeleteModalOpen();
                    closeJob();
                }
            })
            .catch((err) => console.dir(err));
    };

    const handleUserLinkSubmit = (e) => {
        if (userMeetLink !== '') {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const payload = {
                jobId: jobData._id,
                interviewLink: userMeetLink,
                action: 'user-interview',
                userId: currUserLinkId,
            };
            axios
                .put('/api/admin/interview-link', payload, config)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );
                        resetUserMeetLink();
                        handleUserLinkModalClose();
                        getJobData();
                    }
                })
                .catch((err) => console.dir(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter meet link!',
                    variant: 'warning',
                })
            );
        }
    };

    const getJobData = () => {
        let filters = {};
        if (degreeFilter !== '') filters.degree = degreeFilter;
        if (skillsFilter !== '') filters.skills = skillsFilter;
        if (graduationYearFilter !== '')
            filters.graduationYear = graduationYearFilter;
        if (nameFilter !== '' && nameFilter.length > 3)
            filters.name = nameFilter;

        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .post(
                `/api/admin/jobs/${jobId}`,
                { filters, candidateType: 'all' },
                config
            )
            .then((res) => {
                if (res.data.jobData) setJobData(res.data.jobData);
            })
            .catch((err) => console.log(err.response));
    };

    useEffect(() => {
        getJobData();
    }, [degreeFilter, skillsFilter, graduationYearFilter, nameFilter, tab]);
    console.log(jobData);
    function nextdata() {
        setShow('noshow');
        setNoshow('show');
    }

    function prevdata() {
        setShow('show');
        setNoshow('noshow');
    }

    function changetab1(e) {
        setTab('noshow');
        setTab1('show');
        setTab2('noshow');
        setTab3('noshow');
        setTab4('noshow');
        setCurrTab(e.currentTarget.getAttribute('name'));
    }

    function changetab2(e) {
        setTab1('noshow');
        setTab('show');
        setTab2('noshow');
        setTab3('noshow');
        setTab4('noshow');
        setCurrTab(e.currentTarget.getAttribute('name'));
    }

    function changetab3(e) {
        setTab('noshow');
        setTab2('show');
        setTab1('noshow');
        setTab3('noshow');
        setTab4('noshow');
        setCurrTab(e.currentTarget.getAttribute('name'));
    }

    function changetab4(e) {
        setTab('noshow');
        setTab2('noshow');
        setTab1('noshow');
        setTab3('show');
        setTab4('noshow');
        setCurrTab(e.currentTarget.getAttribute('name'));
    }

    function changetab5(e) {
        setTab('noshow');
        setTab2('noshow');
        setTab1('noshow');
        setTab3('noshow');
        setTab4('show');
        setCurrTab(e.currentTarget.getAttribute('name'));
    }

    function getSkillTestScore(applicant, short) {
        if (!applicant.testsTaken || applicant.testsTaken.length === 0) {
            return 'Not Given';
        }
        const skillTest = applicant.testsTaken.find(
            (test) => test.test.testType === 'skill test'
        );
        if (!skillTest) return 'Test Not Given';
        if (short)
            return `${skillTest.score}/${skillTest.test.questions.length}`;
        return (
            <div>
                <strong>Score:</strong> {skillTest.score}/
                {skillTest.test.questions.length}
                <br />
                <strong>Test Given:</strong> {skillTest.test.title}
                <br />
                <strong>Total Answered:</strong> {skillTest.answers.length}
            </div>
        );
    }

    function handlePhoneTabChange(e) {
        //console.log(e.currentTarget.getAttribute('name'));

        switch (e.target.value) {
            case 'All candidates':
                e.currentTarget.setAttribute('name', 'all');
                changetab2(e);
                return;
            case 'Shortlisted Candidates':
                e.currentTarget.setAttribute('name', 'shortlisted');
                changetab1(e);
                return;
            case 'Round 2':
                e.currentTarget.setAttribute('name', 'recruiter-shortlisted');
                changetab3(e);
                return;
            case 'Waitlisted':
                e.currentTarget.setAttribute('name', 'waitlisted');
                changetab4(e);
                return;
            case 'Hired':
                e.currentTarget.setAttribute('name', 'hired');
                changetab5(e);
                return;
        }
    }

    function changeshortl() {
        setShortl('noshow');
        setShortl1('show');
    }

    function changeshortl1() {
        setShortl1('noshow');
        setShortl('show');
    }

    function changefilter1() {
        setFilter('show');
        setContainer('noshow');
    }
    function changefilter2() {
        setFilter('noshow');
        setContainer('show');
    }

    const handleActions = async (e) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        let payload = {
            applicantId: e.currentTarget.getAttribute('value'),
            jobId: jobData._id,
            action: e.currentTarget.getAttribute('name'),
        };
        const result = await CustomDialog(<RatingDialog />, {});
        if (!result) return;
        payload = { ...payload, ...result };
        axios
            .put('/api/admin/jobs/actions', payload, config)
            .then((res) => {
                if (res.data.job) {
                    setJobData(res.data.job);
                    dispatch(
                        handleSnackOpen({
                            message: res.data.msg,
                            variant: 'success',
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    let gridView;
    if (currTab === 'all') {
        gridView =
            jobData.applicants && jobData.applicants.length > 0 ? (
                jobData.applicants.map((applicant) => {
                    const isShortlisted = jobData.shortlisted.find(
                        (user) => user._id === applicant._id
                    );
                    const skillTestScore = getSkillTestScore(applicant, false);
                    return (
                        <MSet1 key={applicant._id}>
                            {applicant.adminCreated && (
                                <MSTitle>
                                    <MSTL>
                                        <h1>Created by Admin</h1>
                                    </MSTL>
                                </MSTitle>
                            )}
                            <MSTitle>
                                <MSTL>
                                    <h1>{applicant.fullName}</h1>
                                    <span>{applicant.state}</span>
                                </MSTL>
                            </MSTitle>
                            <MSBody>
                                <MSBT>Email </MSBT>
                                <MSBB>({applicant.email})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Mobile Number </MSBT>
                                <MSBB>({applicant.mobileNo})</MSBB>
                            </MSBody>
                            {applicant.college && (
                                <MSBody>
                                    <MSBT>Education</MSBT>
                                    <MSBB>
                                        {applicant?.college.collegeName},{' '}
                                        {applicant?.college.degree}{' '}
                                        {`(${applicant?.college.startingYear}-${applicant?.college.graduationYear})`}
                                    </MSBB>
                                </MSBody>
                            )}
                            {applicant.experience && (
                                <MSBody>
                                    <MSBT>
                                        Experience (
                                        {applicant.experience.workExperience})
                                    </MSBT>
                                </MSBody>
                            )}
                            {applicant.experience && (
                                <MSBody>
                                    <MSBT>Skills</MSBT>
                                    <MSBB>
                                        {applicant.experience.skills.map(
                                            (s, idx) => (
                                                <React.Fragment key={idx}>
                                                    {`${s.skill} (${s.level})`}
                                                    <br />
                                                </React.Fragment>
                                            )
                                        )}
                                    </MSBB>
                                </MSBody>
                            )}
                            <MSBody>
                                <MSBT>Projects</MSBT>
                                <MSBB>
                                    {applicant.projects.map((project, idx) => (
                                        <React.Fragment key={idx}>
                                            <a
                                                href={project.link}
                                                target="_blank"
                                            >
                                                {project.title}
                                            </a>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Skill Test Data</MSBT>
                                <MSBB>{skillTestScore}</MSBB>
                            </MSBody>
                            <br />
                            <br />
                            <MSFoot>
                                {/* <p>View Full Application</p> */}
                                <MSF>
                                    <div className={tab}>
                                        <MSF1>
                                            <button
                                                type="submit"
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="shortlist"
                                                disabled={isShortlisted}
                                            >
                                                {isShortlisted
                                                    ? 'Shortlisted'
                                                    : 'Shortlist'}
                                            </button>
                                        </MSF1>
                                    </div>
                                    <div className={tab1}>
                                        <MSF2 className={tab1}>
                                            <button
                                                type="submit"
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="shortlist"
                                                disabled={isShortlisted}
                                            >
                                                {isShortlisted
                                                    ? 'Shortlisted'
                                                    : 'Shortlist'}
                                            </button>
                                        </MSF2>
                                    </div>
                                    {/* <div>
                                        <MSF3>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="remove"
                                            >
                                                Remove
                                            </button>
                                        </MSF3>
                                    </div> */}
                                    <BResume>
                                        {applicant.resume &&
                                        applicant.resume.resumeS3FileKey ? (
                                            <a
                                                onClick={handleResumeModalOpen}
                                                value={
                                                    applicant.resume
                                                        .resumeS3FileKey
                                                }
                                                target="_blank"
                                            >
                                                Resume
                                            </a>
                                        ) : (
                                            'No Resume'
                                        )}
                                    </BResume>
                                </MSF>
                            </MSFoot>
                        </MSet1>
                    );
                })
            ) : (
                <p>No applicants yet!</p>
            );
    } else if (currTab === 'shortlisted') {
        gridView =
            jobData.shortlisted && jobData.shortlisted.length > 0 ? (
                jobData.shortlisted.map((applicant) => {
                    const isShortlisted = jobData?.shortlisted?.find(
                        (user) => user._id === applicant._id
                    );
                    const interviewRequested = applicant?.interviews?.find(
                        (interview) => interview.job === jobData._id
                    );
                    const skillTestScore = getSkillTestScore(applicant, false);
                    return (
                        <MSet1 key={applicant._id}>
                            <MSTitle>
                                <MSTL>
                                    <h1>{applicant.fullName}</h1>
                                    <span>{applicant.state}</span>
                                </MSTL>
                            </MSTitle>
                            <MSBody>
                                <MSBT>Email </MSBT>
                                <MSBB>({applicant.email})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Mobile Number </MSBT>
                                <MSBB>({applicant.mobileNo})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Education</MSBT>
                                <MSBB>
                                    {applicant.college.collegeName},{' '}
                                    {applicant.college.degree}{' '}
                                    {`(${applicant.college.startingYear}-${applicant.college.graduationYear})`}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>
                                    Experience (
                                    {applicant.experience.workExperience})
                                </MSBT>
                                {/* <MSBB>
                                    {applicant.experience.experienceDesc &&
                                        applicant.experience.map((exp) => (
                                            <>
                                                {exp.companyName} :{' '}
                                                {exp.description}
                                            </>
                                        ))}
                                </MSBB> */}
                            </MSBody>
                            <MSBody>
                                <MSBT>Skills</MSBT>
                                <MSBB>
                                    {applicant.experience.skills.map(
                                        (s, idx) => (
                                            <React.Fragment key={idx}>
                                                {`${s.skill} (${s.level})`}
                                                <br />
                                            </React.Fragment>
                                        )
                                    )}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Projects</MSBT>
                                <MSBB>
                                    {applicant.projects.map((project, idx) => (
                                        <React.Fragment key={idx}>
                                            <a
                                                href={project.link}
                                                target="_blank"
                                            >
                                                {project.title}
                                            </a>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Skill Test Data</MSBT>
                                <MSBB>{skillTestScore}</MSBB>
                            </MSBody>
                            <MSFoot style={{ margin: '10px' }}>
                                {/* <p>View Full Application</p> */}
                                <MSF>
                                    <div className={tab}>
                                        <MSF1>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="shortlist"
                                                style={{ cursor: 'pointer' }}
                                                disabled={isShortlisted}
                                            >
                                                {isShortlisted
                                                    ? 'Shortlisted'
                                                    : 'Shortlist'}
                                            </button>
                                        </MSF1>
                                    </div>
                                    <div className={tab1}>
                                        <MSF2 className={tab1}>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="shortlist"
                                                style={{ cursor: 'pointer' }}
                                                disabled={isShortlisted}
                                            >
                                                {isShortlisted
                                                    ? 'Shortlisted'
                                                    : 'Shortlist'}
                                            </button>
                                        </MSF2>
                                    </div>
                                    <div>
                                        <MSF3>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="remove"
                                            >
                                                Remove
                                            </button>
                                        </MSF3>
                                    </div>
                                    <div>
                                        <MSF2>
                                            <button
                                                onClick={
                                                    handleUserLinkModalOpen
                                                }
                                                disabled={
                                                    !interviewRequested ||
                                                    (interviewRequested?.interviewLink &&
                                                        interviewRequested.interviewLink !==
                                                            '')
                                                }
                                                style={{
                                                    cursor:
                                                        !interviewRequested ||
                                                        (interviewRequested?.interviewLink &&
                                                            interviewRequested.interviewLink !==
                                                                '')
                                                            ? 'not-allowed'
                                                            : 'pointer',
                                                    marginRight: '10px',
                                                }}
                                                value={applicant._id}
                                            >
                                                {interviewRequested?.interviewLink &&
                                                interviewRequested.interviewLink !==
                                                    ''
                                                    ? 'Interview Scheduled'
                                                    : interviewRequested &&
                                                      (!interviewRequested.interviewLink ||
                                                          interviewRequested.interviewLink ===
                                                              '')
                                                    ? 'Interview Requested'
                                                    : 'Interview Not Requested'}
                                            </button>
                                        </MSF2>
                                    </div>
                                </MSF>
                            </MSFoot>
                        </MSet1>
                    );
                })
            ) : (
                <p>No applicants yet!</p>
            );
    } else if (currTab === 'recruiter-shortlisted') {
        gridView =
            jobData.recruiterShortlisted &&
            jobData.recruiterShortlisted.length > 0 ? (
                jobData.recruiterShortlisted.map((applicant) => {
                    const isShortlisted = jobData.shortlisted.find(
                        (user) => user._id === applicant._id
                    );
                    const interviewRequested = applicant?.interviews?.find(
                        (interview) => interview.job === jobData._id
                    );
                    return (
                        <MSet1 key={applicant._id}>
                            <MSTitle>
                                <MSTL>
                                    <h1>{applicant.fullName}</h1>
                                    <span>{applicant.state}</span>
                                </MSTL>
                            </MSTitle>
                            <MSBody>
                                <MSBT>Email </MSBT>
                                <MSBB>({applicant.email})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Mobile Number </MSBT>
                                <MSBB>({applicant.mobileNo})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Education</MSBT>
                                <MSBB>
                                    {applicant.college.collegeName},{' '}
                                    {applicant.college.degree}{' '}
                                    {`(${applicant.college.startingYear}-${applicant.college.graduationYear})`}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>
                                    Experience (
                                    {applicant.experience.workExperience})
                                </MSBT>
                                {/* <MSBB>
                                    {applicant.experience.experienceDesc}
                                </MSBB> */}
                            </MSBody>
                            <MSBody>
                                <MSBT>Skills</MSBT>
                                <MSBB>
                                    {applicant.experience.skills.map(
                                        (s, idx) => (
                                            <React.Fragment key={idx}>
                                                {`${s.skill} (${s.level})`}
                                                <br />
                                            </React.Fragment>
                                        )
                                    )}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Projects</MSBT>
                                <MSBB>
                                    {applicant.projects.map((project, idx) => (
                                        <React.Fragment key={idx}>
                                            <a
                                                href={project.link}
                                                target="_blank"
                                            >
                                                {project.title}
                                            </a>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </MSBB>
                            </MSBody>
                            <MSFoot>
                                {/* <p>View Full Application</p> */}
                                <MSF>
                                    <div className={tab}>
                                        <MSF1>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="shortlist"
                                                style={{ cursor: 'pointer' }}
                                                disabled={isShortlisted}
                                            >
                                                {isShortlisted
                                                    ? 'Shortlisted'
                                                    : 'Shortlist'}
                                            </button>
                                        </MSF1>
                                    </div>
                                    <div className={tab1}>
                                        <MSF2 className={tab1}>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="shortlist"
                                                style={{ cursor: 'pointer' }}
                                                disabled={isShortlisted}
                                            >
                                                {isShortlisted
                                                    ? 'Shortlisted'
                                                    : 'Shortlist'}
                                            </button>
                                        </MSF2>
                                    </div>
                                    {/* <div>
                                        <MSF3>
                                            <button
                                                type="submit"
                                                onClick={handleActions}
                                                value={applicant._id}
                                                name="remove"
                                            >
                                                Remove
                                            </button>
                                        </MSF3>
                                    </div> */}
                                </MSF>
                                <div>
                                    <MSF2>
                                        <button
                                            onClick={handleUserLinkModalOpen}
                                            disabled={
                                                !interviewRequested ||
                                                (interviewRequested?.interviewLink &&
                                                    interviewRequested.interviewLink !==
                                                        '')
                                            }
                                            style={{
                                                cursor:
                                                    !interviewRequested ||
                                                    (interviewRequested?.interviewLink &&
                                                        interviewRequested.interviewLink !==
                                                            '')
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                                marginRight: '10px',
                                            }}
                                            value={applicant._id}
                                        >
                                            {interviewRequested?.interviewLink &&
                                            interviewRequested.interviewLink !==
                                                ''
                                                ? 'Interview Scheduled'
                                                : interviewRequested &&
                                                  (!interviewRequested.interviewLink ||
                                                      interviewRequested.interviewLink ===
                                                          '')
                                                ? 'Interview Requested'
                                                : 'Interview Not Requested'}
                                        </button>
                                    </MSF2>
                                </div>
                            </MSFoot>
                        </MSet1>
                    );
                })
            ) : (
                <p>No applicants yet!</p>
            );
    } else if (currTab === 'waitlisted') {
        gridView =
            jobData.waitlisted && jobData.waitlisted.length > 0 ? (
                jobData.waitlisted.map((applicant) => {
                    const isShortlisted = jobData.shortlisted.find(
                        (user) => user._id === applicant._id
                    );
                    const interviewRequested = applicant.interviews.find(
                        (interview) => interview.job === jobData._id
                    );
                    return (
                        <MSet1 key={applicant._id}>
                            <MSTitle>
                                <MSTL>
                                    <h1>{applicant.fullName}</h1>
                                    <span>{applicant.state}</span>
                                </MSTL>
                            </MSTitle>
                            <MSBody>
                                <MSBT>Email </MSBT>
                                <MSBB>({applicant.email})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Mobile Number </MSBT>
                                <MSBB>({applicant.mobileNo})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Education</MSBT>
                                <MSBB>
                                    {applicant.college.collegeName},{' '}
                                    {applicant.college.degree}{' '}
                                    {`(${applicant.college.startingYear}-${applicant.college.graduationYear})`}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>
                                    Experience (
                                    {applicant.experience.workExperience})
                                </MSBT>
                                {/* <MSBB>
                                    {applicant.experience.experienceDesc}
                                </MSBB> */}
                            </MSBody>
                            <MSBody>
                                <MSBT>Skills</MSBT>
                                <MSBB>
                                    {applicant.experience.skills.map(
                                        (s, idx) => (
                                            <React.Fragment key={idx}>
                                                {`${s.skill} (${s.level})`}
                                                <br />
                                            </React.Fragment>
                                        )
                                    )}
                                </MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Projects</MSBT>
                                <MSBB>
                                    {applicant.projects.map((project, idx) => (
                                        <React.Fragment key={idx}>
                                            <a
                                                href={project.link}
                                                target="_blank"
                                            >
                                                {project.title}
                                            </a>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </MSBB>
                            </MSBody>
                            <MSFoot>
                                {/* <p>View Full Application</p> */}
                                <MSF>
                                    <div className={tab3}>
                                        <MSF2>
                                            <button
                                                onClick={
                                                    handleUserLinkModalOpen
                                                }
                                                disabled={
                                                    !interviewRequested ||
                                                    (interviewRequested?.interviewLink &&
                                                        interviewRequested.interviewLink !==
                                                            '')
                                                }
                                                style={{
                                                    cursor:
                                                        !interviewRequested ||
                                                        (interviewRequested?.interviewLink &&
                                                            interviewRequested.interviewLink !==
                                                                '')
                                                            ? 'not-allowed'
                                                            : 'pointer',
                                                    marginRight: '10px',
                                                }}
                                                value={applicant._id}
                                            >
                                                {interviewRequested?.interviewLink &&
                                                interviewRequested.interviewLink !==
                                                    ''
                                                    ? 'Interview Scheduled'
                                                    : interviewRequested &&
                                                      (!interviewRequested.interviewLink ||
                                                          interviewRequested.interviewLink ===
                                                              '')
                                                    ? 'Interview Requested'
                                                    : 'Interview Not Requested'}
                                            </button>
                                        </MSF2>
                                    </div>
                                </MSF>
                            </MSFoot>
                        </MSet1>
                    );
                })
            ) : (
                <p>No applicants yet!</p>
            );
    } else if (currTab === 'hired') {
        console.log('in hired');
        gridView =
            jobData.hired && jobData.hired.length > 0 ? (
                jobData.hired.map((applicant) => {
                    return (
                        <MSet1 key={applicant._id}>
                            <MSTitle>
                                <MSTL>
                                    <h1>{applicant.fullName}</h1>
                                    <span>{applicant.state}</span>
                                </MSTL>
                            </MSTitle>
                            <MSBody>
                                <MSBT>Email </MSBT>
                                <MSBB>({applicant.email})</MSBB>
                            </MSBody>
                            <MSBody>
                                <MSBT>Mobile Number </MSBT>
                                <MSBB>({applicant.mobileNo})</MSBB>
                            </MSBody>
                        </MSet1>
                    );
                })
            ) : (
                <p>No one hired yet!</p>
            );
    }

    let candidateList = [],
        userInterviewData = {};

    if (currTab === 'all') {
        candidateList = jobData.applicants;
    } else if (currTab === 'shortlisted') {
        candidateList = jobData.shortlisted;
    } else if (currTab === 'recruiter-shortlisted') {
        candidateList = jobData.recruiterShortlisted;
    } else if (currTab === 'waitlisted') {
        candidateList = jobData.waitlisted;
    } else if (currTab === 'hired') {
        candidateList = jobData.hired;
    }
    if (candidateList && candidateList.length > 0) {
        let userInQuestion = candidateList.find(
            (candidate) => currUserLinkId == candidate._id
        );
        userInterviewData = userInQuestion?.interviews?.find(
            (interview) => interview.job === jobData._id
        );
    }

    return (
        <>
            <section className="AdminJobDetails">
                <Ribbon>
                    <ArrowBackIcon
                        onClick={() => {
                            if (closeJob) closeJob();
                        }}
                        style={{
                            color: 'white',
                            cursor: 'pointer',
                            margin: 'auto',
                        }}
                    />
                    <Left>
                        <Title>
                            {jobData.jobTitle
                                ? `${jobData.jobTitle}, ${moment(
                                      jobData.createdAt
                                  ).format('YYYY')}`
                                : 'Loading...'}
                        </Title>
                        <Date>
                            {moment(jobData.createdAt).format('DD/MM/YYYY')}
                        </Date>
                        {/* <h2 className="interview-selected">
                            Interview Date:{' '}
                            {jobData && jobData.interviewDate
                                ? `${moment(jobData.interviewDate).format(
                                      'LLL'
                                  )} (${
                                      jobData && jobData.interviewDuration
                                          ? jobData.interviewDuration
                                          : 'Duration not specified'
                                  })`
                                : 'Not Selected'}
                        </h2>
                        <h2 className="interview-selected">
                            Interview Link:{' '}
                            {jobData && !jobData.interviewDate ? (
                                'Interview not requested yet.'
                            ) : jobData &&
                              jobData.interviewDate &&
                              !jobData.interviewLink ? (
                                'Interview link not added!'
                            ) : (
                                <a
                                    href={jobData.interviewLink}
                                    target="_blank"
                                    className="link"
                                >
                                    {jobData.interviewLink}
                                </a>
                            )}
                        </h2> */}
                    </Left>
                    <Right>
                        {/* <button
                            className="banner-btn"
                            onClick={handleLinkModalOpen}
                            disabled={!jobData || !jobData.interviewDate}
                            style={{
                                cursor:
                                    !jobData || !jobData.interviewDate
                                        ? 'not-allowed'
                                        : 'pointer',
                            }}
                        >
                            Add Interview Link
                        </button> */}
                        <button
                            className="banner-btn"
                            onClick={handleModalOpen}
                            name="job"
                        >
                            View Details
                        </button>
                        <button
                            className="banner-btn"
                            onClick={handleModalOpen}
                            name="company"
                        >
                            Company Details
                        </button>
                        <a
                            style={{ margin: '0 20px' }}
                            href={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${jobData.description}`}
                            className="banner-btn"
                        >
                            Download JD
                        </a>
                        <button
                            className="banner-btn"
                            onClick={handleDeleteModalOpen}
                            name="company"
                        >
                            Delete Job
                        </button>
                    </Right>
                </Ribbon>

                <AFRibbon>
                    <AFRLeft>
                        <select onChange={handlePhoneTabChange}>
                            <option>All candidates</option>
                            <option>Shortlisted Candidates</option>
                            <option>Round 2</option>
                            <option>Waitlisted</option>
                            <option>Hired</option>
                        </select>
                    </AFRLeft>
                    <AFRRight onClick={changefilter1}>Filter</AFRRight>
                </AFRibbon>

                <Openfilter className={filter}>
                    <OPHead>
                        <h1>Filter Candidates</h1>
                        <i onClick={changefilter2}>
                            <CloseIcon />
                        </i>
                    </OPHead>
                    <OPSet>
                        <h1>Degree</h1>
                        <input
                            type="text"
                            placeholder="Eg: MBA"
                            value={degreeFilter}
                            onChange={setDegreeFilter}
                        />
                    </OPSet>
                    <OPSet>
                        <h1>Skills</h1>
                        <input
                            type="text"
                            placeholder="Eg: Java"
                            value={skillsFilter}
                            onChange={setSkillsFilter}
                        />
                    </OPSet>
                    <OPSet>
                        <h1>Graduation Year</h1>
                        <input
                            type="text"
                            placeholder="Eg: 2022"
                            value={graduationYearFilter}
                            onChange={setGraduationYearFilter}
                        />
                    </OPSet>
                    <OPSet>
                        <h1>Name</h1>
                        <input
                            type="text"
                            placeholder="Eg: John"
                            value={nameFilter}
                            onChange={setNameFilter}
                        />
                    </OPSet>
                    <OPClear>Clear All</OPClear>
                </Openfilter>

                <Container>
                    <Cleft>
                        <Middle className={container}>
                            <div className={tab}>
                                <TabButton>
                                    <TB3>
                                        <button type="submit" name="all">
                                            All candidates
                                        </button>
                                    </TB3>
                                    <TB4
                                        onClick={changetab1}
                                        name="shortlisted"
                                    >
                                        <button type="submit">
                                            Shortlisted Candidates
                                        </button>
                                    </TB4>
                                    <TB4
                                        onClick={changetab3}
                                        name="recruiter-shortlisted"
                                    >
                                        <button type="submit">Round 2</button>
                                    </TB4>
                                    <TB4 onClick={changetab4} name="waitlisted">
                                        <button type="submit">
                                            Waitlisted
                                        </button>
                                    </TB4>
                                    <TB4 onClick={changetab5} name="hired">
                                        <button type="submit">Hired</button>
                                    </TB4>
                                </TabButton>
                            </div>

                            <div className={tab1}>
                                <TabButton>
                                    <TB1 onClick={changetab2} name="all">
                                        <button type="submit">
                                            All candidates
                                        </button>
                                    </TB1>
                                    <TB2>
                                        <button
                                            type="submit"
                                            name="shortlisted"
                                        >
                                            Shortlisted Candidates
                                        </button>
                                    </TB2>
                                    <TB4
                                        onClick={changetab3}
                                        name="recruiter-shortlisted"
                                    >
                                        <button type="submit">Round 2</button>
                                    </TB4>
                                    <TB4 onClick={changetab4} name="waitlisted">
                                        <button type="submit">
                                            Waitlisted
                                        </button>
                                    </TB4>
                                    <TB4 onClick={changetab5} name="hired">
                                        <button type="submit">Hired</button>
                                    </TB4>
                                </TabButton>
                            </div>

                            <div className={tab2}>
                                <TabButton>
                                    <TB1 onClick={changetab2} name="all">
                                        <button type="submit">
                                            All candidates
                                        </button>
                                    </TB1>
                                    <TB4
                                        onClick={changetab1}
                                        name="shortlisted"
                                    >
                                        <button type="submit">
                                            Shortlisted Candidates
                                        </button>
                                    </TB4>
                                    <TB2
                                        onClick={changetab3}
                                        name="recruiter-shortlisted"
                                    >
                                        <button type="submit">Round 2</button>
                                    </TB2>
                                    <TB4 onClick={changetab4} name="waitlisted">
                                        <button type="submit">
                                            Waitlisted
                                        </button>
                                    </TB4>
                                    <TB4 onClick={changetab5} name="hired">
                                        <button type="submit">Hired</button>
                                    </TB4>
                                </TabButton>
                            </div>

                            <div className={tab3}>
                                <TabButton>
                                    <TB1 onClick={changetab2} name="all">
                                        <button type="submit">
                                            All candidates
                                        </button>
                                    </TB1>
                                    <TB4
                                        onClick={changetab1}
                                        name="shortlisted"
                                    >
                                        <button type="submit">
                                            Shortlisted Candidates
                                        </button>
                                    </TB4>
                                    <TB4
                                        onClick={changetab3}
                                        name="recruiter-shortlisted"
                                    >
                                        <button type="submit">Round 2</button>
                                    </TB4>
                                    <TB2 onClick={changetab4} name="waitlisted">
                                        <button type="submit">
                                            Waitlisted
                                        </button>
                                    </TB2>
                                    <TB4 onClick={changetab5} name="hired">
                                        <button type="submit">Hired</button>
                                    </TB4>
                                </TabButton>
                            </div>

                            <div className={tab4}>
                                <TabButton>
                                    <TB1 onClick={changetab2} name="all">
                                        <button type="submit">
                                            All candidates
                                        </button>
                                    </TB1>
                                    <TB4
                                        onClick={changetab1}
                                        name="shortlisted"
                                    >
                                        <button type="submit">
                                            Shortlisted Candidates
                                        </button>
                                    </TB4>
                                    <TB4
                                        onClick={changetab3}
                                        name="recruiter-shortlisted"
                                    >
                                        <button type="submit">Round 2</button>
                                    </TB4>
                                    <TB4 onClick={changetab4} name="waitlisted">
                                        <button type="submit">
                                            Waitlisted
                                        </button>
                                    </TB4>
                                    <TB2 onClick={changetab5} name="hired">
                                        <button type="submit">Hired</button>
                                    </TB2>
                                </TabButton>
                            </div>

                            <div className={show}>
                                <DefineButton>
                                    <DB1>
                                        <DescriptionOutlinedIcon />
                                    </DB1>
                                    <DB4 onClick={nextdata}>
                                        <GridViewIcon />
                                    </DB4>
                                </DefineButton>
                            </div>
                            <div className={noshow}>
                                <DefineButton>
                                    <DB3 onClick={prevdata}>
                                        <DescriptionOutlinedIcon />
                                    </DB3>
                                    <DB2>
                                        <GridViewIcon />
                                    </DB2>
                                </DefineButton>
                            </div>

                            <div className={`${show} all-candidates-view1`}>
                                {gridView}
                            </div>
                            <div className={noshow}>
                                <div className={tab}>
                                    <table>
                                        <tr className="mainheadd">
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    name="listview"
                                                    className="chbx"
                                                />
                                            </th>
                                            <th>Name</th>
                                            <th>Mobile No</th>
                                            {/* <th>Passing Year</th> */}
                                            <th>Resume</th>
                                            <th>Remark</th>
                                            <th>Rating</th>
                                            <th>Skill Test Score</th>
                                            <th>Shortlist</th>
                                            {/* <th>Remove</th> */}
                                        </tr>
                                        {jobData.applicants &&
                                        jobData.applicants.length > 0
                                            ? jobData.applicants.map(
                                                  (applicant) => {
                                                      const isShortlisted =
                                                          jobData.shortlisted.find(
                                                              (user) =>
                                                                  user._id ===
                                                                  applicant._id
                                                          );
                                                      const foundRating =
                                                          applicant.ratings &&
                                                          applicant.ratings
                                                              .length > 0
                                                              ? applicant.ratings.find(
                                                                    (r) =>
                                                                        r.job ===
                                                                        jobId
                                                                )
                                                              : null;
                                                      const skillTestScore =
                                                          getSkillTestScore(
                                                              applicant,
                                                              true
                                                          );
                                                      return (
                                                          <tr
                                                              key={
                                                                  applicant._id
                                                              }
                                                              style={{
                                                                  background:
                                                                      applicant.adminCreated &&
                                                                      'grey',
                                                              }}
                                                          >
                                                              <td>
                                                                  <input
                                                                      type="checkbox"
                                                                      name="listview"
                                                                      className="chbx"
                                                                  />
                                                              </td>
                                                              <td>
                                                                  <div>
                                                                      <span>
                                                                          {
                                                                              applicant.fullName
                                                                          }
                                                                      </span>
                                                                      {/* <p>
                                                                          {
                                                                              applicant.mobileNo
                                                                          }
                                                                      </p> */}
                                                                  </div>
                                                              </td>
                                                              <td>
                                                                  {
                                                                      applicant.mobileNo
                                                                  }
                                                              </td>
                                                              {/* <td>
                                                                  {
                                                                      applicant
                                                                          .college
                                                                          .graduationYear
                                                                  }
                                                              </td> */}
                                                              <td>
                                                                  <BResume>
                                                                      {applicant.resume &&
                                                                      applicant
                                                                          .resume
                                                                          .resumeS3FileKey ? (
                                                                          <a
                                                                              onClick={
                                                                                  handleResumeModalOpen
                                                                              }
                                                                              value={
                                                                                  applicant
                                                                                      .resume
                                                                                      .resumeS3FileKey
                                                                              }
                                                                              target="_blank"
                                                                          >
                                                                              Resume
                                                                          </a>
                                                                      ) : (
                                                                          'No Resume'
                                                                      )}
                                                                  </BResume>
                                                              </td>
                                                              <td>
                                                                  {foundRating
                                                                      ? foundRating
                                                                            .comment
                                                                            .length >
                                                                        50
                                                                          ? `${foundRating.comment.substring(
                                                                                0,
                                                                                50
                                                                            )}...`
                                                                          : foundRating.comment
                                                                      : 'No Remarks'}
                                                              </td>
                                                              <td>
                                                                  {foundRating
                                                                      ? `${foundRating.rating}/5`
                                                                      : '0/5'}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      skillTestScore
                                                                  }
                                                              </td>
                                                              <td>
                                                                  <div
                                                                      className={
                                                                          shortl
                                                                      }
                                                                  >
                                                                      <BShortlist
                                                                          onClick={
                                                                              handleActions
                                                                          }
                                                                          value={
                                                                              applicant._id
                                                                          }
                                                                          style={{
                                                                              cursor: 'pointer',
                                                                          }}
                                                                          name="shortlist"
                                                                          disabled={
                                                                              isShortlisted
                                                                          }
                                                                      >
                                                                          {isShortlisted
                                                                              ? 'ShortListed'
                                                                              : 'Shortlist'}
                                                                      </BShortlist>
                                                                  </div>
                                                              </td>
                                                              {/* <td>
                                                                  <BRemove
                                                                      onClick={
                                                                          handleActions
                                                                      }
                                                                      name="remove"
                                                                      value={
                                                                          applicant._id
                                                                      }
                                                                  >
                                                                      Remove
                                                                  </BRemove>
                                                              </td> */}
                                                          </tr>
                                                      );
                                                  }
                                              )
                                            : 'No applicants yet!'}
                                    </table>
                                </div>

                                <div className={tab1}>
                                    <table>
                                        <tr className="mainheadd">
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    name="listview"
                                                    className="chbx"
                                                />
                                            </th>
                                            <th>Name</th>
                                            <th>Mobile No</th>
                                            {/* <th>Passing Year</th> */}
                                            <th>Resume</th>
                                            <th>Remark</th>
                                            <th>Rating</th>
                                            <th>Skill Test Score</th>
                                            <th>Shortlist</th>
                                            <th>Interview Schedule</th>
                                            <th>Remove</th>
                                        </tr>
                                        {jobData.shortlisted &&
                                        jobData.shortlisted.length > 0
                                            ? jobData.shortlisted.map(
                                                  (applicant) => {
                                                      const interviewRequested =
                                                          applicant?.interviews?.find(
                                                              (interview) =>
                                                                  interview.job ===
                                                                  jobData._id
                                                          );
                                                      const foundRating =
                                                          applicant.ratings &&
                                                          applicant.ratings
                                                              .length > 0
                                                              ? applicant.ratings.find(
                                                                    (r) =>
                                                                        r.job ===
                                                                        jobId
                                                                )
                                                              : null;
                                                      const skillTestScore =
                                                          getSkillTestScore(
                                                              applicant,
                                                              true
                                                          );
                                                      return (
                                                          <tr
                                                              key={
                                                                  applicant._id
                                                              }
                                                          >
                                                              <td>
                                                                  <input
                                                                      type="checkbox"
                                                                      name="listview"
                                                                      className="chbx"
                                                                  />
                                                              </td>
                                                              <td>
                                                                  {
                                                                      applicant.fullName
                                                                  }
                                                                  {/* <div>
                                                                  <span>
                                                                      {
                                                                          applicant.fullName
                                                                      }
                                                                  </span>
                                                                  <p>
                                                                      {
                                                                          applicant.mobileNo
                                                                      }
                                                                  </p>
                                                              </div> */}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      applicant.mobileNo
                                                                  }
                                                              </td>
                                                              {/* <td>
                                                              {
                                                                  applicant
                                                                      .college
                                                                      .graduationYear
                                                              }
                                                          </td> */}
                                                              <td>
                                                                  <BResume>
                                                                      {applicant.resume &&
                                                                      applicant
                                                                          .resume
                                                                          .resumeS3FileKey ? (
                                                                          <a
                                                                              onClick={
                                                                                  handleResumeModalOpen
                                                                              }
                                                                              value={
                                                                                  applicant
                                                                                      .resume
                                                                                      .resumeS3FileKey
                                                                              }
                                                                              target="_blank"
                                                                          >
                                                                              Resume
                                                                          </a>
                                                                      ) : (
                                                                          'No Resume'
                                                                      )}
                                                                  </BResume>
                                                              </td>
                                                              <td>
                                                                  {foundRating
                                                                      ? foundRating
                                                                            .comment
                                                                            .length >
                                                                        50
                                                                          ? `${foundRating.comment.substring(
                                                                                0,
                                                                                50
                                                                            )}...`
                                                                          : foundRating.comment
                                                                      : 'No Remarks'}
                                                              </td>
                                                              <td>
                                                                  {foundRating
                                                                      ? `${foundRating.rating}/5`
                                                                      : '0/5'}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      skillTestScore
                                                                  }
                                                              </td>
                                                              <td>
                                                                  <BShortlist1>
                                                                      Shortlisted
                                                                  </BShortlist1>
                                                              </td>
                                                              <td>
                                                                  <MSF2>
                                                                      <button
                                                                          onClick={
                                                                              handleUserLinkModalOpen
                                                                          }
                                                                          disabled={
                                                                              !interviewRequested ||
                                                                              (interviewRequested?.interviewLink &&
                                                                                  interviewRequested.interviewLink !==
                                                                                      '')
                                                                          }
                                                                          style={{
                                                                              cursor:
                                                                                  !interviewRequested ||
                                                                                  (interviewRequested?.interviewLink &&
                                                                                      interviewRequested.interviewLink !==
                                                                                          '')
                                                                                      ? 'not-allowed'
                                                                                      : 'pointer',
                                                                              marginRight:
                                                                                  '10px',
                                                                          }}
                                                                          value={
                                                                              applicant._id
                                                                          }
                                                                      >
                                                                          {interviewRequested?.interviewLink &&
                                                                          interviewRequested.interviewLink !==
                                                                              ''
                                                                              ? 'Interview Scheduled'
                                                                              : interviewRequested &&
                                                                                (!interviewRequested.interviewLink ||
                                                                                    interviewRequested.interviewLink ===
                                                                                        '')
                                                                              ? 'Interview Requested'
                                                                              : 'Interview Not Requested'}
                                                                      </button>
                                                                  </MSF2>
                                                              </td>
                                                              <td>
                                                                  <BRemove
                                                                      onClick={
                                                                          handleActions
                                                                      }
                                                                      value={
                                                                          applicant._id
                                                                      }
                                                                      name="remove"
                                                                  >
                                                                      Remove
                                                                  </BRemove>
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                              )
                                            : 'No shortlisted candidates yet!'}
                                    </table>
                                </div>

                                <div className={tab2}>
                                    <br />
                                    <br />
                                    {/* <EMAILALL>
                                        <button type="submit">
                                            <i>
                                                <EmailIcon />
                                            </i>
                                            Mail All
                                        </button>
                                    </EMAILALL> */}
                                    <table>
                                        <tr className="mainheadd1">
                                            <th className="sno">S.no.</th>
                                            <th className="name">Name</th>
                                            <th className="phnumber">
                                                Phone Number
                                            </th>
                                            <th className="email">Email</th>
                                        </tr>
                                        {jobData.recruiterShortlisted &&
                                        jobData.recruiterShortlisted.length > 0
                                            ? jobData.recruiterShortlisted.map(
                                                  (applicant, idx) => (
                                                      <tr
                                                          className="tab2desc"
                                                          key={applicant._id}
                                                      >
                                                          <td>{idx + 1}</td>
                                                          <td>
                                                              <div>
                                                                  <span>
                                                                      {
                                                                          applicant.fullName
                                                                      }
                                                                  </span>
                                                              </div>
                                                          </td>
                                                          <td>
                                                              {
                                                                  applicant.mobileNo
                                                              }
                                                          </td>
                                                          <td>
                                                              {applicant.email}
                                                          </td>
                                                      </tr>
                                                  )
                                              )
                                            : 'No shortlisted candidates yet!'}
                                    </table>
                                </div>
                                <div className={tab3}>
                                    <table>
                                        <tr className="mainheadd">
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    name="listview"
                                                    className="chbx"
                                                />
                                            </th>
                                            <th>Name</th>
                                            <th>Mobile No</th>
                                            {/* <th>Passing Year</th> */}
                                            <th>Resume</th>
                                            <th>Remark</th>
                                            <th>Rating</th>
                                            <th>Interview</th>
                                        </tr>
                                        {jobData.waitlisted &&
                                        jobData.waitlisted.length > 0
                                            ? jobData.waitlisted.map(
                                                  (applicant) => {
                                                      const interviewRequested =
                                                          applicant?.interviews?.find(
                                                              (interview) =>
                                                                  interview.job ===
                                                                  jobData._id
                                                          );
                                                      const foundRating =
                                                          applicant.ratings &&
                                                          applicant.ratings
                                                              .length > 0
                                                              ? applicant.ratings.find(
                                                                    (r) =>
                                                                        r.job ===
                                                                        jobId
                                                                )
                                                              : null;
                                                      return (
                                                          <tr
                                                              key={
                                                                  applicant._id
                                                              }
                                                          >
                                                              <td>
                                                                  <input
                                                                      type="checkbox"
                                                                      name="listview"
                                                                      className="chbx"
                                                                  />
                                                              </td>
                                                              <td>
                                                                  {
                                                                      applicant.fullName
                                                                  }
                                                                  {/* <div>
                                                                  <span>
                                                                      {
                                                                          applicant.fullName
                                                                      }
                                                                  </span>
                                                                  <p>
                                                                      {
                                                                          applicant.mobileNo
                                                                      }
                                                                  </p>
                                                              </div> */}
                                                              </td>
                                                              <td>
                                                                  {
                                                                      applicant.mobileNo
                                                                  }
                                                              </td>
                                                              {/* <td>
                                                              {
                                                                  applicant
                                                                      .college
                                                                      .graduationYear
                                                              }
                                                          </td> */}
                                                              <td>
                                                                  <BResume>
                                                                      {applicant.resume &&
                                                                      applicant
                                                                          .resume
                                                                          .resumeS3FileKey ? (
                                                                          <a
                                                                              onClick={
                                                                                  handleResumeModalOpen
                                                                              }
                                                                              value={
                                                                                  applicant
                                                                                      .resume
                                                                                      .resumeS3FileKey
                                                                              }
                                                                              target="_blank"
                                                                          >
                                                                              Resume
                                                                          </a>
                                                                      ) : (
                                                                          'No Resume'
                                                                      )}
                                                                  </BResume>
                                                              </td>
                                                              <td>
                                                                  {foundRating
                                                                      ? foundRating
                                                                            .comment
                                                                            .length >
                                                                        50
                                                                          ? `${foundRating.comment.substring(
                                                                                0,
                                                                                50
                                                                            )}...`
                                                                          : foundRating.comment
                                                                      : 'No Remarks'}
                                                              </td>
                                                              <td>
                                                                  {foundRating
                                                                      ? `${foundRating.rating}/5`
                                                                      : '0/5'}
                                                              </td>
                                                              <td>
                                                                  <MSF2>
                                                                      <button
                                                                          onClick={
                                                                              handleUserLinkModalOpen
                                                                          }
                                                                          disabled={
                                                                              !interviewRequested ||
                                                                              (interviewRequested?.interviewLink &&
                                                                                  interviewRequested.interviewLink !==
                                                                                      '')
                                                                          }
                                                                          style={{
                                                                              cursor:
                                                                                  !interviewRequested ||
                                                                                  (interviewRequested?.interviewLink &&
                                                                                      interviewRequested.interviewLink !==
                                                                                          '')
                                                                                      ? 'not-allowed'
                                                                                      : 'pointer',
                                                                              marginRight:
                                                                                  '10px',
                                                                          }}
                                                                          value={
                                                                              applicant._id
                                                                          }
                                                                      >
                                                                          {interviewRequested?.interviewLink &&
                                                                          interviewRequested.interviewLink !==
                                                                              ''
                                                                              ? 'Interview Scheduled'
                                                                              : interviewRequested &&
                                                                                (!interviewRequested.interviewLink ||
                                                                                    interviewRequested.interviewLink ===
                                                                                        '')
                                                                              ? 'Interview Requested'
                                                                              : 'Interview Not Requested'}
                                                                      </button>
                                                                  </MSF2>
                                                              </td>
                                                          </tr>
                                                      );
                                                  }
                                              )
                                            : 'No shortlisted candidates yet!'}
                                    </table>
                                </div>
                                <div className={tab4}>
                                    <table>
                                        <tr className="mainheadd">
                                            <th>Name</th>
                                            <th>ID</th>
                                            <th>Email</th>
                                            <th>Mobile No</th>
                                        </tr>
                                        {jobData.hired &&
                                        jobData.hired.length > 0
                                            ? jobData.hired.map((applicant) => {
                                                  return (
                                                      <tr key={applicant._id}>
                                                          <td>
                                                              {
                                                                  applicant.fullName
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  applicant.uniqueId
                                                              }
                                                          </td>
                                                          <td>
                                                              {applicant.email}
                                                          </td>
                                                          <td>
                                                              {
                                                                  applicant.mobileNo
                                                              }
                                                          </td>
                                                      </tr>
                                                  );
                                              })
                                            : 'No hired candidates yet!'}
                                    </table>
                                </div>
                            </div>
                        </Middle>
                    </Cleft>

                    <Cright>
                        <CRhead>
                            <span>Filter Candidates</span>
                            <p>Clear all</p>
                        </CRhead>
                        <CRS>
                            <span>Degree</span>
                            <input
                                type="text"
                                placeholder="Eg :MBA"
                                value={degreeFilter}
                                onChange={setDegreeFilter}
                            />
                        </CRS>
                        <CRS>
                            <span>Skills</span>
                            <input
                                type="text"
                                placeholder="Eg :Java"
                                value={skillsFilter}
                                onChange={setSkillsFilter}
                            />
                        </CRS>
                        <CRS>
                            <span>Graduation Year</span>
                            <input
                                type="text"
                                placeholder="Eg :2022"
                                value={graduationYearFilter}
                                onChange={setGraduationYearFilter}
                            />
                        </CRS>
                        <CRS>
                            <span>Name</span>
                            <input
                                type="text"
                                placeholder="Eg: John"
                                value={nameFilter}
                                onChange={setNameFilter}
                            />
                        </CRS>
                    </Cright>
                </Container>
            </section>
            <DetailsModal
                open={modalOpen}
                handleModalOpen={handleModalOpen}
                title={modalContent.title}
                content={modalContent.content}
            />
            <Modal
                open={linkModalOpen}
                onClose={handleLinkModalOpen}
                aria-labelledby="Interview Link"
                aria-describedby="Add interview link"
                style={{ overflow: 'auto', height: '100%' }}
            >
                <Box sx={style}>
                    <div className="interview-modal">
                        <h1>Enter Interview Meet Link</h1>
                        <input
                            type="text"
                            value={meetLink}
                            onChange={setMeetLink}
                            placeholder="Enter Link"
                        />
                        <button onClick={handleLinkSubmit}>Submit</button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={userLinkModalOpen}
                onClose={handleUserLinkModalClose}
                aria-labelledby="Interview Link"
                aria-describedby="Add interview link"
                style={{ overflow: 'auto', height: '100%' }}
            >
                <Box sx={style}>
                    <div className="interview-modal">
                        <h1>Enter Interview Meet Link</h1>
                        <h3>
                            {moment
                                .utc(userInterviewData?.interviewDate)
                                .format('llll')}
                        </h3>
                        <h3>{userInterviewData?.interviewEmail}</h3>
                        <h3>{userInterviewData?.interviewDuration}</h3>
                        <input
                            type="text"
                            value={userMeetLink}
                            onChange={setUserMeetLink}
                            placeholder="Enter Link"
                        />
                        <button onClick={handleUserLinkSubmit}>Submit</button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={resumeModalOpen}
                onClose={handleResumeModalClose}
                aria-labelledby="Resume"
                aria-describedby="View Resume"
                style={{ overflow: 'auto', height: '100%', width: '80vw' }}
            >
                <Box sx={style} style={{ width: '80vw', margin: 'auto' }}>
                    <div className="interview-modal" style={{ width: '75vw' }}>
                        <h1>Resume</h1>
                        <a
                            href={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${currResume}`}
                        >
                            Download File
                        </a>

                        <div
                            className="resume-pdf-container"
                            onMouseEnter={showPageNav}
                            onMouseLeave={hidePageNav}
                            style={{ marginBottom: '20px', marginTop: '20px' }}
                        >
                            <Document
                                file={`${Config.S3_URL}/${currResume}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                className="resume-pdf"
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <div
                                className="page-nav"
                                style={{
                                    display: !displayPageNav ? 'none' : '',
                                }}
                            >
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    <i className="fa-solid fa-chevron-left"></i>
                                </button>
                                <p>
                                    Page {pageNumber || (numPages ? 1 : '--')}{' '}
                                    of {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    <i className="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={deleteModalOpen}
                onClose={handleDeleteModalOpen}
                aria-labelledby="Delete Job"
                aria-describedby="Hide this job permanently!"
                style={{ overflow: 'auto', height: '100%' }}
            >
                <Box sx={style}>
                    <div className="interview-modal">
                        <h1>Delete this job?</h1>
                        <button onClick={handleDeleteJob}>Yes</button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default AdminJobDetails;
