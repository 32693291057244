import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Community.css';
import heroBanner from '../../assets/illustrations/community-hero.svg';
import networkImg1 from '../../assets/images/network-1.png';
import networkImg2 from '../../assets/images/network-2.png';
import networkImg3 from '../../assets/images/network-3.png';
import networkImg4 from '../../assets/images/network-4.png';
import eventBanner from '../../assets/images/community-events-banner.png';
import interactBanner from '../../assets/images/community-banner.png';
import interactBanner2 from '../../assets/images/interact-banner-2.png';
import microsoftLogo from '../../assets/companies/microsoft-logo-purple.svg';
// import mckinseyLogo from '../../assets/companies/mckinsey-logo.svg';
// import sapLogo from '../../assets/companies/sap-logo.svg';
// import amazonLogo from '../../assets/companies/amazon-logo-purple.svg';
import uberLogo from '../../assets/companies/uber-logo-purple.svg';
// import zomatoLogo from '../../assets/companies/zomato-logo.svg';
import googleLogo from '../../assets/companies/google-logo-purple.svg';
import mamaearthLogo from '../../assets/companies/mamaearth-logo.svg';
import goldmanLogo from '../../assets/companies/goldman-logo-purple.svg';
import paytmLogo from '../../assets/companies/paytm-logo-purple.svg';
import ivyLogo from '../../assets/companies/ivy-logo-purple.svg';
import { HashLink } from 'react-router-hash-link';
import PrimaryButton from '../Common/PrimaryButton';
import { FooterContainer, LinksWrap } from '../Footer/FooterComponents';
import {
    GrLinkedinOption,
    GrFacebookOption,
    GrInstagram,
} from 'react-icons/gr';
import { FaDiscord } from 'react-icons/fa';

function Community(props) {
    const [matches, setMatches] = useState(
        window.matchMedia('(max-width: 768px)').matches
    );

    useEffect(() => {
        window
            .matchMedia('(max-width: 768px)')
            .addEventListener('change', (e) => setMatches(e.matches));
    }, []);

    function iOS() {
        return (
            [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }

    return (
        <main className="Community">
            <div className="filler-div"></div>
            <section className="community-hero">
                <div className="hero-banner">
                    <img src={heroBanner} alt="community" />
                </div>
                <div className="hero-text">
                    <h1>
                        Get One Step Ahead By <br />
                        <span>Leveraging Peer Led Learning</span>
                        <br />
                        and <span>Engagement</span>
                    </h1>
                    <button>
                        <Link to="/discord">Join Community</Link>
                    </button>
                </div>
            </section>
            <section className="community-features">
                <p>
                    <i className="fa-solid fa-comments"></i> Meet talented
                    people
                </p>
                <p>
                    <i className="fa-solid fa-chalkboard-user"></i> Get
                    mentorship from experts
                </p>
                <p>
                    <i className="fa-solid fa-lightbulb"></i> Participate in
                    various events/competition
                </p>
            </section>
            <section className="community-network">
                <div className="network-text">
                    <h1>Build Strong Network</h1>
                    <p>
                        Engage with several industry veterans, <br />
                        brainstorm with like minded people, leverage
                        peer-to-peer learning
                    </p>
                    <div className="company-logos">
                        <span style={{ marginBottom: '10px' }}>
                            <img src={microsoftLogo} alt="microsoft" />
                            <img
                                src={googleLogo}
                                alt="google"
                                style={{ height: '100px' }}
                            />
                            {/* <img src={amazonLogo} alt="amazon" style={{width: '140px'}}/> */}
                            <img src={uberLogo} alt="uber" style={{height: '100px'}}/>
                            <p style={{color: '#4544a2'}}>IIMs</p>
                        </span>
                        <span>
                            <img src={goldmanLogo} alt="goldman" />
                            <img
                                src={paytmLogo}
                                alt="paytm"
                                style={{ height: '100px' }}
                            />
                            <img src={ivyLogo} alt="ivy" />
                            <p style={{color: '#4544a2'}}>IITs</p>
                            {/* <img src={mamaearthLogo} alt="mamaearth"/> */}
                        </span>
                    </div>
                </div>
                <div className="network-img">
                    <div className="row1">
                        <img
                            src={networkImg4}
                            alt="network img"
                            className="border linear-repeating"
                        />
                    </div>
                    <div className="row2">
                        <img src={networkImg3} alt="network img" />
                        <img src={networkImg2} alt="network img" />
                    </div>
                    <div className="row3">
                        <img src={networkImg1} alt="network img" />
                    </div>
                </div>
                <div className="network-img mobile-view">
                    <div className="row1" id="row1">
                        <img src={networkImg1} alt="network img" />
                        <img
                            src={networkImg4}
                            alt="network img"
                            style={{ marginBottom: '60px' }}
                        />
                    </div>
                    <div className="row1">
                        <img src={networkImg3} alt="network img" />
                        <img
                            src={networkImg2}
                            alt="network img"
                            style={{ marginBottom: '60px' }}
                        />
                    </div>
                </div>
            </section>
            <section className="community-events">
                <div className="events-text">
                    <h1>
                        Become A Part Of Various <br />
                        <span>Events</span> And <span>Meetups.</span>
                    </h1>
                    <p>
                        Extend your circle and socialise with other <br />
                        community members in exclusive community <br />
                        events and meetups.
                    </p>
                </div>
                <div className="events-banner">
                    <img src={eventBanner} alt="community events" />
                </div>
            </section>
            <section className="community-interact">
                <div className="interact-banner">
                    <img
                        src={!matches ? interactBanner : interactBanner2}
                        alt="join community"
                    />
                    <button>
                        <Link to="/discord">Join Community</Link>
                    </button>
                </div>
                <div className="interact-text">
                    <p>
                        Practical and engaging learning, learn by doing, <br />
                        participate in various live projects and activities to
                        grow <br />
                        holistically with other community members.
                    </p>
                    <h1>
                        Become part of our <span>Smart Community</span>
                    </h1>
                    <button>
                        <Link to="/discord">Join Community</Link>
                    </button>
                </div>
            </section>
            {!iOS() && (
                <FooterContainer>
                    <PrimaryButton>
                        <Link to="/whatsapp">Contact Us</Link>
                    </PrimaryButton>
                    <p className="footer-copyright">
                        © 2022 SuperGrad (A unit of Pre Aspiring Entrepreneur
                        Pvt Ltd)
                    </p>
                    <hr />
                    <LinksWrap>
                        <div></div>
                        <div className="NavLinks">
                            <Link to="/candidate/register">
                                <p>Register</p>
                            </Link>
                            <HashLink to="/#about">
                                <p>About Us</p>
                            </HashLink>
                        </div>
                        <div className="SocialLinks">
                            <a
                                href="/linkedin"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <GrLinkedinOption />
                            </a>
                            <a
                                href="/facebook"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <GrFacebookOption />
                            </a>
                            <a
                                href="/instagram"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <GrInstagram />
                            </a>
                            <a href="/discord" target="_blank" rel="noreferrer">
                                <FaDiscord />
                            </a>
                        </div>
                    </LinksWrap>
                </FooterContainer>
            )}
        </main>
    );
}

export default Community;
