import React, { useState } from 'react';

const Terms = ({}) => {
    return (
        <div>
            <div className="filler-div"></div>
            <h1
                style={{
                    textAlign: 'center',
                    marginBottom: '1.5rem',
                    marginTop: '2rem',
                }}
            >
                TERMS OF SERVICE AGREEMENT
            </h1>
            <div
                style={{ maxWidth: '80vw', margin: 'auto' }}
                className="terms-container"
            >
                <p>
                    <p>LAST REVISION: 27-05-2022</p> <br />
                   
                   <p>PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY
                    USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE
                    YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF
                    THIS AGREEMENT.</p> 
                    <br></br>
                    <p>
                    This Terms of Service Agreement (the "Agreement") governs
                    your use of this website, www.supergrad.co.in (the
                    "Website"), Supergrad ("Business Name") offer of products
                    for purchase on this Website, or your purchase of products
                    available on this Website. This Agreement includes, and
                    incorporates by this reference, the policies and guidelines
                    referenced below. Supergrad reserves the right to change or
                    revise the terms and conditions of this Agreement at any
                    time by posting any changes or a revised Agreement on this
                    Website. Supergrad will alert you that changes or revisions
                    have been made by indicating on the top of this Agreement
                    the date it was last revised. The changed or revised
                    Agreement will be effective immediately after it is posted
                    on this Website. Your use of the Website following the
                    posting any such changes or of a revised Agreement will
                    constitute your acceptance of any such changes or revisions.
                    Supergrad encourages you to review this Agreement whenever
                    you visit the Website to make sure that you understand the
                    terms and conditions governing use of the Website. This
                    Agreement does not alter in any way the terms or conditions
                    of any other written agreement you may have with Supergrad
                    for other products or services. If you do not agree to this
                    Agreement (including any referenced policies or guidelines),
                    please immediately terminate your use of the Website. If you
                    would like to print this Agreement, please click the print
                    button on your browser toolbar.</p> <br />
                </p>

                <h3>Products</h3> <br />
                <p>
                    Terms of Offer. This Website offers for sale certain
                    products (the "Products"). By placing an order for Products
                    through this Website, you agree to the terms set forth in
                    this Agreement. Customer Solicitation: Unless you notify our
                    third party call center reps or direct Supergrad sales reps,
                    while they are calling you, of your desire to opt out from
                    further direct company communications and solicitations, you
                    are agreeing to continue to receive further emails and call
                    solicitations Supergrad and its designated in house or third
                    party call team(s). Opt Out Procedure: We provide 3 easy
                    ways to opt out of from future solicitations. 1. You may use
                    the opt out link found in any email solicitation that you
                    may receive. 2. You may also choose to opt out, via sending
                    your email address to: info@supergrad.co.in 3. You may send
                    a written remove request to 792, Sector 40, Gurugram,
                    Haryana. Proprietary Rights. Supergrad has proprietary
                    rights and trade secrets in the Products. You may not copy,
                    reproduce, resell or redistribute any Product manufactured
                    and/or distributed by Supergrad. Supergrad also has rights
                    to all trademarks and trade dress and specific layouts of
                    this webpage, including calls to action, text placement,
                    images and other information. Sales Tax. If you purchase any
                    Products, you will be responsible for paying any applicable
                    sales tax.
                </p><br />

                <h3>Website</h3><br />
                <p>
                    Content; Intellectual Property; Third Party Links. In
                    addition to making Products available, this Website also
                    offers information and marketing materials. This Website
                    also offers information, both directly and through indirect
                    links to third-party websites, about nutritional and dietary
                    supplements. Supergrad does not always create the
                    information offered on this Website; instead the information
                    is often gathered from other sources. To the extent that
                    Supergrad does create the content on this Website, such
                    content is protected by intellectual property laws of the
                    India, foreign nations, and international bodies.
                    Unauthorized use of the material may violate copyright,
                    trademark, and/or other laws. You acknowledge that your use
                    of the content on this Website is for personal,
                    noncommercial use. Any links to third- party websites are
                    provided solely as a convenience to you. Supergrad does not
                    endorse the contents on any such third-party websites.
                    Supergrad is not responsible for the content of or any
                    damage that may result from your access to or reliance on
                    these third-party websites. If you link to third-party
                    websites, you do so at your own risk. Use of Website;
                    Supergrad is not responsible for any damages resulting from
                    use of this website by anyone. You will not use the Website
                    for illegal purposes. You will (1) abide by all applicable
                    local, state, national, and international laws and
                    regulations in your use of the Website (including laws
                    regarding intellectual property), (2) not interfere with or
                    disrupt the use and enjoyment of the Website by other users,
                    (3) not resell material on the Website, (4) not engage,
                    directly or indirectly, in transmission of "spam", chain
                    letters, junk mail or any other type of unsolicited
                    communication, and (5) not defame, harass, abuse, or disrupt
                    other users of the Website License. By using this Website,
                    you are granted a limited, non-exclusive, non- transferable
                    right to use the content and materials on the Website in
                    connection with your normal, noncommercial, use of the
                    Website. You may not copy, reproduce, transmit, distribute,
                    or create derivative works of such content or information
                    without express written authorization from Supergrad or the
                    applicable third party (if third party content is at issue).
                    Posting. By posting, storing, or transmitting any content on
                    the Website, you hereby grant Supergrad a perpetual,
                    worldwide, non-exclusive, royalty-free, assignable, right
                    and license to use, copy, display, perform, create
                    derivative works from, distribute, have distributed,
                    transmit and assign such content in any form, in all media
                    now known or hereinafter created, anywhere in the world.
                    Supergrad does not have the ability to control the nature of
                    the user-generated content offered through the Website. You
                    are solely responsible for your interactions with other
                    users of the Website and any content you post. Supergrad is
                    not liable for any damage or harm resulting from any posts
                    by or interactions between users. Supergrad reserves the
                    right, but has no obligation, to monitor interactions
                    between and among users of the Website and to remove any
                    content Supergrad deems objectionable, in MuscleUP Nutrition
                    's sole discretion.
                </p><br />

                <h3>DISCLAIMER OF WARRANTIES</h3><br />
                <p>
                    YOUR USE OF THIS WEBSITE AND/OR PRODUCTS ARE AT YOUR SOLE
                    RISK. THE WEBSITE AND PRODUCTS ARE OFFERED ON AN "AS IS" AND
                    "AS AVAILABLE" BASIS. Supergrad EXPRESSLY DISCLAIMS ALL
                    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                    INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                    NON-INFRINGEMENT WITH RESPECT TO THE PRODUCTS OR WEBSITE
                    CONTENT, OR ANY RELIANCE UPON OR USE OF THE WEBSITE CONTENT
                    OR PRODUCTS. ("PRODUCTS" INCLUDE TRIAL PRODUCTS.) WITHOUT
                    LIMITING THE GENERALITY OF THE FOREGOING, Supergrad MAKES NO
                    WARRANTY: THAT THE INFORMATION PROVIDED ON THIS WEBSITE IS
                    ACCURATE, RELIABLE, COMPLETE, OR TIMELY. THAT THE LINKS TO
                    THIRD-PARTY WEBSITES ARE TO INFORMATION THAT IS ACCURATE,
                    RELIABLE, COMPLETE, OR TIMELY. NO ADVICE OR INFORMATION,
                    WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THIS WEBSITE
                    WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. AS TO
                    THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                    PRODUCTS OR THAT DEFECTS IN PRODUCTS WILL BE CORRECTED.
                    REGARDING ANY PRODUCTS PURCHASED OR OBTAINED THROUGH THE
                    WEBSITE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
                    CERTAIN WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT
                    APPLY TO YOU.
                </p><br />

                <h3>LIMITATION OF LIABILITY</h3><br />
                <p>
                    SupergradENTIRE LIABILITY, AND YOUR EXCLUSIVE REMEDY, IN
                    LAW, IN EQUITY, OR OTHWERWISE, WITH RESPECT TO THE WEBSITE
                    CONTENT AND PRODUCTS AND/OR FOR ANY BREACH OF THIS AGREEMENT
                    IS SOLELY LIMITED TO THE AMOUNT YOU PAID, LESS SHIPPING AND
                    HANDLING, FOR PRODUCTS PURCHASED VIA THE WEBSITE. Supergrad
                    WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                    SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THIS
                    AGREEMENT OR THE PRODUCTS IN ANY MANNER, INCLUDING
                    LIABILITIES RESULTING FROM (1) THE USE OR THE INABILITY TO
                    USE THE WEBSITE CONTENT OR PRODUCTS; (2) THE COST OF
                    PROCURING SUBSTITUTE PRODUCTS OR CONTENT; (3) ANY PRODUCTS
                    PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH
                    THE WEBSITE; OR (4) ANY LOST PROFITS YOU ALLEGE. SOME
                    JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
                    LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF
                    THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p><br />

                <h3>INDEMNIFICATION</h3><br />
                <p>
                    You will release, indemnify, defend and hold harmless
                    Supergrad, and any of its contractors, agents, employees,
                    officers, directors, shareholders, affiliates and assigns
                    from all liabilities, claims, damages, costs and expenses,
                    including reasonable attorneys' fees and expenses, of third
                    parties relating to or arising out of (1) this Agreement or
                    the breach of your warranties, representations and
                    obligations under this Agreement; (2) the Website content or
                    your use of the Website content; (3) the Products or your
                    use of the Products (including Trial Products); (4) any
                    intellectual property or other proprietary right of any
                    person or entity; (5) your violation of any provision of
                    this Agreement; or (6) any information or data you supplied
                    to Supergrad. When Supergrad is threatened with suit or sued
                    by a third party, Supergrad may seek written assurances from
                    you concerning your promise to indemnify Supergrad; your
                    failure to provide such assurances may be considered by
                    Supergrad to be a material breach of this Agreement.
                    Supergrad will have the right to participate in any defense
                    by you of a third- party claim related to your use of any of
                    the Website content or Products, with counsel of Supergrad
                    choice at its expense. Supergrad will reasonably cooperate
                    in any defense by you of a third-party claim at your request
                    and expense. You will have sole responsibility to defend
                    Supergrad against any claim, but you must receive Supergrad
                    prior written consent regarding any related settlement. The
                    terms of this provision will survive any termination or
                    cancellation of this Agreement or your use of the Website or
                    Products.
                </p><br />

                <h3>PRIVACY</h3><br />
                <p>
                    Supergrad believes strongly in protecting user privacy and
                    providing you with notice of MuscleUP Nutrition 's use of
                    data. Please refer to Supergrad’s privacy policy,
                    incorporated by reference herein, that is posted on the
                    Website.
                </p><br />

                <h3>AGREEMENT TO BE BOUND</h3><br />
                <p>
                    By using this Website or ordering Products, you acknowledge
                    that you have read and agree to be bound by this Agreement
                    and all terms and conditions on this Website.
                </p><br />

                <h3>General</h3><br />
                <p>
                    By using this Website or ordering Products, you acknowledge
                    that you have read and agree to be bound by this Agreement
                    and all terms and conditions on this Website.
                </p> <br />

            </div>
        </div>
    );
};

export default Terms;
