import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Login } from '../../api/requests';
import registerFormSchema from '../../lib/validation/registerationSchema';
import { setToken, setUser, setUserType } from '../../store/auth/authStatus';
import {
    closeLoginModal,
    loginFail,
    loginPending,
    loginSuccess,
    updateEmail,
    updatePassword,
} from '../../store/auth/Login';
import { handleSnackOpen } from '../../store/Toast/Toast';
import FacebookButton from '../Common/FacebookButton';
import GoogleButton from '../Common/GoogleButton';
import { LoginTab } from './AuthComponents';
import LoginForm from './LoginForm';

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};

export const CandidateLogin = () => {
    const { email, password, isLoading } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        const data = {
            email: email,
            password: password,
        };

        const { error } = registerFormSchema.validate(data);

        if (error) {
            dispatch(loginFail(error.message));
            dispatch(
                handleSnackOpen({
                    message: error.message,
                    variant: 'error',
                })
            );
            return;
        }

        dispatch(loginPending());

        const response = await Login({
            email: email,
            password: password,
            userType: 'candidate',
        });

        if (response.success) {
            dispatch(setToken(response.data.token));
            dispatch(setUser(response.data.user));
            dispatch(setUserType('candidate'));
            dispatch(loginSuccess());
            dispatch(
                handleSnackOpen({
                    message: 'You have been successfully logged in!',
                    variant: 'success',
                    title: 'Candidate login successful',
                })
            );
            dispatch(closeLoginModal());
            if (
                response.data.user &&
                response.data.user.profileCompleted === true
            ) {
                navigate('/dashboard');
            } else {
                navigate('/candidate/profile/edit');
            }
        } else {
            dispatch(loginFail(response.data.msg));
            dispatch(
                handleSnackOpen({
                    message: response.data.msg,
                    variant: 'error',
                    title: 'Candidate login failed',
                })
            );
        }
    };

    return (
        <LoginTab>
            <h1 className="r-heading">Candidate Login</h1>
            <div className="fg-registration">
                <GoogleButton userType="user">Sign in with Google</GoogleButton>
                {/* <FacebookButton userType="user">
                    Sign up with Facebook
                </FacebookButton> */}
            </div>
            <div className="or-symbol">- OR -</div>
            <LoginForm
                email={email}
                password={password}
                setEmail={(email) => dispatch(updateEmail(email))}
                setPassword={(password) => dispatch(updatePassword(password))}
                isLoading={isLoading}
                onSubmit={onSubmit}
            />
            {/* <p>
                    <span className="inline-button">Sign up</span> with Phone
                    Number
                </p> */}
            <hr />
            <p>
                Do not have an account?{' '}
                <Link to="/candidate/register">
                    <span className="inline-button">Sign up</span>
                </Link>
            </p>
        </LoginTab>
    );
};

export const RecruiterLogin = () => {
    const { email, password } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        const data = {
            email: email,
            password: password,
        };

        const { error } = registerFormSchema.validate(data);

        if (error) {
            dispatch(loginFail(error.message));
            dispatch(
                handleSnackOpen({
                    message: error.message,
                    variant: 'error',
                })
            );
            return;
        }

        dispatch(loginPending());

        const response = await Login({
            email: email,
            password: password,
            userType: 'recruiter',
        });

        if (response.success) {
            dispatch(setToken(response.data.token));
            dispatch(setUser(response.data.user));
            dispatch(setUserType('recruiter'));
            dispatch(loginSuccess());
            dispatch(
                handleSnackOpen({
                    message: 'You have been successfully logged in!',
                    variant: 'success',
                    title: 'Recruiter login successful',
                })
            );
            dispatch(closeLoginModal());
            if (
                response.data.user &&
                response.data.user.profileCompleted === true
            ) {
                navigate('/rdashboard');
            } else {
                navigate('/recruiter/profile/edit');
            }
        } else {
            dispatch(loginFail(response.data.msg));
            dispatch(
                handleSnackOpen({
                    message: response.data.msg,
                    variant: 'error',
                    title: 'Recruiter login failed',
                })
            );
        }
    };

    return (
        <LoginTab>
            <h1 className="r-heading">Recruiter Login</h1>
            <div className="fg-registration">
                <GoogleButton userType="recruiter">
                    Sign in with Google
                </GoogleButton>
                {/* <FacebookButton userType="recruiter">
                    Sign up with Facebook
                </FacebookButton> */}
            </div>
            <div className="or-symbol">- OR -</div>
            <LoginForm
                email={email}
                password={password}
                setEmail={(email) => dispatch(updateEmail(email))}
                setPassword={(password) => dispatch(updatePassword(password))}
                onSubmit={onSubmit}
            />
            {/* <p>
                    <span className="inline-button">Sign up</span> with Phone
                    Number
                </p> */}
            <hr />
            <p>
                Do not have an account?{' '}
                <Link
                    to="/recruiter/register"
                    onClick={() => dispatch(closeLoginModal())}
                >
                    <span className="inline-button">Sign up</span>
                </Link>
            </p>
        </LoginTab>
    );
};
