import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CDupcomingBanner1, SubHeaading } from './CDComponents';
import EditProfileImage from '../../assets/illustrations/edit_profile.svg';

const CDupcomingBanners = () => {
    const [allEvents, setAllEvents] = useState([]);

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post('/api/events', {}, config)
            .then((res) => {
                if (res.data.events) setAllEvents(res.data.events);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div>
            {allEvents && allEvents.length > 0
                ? allEvents.map(
                      (event) =>
                          event.isActive && (
                              <Link to={`/events/${event._id}`}>
                                  <CDupcomingBanner1 key={event._id}>
                                      <img
                                          style={{ borderRadius: '10px' }}
                                          src={
                                              event.bannerS3FileKey
                                                  ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${event.bannerS3FileKey}`
                                                  : EditProfileImage
                                          }
                                      />
                                      <div>
                                          <div
                                              style={{
                                                  display: 'flex',
                                                  padding: 0,
                                              }}
                                          >
                                              <span>
                                                  {event.relatedTopics[0]}
                                              </span>
                                              &nbsp;
                                              <span>
                                                  {moment(
                                                      event.eventDate
                                                  ).fromNow()}
                                              </span>
                                          </div>
                                          <h1>{event.title}</h1>
                                      </div>
                                  </CDupcomingBanner1>
                              </Link>
                          )
                  )
                : 'No upcoming events'}
        </div>
    );
};

export default CDupcomingBanners;
