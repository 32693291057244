import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import Grid from '@mui/material/Grid';
import ResumeReview from './ResumeReview';
import '../../styles/Admin/AdminResumeReview.css';
import { HiDownload } from "react-icons/hi";
import {Link}  from "react-router-dom";
import Config from '../../config.json';
function AdminResumeReview(props) {
    const [candidates, setCandidates] = useState([]);
    const [resumeIsOpen, setResumeIsOpen] = useState(false);
    const [currResumeReview, setCurrResumeReview] = useState('');

    useEffect(() => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };

        axios.get('/api/admin/resume-review', config)
            .then(res => {
                if(res.data.users) setCandidates(res.data.users);
            }).catch(err => console.log(err));
    }, [resumeIsOpen]);

    const handleResumeOpen = e => {
        setResumeIsOpen(!resumeIsOpen);
        if(e && e.currentTarget.value) setCurrResumeReview(parseInt(e.currentTarget.value));
    }

    // console.log("resume", candidates);

    return (
        !resumeIsOpen 
        ? (
            <section className='AdminResumeReview'>
                <h1>Resume Review Requests</h1>
                <div className='candidate-review-cards'>
                    <Grid container spacing={2} sx={{width: '100%'}}>
                        {
                            candidates && candidates.length > 0
                            ? (
                                candidates.map((candidate, idx) => (
                                    <Grid item md={4} sm={6} xs={12} key={idx}>
                                        <div className='review-card'>
                                            <span>
                                                <h2>{candidate.fullName}</h2>
                                                <p>{candidate.mobileNo}</p>
                                               <p><a href={`${Config.S3_URL}/${candidate.resume.resumeS3FileKey}`} target='_blank' download>Download here<HiDownload/></a></p> 
                                            </span>
                                            
                                            
                                            <button 
                                                onClick={handleResumeOpen}
                                                value={idx}
                                            >
                                                Review
                                            </button>
                                        </div>
                                    </Grid>
                                ))
                            )
                            : <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center', marginTop: '10%'}}>
                                <p className='no-data-msg'>No resumes left to review 📃</p>
                            </Grid>
                        }
                    </Grid>
                </div>
            </section>
        )
        : <ResumeReview 
            userData={candidates[currResumeReview]}
            handleResumeOpen={handleResumeOpen}
        />
    );
}

export default AdminResumeReview;