import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RDashboardFooter from '../components/RecruiterDashboard/RDashboardFooter';
import {
    RDContainer,
    RDLeft,
    RDRight,
    RDContainerjobs,
    RDPagenumber,
} from '../components/RecruiterDashboard/RDComponents';
import RDCreate from '../components/RecruiterDashboard/RDCreate';
import RDJobs from '../components/RecruiterDashboard/RDJobs';
import RDStats from '../components/RecruiterDashboard/RDStats';

export default function RecruiterDashboard() {
    const [jobs, setJobs] = useState([]);
    const [numJobs, setNumJobs] = useState(0);

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/rdashboard/jobs', config)
            .then((res) => {
                if (res.data.postedJobs) {
                    setJobs(res.data.postedJobs);
                    setNumJobs(res.data.postedJobs.length);
                }
            })
            .catch((err) => console.log(err));
    }, [numJobs]);

    const updateNumJobs = () => {
        setNumJobs(numJobs + 1);
    };

    const [updateJobs, setUpdateJobs] = useState(false);
    return (
        <>
            <div className="filler-div"></div>
            <RDContainer>
                <RDLeft>
                    <br />
                    <RDStats />
                </RDLeft>
                <RDRight>
                    <RDCreate
                        updateNumJobs={updateNumJobs}
                        setJobUpdate={setUpdateJobs}
                    />
                </RDRight>
            </RDContainer>

            <RDContainerjobs>
                <RDJobs
                    updateJob={updateJobs}
                    setJobUpdate={setUpdateJobs}
                    jobs={jobs}
                />
            </RDContainerjobs>
            <RDashboardFooter />
        </>
    );
}
