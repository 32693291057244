import styled from "styled-components";

export const  Ribbon=styled.div`
    display: flex;
    flex-direction:row;
    background: linear-gradient(109.88deg, #6871E9 0%, #4544A2 73.54%), #4544A2;
    padding: 30px 20px;
    justify-content: space-between;
    @media screen and (max-width: 900px){
        flex-direction:column;
        padding:30px 10px;
    }
`;

export const  Left=styled.div`
    width: 55vw;
    display:flex;
    flex-direction:column;
    // text-align: center;
    margin-left: 61px;
    @media screen and (max-width: 1000px){
        margin-left: 5vw;
    }
    @media screen and (max-width: 900px){
        width: 95vw;
        margin-left: 1vw;
    }
`;

export const  Right=styled.div`
    width: 40vw;
    display:flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 900px){
        margin-top:20px;
        width:95vw;
        justify-content: flex-start;
    }
`;

export const  Title=styled.div`
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 100%;
    color: #FFFFFF;
    @media screen and (max-width: 900px){
        font-size: 25px;
    }
`;

export const  Date=styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    margin-top:10px;
`;

export const  HiringP=styled.div`
    button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        width: 170px;
        height: 44px;
        left: 1018px;
        top: 162px;
        background: #FFFFFF;
        border-radius: 8px;
        border: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #4544A2;
    }

    @media screen and (max-width: 900px){
        button{
            width:150px;
            padding: 5px 7px;
        }
    }
`;

export const  EditJob=styled.div`
    button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        width: 170px;
        height: 44px;
        left: 1018px;
        top: 162px;
        background: #FFFFFF;
        border-radius: 8px;
        border: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #4544A2;
    }

    @media screen and (max-width: 900px){
        margin-left: 40px;
        button{
            width:100px;
            padding: 5px 7px;
        }
    }
`;





export const State1=styled.div`
    margin-top:30px;
    margin-left: 80px;
    margin-right: 80px;
    @media screen and (max-width: 900px){
        margin-left:20px;
        margin-right:20px;
    }
`;

export const Head1=styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #2E353A;
    `;
    
    export const Desc1=styled.div`
    margin-top:15px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #A4A7A9;
    ul{
        margin-left:20px;
    }
`;