import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import { openLoginModal } from '../../store/auth/Login';
import { logout } from '../../store/auth/authStatus';
import { ReactComponent as SidebarIcon } from '../../assets/icons/sidebar_logo.svg';
import SecondaryButton from '../Common/SecondaryButton';
import {
    Link,
    NavLink as LinkRouter,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
    NavbarContainer,
    NavbarLink,
    NavbarLogo,
    NavbarTabs,
    SidebarIconWrap,
} from './NavbarComponents';
import Sidebar from './Sidebar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleIcon from '@mui/icons-material/Circle';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../../styles/Navbar.css';
import supergradLogo from '../../assets/supergrad_logo-2.svg';
// import logo from '../../assets/logo_light.png';
import defaultAvatar from '../../assets/illustrations/PPic.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
};

function Navbar(props) {
    const [notifications, setNotifications] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    let authToken = useSelector((st) => st.authStatus.token);
    let userType = useSelector((st) => st.authStatus.userType);
    let fullName = useSelector((st) => st.authStatus.user.fullName);
    let avatarS3Key = useSelector((st) => st.authStatus.user.avatarS3FileKey);
    let user = JSON.parse(localStorage.getItem('user'));
    if (!userType) {
        userType = localStorage.getItem('usertype');
    }
    if (!authToken) {
        authToken = localStorage.getItem('token');
    }
    if (!fullName) {
        fullName = user && user.fullName;
    }
    if (!avatarS3Key) {
        avatarS3Key = user && user.avatarS3FileKey;
    }

    const dispatch = useDispatch();
    let location = useLocation();
    const isProfileEditPage = location.pathname.indexOf('/profile/edit') !== -1;

    let navigate = useNavigate();

    const handleLogout = (e) => {
        dispatch(logout());
        localStorage.clear();
        setAnchorMenu(null);
        navigate('/');
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Profile Menu
    const [anchorMenu, setAnchorMenu] = useState(null);
    const menuOpen = Boolean(anchorMenu);
    const handleProfileClick = (e) => {
        setAnchorMenu(e.currentTarget);
    };
    const handleProfileClose = () => setAnchorMenu(null);

    const loadNotifications = () => {
        // Load notifications
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/rdashboard/notifications', config)
            .then((res) => {
                if (res.data.notifications) {
                    setNotifications(res.data.notifications);
                }
            })
            .catch((err) => console.log(err));
    };

    const clearNotifications = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            action: 'clear',
        };
        axios
            .put('/api/rdashboard/notifications', payload, config)
            .then((res) => {
                if (res.data.notifications) {
                    setNotifications(res.data.notifications);
                    dispatch(
                        handleSnackOpen({
                            message: res.data.msg,
                            variant: 'success',
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (userType === 'recruiter') {
            loadNotifications();
        }
    }, []);

    // Notification Menu
    const [notifAnchorEl, setNotifAnchorEl] = useState(null);
    const notifOpen = Boolean(notifAnchorEl);
    const handleNotifClick = (event) => {
        setNotifAnchorEl(event.currentTarget);
    };
    const handleNotifClose = () => {
        setNotifAnchorEl(null);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [currNotifData, setCurrNotifData] = useState({});
    const handleModalOpen = (notifId, notifData) => {
        setCurrNotifData(notifData);
        setModalOpen(true);

        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            action: 'read',
            notifId,
        };
        axios
            .put('/api/rdashboard/notifications', payload, config)
            .then((res) => {
                if (res.data.notifications)
                    setNotifications(res.data.notifications);
            })
            .catch((err) => console.log(err));
    };
    const handleModalClose = () => {
        setCurrNotifData({});
        setModalOpen(false);
    };

    return location.pathname.match('/admin') ? null : (
        <>
            <NavbarContainer>
                <NavbarLogo>
                    <Link to="/">
                        <img src={supergradLogo} alt="SuperGrad Logo" />
                        {/* <img src={logo} alt="PreEntrepreneur Logo" /> */}
                    </Link>
                </NavbarLogo>
                <NavbarTabs>
                    {!authToken || authToken === '' ? (
                        <>
                            {/* <HashLink to="/#about">
                            <NavbarLink>About Us</NavbarLink>
                        </HashLink> */}
                            <HashLink to="/community">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/community'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Community
                                </NavbarLink>
                            </HashLink>
                            <HashLink to="/bootcamp">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/bootcamp'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Career Accelerator Program{' '}
                                    <sup style={{ color: 'red' }}>New</sup>
                                </NavbarLink>
                            </HashLink>
                            <HashLink to="/events">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/events'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Events
                                </NavbarLink>
                            </HashLink>
                            <HashLink to="/blogs">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match('/blogs')
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Blogs
                                </NavbarLink>
                            </HashLink>

                            <NavbarLink
                                onClick={() => dispatch(openLoginModal())}
                            >
                                Log in
                            </NavbarLink>
                            <Button
                                id="register-button"
                                aria-controls={
                                    open ? 'register-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{
                                    borderRadius: 2.5,
                                    textTransform: 'none',
                                    border: '2px solid var(--primary)',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: '#263238',
                                        border: '2px solid #263238',
                                    },
                                    height: 'fit-content',
                                }}
                            >
                                Register
                            </Button>
                            <Menu
                                id="register-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'register-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <LinkRouter to="/candidate/register">
                                        As a Student
                                    </LinkRouter>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <LinkRouter to="/recruiter/register">
                                        As a Recruiter
                                    </LinkRouter>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : isProfileEditPage ? (
                        <NavbarLink onClick={handleLogout}>
                            <SecondaryButton>Logout</SecondaryButton>
                        </NavbarLink>
                    ) : userType === 'user' || userType === 'candidate' ? (
                        <>
                            <LinkRouter to="/dashboard">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/dashboard'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Dashboard
                                </NavbarLink>
                            </LinkRouter>
                            <LinkRouter to="/skilltests">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/skilltests'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Workstation
                                </NavbarLink>
                            </LinkRouter>
                            <LinkRouter to="/events">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/events'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Events
                                </NavbarLink>
                            </LinkRouter>
                            <LinkRouter to="/forum/latest-post">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match('/forum')
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Forum
                                </NavbarLink>
                            </LinkRouter>
                            {/* <LinkRouter to="/companies">
                                        <NavbarLink
                                            style={{color: location.pathname.match('/companies') ? '#150F37' : 'rgba(21, 15, 55, 0.6)'}}
                                        >
                                            Companies
                                        </NavbarLink>    
                                    </LinkRouter> */}
                            <div className="nav-profile">
                                {avatarS3Key ? (
                                    <img
                                        src={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${avatarS3Key}`}
                                        className="nav-avatar"
                                        alt="user avatar"
                                    />
                                ) : (
                                    <img
                                        src={defaultAvatar}
                                        className="nav-avatar"
                                        alt="default avatar"
                                    />
                                )}
                                <span
                                    id="profile-button"
                                    aria-controls={
                                        menuOpen ? 'profile-menu' : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        menuOpen ? 'true' : undefined
                                    }
                                    onClick={handleProfileClick}
                                >
                                    <i
                                        className={`fa-solid fa-chevron-${
                                            menuOpen ? 'up' : 'down'
                                        }`}
                                    ></i>
                                </span>
                            </div>
                            <Menu
                                id="profile-menu"
                                anchorEl={anchorMenu}
                                open={menuOpen}
                                onClose={handleProfileClose}
                                MenuListProps={{
                                    'aria-labelledby': 'profile-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    width: '200px',
                                    marginTop: '15px',
                                }}
                            >
                                <p
                                    style={{
                                        padding: '10px 15px',
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: '1rem',
                                    }}
                                >
                                    {fullName && fullName.split(' ')[0]}
                                </p>
                                <Divider />
                                <MenuItem>
                                    <LinkRouter to="/candidate/profile">
                                        View Profile
                                    </LinkRouter>
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    Logout
                                </MenuItem>
                            </Menu>
                            {/* <NavbarLink onClick={handleLogout}>
                            <SecondaryButton>Logout</SecondaryButton>
                        </NavbarLink> */}
                        </>
                    ) : (
                        <>
                            <IconButton
                                onClick={handleNotifClick}
                                id="notifications-button"
                                aria-controls={
                                    notifOpen ? 'notifications-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={notifOpen ? 'true' : undefined}
                            >
                                <NotificationsIcon />
                            </IconButton>
                            <Menu
                                id="notifications-menu"
                                anchorEl={notifAnchorEl}
                                open={notifOpen}
                                onClose={handleNotifClose}
                                MenuListProps={{
                                    'aria-labelledby': 'notifications-button',
                                }}
                                // anchorOrigin={{
                                //     horizontal: 'center',
                                // }}
                                transformOrigin={{
                                    horizontal: 'center',
                                    vertical: 'top',
                                }}
                                sx={{ width: '100%' }}
                            >
                                {notifications && notifications.length > 0 ? (
                                    notifications.map((notif) => (
                                        <MenuItem
                                            key={notif._id}
                                            sx={{
                                                backgroundColor:
                                                    notif.status === 'unread'
                                                        ? '#F5F5F5'
                                                        : '',
                                                width: 'fit-content',
                                            }}
                                        >
                                            <div className="notification">
                                                {notif.text}
                                                {notif.status === 'unread' ? (
                                                    <CircleIcon
                                                        fontSize="small"
                                                        sx={{
                                                            fontSize: '10px',
                                                            marginLeft: '10px',
                                                            color: '#D0342C',
                                                        }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <span
                                                className="open-notif"
                                                onClick={() =>
                                                    handleModalOpen(
                                                        notif._id,
                                                        notif.data
                                                    )
                                                }
                                            >
                                                Open{' '}
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </span>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>No Notifications.</MenuItem>
                                )}
                                {/* <Divider /> */}
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginLeft: '15px',
                                        fontSize: '11px',
                                        marginTop: '15px',
                                    }}
                                    onClick={loadNotifications}
                                >
                                    Refresh{' '}
                                    <RefreshIcon sx={{ fontSize: '20px' }} />
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginLeft: '15px',
                                        fontSize: '11px',
                                        marginTop: '15px',
                                        marginRight: '15px',
                                    }}
                                    color="warning"
                                    onClick={clearNotifications}
                                >
                                    Clear Notifications
                                </Button>
                            </Menu>
                            <LinkRouter to="/rdashboard">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/rdashboard'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Dashboard
                                </NavbarLink>
                            </LinkRouter>
                            <LinkRouter to="/hiringpanel">
                                <NavbarLink
                                    style={{
                                        color: location.pathname.match(
                                            '/hiringpanel'
                                        )
                                            ? '#150F37'
                                            : 'rgba(21, 15, 55, 0.6)',
                                    }}
                                >
                                    Hiring Panel
                                </NavbarLink>
                            </LinkRouter>
                            <div className="nav-profile">
                                {avatarS3Key ? (
                                    <img
                                        src={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${avatarS3Key}`}
                                        className="nav-avatar"
                                        alt="user avatar"
                                    />
                                ) : (
                                    <img
                                        src={defaultAvatar}
                                        className="nav-avatar"
                                        alt="default avatar"
                                    />
                                )}
                                <span
                                    id="profile-button"
                                    aria-controls={
                                        menuOpen ? 'profile-menu' : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        menuOpen ? 'true' : undefined
                                    }
                                    onClick={handleProfileClick}
                                >
                                    <i
                                        className={`fa-solid fa-chevron-${
                                            menuOpen ? 'up' : 'down'
                                        }`}
                                    ></i>
                                </span>
                            </div>
                            <Menu
                                id="profile-menu"
                                anchorEl={anchorMenu}
                                open={menuOpen}
                                onClose={handleProfileClose}
                                MenuListProps={{
                                    'aria-labelledby': 'profile-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    width: '200px',
                                    marginTop: '15px',
                                }}
                            >
                                <p
                                    style={{
                                        padding: '10px 15px',
                                        fontFamily: "'Roboto', sans-serif",
                                        fontSize: '1rem',
                                    }}
                                >
                                    {fullName && fullName.split(' ')[0]}
                                </p>
                                <Divider />
                                <MenuItem>
                                    <LinkRouter to="/recruiter/profile">
                                        View Profile
                                    </LinkRouter>
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </NavbarTabs>
                <SidebarIconWrap>
                    <SidebarIcon onClick={() => setSidebarOpen(true)} />
                </SidebarIconWrap>
                <Sidebar
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
            </NavbarContainer>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="Notification modal"
                aria-describedby="notification details"
            >
                <Box sx={style}>
                    <div className="notif-modal">
                        <h1>Job Details:</h1>
                        <hr
                            className="styled-hr"
                            style={{ margin: '5px 0', marginBottom: '15px' }}
                        />
                        <p>
                            <span>Job Title: </span>
                            {currNotifData.jobTitle}
                        </p>
                        <p>
                            <span>Job ID: </span>
                            {currNotifData.jobId}
                        </p>
                        <h1>Candidate Details:</h1>
                        <hr
                            className="styled-hr"
                            style={{ margin: '5px 0', marginBottom: '15px' }}
                        />
                        <p>
                            <span>Candidate Name: </span>
                            {currNotifData.user}
                        </p>
                        <p>
                            <span>Candidate ID: </span>
                            {currNotifData.userId}
                        </p>
                        {currNotifData && currNotifData.interviewLink ? (
                            <p>
                                <span>Interview Link: </span>
                                {currNotifData.interviewLink}
                            </p>
                        ) : (
                            ''
                        )}
                        <Link to={`/hiringpanel/${currNotifData.jobId}`}>
                            <button>Open Job</button>
                        </Link>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Navbar;
