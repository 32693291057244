import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import '../../styles/RecruiterDashboard/HiringPanel.css';

function HiringPanelList(props) {
    const [allJobs, setAllJobs] = useState([]);

    useEffect(() => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };
        axios.get('/api/rdashboard/jobs', config)
            .then(res => {
                if(res.data.postedJobs) setAllJobs(res.data.postedJobs);
            }).catch(err => console.log(err));
    }, []);

    return (
        <section className='HiringPanelList'>
            <div className='filler-div'></div>
            <h1>All jobs</h1>
            <Grid container spacing={2} sx={{width: '100%'}}>
                {
                    allJobs && allJobs.length > 0
                    ? (
                        allJobs.map(job => (
                            <Grid item md={4} sm={6} xs={12} key={job._id}>
                                <Link to={`/hiringpanel/${job._id}`}>
                                    <div className='admin-job-card' value={job._id}>
                                        <h1>{job.jobTitle}, {moment(job.createdAt).format('YYYY')}</h1>
                                        <h2>{job.recruiter.company.companyName}</h2>
                                        <p>{moment(job.createdAt).format('DD/MM/YYYY')}</p>
                                    </div>
                                </Link>
                            </Grid>
                        ))
                    )
                    : <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center', marginTop: '10%'}}>
                        <p className='no-data-msg'>No new jobs today 📅</p>
                    </Grid>
                }    
            </Grid>
        </section>
    )
}

export default HiringPanelList;