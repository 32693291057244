import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = (props) => {
    const isAdmin = useSelector(
        (state) => state.authStatus && state.authStatus.isAdmin
    );
    if (!isAdmin) {
        return <Navigate to="/admin/login" />;
    }
    return props.children;
};

export default AdminRoute;
