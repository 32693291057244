import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import useInputState from '../../hooks/useInputState';
import Config from '../../config.json';
import '../../styles/CandidateDashboard/CandidateProfile.css';
import defaultAvatar from '../../assets/illustrations/PPic.png';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function AdminCandidateProfiles(props) {
    const [userData, setUserData] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [avatarS3FileKey, setAvatarS3FileKey] = useState('');
    const [avatarS3PublicUrl, setAvatarS3PublicUrl] = useState('');
    const [avatarUploaded, setAvatarUploaded] = useState(false);
    const [resumeUploadProgress, setResumeUploadProgress] = useState(0);
    const [resumeS3FileKey, setResumeS3FileKey] = useState('');
    const [resumeS3PublicUrl, setResumeS3PublicUrl] = useState('');

    const [fullName, setFullName, resetFullName, initFullName] =
        useInputState('');
    const [mobileNo, setMobileNo, resetMobileNo, initMobileNo] =
        useInputState('');
    const [state, setState, resetState, initState] = useInputState('');
    const [email, setEmail, resetEmail, initEmail] = useInputState('');
    const [college, setCollege, resetCollege, initCollege] = useInputState('');
    const [degree, setDegree, resetDegree, initDegree] = useInputState('');
    const [startingYear, setStartingYear, resetStartingYear, initStartingYear] =
        useInputState('');
    const [expYear, setExpYear, resetExpYear, initExpYear] = useInputState('');
    const [
        graduationYear,
        setGraduationYear,
        resetGraduationYear,
        initGraduationYear,
    ] = useInputState('');
    const [userId, setUserId] = useState('New Candidate');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('New Candidate');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const getUserData = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get(`/api/admin/user/${userId}`, config)
            .then((res) => {
                if (res.data.user) {
                    console.log(res.data.user);
                    const user = res.data.user;
                    setUserData(user);
                    initFullName(user.fullName);
                    initMobileNo(user.mobileNo);
                    initState(user.state);
                    initEmail(user.email);
                    initCollege(user.college.collegeName);
                    initDegree(user.college.degree);
                    initStartingYear(user.college.startingYear);
                    initGraduationYear(user.college.graduationYear);
                    initExpYear(user.experience.workExperience);
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllUsers = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios.get('/api/admin/candidates', config).then((res) => {
            if (res.data.users) {
                setUsers(res.data.users);
            }
        });
    };

    const createNewUser = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            userId,
            email,
            userType: 'user',
            fullName,
            mobileNo,
            state,
            college,
            degree,
            startingYear,
            graduationYear,
            resumeS3FileKey,
            resumeS3PublicUrl,
            avatarS3FileKey,
            avatarS3PublicUrl,
            workExperience: expYear,
        };

        axios
            .post(`/api/admin/user/create`, payload, config)
            .then((res) => {
                if (res.data.user) {
                    resetData();
                    getAllUsers();
                    dispatch(
                        handleSnackOpen({
                            message: 'User created successfully!',
                            variant: 'success',
                        })
                    );
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const updateUser = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            email,
            userType: 'user',
            fullName,
            mobileNo,
            state,
            college,
            degree,
            startingYear,
            graduationYear,
            resumeS3FileKey,
            resumeS3PublicUrl,
            avatarS3FileKey,
            avatarS3PublicUrl,
            workExperience: expYear,
        };

        axios
            .post(`/api/admin/user/${userId}`, payload, config)
            .then((res) => {
                setLoading(false);
                if (res.data.user) {
                    const user = res.data.user;
                    setUserData(user);
                    initFullName(user.fullName);
                    initMobileNo(user.mobileNo);
                    initState(user.state);
                    initEmail(user.email);
                    initCollege(user.college.collegeName);
                    initDegree(user.college.degree);
                    initStartingYear(user.college.startingYear);
                    initGraduationYear(user.college.graduationYear);
                    initExpYear(user.experience.workExperience);
                    getAllUsers();
                    dispatch(
                        handleSnackOpen({
                            message: 'User updated successfully!',
                            variant: 'success',
                        })
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    const resetData = () => {
        setUserData({});
        resetFullName();
        resetCollege();
        resetMobileNo();
        resetEmail();
        resetDegree();
        resetGraduationYear();
        resetStartingYear();
        resetState();
        resetExpYear();
        resumeUploader.current.clear();
        uploader.current.clear();
    };

    useEffect(() => {
        if (!userId) return;
        resetData();
        if (userId !== 'New Candidate') {
            getUserData();
        }
    }, [userId]);

    useEffect(() => {
        getAllUsers();
    }, []);

    // Avatar Update
    const uploader = useRef();

    const saveAvatar = (data) => {
        setAvatarS3FileKey(data.fileKey);
        setAvatarS3PublicUrl(data.publicUrl);
        setAvatarUploaded(true);
        setUserData((currSt) => ({
            ...currSt,
            avatarS3FileKey: data.fileKey,
        }));
    };

    const handleUserSelect = (e) => {
        setSelectedUser(e.target.value);
        setUserId(e.target.value);
    };

    // Resume
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayPageNav, setDisplayPageNav] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const showPageNav = (e) => {
        setDisplayPageNav(true);
    };

    const hidePageNav = (e) => {
        setDisplayPageNav(false);
    };

    const resumeUploader = useRef();

    const saveResume = (data) => {
        setResumeS3FileKey(data.fileKey);
        setResumeS3PublicUrl(data.publicUrl);
        setResumeUploadProgress(0);
        resumeUploader.current.clear();
        if (userId === 'New Candidate') {
            setUserData((currSt) => ({
                ...currSt,
                resume: {
                    ...currSt.resume,
                    resumeS3FileKey: data.fileKey,
                    resumeS3PublicUrl: data.publicUrl,
                },
            }));
            return;
        }
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            userId: userData._id,
            resumeS3FileKey: data.fileKey,
            resumeS3PublicUrl: data.publicUrl,
        };
        setUserData((currSt) => ({
            ...currSt,
            resume: {
                ...currSt.resume,
                resumeS3FileKey: data.fileKey,
                resumeS3PublicUrl: data.publicUrl,
            },
        }));
        axios
            .put('/api/admin/upload-resume', payload, config)
            .then((res) => {
                if (res.data.userId) {
                    dispatch(
                        handleSnackOpen({
                            message: 'Resume updated successfully!',
                            variant: 'success',
                        })
                    );
                    setUserData((currSt) => ({
                        ...currSt,
                        resume: {
                            ...currSt.resume,
                            resumeS3FileKey: data.fileKey,
                            resumeS3PublicUrl: data.publicUrl,
                        },
                    }));
                }
            })
            .catch((err) => console.log(err));
    };

    const handleSubmit = () => {
        if (loading) return;
        setLoading(true);
        if (selectedUser === 'New Candidate') {
            createNewUser();
        } else {
            updateUser();
        }
    };

    return (
        <div
            style={{ height: '98%', scroll: 'auto' }}
            className="CandidateDashboardProfile"
        >
            <Select
                labelId="role-select"
                id="role-select-menu"
                value={selectedUser}
                label="Select your role"
                onChange={handleUserSelect}
                className="roles-select"
                disableUnderline
                style={{ marginTop: 0 }}
            >
                <MenuItem value="New Candidate">New Candidate</MenuItem>
                {users.map(
                    (user, idx) =>
                        user.profileCompleted && (
                            <MenuItem
                                style={{
                                    background: user.adminCreated && '#6871e9',
                                }}
                                key={idx}
                                value={user._id}
                            >
                                {user.fullName}
                            </MenuItem>
                        )
                )}
            </Select>
            <h1 className="header">Profile</h1>
            <div
                style={{ height: '70%', overflow: 'auto' }}
                className="profile-container"
            >
                {
                    <>
                        <div className="avatar-container">
                            <img
                                src={
                                    userData && userData.avatarS3FileKey
                                        ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${userData.avatarS3FileKey}`
                                        : defaultAvatar
                                }
                                
                            />
                            <span>
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="img/*"
                                    s3path="profile_pics/candidate/"
                                    scrubFilename={(filename) =>
                                        `profile_pic-${
                                            userData && userData.userId
                                        }`
                                    }
                                    onProgress={setUploadProgress}
                                    onFinish={saveAvatar}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={true}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                            </span>
                        </div>
                        <div className="field-container">
                            <div>
                                <h2>General Details</h2>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="full-name">
                                            Full Name
                                        </label>
                                        <input
                                            type="text"
                                            value={fullName}
                                            onChange={setFullName}
                                            id="full-name"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="mobileNo">
                                            Mobile No
                                        </label>
                                        <input
                                            type="number"
                                            value={mobileNo}
                                            onChange={setMobileNo}
                                            id="mobileNo"
                                        />
                                    </span>
                                </div>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            value={state}
                                            onChange={setState}
                                            id="state"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => {
                                                userId === 'New Candidate' &&
                                                    setEmail(e);
                                            }}
                                            id="email"
                                        />
                                    </span>
                                </div>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="years">
                                            Years of Experience
                                        </label>
                                        <input
                                            type="text"
                                            value={expYear}
                                            onChange={setExpYear}
                                            id="years"
                                        />
                                    </span>
                                </div>
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '100%', margin: '10px auto' }}
                            />
                            <div>
                                <h2>Education Details</h2>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="college">College</label>
                                        <input
                                            type="text"
                                            value={college}
                                            onChange={setCollege}
                                            id="college"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="degree">Degree</label>
                                        <input
                                            type="degree"
                                            value={degree}
                                            onChange={setDegree}
                                            id="degree"
                                        />
                                    </span>
                                </div>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="starting-year">
                                            StartingYear
                                        </label>
                                        <input
                                            type="number"
                                            value={startingYear}
                                            onChange={setStartingYear}
                                            id="starting-year"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="graduation-year">
                                            GraduationYear
                                        </label>
                                        <input
                                            type="number"
                                            value={graduationYear}
                                            onChange={setGraduationYear}
                                            id="graduation-year"
                                        />
                                    </span>
                                </div>
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '100%', margin: '10px auto' }}
                            />
                        </div>
                        <div
                            className="avatar-container"
                            style={{ marginBottom: '20px' }}
                        >
                            <h1>
                                {resumeUploadProgress > 0
                                    ? `Uploading (${resumeUploadProgress})%`
                                    : `${
                                          userId === 'New Candidate'
                                              ? 'Upload'
                                              : 'Update'
                                      }  Resume`}
                            </h1>
                            <span>
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="pdf/*"
                                    s3path="resumes/"
                                    scrubFilename={(filename) =>
                                        `resume-${userData && userData._id}.pdf`
                                    }
                                    onProgress={setResumeUploadProgress}
                                    onFinish={saveResume}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={true}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={resumeUploader}
                                />
                            </span>
                        </div>
                        {userData &&
                        userData.resume &&
                        userData.resume.resumeS3FileKey ? (
                            <div
                                className="resume-pdf-container"
                                onMouseEnter={showPageNav}
                                onMouseLeave={hidePageNav}
                                style={{ marginBottom: '20px' }}
                            >
                                <Document
                                    file={`${Config.S3_URL}/${userData.resume.resumeS3FileKey}`}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="resume-pdf"
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                <div
                                    className="page-nav"
                                    style={{
                                        display: !displayPageNav ? 'none' : '',
                                    }}
                                >
                                    <button
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                    >
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <p>
                                        Page{' '}
                                        {pageNumber || (numPages ? 1 : '--')} of{' '}
                                        {numPages || '--'}
                                    </p>
                                    <button
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <h1 className="not-found">Resume not uploaded!</h1>
                        )}
                        <div
                            style={{
                                textAlign: 'center',
                                padding: '2rem',
                            }}
                        >
                            <button
                                className="save-btn"
                                style={{
                                    margin: 'auto',
                                    background: '#4544a2',
                                }}
                                onClick={handleSubmit}
                            >
                                {selectedUser === 'New Candidate'
                                    ? loading
                                        ? 'Creating'
                                        : 'Create New'
                                    : loading
                                    ? 'Saving'
                                    : 'Save Changes'}
                            </button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default AdminCandidateProfiles;
