import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import axios from 'axios';
import useInputState from '../../hooks/useInputState';
import Grid from '@mui/material/Grid';

function CreateSkillTest({ handleFormOpen, isEditTest, testId }) {
    const [testName, setTestName, resetTestName, initTestName] =
        useInputState('');
    const [testDetails, setTestDetails, resetTestDetails, initTestDetails] =
        useInputState('');
    const [testLevel, setTestLevel] = useState('beginner');
    const [jobTypes, setJobTypes] = useState([]);
    const [numQues, setNumQues] = useState(1);
    const [testType, setTestType] = useState('skill test');
    const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);

    const [questions, setQuestions] = useState([]);
    const [currQuesText, setCurrQuesText, resetCurrQuesText] =
        useInputState('');
    const [op1, setOp1, resetOp1] = useInputState('');
    const [op2, setOp2, resetOp2] = useInputState('');
    const [op3, setOp3, resetOp3] = useInputState('');
    const [op4, setOp4, resetOp4] = useInputState('');
    const [correctOption, setCorrectOption] = useState('1');

    const dispatch = useDispatch();

    useEffect(() => {
        if (editTest) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            axios
                .get(`/api/quiz/${testId}/edit`, config)
                .then((res) => {
                    if (res.data._id) {
                        initTestName(res.data.title);
                        initTestDetails(res.data.description);
                        setTestLevel(res.data.difficulty);
                        setJobTypes(res.data.relatedRoles);
                        setTestType(res.data.testType);
                        setQuestions(
                            res.data.questions.map((ques) => ({
                                ...ques,
                                disable: true,
                            }))
                        );
                        setNumQues(res.data.questions.length);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const handleJobTypes = (e) => {
        if (e.target.value !== '') {
            setJobTypes((currState) => {
                const existingType = currState.find(
                    (type) => type === e.target.value
                );
                if (existingType) return currState;
                else return [...currState, e.target.value];
            });
        }
    };

    const handleTestLevelChange = (e) => {
        setTestLevel(e.currentTarget.value);
    };

    const handleTestTypeChange = (e) => {
        setTestType(e.currentTarget.value);
    };

    const removeJobType = (e) => {
        setJobTypes((currTypes) =>
            currTypes.filter((type) => type !== e.target.getAttribute('name'))
        );
    };

    const handleCorrectOption = (e) => {
        setCorrectOption(e.currentTarget.value);
    };

    const saveQuestion = (e) => {
        e.preventDefault();
        if ((currQuesText && op1 && op2 && op3 && op4) !== '') {
            setQuestions((currState) => [
                ...currState,
                {
                    question: currQuesText,
                    options: [
                        { text: op1, isCorrect: correctOption === '1' },
                        { text: op2, isCorrect: correctOption === '2' },
                        { text: op3, isCorrect: correctOption === '3' },
                        { text: op4, isCorrect: correctOption === '4' },
                    ],
                    disable: true,
                },
            ]);
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const enableQuestion = (e) => {
        e.preventDefault();
        const quesIdx = parseInt(e.currentTarget.value);
        if (numQues > questions.length) setNumQues(questions.length);
        setQuestions((currState) =>
            currState.map((ques, idx) => {
                if (idx === quesIdx) return { ...ques, disable: false };
                else return ques;
            })
        );
        setTimeout(() => {
            setCurrQuesText({ target: { value: questions[quesIdx].question } });
            setOp1({ target: { value: questions[quesIdx].options[0].text } });
            setOp2({ target: { value: questions[quesIdx].options[1].text } });
            setOp3({ target: { value: questions[quesIdx].options[2].text } });
            setOp4({ target: { value: questions[quesIdx].options[3].text } });
            setCorrectOption(
                `${
                    questions[quesIdx].options.findIndex((op) => op.isCorrect) +
                    1
                }`
            );
        }, 100);
    };

    const editQuestion = (e) => {
        e.preventDefault();
        const quesIdx = parseInt(e.currentTarget.value);
        if ((currQuesText && op1 && op2 && op3 && op4) !== '') {
            setQuestions((currState) =>
                currState.map((ques, idx) => {
                    if (idx === quesIdx)
                        return {
                            ...ques,
                            question: currQuesText,
                            options: [
                                { text: op1, isCorrect: correctOption === '1' },
                                { text: op2, isCorrect: correctOption === '2' },
                                { text: op3, isCorrect: correctOption === '3' },
                                { text: op4, isCorrect: correctOption === '4' },
                            ],
                            disable: true,
                        };
                    else return ques;
                })
            );
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const deleteQuestion = (e) => {
        e.preventDefault();
        const quesIdx = parseInt(e.currentTarget.value);
        setDeletedQuestionIds((deleted) => [
            ...deleted,
            questions[quesIdx]._id,
        ]);
        setQuestions((currState) =>
            currState.filter((ques, idx) => idx !== quesIdx)
        );
        setNumQues(numQues > 1 ? numQues - 1 : 1);
    };

    useEffect(() => {
        resetCurrQuesText();
        resetOp1();
        resetOp2();
        resetOp3();
        resetOp4();
        setCorrectOption('1');
    }, [questions]);

    const createTest = (e) => {
        e.preventDefault();
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        if (
            (testName && testDetails && testLevel) !== '' &&
            jobTypes.length > 0
        ) {
            const payload = {
                title: testName,
                description: testDetails,
                relatedRoles: jobTypes,
                difficulty: testLevel,
                questions,
                testType,
            };
            axios
                .post('/api/quiz/create', payload, config)
                .then((res) => {
                    if (res.data && res.data.quizId) {
                        resetTestName();
                        resetTestDetails();
                        setJobTypes([]);
                        setTestLevel('beginner');
                        setTestType('skill test');
                        setQuestions([]);
                        setNumQues(1);
                        handleFormOpen();
                        dispatch(
                            handleSnackOpen({
                                message: 'Skill Test created successfully!',
                                variant: 'success',
                            })
                        );
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const editTest = (e) => {
        e.preventDefault();
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        if (
            (testName && testDetails && testLevel) !== '' &&
            jobTypes.length > 0
        ) {
            const payload = {
                title: testName,
                description: testDetails,
                relatedRoles: jobTypes,
                difficulty: testLevel,
                questions,
                testType,
                deletedQuestionIds,
            };
            axios
                .post(`/api/quiz/modify/${testId}`, payload, config)
                .then((res) => {
                    if (res.data && res.data.quizId) {
                        resetTestName();
                        resetTestDetails();
                        setJobTypes([]);
                        setTestLevel('beginner');
                        setTestType('skill test');
                        setQuestions([]);
                        setNumQues(1);
                        handleFormOpen();
                        dispatch(
                            handleSnackOpen({
                                message: 'Skill Test updated successfully!',
                                variant: 'success',
                            })
                        );
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const closeForm = (e) => {
        resetTestName();
        resetTestDetails();
        setJobTypes([]);
        setTestLevel('beginner');
        setQuestions([]);
        setNumQues(1);
        resetCurrQuesText();
        resetOp1();
        resetOp2();
        resetOp3();
        resetOp4();
        setCorrectOption('1');
        handleFormOpen();
    };

    return (
        <section className="SkillTestForm">
            <h1>New Skill Test</h1>
            <form className="create-test-form">
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <label htmlFor="test-name" className="input-label">
                            Test Name
                        </label>
                        <input
                            type="text"
                            id="test-name"
                            value={testName}
                            onChange={setTestName}
                            placeholder="Enter Here"
                            className="test-name-input"
                            required
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <label htmlFor="job-types" className="input-label">
                            Related Fields/Profiles
                        </label>
                        <select
                            name="job-types"
                            id="job-types"
                            onChange={handleJobTypes}
                            required
                        >
                            <option value="">
                                Add related fields/profiles
                            </option>
                            <optgroup>
                                <option
                                    value=""
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '15px',
                                    }}
                                    disabled
                                >
                                    Non-Technical
                                </option>
                                {/* <option value="" disabled> </option> */}
                                <option value="marketing">Marketing</option>
                                <option value="content">Content</option>
                                <option value="management">Management</option>
                                <option value="finance">Finance</option>
                                <option value="customer satisfaction">
                                    Customer Satisfaction
                                </option>
                                <option value="business development">
                                    Business Development
                                </option>
                            </optgroup>
                            <optgroup>
                                <option
                                    value=""
                                    style={{
                                        fontWeight: 700,
                                        fontSize: '15px',
                                    }}
                                    disabled
                                >
                                    Technical
                                </option>
                                {/* <option value="" disabled> </option> */}
                                <option value="backend">Backend</option>
                                <option value="frontend">Frontend</option>
                                <option value="data analyst">
                                    Data Analyst
                                </option>
                                <option value="python">Python</option>
                                <option value="sde">SDE</option>
                                <option value="java">Java</option>
                                <option value="react">React</option>
                            </optgroup>
                        </select>
                        <div className="job-types-display">
                            {jobTypes && jobTypes.length > 0
                                ? jobTypes.map((type, idx) => (
                                      <span key={idx}>
                                          {type}
                                          <i
                                              className="fa-solid fa-xmark"
                                              onClick={removeJobType}
                                              name={type}
                                          ></i>
                                      </span>
                                  ))
                                : ''}
                        </div>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <label htmlFor="test-details" className="input-label">
                            Test Details
                        </label>
                        <textarea
                            id="test-details"
                            value={testDetails}
                            onChange={setTestDetails}
                            placeholder="Enter Here"
                            required
                        ></textarea>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <label className="input-label">Test Level</label>
                        <div className="level-btns">
                            <span>
                                <input
                                    type="radio"
                                    id="beginner"
                                    value="beginner"
                                    name="test-level"
                                    onChange={handleTestLevelChange}
                                    checked={testLevel === 'beginner'}
                                />
                                <label
                                    htmlFor="beginner"
                                    className="radio-label"
                                >
                                    Beginner
                                </label>
                            </span>
                            <span>
                                <input
                                    type="radio"
                                    id="intermediate"
                                    value="intermediate"
                                    name="test-level"
                                    onChange={handleTestLevelChange}
                                    checked={testLevel === 'intermediate'}
                                />
                                <label
                                    htmlFor="intermediate"
                                    className="radio-label"
                                >
                                    Intermediate
                                </label>
                            </span>
                            <span>
                                <input
                                    type="radio"
                                    id="advance"
                                    value="advance"
                                    name="test-level"
                                    onChange={handleTestLevelChange}
                                    checked={testLevel === 'advance'}
                                />
                                <label
                                    htmlFor="advance"
                                    className="radio-label"
                                >
                                    Advance
                                </label>
                            </span>
                        </div>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <label className="input-label">Test Type</label>
                        <div className="level-btns">
                            <span>
                                <input
                                    type="radio"
                                    id="skill-test"
                                    value="skill test"
                                    name="test-type"
                                    onChange={handleTestTypeChange}
                                    checked={testType === 'skill test'}
                                />
                                <label
                                    htmlFor="skill-test"
                                    className="radio-label"
                                >
                                    Skill Test
                                </label>
                            </span>
                            <span>
                                <input
                                    type="radio"
                                    id="practice-test"
                                    value="practice test"
                                    name="test-type"
                                    onChange={handleTestTypeChange}
                                    checked={testType === 'practice test'}
                                />
                                <label
                                    htmlFor="practice-test"
                                    className="radio-label"
                                >
                                    Practice Test
                                </label>
                            </span>
                        </div>
                    </Grid>
                </Grid>
                <div className="add-questions">
                    {[...Array(numQues)].map((e, i) => (
                        <div
                            className="question-div"
                            key={i}
                            disabled={
                                questions[i] ? questions[i].disable : false
                            }
                        >
                            <div>
                                <h1>Question {i + 1}</h1>
                                <input
                                    type="text"
                                    value={
                                        questions.length >= i + 1 &&
                                        questions[i] &&
                                        questions[i].disable
                                            ? questions[i].question
                                            : currQuesText
                                    }
                                    onChange={setCurrQuesText}
                                    name={i}
                                    placeholder="Enter Question"
                                    disabled={
                                        questions[i]
                                            ? questions[i].disable
                                            : false
                                    }
                                    className="question-text"
                                    required
                                />
                                <span>
                                    <i className="fa-regular fa-circle"></i>
                                    <input
                                        type="text"
                                        value={
                                            questions.length >= i + 1 &&
                                            questions[i] &&
                                            questions[i].disable
                                                ? questions[i].options[0].text
                                                : op1
                                        }
                                        onChange={setOp1}
                                        disabled={
                                            questions[i]
                                                ? questions[i].disable
                                                : false
                                        }
                                        placeholder="Option 1"
                                        className="option-input"
                                    />
                                </span>
                                <span>
                                    <i className="fa-regular fa-circle"></i>
                                    <input
                                        type="text"
                                        value={
                                            questions.length >= i + 1 &&
                                            questions[i] &&
                                            questions[i].disable
                                                ? questions[i].options[1].text
                                                : op2
                                        }
                                        onChange={setOp2}
                                        disabled={
                                            questions[i]
                                                ? questions[i].disable
                                                : false
                                        }
                                        placeholder="Option 2"
                                        className="option-input"
                                    />
                                </span>
                                <span>
                                    <i className="fa-regular fa-circle"></i>
                                    <input
                                        type="text"
                                        value={
                                            questions.length >= i + 1 &&
                                            questions[i] &&
                                            questions[i].disable
                                                ? questions[i].options[2].text
                                                : op3
                                        }
                                        onChange={setOp3}
                                        disabled={
                                            questions[i]
                                                ? questions[i].disable
                                                : false
                                        }
                                        placeholder="Option 3"
                                        className="option-input"
                                    />
                                </span>
                                <span>
                                    <i className="fa-regular fa-circle"></i>
                                    <input
                                        type="text"
                                        value={
                                            questions.length >= i + 1 &&
                                            questions[i] &&
                                            questions[i].disable
                                                ? questions[i].options[3].text
                                                : op4
                                        }
                                        onChange={setOp4}
                                        disabled={
                                            questions[i]
                                                ? questions[i].disable
                                                : false
                                        }
                                        placeholder="Option 4"
                                        className="option-input"
                                    />
                                </span>
                                <div className="question-btns">
                                    <button
                                        onClick={saveQuestion}
                                        disabled={questions.length >= i + 1}
                                        style={{
                                            display:
                                                questions.length >= i + 1
                                                    ? 'none'
                                                    : 'block',
                                        }}
                                    >
                                        Save Question
                                    </button>
                                    <button
                                        onClick={enableQuestion}
                                        disabled={questions.length < i + 1}
                                        style={{
                                            display:
                                                questions[i] &&
                                                questions[i] &&
                                                questions[i].disable
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                        value={i}
                                    >
                                        <i className="fa-solid fa-pen"></i>
                                    </button>
                                    <button
                                        onClick={editQuestion}
                                        disabled={questions.length < i + 1}
                                        style={{
                                            display:
                                                questions[i] &&
                                                questions[i] &&
                                                !questions[i].disable
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                        value={i}
                                    >
                                        Edit Question
                                    </button>
                                    <button
                                        onClick={deleteQuestion}
                                        disabled={questions.length < i + 1}
                                        style={{
                                            display:
                                                questions.length >= i + 1
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                        value={i}
                                    >
                                        <i className="fa-solid fa-trash-can"></i>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <h1>Correct Option</h1>
                                {[...Array(4)].map((el, idx) => (
                                    <React.Fragment key={idx}>
                                        <input
                                            type="radio"
                                            value={`${idx + 1}`}
                                            id={idx}
                                            name={`correct-option-ques-${
                                                i + 1
                                            }`}
                                            onChange={handleCorrectOption}
                                            checked={
                                                questions.length >= i + 1 &&
                                                questions[i] &&
                                                questions[i].disable
                                                    ? questions[i].options[idx]
                                                          .isCorrect
                                                    : correctOption ===
                                                      `${idx + 1}`
                                            }
                                            disabled={
                                                questions[i]
                                                    ? questions[i].disable
                                                    : false
                                            }
                                        />
                                        <label
                                            htmlFor={idx}
                                            className="correct-option-label"
                                        >
                                            {idx + 1}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    ))}
                    <button
                        onClick={() => setNumQues(numQues + 1)}
                        disabled={numQues > questions.length}
                        className="new-question-btn"
                    >
                        Add new question
                    </button>
                </div>
                <div className="submit-btns">
                    <button onClick={closeForm}>Cancel</button>
                    <button
                        type="submit"
                        onClick={isEditTest ? editTest : createTest}
                    >
                        {isEditTest ? 'Save' : 'Create'}
                    </button>
                </div>
            </form>
        </section>
    );
}

export default CreateSkillTest;
