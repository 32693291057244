import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import useInputState from '../../hooks/useInputState';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
};

function CreateForumModal({ open, handleOpen }) {
    const [forumTitle, setForumTitle, resetTitle] = useInputState('');
    const [forumDescription, setForumDescription, resetDescription] =
        useInputState('');
    const [forumTags, setForumTags] = useState([]);
    const [posterS3FileKey, setPosterS3FileKey] = useState('');
    const [posterS3PublicUrl, setPosterS3PublicUrl] = useState('');
    const [bannerUploaded, setBannerUploaded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const dispatch = useDispatch();

    const handleForumTags = (e) => {
        if (e.target.value !== '') {
            setForumTags((currState) => {
                const existingTag = currState.find(
                    (tag) => tag === e.target.value
                );
                if (existingTag) return currState;
                else return [...currState, e.target.value];
            });
        }
    };

    const removeTag = (e) => {
        setForumTags((currTags) =>
            currTags.filter((tag) => tag !== e.target.getAttribute('name'))
        );
    };

    const createForum = (e) => {
        e.preventDefault();
        if (
            (forumTitle &&
                forumDescription &&
                posterS3FileKey &&
                posterS3PublicUrl) !== '' &&
            forumTags.length > 0
        ) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const payload = {
                title: forumTitle,
                description: forumDescription,
                tags: forumTags,
                posterS3FileKey,
                posterS3PublicUrl,
            };
            axios
                .post('/api/forum/post', payload, config)
                .then((res) => {
                    if (res.data && res.data.postId) {
                        dispatch(
                            handleSnackOpen({
                                message: 'Forum Created Successfully!',
                                variant: 'success',
                            })
                        );
                        resetTitle();
                        resetDescription();
                        handleOpen();
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields/Upload Forum banner',
                    variant: 'warning',
                })
            );
        }
    };

    const uploader = useRef();

    const uploadBanner = (e) => {
        uploader.current.uploadFile();
    };

    const saveBanner = (data) => {
        setPosterS3FileKey(data.fileKey);
        setPosterS3PublicUrl(data.publicUrl);
        setBannerUploaded(true);
    };

    return (
        <Modal
            open={open}
            onClose={handleOpen}
            aria-labelledby="Create Forum"
            aria-describedby="create new forum"
        >
            <Box sx={style}>
                <section className="forum-modal-content">
                    <h1>Create Forum</h1>
                    <div className="banner-upload">
                        <h2>Upload Forum Banner</h2>
                        <ReactS3Uploader
                            signingUrl="/s3/sign"
                            signingUrlMethod="GET"
                            accept="img/*"
                            s3path="forum_banner/"
                            scrubFilename={(filename) =>
                                `forum_banner_${filename}`
                            }
                            onProgress={setUploadProgress}
                            onFinish={saveBanner}
                            server={process.env.REACT_APP_BACKEND_URL}
                            autoUpload={false}
                            uploadRequestHeaders={{
                                'x-amz-acl': 'public-read',
                            }}
                            contentDisposition="auto"
                            ref={uploader}
                        />
                        <button
                            onClick={uploadBanner}
                            disabled={bannerUploaded}
                        >
                            {bannerUploaded
                                ? 'Uploaded'
                                : uploadProgress === 0
                                ? 'Upload'
                                : `${uploadProgress}%`}
                        </button>
                    </div>
                    <form className="forum-form" onSubmit={createForum}>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="forum-title">Forum Title</label>
                                <input
                                    type="text"
                                    id="forum-title"
                                    value={forumTitle}
                                    onChange={setForumTitle}
                                    placeholder="Enter Here"
                                    required
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="forum-tags">Forum Tags</label>
                                <select
                                    name="forum-tags"
                                    id="forum-tags"
                                    onChange={handleForumTags}
                                    required
                                >
                                    <option value="">Select Tags</option>
                                    <option value="technical">Technical</option>
                                    <option value="personal development">
                                        Personal Development
                                    </option>
                                    <option value="business">Business</option>
                                    <option value="gaming">Gaming</option>
                                    <option value="media">Media</option>
                                    <option value="non technical">
                                        Non Technical
                                    </option>
                                </select>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="forum-description">
                                    Forum Description
                                </label>
                                <textarea
                                    id="forum-description"
                                    value={forumDescription}
                                    onChange={setForumDescription}
                                    placeholder="Enter Here"
                                    rows={1}
                                    required
                                ></textarea>
                            </Grid>
                            <div className="forum-tags-display">
                                {forumTags && forumTags.length > 0
                                    ? forumTags.map((tag, idx) => (
                                          <span key={idx}>
                                              {tag}
                                              <i
                                                  className="fa-solid fa-xmark"
                                                  onClick={removeTag}
                                                  name={tag}
                                              ></i>
                                          </span>
                                      ))
                                    : ''}
                            </div>
                        </Grid>
                        <button type="submit">Create</button>
                    </form>
                </section>
            </Box>
        </Modal>
    );
}

export default CreateForumModal;
