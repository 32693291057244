import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import useInputState from '../../hooks/useInputState';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import '../../styles/Events.css';
import eventIllus from '../../assets/illustrations/event-illustration.webp';
import profilePic from '../../assets/illustrations/profile-pic.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';

function Events(props) {
    const topics = [
        { title: 'All', selected: true },
        { title: 'Technical', selected: false },
        { title: 'Personal Development', selected: false },
        { title: 'Business', selected: false },
        { title: 'Gaming', selected: false },
        { title: 'Media', selected: false },
        { title: 'Non Technical', selected: false },
        { title: 'Bootcamp', selected: false },
    ];
    const dispatch = useDispatch();
    const [relatedTopics, setRelatedTopics] = useState(topics);
    const [searchQuery, setSearchQuery] = useInputState('');
    const [events, setEvents] = useState([]);
    let token = useSelector(
        (state) => state.authStatus && state.authStatus.token
    );
    if (token === '') token = localStorage.getItem('token');
    const handleTopicClick = (e) => {
        const currTitle = e.currentTarget.title.toLowerCase();
        if (currTitle === 'all') {
            setRelatedTopics(topics);
        } else {
            setRelatedTopics((currState) => {
                let updateState = currState.map((topic) => {
                    if (topic.title.toLowerCase() === currTitle)
                        return { ...topic, selected: !topic.selected };
                    else if (topic.title.toLowerCase() === 'all')
                        return { ...topic, selected: false };
                    else return topic;
                });
                return updateState.find((topic) => topic.selected)
                    ? updateState
                    : topics;
            });
        }
    };

    const handleSearchClick = (e) => {
        let filters = {
            title: searchQuery,
        };
        const allTopics = relatedTopics.find((topic) => topic.title === 'All');
        if (!allTopics.selected) {
            const topicFilters = relatedTopics
                .filter((topic) => topic.selected)
                .map((topic) => topic.title);
            filters.relatedTopics = topicFilters;
        }
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post('/api/events', { filters }, config)
            .then((res) => {
                if (res.data.events && res.data.events.length > 0) {
                    setEvents(res.data.events);
                } else if (res.data.msg) setEvents([]);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        let filters = {};
        const allTopics = relatedTopics.find((topic) => topic.title === 'All');
        if (!allTopics.selected) {
            const topicFilters = relatedTopics
                .filter((topic) => topic.selected)
                .map((topic) => topic.title);
            filters.relatedTopics = topicFilters;
        }
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post('/api/events', { filters }, config)
            .then((res) => {
                if (res.data.events && res.data.events.length > 0) {
                    setEvents(res.data.events);
                } else if (res.data.msg) setEvents([]);
            })
            .catch((err) => console.log(err));
    }, [relatedTopics]);

    const showMessageForRegister = () => {
        if (!token || token === '') {
            dispatch(
                handleSnackOpen({
                    message: 'Please Register or Login to Access Events',
                    variant: 'warning',
                })
            );
        }
    };
    return (
        <section className="Events">
            <div className="filler-div"></div>
            <div className="Events-filters">
                <div className="related-topics">
                    <h1>Upcoming Events</h1>
                    {relatedTopics.map((topic, idx) => {
                        return (
                            <button
                                key={idx}
                                title={topic.title}
                                onClick={handleTopicClick}
                                className={topic.selected ? 'btn-selected' : ''}
                            >
                                {topic.title}
                            </button>
                        );
                    })}
                </div>
                <div className="search">
                    <h1>Search</h1>
                    <TextField
                        type="text"
                        onChange={setSearchQuery}
                        value={searchQuery}
                        name="search"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        className="input-icons"
                                        onClick={handleSearchClick}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                border: '1px solid #e9ecf2',
                                cursor: 'pointer',
                            },
                        }}
                    />
                </div>
            </div>
            <div className="Events-cards">
                <Grid container spacing={2}>
                    {events && events.length > 0 ? (
                        events.map((event) => {
                            const eventDate = moment(event.eventDate).fromNow();
                            return (
                                event.isActive && (
                                    <Grid item md={3} sm={6} key={event._id}>
                                        <Card
                                            style={{
                                                borderRadius: '16px',
                                                height: '100%',
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="160"
                                                image={
                                                    event.bannerS3FileKey
                                                        ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${event.bannerS3FileKey}`
                                                        : eventIllus
                                                }
                                                alt="event illustration"
                                                className="event-banner"
                                            />
                                            <CardContent
                                                className="event-content"
                                                style={{ padding: '20px' }}
                                            >
                                                <div className="info">
                                                    <p>
                                                        {event.relatedTopics[0]
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            event.relatedTopics[0].substring(
                                                                1
                                                            )}
                                                    </p>
                                                    <p>
                                                        {eventDate
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            eventDate.substring(
                                                                1
                                                            )}
                                                    </p>
                                                </div>
                                                <h1>{event.title}</h1>
                                                <p>
                                                    {event.description.slice(
                                                        0,
                                                        100
                                                    )}
                                                    .....
                                                </p>
                                            </CardContent>
                                            <CardActions
                                                className="event-actions"
                                                style={{ padding: '20px' }}
                                            >
                                                <div>
                                                    <Link
                                                        to={
                                                            token === null ||
                                                            token === ''
                                                                ? '/candidate/register'
                                                                : `/events/${event._id}`
                                                        }
                                                        onClick={
                                                            showMessageForRegister
                                                        }
                                                    >
                                                        Register{' '}
                                                        <i className="fas fa-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            );
                        })
                    ) : (
                        <Grid item md={12}>
                            <h1>🔍 Looking for Events!</h1>
                        </Grid>
                    )}
                </Grid>
            </div>
        </section>
    );
}

export default Events;
