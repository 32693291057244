import React from 'react';
import {
    ProcessCard,
    ProcessCardContainer,
    SimpleProcessContainer,
} from './HomeComponents';

export default function SimpleProcess() {
    return (
        <SimpleProcessContainer id="simple-process">
            <h1>Simple Process</h1>
            <ProcessCardContainer>
                <ProcessCard>
                    <h2>1</h2>
                    <h1>
                        Create your
                        <br />
                        free account
                    </h1>
                    <p>
                        All you need is your email address to create an account
                        and start building your profile.
                    </p>
                </ProcessCard>
                <ProcessCard>
                    <h2>2</h2>
                    <h1>
                        Complete
                        <br />
                        Your screening
                    </h1>
                    <p>
                        Get yourself evaluated and be a part of our smart
                        learning community.
                    </p>
                </ProcessCard>
                <ProcessCard>
                    <h2>3</h2>
                    <h1>
                        Get
                        <br />
                        Hired
                    </h1>
                    <p>
                        Get connected with the companies looking for same
                        skillset.
                    </p>
                </ProcessCard>
            </ProcessCardContainer>
        </SimpleProcessContainer>
    );
}
