import React from 'react';
import Grid from '@mui/material/Grid';
import Footer from '../Footer/Footer';
import '../../styles/CandidateDashboard/CandidateDashboard.css';

function RDashboardFooter(props) {
    return (
        <footer className="DashboardFooter" style={{ marginTop: '50px' }}>
            <h1>Here's what you can do here!</h1>
            <Grid container sx={{ width: '100%', padding: '20px' }} spacing={1}>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <i className="fa-solid fa-share-nodes"></i>
                        <h2>Effective Match Making</h2>
                        <p style={{ height: '100px' }}>
                            Matches with the candidates with required skills.
                        </p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <i className="fa-solid fa-business-time"></i>
                        <h2>Time Saving</h2>
                        <p style={{ height: '100px' }}>
                            Hire the potential candidate directly in a short
                            time.
                        </p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                        <h2>No filteration</h2>
                        <p style={{ height: '100px' }}>
                            No need to review hundreds of CV. Directly interview
                            and re-evaluate candidates.
                        </p>
                    </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12} sx={{ marginBottom: '20px' }}>
                    <div className="dashboard-footer-div">
                        <i className="fa-solid fa-users"></i>
                        <h2>Industry Ready Candidate</h2>
                        <p style={{ height: '100px' }}>
                            We train our resources with industry level skills so
                            that they can start looking from day 1.
                        </p>
                    </div>
                </Grid>
            </Grid>
            {/* <Footer /> */}
        </footer>
    );
}

export default RDashboardFooter;
