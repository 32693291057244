import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    RegistrationContainer,
    RegistrationLeft,
    RegistrationRight,
} from '../auth/AuthComponents';
import RegisterForm from '../auth/RegisterForm';
import {
    candidateRegistrationFail,
    candidateRegistrationPending,
    candidateRegistrationSuccess,
    updateEmail,
    updatePassword,
} from '../../store/auth/CandidateRegister';
import ALoginForm from '../../assets/illustrations/recruiter_register.svg';
import registerFormSchema from '../../lib/validation/registerationSchema';
import { handleSnackOpen } from '../../store/Toast/Toast';
import { Register } from '../../api/requests';
import {
    adminLoginPending,
    adminLoginSuccess,
    setToken,
    setUser,
    setUserType,
} from '../../store/auth/authStatus';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function AdminLogin() {
    const navigate = useNavigate();
    const { email, password, isLoading } = useSelector(
        (state) => state.candidateRegister
    );
    const dispatch = useDispatch();

    const onSubmit = async (e) => {
        const data = {
            email: email,
            password: password,
        };

        dispatch(adminLoginPending());

        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .post(`/api/admin/login`, data, config)
            .then((response) => {
                if (response.data) {
                    dispatch(adminLoginSuccess(response.data));
                    dispatch(
                        handleSnackOpen({
                            message: 'You have been successfully logged in!',
                            variant: 'success',
                            title: 'Login Success',
                        })
                    );
                    navigate('/admin');
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(
                    handleSnackOpen({
                        message: 'Login Failed!',
                        variant: 'error',
                        title: 'Wrong credentials',
                    })
                );
            });
    };

    return (
        <>
            <RegistrationContainer>
                <RegistrationLeft image={ALoginForm} />
                <RegistrationRight>
                    <h1 className="r-heading">Admin Login</h1>
                    <RegisterForm
                        email={email}
                        password={password}
                        setEmail={(email) => dispatch(updateEmail(email))}
                        setPassword={(password) =>
                            dispatch(updatePassword(password))
                        }
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        buttonMessage={'Login'}
                    />
                </RegistrationRight>
            </RegistrationContainer>
        </>
    );
}

export default AdminLogin;
