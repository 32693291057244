import axios from 'axios';

export const baseURL = process.env.REACT_APP_BACKEND_URL;

export const authToken = localStorage.getItem('token');

export const axiosInstance = axios.create({
    baseURL: baseURL,
    responseType: 'json',
    headers: { Authorization: authToken },
});
