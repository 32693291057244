import styled from 'styled-components';

export const Ribbon = styled.div`
    z-index: 10;
    position: fixed;
    display: flex;
    flex-direction: row;
    background: linear-gradient(109.88deg, #6871e9 0%, #4544a2 73.54%), #4544a2;
    padding: 30px 20px;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
        flex-direction: column;
        padding: 30px 10px;
    }
`;

export const Left = styled.div`
    width: 55vw;
    display: flex;
    flex-direction: column;
    // text-align: center;
    margin-left: 61px;
    @media screen and (max-width: 1000px) {
        margin-left: 5vw;
    }
    @media screen and (max-width: 900px) {
        width: 95vw;
        margin-left: 1vw;
    }
`;

export const Right = styled.div`
    width: 40vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 900px) {
        margin-top: 20px;
        width: 95vw;
        justify-content: flex-start;
    }
`;

export const Title = styled.div`
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 100%;
    color: #ffffff;
    @media screen and (max-width: 900px) {
        font-size: 25px;
    }
`;

export const Date = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    margin-top: 10px;
`;

export const HiringP = styled.div`
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        width: 170px;
        height: 44px;
        left: 1018px;
        top: 162px;
        background: #ffffff;
        border-radius: 8px;
        border: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #4544a2;
    }

    @media screen and (max-width: 900px) {
        button {
            width: 150px;
            padding: 5px 7px;
        }
    }
`;

export const EditJob = styled.div`
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        width: 170px;
        height: 44px;
        left: 1018px;
        top: 162px;
        background: #ffffff;
        border-radius: 8px;
        border: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #4544a2;
    }

    @media screen and (max-width: 900px) {
        margin-left: 40px;
        button {
            width: 100px;
            padding: 5px 7px;
        }
    }
`;

export const AFRibbon = styled.div`
    display: none;
    padding-top: 175px;
    @media screen and (max-width: 900px) {
        display: flex;
    }

    @media screen and (max-width: 354px) {
        // display:flex;
        padding-top: 200px;
    }
`;

export const AFRLeft = styled.div`
    width: 60vw;
    border: 1px solid #e9ecf2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0px;
    color: #4544a2;
    select {
        border: none;
        width: 50vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px 0px;
        color: #4544a2;

        option {
            line-height: 40px;
            color: black;
        }
    }

    select:focus {
        outline: none !important;
    }
`;

export const AFRRight = styled.div`
    width: 40vw;
    border: 1px solid #e9ecf2;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const Openfilter = styled.div`
    // height: 68vh;
    // overflow-y: hidden;
    width: 95vw;
    overflow-x: hidden;

    @media screen and (min-width: 901px) {
        display: none;
    }
`;

export const OPHead = styled.div`
    width: 80vw;
    padding: 40px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 100%;
        color: #2e353a;
    }

    i {
        color: #4544a2;
        border-radius: 2px;
    }
`;

export const OPSet = styled.div`
    padding: 10px 25px;
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.680565px;
        color: #263238;
    }

    input {
        margin-top: 10px;
        border: 1px solid #263238;
        box-sizing: border-box;
        border-radius: 9.54545px;
        height: 40px;
        padding-left: 10px;
        width: 80vw;
    }
`;

export const OPClear = styled.div`
    margin-top: 30px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #4544a2;
    padding: 10px 25px;
    margin-bottom: 40px;
`;

export const Container = styled.div`
    display: flex;
    height: 87vh;
`;

export const LeftMost = styled.div`
    z-index: 1;
    position: fixed;
    margin-top: 18vh;
    width: 23vw;
    background: #d7def0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 15px 20px 15px;
    min-height: 62vh;
    @media screen and (max-width: 900px) {
        display: none;
    }
`;

export const Desc = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    height: 20px;
    left: 37px;
    top: 294px;
    background: #ffffff;
    border-radius: 10px;
    width: 12vw;
    max-width: 12vw;
    min-width: 12vw;
    margin-top: 20px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    color: #4544a2;
    span {
        color: #000000;
    }
`;

export const Desc1 = styled.div`
    opacity: 0.5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 49px;
    height: 20px;
    left: 37px;
    top: 294px;
    background: #ffffff;
    border-radius: 10px;
    width: 12vw;
    max-width: 12vw;
    min-width: 12vw;
    margin-top: 20px;

    align-items: center;
    text-align: center;
    color: #4544a2;
    span {
        color: #000000;
    }
`;

export const Middle = styled.div`
    margin-left: 25vw;
    margin-top: 15vh;
    width: 72vw;
    // height: 100%;
    overflow-x: scroll;
    // border-right: 1px solid #D7DEF0;
    border-left: 1px solid #d7def0;
    padding: 40px 20px 20px 20px;
    @media screen and (max-width: 900px) {
        margin-left: 0vw;
        width: 100vw;
        border: none;
    }

    @media screen and (max-width: 900px) {
        margin-top: 0vh;
    }
    &::-webkit-scrollbar {
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const MSet1 = styled.div`
    border: 1px solid rgba(38, 50, 56, 0.7);
    border-radius: 10px;
    padding: 30px 20px;
    margin-top: 30px;

    p {
        margin-top: 20px;
        margin-left: 30px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #4544a2;
    }

    @media screen and (max-width: 900px) {
        margin-left: 0px;
        p {
            margin-left: 0px;
        }
    }
`;

export const MSTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
`;

export const MSTL = styled.div`
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #263238;
    }

    span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: rgba(38, 50, 56, 0.7);
    }
`;

export const MSTR = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2e353a;

    span {
        color: #6871e9;
    }
`;

export const MSBody = styled.div`
    margin-top: 30px;
    margin-left: 30px;
    @media screen and (max-width: 900px) {
        margin-left: 0px;
    }
`;

export const MSBT = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #2e353a;
`;

export const MSBB = styled.div`
    margin-top: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: rgba(38, 50, 56, 0.7);
`;

export const DefineButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const DB1 = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4544a2;
    color: #ffffff;
    margin-right: 8px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
`;

export const DB3 = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    color: #4544a2;
    border: 1px solid #4544a2;
    margin-right: 8px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
`;

export const DB2 = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4544a2;
    color: #ffffff;
    margin-right: 8px;
    height: 40px;
    width: 40px;
    border-radius: 10px;

    @media screen and (max-width: 900px) {
        display: none;
    }
`;

export const DB4 = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    color: #4544a2;
    border: 1px solid #4544a2;
    margin-right: 8px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    // margin-top: 30px;

    input {
        width: 40px;
        height: 40px;
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
`;

export const BRemove = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fb174e;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 10px;
    height: 20px;
    color: #ffffff;
    cursor: pointer;
`;

export const BShortlist = styled.div`
    margin-right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #27ae60;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 10px;
    height: 20px;
    color: #ffffff;
    cursor: pointer;
`;

export const BResume = styled.div`
    margin-right: -5px;
    // display: flex;
    // justify-content:center;
    // align-items:center;
    border: 1px solid #4544a2;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 10px;
    height: fit-content;
    color: #4544a2;
    cursor: pointer;
`;

export const RightMost = styled.div`
    z-index: 2;
    position: fixed;
    margin-top: 20vh;
    margin-left: 75vw;
    width: 20vw;
    padding: 40px 40px;
    @media screen and (max-width: 900px) {
        display: none;
    }
`;

export const RMHead = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
`;

export const RMHL = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

export const RMHR = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #4544a2;
`;

export const RMState1 = styled.div`
    margin-top: 28px;
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.680565px;
        color: rgba(38, 50, 56, 0.7);
        margin-bottom: 8px;
    }

    input {
        border: 1px solid rgba(38, 50, 56, 0.7);
        box-sizing: border-box;
        border-radius: 9.54545px;
        height: 35px;
        padding-left: 15px;
    }
`;
