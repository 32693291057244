import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {
    Ribbon,
    Left,
    Right,
    Title,
    Date,
    HiringP,
    State1,
    Head1,
    Desc1,
} from './Jobchange';
import GridViewIcon from '@mui/icons-material/GridView';

const Job = () => {
    const [jobData, setJobData] = useState({});
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post(`/api/rdashboard/jobs/${params.id}`, {}, config)
            .then((res) => {
                if (res.data.jobData) setJobData(res.data.jobData);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <>
            <div className="filler-div"></div>
            <Ribbon>
                <Left>
                    <Title>
                        {jobData && jobData.jobTitle ? jobData.jobTitle : 'N/A'}
                        ,{' '}
                        {jobData && jobData.createdAt
                            ? moment(jobData.createdAt).format('YYYY')
                            : '-'}
                    </Title>
                    <Date>
                        {jobData && jobData.createdAt
                            ? moment(jobData.createdAt).format('DD/MM/YYYY')
                            : '-/-/-'}
                    </Date>
                </Left>
                <Right>
                    <HiringP>
                        <button
                            onClick={() =>
                                navigate(`/hiringpanel/${params.id}`)
                            }
                        >
                            <GridViewIcon />
                            Hiring Panel
                        </button>
                    </HiringP>
                </Right>
            </Ribbon>

            <State1>
                <Head1>Job Description</Head1>
                <Desc1>
                    {jobData && jobData.description
                        ? jobData.description
                        : 'N/A'}
                </Desc1>
            </State1>

            <State1>
                <Head1>Required Qualifications</Head1>
                <Desc1>
                    {jobData && jobData.qualifications
                        ? jobData.qualifications
                        : 'N/A'}
                </Desc1>
            </State1>

            <State1>
                <Head1>Stipend Range/CTA</Head1>
                <Desc1>
                    {jobData && jobData.salary ? jobData.salary : 'N/A'}
                </Desc1>
            </State1>

            <State1>
                <Head1>Location</Head1>
                <Desc1>
                    {jobData &&
                    jobData.jobLocations &&
                    jobData.jobLocations.length > 0
                        ? jobData.jobLocations.map((location, idx) =>
                              idx === jobData.jobLocations.length - 1
                                  ? `${location}`
                                  : `${location}, `
                          )
                        : 'N/A'}
                </Desc1>
            </State1>

            <State1>
                <Head1>Openings</Head1>
                <Desc1>
                    {jobData && jobData.numOpenings
                        ? jobData.numOpenings
                        : 'N/A'}
                </Desc1>
            </State1>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default Job;
