import React, { useState } from 'react';
import moment from 'moment';
import { Desc, Foot, Set, Title } from './Forum';
import './Forum.css';
import Python from '../../assets/illustrations/Py.png';

const FRight = ({comments, likeComment}) => {

    const [show, setShow] = useState('show');
    const [noshow, setNoshow] = useState('noshow');

    function like() {
        setShow('noshow');
        setNoshow('show');
    }

    function nolike() {
        setShow('show');
        setNoshow('noshow');
    }
 
    return (
        <>
            <Set>
                {
                    comments && comments.length > 0
                    ? (
                        comments.map((c, idx) => (
                            <React.Fragment key={idx}>
                                <Title>
                                    <span>
                                        <img src={
                                            c.postedBy && c.postedBy.avatarS3FileKey
                                            ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${c.postedBy.avatarS3FileKey}`
                                            : Python
                                        } />
                                    </span>
                                    <h1>
                                        {c.postedBy && c.postedBy.fullName ? c.postedBy.fullName : ''} 
                                    </h1>
                                    <p> {moment(c.postedAt).fromNow()}</p>
                                </Title>
                                <Desc>
                                    {c.comment}
                                </Desc>
                                <Foot>
                                    <p>{c.likes.length} {c.likes.length > 1 ? 'Likes' : 'Like'}</p>
                                    <i className="fa-solid fa-thumbs-up" onClick={likeComment} value={c._id}></i>
                                </Foot>
                            </React.Fragment>
                        ))
                    )
                    : <h2 className='no-comments'>No comments on this post</h2>
                }
            </Set>
        </>
    )
}

export default FRight