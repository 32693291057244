import React, { useState } from 'react';

const Privacy = ({}) => {
    return (
        <div>
            <div className="filler-div"></div>
            <h1
                style={{
                    textAlign: 'center',
                    marginBottom: '1.5rem',
                    marginTop: '2rem',
                }}
            >
                Privacy Policy
            </h1><br />
            <div
                style={{ maxWidth: '80vw', margin: 'auto' }}
                className="privacy-container"
            >
                <p>
                    This privacy policy ("policy") will help you understand how
                    Supergrad ("us", "we", "our") uses and protects the data you
                    provide to us when you visit and use www.supergrad.co.in
                    ("website", "service"). We reserve the right to change this
                    policy at any given time, of which you will be promptly
                    updated. If you want to make sure that you are up to date
                    with the latest changes, we advise you to frequently visit
                    this page.
                </p> <br />

                <h3>What User Data We Collect</h3> <br />
                <p>
                    - Your contact information and email address. <br></br>-
                    Other information such as interests and preferences.{' '}
                    <br></br>- Data profile regarding your online behavior on
                    our website. <br></br>
                </p> <br />

                <h3>Why We Collect Your Datat</h3> <br />
                <p>
                    - To better understand your needs. <br></br>- To improve our
                    services and products. <br></br>- To send you promotional
                    emails containing the information we think you will find
                    interesting. <br></br>- To customize our website according
                    to your online behavior and personal preferences.
                </p> <br />

                <h3>Safeguarding and Securing the Data</h3> <br />
                <p>
                    Supergrad is committed to securing your data and keeping it
                    confidential. Supergrad has done all in its power to prevent
                    data theft, unauthorized access, and disclosure by
                    implementing the latest technologies and software, which
                    help us safeguard all the information we collect online.
                </p> <br />

                <h3>Our Cookie Policy</h3> <br />
                <p>
                    Once you agree to allow our website to use cookies, you also
                    agree to use the data it collects regarding your online
                    behavior (analyze web traffic, web pages you spend the most
                    time on, and websites you visit). The data we collect by
                    using cookies is used to customize our website to your
                    needs. After we use the data for statistical analysis, the
                    data is completely removed from our systems. Please note
                    that cookies don't allow us to gain control of your computer
                    in any way. They are strictly used to monitor which pages
                    you find useful and which you do not so that we can provide
                    a better experience for you. If you want to disable cookies,
                    you can do it by accessing the settings of your internet
                    browser.
                </p> <br />

                <h3>Links to Other Websites</h3> <br />
                <p>
                    Our website contains links that lead to other websites. If
                    you click on these links [name] is not held responsible for
                    your data and privacy protection. Visiting those websites is
                    not governed by this privacy policy agreement. Make sure to
                    read the privacy policy documentation of the website you go
                    to from our website.
                </p>
                <br />
                <h3>Restricting the Collection of your Personal Data</h3> <br />
                <p>
                    At some point, you might wish to restrict the use and
                    collection of your personal data. You can achieve this by
                    doing the following: When you are filling the forms on the
                    website, make sure to check if there is a box which you can
                    leave unchecked, if you don't want to disclose your personal
                    information. If you have already agreed to share your
                    information with us, feel free to contact us at
                    info@supergrad.co.in and we will be more than happy to
                    change this for you. Supergrad will not lease, sell or
                    distribute your personal information to any third parties,
                    unless we have your permission. We might do so if the law
                    forces us. Your personal information will be used when we
                    need to send you promotional materials if you agree to this
                    privacy policy.
                </p> <br />
            </div>
        </div>
    );
};

export default Privacy;
