import React, { useState } from 'react';
import { Grid, Row } from '@mui/material';
import '../../styles/Bootcamp/bootcampDetails.css';
import Footer from '../Footer/Footer';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import FAQs from './../Home/FAQs';
import { Link } from 'react-router-dom';
import BootCampData from '../../tempData/bootcamp.json';
import virtualclass from '../../assets/bootcampdetails/virtualclass.png';
import recruitment from '../../assets/bootcampdetails/recruitment.png';
import assignment from '../../assets/bootcampdetails/assignment.png';
import program from '../../assets/images/program.png';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Stcomp, Stdesc, Sthead, Sts, Stst } from '../Home/HomeComponents';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import axios from 'axios';

const BootcampDetails = ({}) => {
    const allFAQs = [
        {
            question: 'Who can take this course',
            answer: 'Any Undergraduate looking to kickstart their career can apply.',
        },
        {
            question: 'Will I be getting a job after completion of the course?',
            answer: 'We will be connecting you to our several portfolio clients which are actively hiring from us. You will also be eligible to participate in various internship/ placement fairs',
        },
        {
            question: 'Why should I join this?',
            answer: 'As corporate landscape become more innovative and technology driven, you cannot learn industry required skills by sitting in classroom. We have created all such skillset and co-created these bootcamps with industry expert to help you in building a career of your choice.',
        },
        {
            question: 'Will I be getting placement assistance in this course?',
            answer: 'During this course, you will be prepared for interviews and will also be trained on soft skills such as communication skills, resume building, etc.',
        },
        {
            question: 'Do you have Refund Policy?',
            answer: 'We have a strict no refund policy. All registrations in the course are full and final.',
        },
        {
            question: 'Who will be taking live workshops',
            answer: 'We have several mentors who are working with reputed companies. They will take live sessions along with our expert team.',
        },
    ];

    const allRoutines = [
        {
            heading: 'Live Online Cohorts',
            image: virtualclass,
            description: {
                one: 'Regular live online Sessions  by Industry experts and Professionals',
                two: 'Dedicated industry level traning to boost practical knowledge',
                three: 'Learn latest tools and technologies',
            },
        },
        {
            heading: 'Assignments, Projects and Assesments',
            image: recruitment,
            description: {
                one: 'Regular assignments to reapply your theorotical learning',
                two: 'Weekly and fortnightly industry based projects for practical learning',
                three: 'Regular assessments to track your progress',
            },
        },
        {
            heading: 'Placement Assistance',
            image: assignment,
            description: {
                one: 'Get dedicated support with your resume, social profile building and soft skills improvement',
                two: 'Get interviewed by companies post completion of bootcamp',
                three: 'Interview preparation with mock interviews daily',
            },
        },
    ];

    const Counter = ({ number, prefix, suffix }) => (
        <CountUp
            end={number}
            redraw={true}
            prefix={prefix ? prefix : ''}
            suffix={suffix}
            separator=","
            formattingFn={(num) =>
                `${prefix ?? ''} ${num.toLocaleString('en-IN')}${suffix}`
            }
        >
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                </VisibilitySensor>
            )}
        </CountUp>
    );

    const { bootcampId } = useParams();
    const navigation = useNavigate();

    const [Data, setData] = useState({
        headerTitle: 'Product Management Bootcamp',
        headerContent:
            'This bootcamp is co-created with industry leaders which is focused on practical learning Through our online workshop where you can interact with mentors from top companies, work closely with other peers on several live projects, track your daily progress and much more. ',
        price: 3400,
        headerImg: '/assets/images/productBootcamp.jpeg',
        paymentLink: '',
        hours: 30,
        linkDescription: 'Product Management Bootcamp', //30 characters max
        mentorDetails: [
            {
                name: '',
                details: '',
                img: '',
            },
        ],
        paymentTemplate: 0,
        Details: [
            {
                heading: '',
                image: '',
                description: {
                    one: '',
                    two: '',
                    three: '',
                },
            },
            {
                heading: '',
                image: '',
                description: {
                    one: '',
                    two: '',
                    three: '',
                },
            },
            {
                heading: '',
                image: '',
                description: {
                    one: '',
                    two: '',
                    three: '',
                },
            },
        ],
    });

    const updatePaymentLink = async (templateId, linkDescription) => {
        const res = await axios.post('/api/payment/createLink', {
            templateId,
            linkDescription,
        });
        setData((data) => {
            return { ...data, paymentLink: res.data.generatedLink };
        });
    };

    useEffect(() => {
        const index = BootCampData.findIndex(
            (detail) => detail.id === bootcampId
        );
        if (index === -1) navigation('/bootcamp');
        updatePaymentLink(
            BootCampData[index].paymentTemplate,
            BootCampData[index].linkDescription
        );
        setData(BootCampData[index]);
    }, []);

    const [whichRaised, setWhichRaised] = useState(0);
    return (
        <div className="Bootcamp-detail">
            <div className="filler-div" />
            <section
                style={{ background: 'rgba(199, 199, 227, 0.12)' }}
                className="bootcamp-hero"
            >
                {window.screen.width >= 992 ? (
                    <div>
                        <Grid
                            container
                            justifyContent="center"
                            style={{ height: '100%' }}
                        >
                            <Grid className="hero-content" md={6} item>
                                <h1>{Data.headerTitle}</h1>
                                <p>{Data.headerContent}</p>
                                {bootcampId === 'summer-internship-bootcamp' ||
                                bootcampId == 'product-management-bootcamp' ? (
                                    <a target={'_blank'} href="/bootcamp">
                                        <button>Enrollment Closed</button>
                                    </a>
                                ) : (
                                    <a
                                        target={'_blank'}
                                        href={Data.paymentLink}
                                    >
                                        <button>
                                            {Data.paymentLink
                                                ? 'Enroll Now'
                                                : 'Loading Link'}
                                        </button>
                                    </a>
                                )}
                            </Grid>
                            <Grid className="hero-img" md={5} item>
                                <img
                                    src={Data.headerImg}
                                    alt={Data.headerTitle}
                                    className="bootcamp-hero-img"
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : (
                    <div>
                        <Grid
                            alignContent={'center'}
                            justifyContent="center"
                            container
                            style={{ height: '100%' }}
                        >
                            <Grid className="hero-img" md={5} item>
                                <img
                                    src={Data.headerImg}
                                    alt="Career Accelerator Program"
                                    className="bootcamp-hero-img"
                                />
                            </Grid>
                            <Grid className="hero-content" md={6} item>
                                <h1>{Data.headerTitle}</h1>
                                <p>{Data.headerContent}</p>
                                <a href={Data.paymentLink}>
                                    {/*<button>{`Apply Now ₹${Data.price}`}</button>*/}
                                    <button>
                                        {Data.paymentLink
                                            ? 'Enroll Now'
                                            : 'Loading Link'}
                                    </button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </section>
            <section>
                <Stcomp>
                    <Sts>
                        <div>
                            <img
                                style={{ height: '4rem' }}
                                src="/assets/images/icons/youtube_white.png"
                                alt="Icon"
                            />
                            <Stst>
                                <Counter number={Data.hours} suffix="+" />
                            </Stst>
                            <Stdesc>Hours Live Sessions</Stdesc>
                        </div>
                        <div>
                            <img
                                style={{ height: '4rem' }}
                                src="/assets/images//icons/assistant_white.png"
                                alt="Icon"
                            />
                            <Stst>
                                <Counter number={5} suffix="+" />
                            </Stst>
                            <Stdesc>Hands on Projects</Stdesc>
                        </div>
                        <div>
                            <img
                                style={{ height: '4rem' }}
                                src="/assets/images/icons/puzzle_white.png"
                                alt="Icon"
                            />
                            <Stst>
                                <Counter number={100} suffix="%" />
                            </Stst>
                            <Stdesc>Assured Placement Assistance</Stdesc>
                        </div>
                    </Sts>
                </Stcomp>
            </section>

            <section className="programWorking">
                <Grid container style={{ height: '100%' }}>
                    <Grid className="image" md={5} item>
                        <img
                            src={program}
                            alt={Data.headerTitle}
                            className="bootcamp-hero-img"
                        />
                    </Grid>
                    <Grid className="content" md={6} item>
                        <h2>How does the program work?</h2>
                        This bootcamp is co-created with industry leaders which
                        is focused on practical learning Through our online
                        workshop where you can interact with mentors from top
                        companies, work closely with other peers on several live
                        projects, track your daily progress and much more.
                    </Grid>
                </Grid>
            </section>

            <section className="bootcampRoutine">
                <div className="boot_heading">
                    <h1>Here's how your Bootcamp</h1>
                    <p>Routine would look like....</p>
                </div>
                <Grid
                    style={{ margin: '0 4vw', padding: '0 0' }}
                    container
                    spacing={2}
                >
                    {Data.Details.map((routine, index) => {
                        return (
                            <Grid
                                style={{ padding: '0 3vw' }}
                                key={index}
                                item
                                xs={4}
                            >
                                <div className="bootcamp_detail">
                                    <div className="bootcamp_img">
                                        <img src={routine.image} alt="imagee" />
                                    </div>
                                    <div className="bootcamp_heading">
                                        <h1>{routine.heading}</h1>
                                    </div>
                                    <div className="bootcamp_content">
                                        <ul>
                                            <li>{routine.description.one}</li>
                                            <li>{routine.description.two}</li>
                                            <li>{routine.description.three}</li>
                                        </ul>
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </section>

            <section className="bootcampRoutine_mobile">
                <div className="boot_heading_m">
                    <h1>Here's how your Bootcamp</h1>
                    <p>Routine would look like....</p>
                </div>
                {allRoutines.map((routine, index) => {
                    return (
                        <div className="bootcamp_details_m">
                            <div className="bootcamp_img_m">
                                <img src={routine.image} alt="imagee" />
                            </div>
                            <div className="bootcamp_head_m">
                                <h1>{routine.heading}</h1>
                            </div>
                            <div className="bootcamp_content_m">
                                <p>{routine.description.one}</p>
                                <p>{routine.description.two}</p>
                                <p>{routine.description.three}</p>
                            </div>
                        </div>
                    );
                })}
            </section>

            <section className="programWorking certi">
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100%' }}
                >
                    <Grid className="img" md={5} item>
                        <img
                            src="/assets/images/certi.jpg"
                            alt={Data.headerTitle}
                            className="bootcamp-hero-img    "
                        />
                    </Grid>

                    <Grid className="content" md={6} item>
                        <h2>Showcase Your Achievement </h2>
                        <i
                            class="fa fa-check-square"
                            style={{ marginRight: '1rem', color: '#4544a2' }}
                            aria-hidden="true"
                        ></i>
                        Recieve a certificate on completion of course
                        <br />
                        <br />
                        <i
                            class="fa fa-file-text"
                            style={{ marginRight: '1rem', color: '#4544a2' }}
                            aria-hidden="true"
                        ></i>
                        Put it up on your resume
                        <br />
                        <br />
                        <i
                            class="fa fa-star"
                            style={{ marginRight: '0.6rem', color: '#4544a2' }}
                            aria-hidden="true"
                        ></i>
                        Showcase on your LinkedIn
                    </Grid>
                </Grid>
            </section>

            <section className="mentors">
                <h2>About the Mentors </h2>
                <div>
                    <Grid
                        className="mentor-options"
                        alignContent="center"
                        justifyContent="center"
                        container
                        item
                        direction="row"
                        spacing={2}
                    >
                        {Data.mentorDetails.map((mentorDetail) => (
                            <MentorCard
                                icon={mentorDetail.img}
                                heading={mentorDetail.name}
                                details={mentorDetail.details}
                                iconAlt={mentorDetail.name}
                            />
                        ))}
                    </Grid>
                </div>
            </section>

            <section
                className="faq"
                style={{
                    padding: '3rem',
                }}
            >
                <FAQs allFAQs={allFAQs} />
            </section>
            <Footer />
        </div>
    );
};

const HeroWhyOptions = ({ icon, heading, iconAlt = '' }) => (
    <Grid
        item
        direction="column"
        className="hero-why-options"
        style={{ justifyContent: 'center', textAlign: 'center' }}
    >
        <img style={{ height: '4rem' }} src={icon} alt={iconAlt} />
        <h5 style={{ padding: '0.5vw' }}>{heading}</h5>
    </Grid>
);

const MentorCard = ({ icon, heading, details, iconAlt = '' }) => (
    <Grid
        item
        direction="column"
        className="hero-mentor-options"
        style={{ justifyContent: 'center', textAlign: 'center' }}
    >
        <img
            style={{ height: '8rem', marginBottom: '1rem' }}
            src={icon}
            alt={iconAlt}
        />
        <h5 style={{ padding: '0.5vw', margin: 'auto' }}>{heading}</h5>
        <p style={{ whiteSpace: 'pre-wrap' }}>{details}</p>
    </Grid>
);
export default BootcampDetails;
