import React, { useState, useRef, useEffect } from 'react';
import './blogcreate.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BlogEditor from './BlogEditor';
import ReactS3Uploader from 'react-s3-uploader';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../../../store/Toast/Toast';
const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
    maxHeight: '90%',
    overflow: 'auto',
};

const Blogcreate = ({ open, handleOpen, blogDataEdit, trigger }) => {
    const [blogData, setBlogData] = useState({
        title: '',
        introduction: '',
        content: [
            {
                type: 'paragraph',
                children: [{ text: 'Start Editing the Blog! ' }],
            },
        ],
        subtitle: '',
    });
    const dispatch = useDispatch();

    const [blogBannerS3Path, setBlogBannerS3Path] = useState('');
    const [bannerUploaded, setBannerUploaded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleChange = (e) => {
        setBlogData({ ...blogData, [e.target.name]: e.target.value });
    };

    const uploader = useRef();

    const uploadBanner = (e) => {
        e.preventDefault();
        uploader.current.uploadFile();
    };

    const saveBanner = (data) => {
        setBlogBannerS3Path(data.fileKey);
        setBannerUploaded(true);
    };

    useEffect(() => {
        if (blogDataEdit) {
            setBlogData({ ...blogData, ...blogDataEdit });
        }
    }, [blogDataEdit]);
    const { title, introduction, subtitle, content } = blogData;

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!blogDataEdit) {
            try {
                const config = {
                    headers: { 'x-auth-token': localStorage.getItem('token') },
                };
                axios
                    .post(
                        '/api/blog/create',
                        { ...blogData, blogBannerS3Path },
                        config
                    )
                    .then((res) => {
                        dispatch(
                            handleSnackOpen({
                                message: 'Blog Created Successfully!',
                                variant: 'success',
                            })
                        );
                        setBlogData({
                            title: '',
                            subtitle: '',
                            introduction: '',
                            date: '',
                            content: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        { text: 'Start Editing the Blog! ' },
                                    ],
                                },
                            ],
                        });
                        if (trigger) trigger();
                    })
                    .catch((err) => console.log(err));
            } catch (err) {
                dispatch(
                    handleSnackOpen({
                        message: 'Fill All Fields!',
                        variant: 'warning',
                    })
                );
            }
        } else {
            try {
                const config = {
                    headers: { 'x-auth-token': localStorage.getItem('token') },
                };
                axios
                    .put(
                        `/api/blog/update/${blogData._id}`,
                        { ...blogData, blogBannerS3Path },
                        config
                    )
                    .then((res) => {
                        dispatch(
                            handleSnackOpen({
                                message: 'Blog Updated Successfully!',
                                variant: 'success',
                            })
                        );
                        setBlogData({
                            title: '',
                            subtitle: '',
                            introduction: '',
                            date: '',
                            content: [
                                {
                                    type: 'paragraph',
                                    children: [
                                        { text: 'Start Editing the Blog! ' },
                                    ],
                                },
                            ],
                        });
                        if (trigger) trigger();
                    })
                    .catch((err) => console.log(err));

                if (trigger) trigger();
            } catch (err) {
                dispatch(
                    handleSnackOpen({
                        message: 'Fill All Fields!',
                        variant: 'warning',
                    })
                );
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleOpen}
            aria-labelledby="Create Blog"
            aria-describedby="create new blog"
        >
            <Box sx={style}>
                <section className="event-modal-content">
                    <h1>Create Blog</h1>

                    <form className="event-form" onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="event-title">Blog Title</label>
                                <input
                                    type="text"
                                    id="blog-title"
                                    value={title}
                                    name="title"
                                    onChange={handleChange}
                                    placeholder="Enter Title here"
                                    required
                                />
                            </Grid>

                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="blog-description">
                                    Blog Description
                                </label>
                                <textarea
                                    id="event-description"
                                    value={introduction}
                                    onChange={handleChange}
                                    name="introduction"
                                    placeholder="Enter Blogs Page Introduction"
                                    rows={1}
                                    required
                                ></textarea>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="event-title">
                                    Blog Subtitle
                                </label>
                                <input
                                    type="text"
                                    id="blog-title"
                                    value={subtitle}
                                    name="subtitle"
                                    onChange={handleChange}
                                    placeholder="Enter Subtitle for Blog page"
                                    required
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <label htmlFor="blog-title">Blog Banner</label>
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="img/*"
                                    s3path="blog_banners/"
                                    scrubFilename={(filename) =>
                                        `blog_banner_${filename}`
                                    }
                                    onProgress={setUploadProgress}
                                    onFinish={saveBanner}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={false}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                                <button
                                    onClick={uploadBanner}
                                    disabled={bannerUploaded}
                                >
                                    {bannerUploaded
                                        ? 'Uploaded'
                                        : uploadProgress === 0
                                        ? 'Upload'
                                        : `${uploadProgress}%`}
                                </button>
                            </Grid>
                            <Grid
                                minHeight={'50vh'}
                                item
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <BlogEditor
                                    content={content}
                                    setContent={(newContent) =>
                                        setBlogData({
                                            ...blogData,
                                            content: newContent,
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                        <hr />
                        <button type="submit">
                            {!blogDataEdit ? 'Create' : 'Save'} Blog
                        </button>
                    </form>
                </section>
            </Box>
        </Modal>
    );
};

export default Blogcreate;
