import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    phoneNumber: '',
    password: '',
    modalOpen: false,
    isCandidateTab: true,
    isLoading: false,
    isAuth: false,
    error: '',
};

export const login = createSlice({
    name: 'login',
    initialState,
    reducers: {
        updateEmail: (state, action) => {
            state.email = action.payload;
        },
        updatePassword: (state, action) => {
            state.password = action.payload;
        },
        updatePhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        openLoginModal: (state) => {
            state.modalOpen = true;
        },
        closeLoginModal: (state) => {
            state.modalOpen = false;
        },
        openCandidateTab: (state) => {
            state.isCandidateTab = true;
        },
        openRecruiterTab: (state) => {
            state.isCandidateTab = false;
        },
        loginPending: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state) => {
            state.isLoading = false;
            state.isAuth = true;
            state.error = '';
            state.email = '';
            state.password = '';
            state.phoneNumber = '';
        },
        loginFail: (state, { payload }) => {
            state.isLoading = false;
            state.isAuth = false;
            state.error = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateEmail,
    updatePassword,
    updatePhoneNumber,
    openLoginModal,
    closeLoginModal,
    openCandidateTab,
    openRecruiterTab,
    loginPending,
    loginSuccess,
    loginFail,
} = login.actions;

export default login.reducer;
