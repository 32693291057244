import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/system';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';

export const RegistrationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh);
`;

export const RegistrationLeft = styled.div`
    width: 50%;
    background-image: url(${({ image }) => image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

export const RegistrationRight = styled.div`
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .r-heading {
        text-align: center;
        font-weight: 800;
    }

    .or-symbol {
        color: #909090;
        font-weight: 500;
        margin: 0.5rem 0;
    }

    hr {
        height: 2px;
        width: 70%;
        border: none;
        background-color: #e9ecf2;
        margin: 0;
    }

    p {
        margin: 0;
        color: #a0a0a0;
    }

    .fg-registration {
        display: flex;
        gap: 0.5rem;
    }

    .inline-button {
        color: var(--primary);
        cursor: pointer;
    }

    @media screen and (max-width: 900px) {
        width: 50%;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }

    @media screen and (max-width: 400px) {
        .fg-registration {
            flex-direction: column;
        }
    }
`;

export const RegisterFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 90%;
`;

export const Tab = MuiStyled(TabUnstyled)`
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    &:hover {
        background-color: gray;
    }

    &:focus {
        color: black;
        border-radius: 3px;
        outline: 2px solid white;
        outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
        background-color: var(--primary);
        color: white;
    }

    &.${buttonUnstyledClasses.disabled} {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const TabPanel = MuiStyled(TabPanelUnstyled)`
    width: 100%;
    font-size: 0.875rem;
`;

export const TabsList = MuiStyled(TabsListUnstyled)`
    min-width: 320px;
    background-color: var(--primary);
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
`;

export const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    boxShadow: 'none',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    outline: 'none',
};

export const TabsStyle = {
    backgroundColor: 'transparent',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    '.MuiTabs-indicator': {
        display: 'none',
    },
};

export const TabStyle = {
    backgroundColor: 'lightgray',
    color: 'gray',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    border: 'none',

    '&:hover': {
        backgroundColor: 'lightgray',
    },

    '&:focus': {
        backgroundColor: 'white',
    },

    '&.Mui-selected': {
        backgroundColor: 'white',
        color: 'black',
        borderBottom: 'none',
    },
};

export const TabPanelStyle = {
    backgroundColor: 'white',
    padding: 0,
};

export const LoginTab = styled.div`
    background-color: white;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-top-right-radius: 0.5rem;

    .r-heading {
        text-align: center;
    }

    .fg-registration {
        display: flex;
        gap: 0.5rem;
    }

    .or-symbol {
        color: #909090;
        font-weight: 500;
        margin: 0.5rem 0;
    }

    hr {
        height: 1px;
        width: 70%;
        border: none;
        background-color: #e9ecf2;
        margin: 0;
    }

    p {
        margin: 0;
        color: #a0a0a0;
    }

    .inline-button {
        color: var(--primary);
        cursor: pointer;
    }

    @media screen and (max-width: 400px) {
        .fg-registration {
            flex-direction: column;
        }
    }
`;
