import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import useInputState from '../../hooks/useInputState';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ShareImgd from '../../assets/illustrations/Send.svg';
import ShareImgd1 from '../../assets/illustrations/Send-1.svg';
import RR from '../../assets/illustrations/Document.svg';
import RR1 from '../../assets/illustrations/Document-1.svg';
import MI from '../../assets/illustrations/2 User.svg';
import MI1 from '../../assets/illustrations/2 User-1.svg';

import Cate from '../../assets/illustrations/Category.svg';
import Cate1 from '../../assets/illustrations/Category-1.svg';

import { CDRR, RRB, RR1h, RR1d, RR1b, RR1t } from './CDComponents';
import { Grid } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '50vh',
    bgcolor: 'background.paper',
    borderRadius: 5,
    border: 'none',
};

const mentorCardStyles = {
    borderRadius: '15px',
    cursor: 'pointer',
    padding: '15px',
};

const CDCardsVal = ({ candidateData, setCandidateData }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = useState(false);
    const [projectModalData, setProjectModalData] = useState({ text: '' });
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => {
        setProjectModalData({ text: '' });
        setOpen3(false);
    };

    const mentorsData = [
        { name: 'Startups' },
        { name: 'Top tech firms' },
        {
            name: 'Global consulting firms',
        },
        {
            name: 'Top global universities',
        },
        { name: 'Top investment banking firms' },
    ];

    const [interviewDate, setInterviewDate, resetInterviewDate] =
        useInputState('');
    const [interviewTime, setInterviewTime, resetInterviewTime] =
        useInputState('');
    const [interviewField, setInterviewField, resetInterviewField] =
        useInputState('');
    const [chosenMentorIdx, setChosenMentorIdx] = useState(null);

    let dispatch = useDispatch();
    const userId = JSON.parse(localStorage.getItem('user')).userId;

    // Resume-review Upload
    const uploader = useRef();

    const uploadResume = (e) => {
        uploader.current.uploadFile();
    };

    const saveResume = (data) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            resumeS3FileKey: data.fileKey,
            resumeS3PublicUrl: data.publicUrl,
        };
        axios
            .put('/api/user/upload-resume', payload, config)
            .then((res) => {
                if (res.data.userId) {
                    setCandidateData(res.data.userData);
                    handleClose();
                    dispatch(
                        handleSnackOpen({
                            message: 'Resume uploaded successfully',
                            variant: 'success',
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    // Mock Interview request
    const handleMockInterview = (e) => {
        if ((interviewDate && interviewTime && interviewField) !== '') {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const payload = {
                requestedDate: interviewDate,
                requestedTime: interviewTime,
                field: interviewField,
                action: 'request',
            };
            axios
                .post('/api/user/interview', payload, config)
                .then((res) => {
                    if (res.data.userId) {
                        resetInterviewDate();
                        resetInterviewTime();
                        resetInterviewField();
                        setCandidateData(res.data.userData);
                        handleClose1();
                        dispatch(
                            handleSnackOpen({
                                message:
                                    'Mock Interview requested successfully, we will get back with you soon via email.',
                                variant: 'success',
                            })
                        );
                    } else if (
                        res.data.status.toLowerCase() === 'not allowed'
                    ) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'warning',
                            })
                        );
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const chooseMentor = (e) => {
        setChosenMentorIdx(parseInt(e.currentTarget.getAttribute('value')));
    };

    const handleMentorship = (e) => {
        if (chosenMentorIdx !== null) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            axios
                .post(
                    '/api/user/mentorship',
                    { mentorName: mentorsData[chosenMentorIdx].name },
                    config
                )
                .then((res) => {
                    if (res.data.mentorship || res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: res.data.mentorship
                                    ? 'warning'
                                    : 'success',
                            })
                        );
                        setChosenMentorIdx(null);
                        setCandidateData(res.data.userData);
                        handleClose2();
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Please choose a mentor',
                    variant: 'warning',
                })
            );
        }
    };

    const handleProject = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            userId: JSON.parse(localStorage.getItem('user'))._id,
        };
        setProjectModalData({ text: 'Applying...' });
        handleOpen3();
        axios
            .post('/api/user/apply/project', payload, config)
            .then((res) => {
                if (res.data.msg) {
                    setProjectModalData({ text: res.data.msg });
                    setCandidateData(res.data.userData);
                }
            })
            .catch((err) => console.log(err));
    };

    const mockInterviewRequested =
        candidateData &&
        candidateData.interviewRequests &&
        candidateData.interviewRequests.length > 0
            ? candidateData.interviewRequests.find(
                  (interview) => interview.interviewType === 'mock'
              )
            : false;
    return (
        <>
            <CDRR>
                <i>
                    <img src={ShareImgd1} />
                </i>
                <span>
                    <img src={ShareImgd} />
                </span>

                <h1>Mentorship</h1>
                <p>
                    Get guidance and <br /> industry exper mentors
                </p>
                <br />
                <div>
                    <button type="submit" onClick={handleOpen2}>
                        Explore
                    </button>
                </div>
            </CDRR>
            <CDRR>
                <i>
                    <img src={RR1} />
                </i>
                <span>
                    <img src={RR} />
                </span>
                <h1>Resume Review</h1>
                <p>
                    Make an <br />
                    impactful resume
                </p>
                <br />
                <div>
                    <button type="submit" onClick={handleOpen} >
                        {candidateData &&
                        candidateData.resume &&
                        candidateData.resume.reviewText
                            ? 'Check Review'
                            : 'Upload'}
                    </button>
                </div>
            </CDRR>
            <CDRR>
                <i>
                    <img src={MI1} />
                </i>
                <span>
                    <img src={MI} />
                </span>
                <h1>Mock Interview</h1>
                <p>
                    Get a step ahead with
                    <br />
                    real interviews
                </p>
                <br />
                <div>
                    <button type="submit" onClick={handleOpen1}>
                        Book Slot
                    </button>
                </div>
            </CDRR>
            <CDRR>
                <i>
                    <img src={Cate1} />
                </i>
                <span>
                    <img src={Cate} />
                </span>
                <h1>Live Projects</h1>
                <p>
                    Learn by doing, work on various live projects with industry
                    experts.
                </p>
                <br />
                <div>
                    <button onClick={handleProject}>Enroll</button>
                </div>
            </CDRR>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Resume review"
                aria-describedby="upload resume"
            >
                <Box sx={style}>
                    <RRB>
                        <RR1h>Resume Review</RR1h>
                        {candidateData &&
                        candidateData.resume &&
                        candidateData.resume.resumeS3FileKey &&
                        !candidateData.resumeIsReviewed ? (
                            <RR1d>
                                Your CV has been received. You will recieve
                                review soon.
                            </RR1d>
                        ) : candidateData &&
                          candidateData.resume &&
                          !candidateData.resume.reviewText ? (
                            <>
                                <RR1d>
                                    Share your resume and you will get your
                                    resume reviwed by our mentors within 48
                                    hours
                                </RR1d>
                                <ReactS3Uploader
                                    
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="pdf/*"
                                    s3path="resumes/"
                                    scrubFilename={(filename) =>
                                        `resume_${userId}`
                                    }
                                    onFinish={saveResume}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={false}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                                <RR1b onClick={uploadResume}>Upload</RR1b>
                            </>
                        ) : candidateData &&
                          candidateData.resume &&
                          candidateData.resume.reviewText ? (
                            <RR1d>{candidateData.resume.reviewText}</RR1d>
                        ) : (
                            <RR1d>Loading</RR1d>
                        )}
                    </RRB>
                </Box>
            </Modal>

            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="Mock Interview"
                aria-describedby="Schedule mock interview"
            >
                <Box sx={style}>
                    <RRB>
                        <RR1h>Mock Interview</RR1h>
                        {mockInterviewRequested &&
                        mockInterviewRequested.status === 'requested' ? (
                            <RR1d>
                                You are added to the waitlist . You will be
                                reached out soon by the team!
                            </RR1d>
                        ) : (
                            <>
                                <RR1d>
                                    Select your date and time for mock interview
                                </RR1d>
                                <RR1t>
                                    <input
                                        type="date"
                                        value={interviewDate}
                                        onChange={setInterviewDate}
                                        placeholder="Enter Date DD/MM/YYYY"
                                    />
                                </RR1t>
                                <RR1t>
                                    <input
                                        type="time"
                                        value={interviewTime}
                                        onChange={setInterviewTime}
                                        placeholder="Enter Time (00:00)"
                                    />
                                </RR1t>
                                <RR1t>
                                    <input
                                        type="text"
                                        value={interviewField}
                                        onChange={setInterviewField}
                                        placeholder="Eg: Full Stack Developer..."
                                        style={{ cursor: 'text' }}
                                    />
                                </RR1t>
                                <RR1b onClick={handleMockInterview}>
                                    Schedule
                                </RR1b>
                            </>
                        )}
                    </RRB>
                </Box>
            </Modal>

            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="Mentorship"
                aria-describedby="get mentorship"
            >
                <Box sx={{ ...style, width: 900, padding: '20px' }}>
                    <RR1h>Mentorship</RR1h>
                    {candidateData &&
                    candidateData.mentorship &&
                    candidateData.mentorship.status === 'requested' ? (
                        <RR1d>
                            You are added to the waitlist . You will be reached
                            out soon by the team!
                        </RR1d>
                    ) : (
                        <>
                            <RR1d>Select a mentorship domain</RR1d>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '80%',
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        marginBottom: '20px',
                                        width: '80vw',
                                        marginLeft: '20%',
                                    }}
                                >
                                    {mentorsData.map((mentor, idx) => (
                                        <Grid item md={4} sm={6} xs={12}>
                                            <div
                                                className="mentor-card"
                                                style={{
                                                    ...mentorCardStyles,
                                                    border:
                                                        chosenMentorIdx &&
                                                        chosenMentorIdx.toString() ===
                                                            idx.toString()
                                                            ? '1px solid #4544A2'
                                                            : '1px solid #D7DEF0',
                                                }}
                                                key={idx}
                                                value={idx}
                                                onClick={chooseMentor}
                                            >
                                                <h1
                                                    style={{ fontSize: '16px' }}
                                                >
                                                    {mentor.name}
                                                </h1>
                                                {/* <p style={{ fontSize: '14px' }}>
                                                        {mentor.company}, {mentor.role}
                                                    </p> */}
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                            <RR1b onClick={handleMentorship}>
                                Request Mentorship
                            </RR1b>
                        </>
                    )}
                </Box>
            </Modal>

            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="Project"
                aria-describedby="apply for project"
            >
                <Box
                    sx={{
                        ...style,
                        height: 'fit-content',
                        paddingBottom: '20px',
                    }}
                >
                    {candidateData && candidateData.appliedForProject ? (
                        <RR1d>
                            You are added to the waitlist . You will be reached
                            out soon by the team!
                        </RR1d>
                    ) : (
                        <>
                            <RR1d>{projectModalData.text}</RR1d>
                            <RR1b onClick={handleClose3}>Close</RR1b>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default CDCardsVal;
