import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';
import { Container, Left, Right } from '../components/Forum/Forum';
import '../components/Forum/Forum.css';
import FLeft from '../components/Forum/FLeft';
import FRight from '../components/Forum/FRight';

function App() {
    const [show, setShow] = useState('show');
    const [noshow, setNoshow] = useState('noshow');
    const [postData, setPostData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [socket, setSocket] = useState(null);
    let userAvatar = useSelector((st) => st.authStatus.user.avatarS3FileKey);

    let params = useParams();
    let dispatch = useDispatch();

    function like() {
        setShow('noshow');
        setNoshow('show');
    }

    function nolike() {
        setShow('show');
        setNoshow('noshow');
    }

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        if (params && params.slug) {
            axios
                .get(`/api/forum/post/${params.slug}`, config)
                .then((res) => {
                    if (res.data.post) {
                        setPostData(res.data.post);
                        setIsLoading(false);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .get('/api/forum/posts', config)
                .then((res) => {
                    if (res.data.posts) {
                        const todaysPost = res.data.posts.find((post) =>
                            moment().isSame(post.createdAt, 'day')
                        );
                        if (todaysPost) {
                            setPostData(todaysPost);
                            setIsLoading(false);
                        } else {
                            setPostData(res.data.posts[0]);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    }, []);

    useEffect(() => {
        setSocket(
            io('https://supergrad.co.in/', {
                query: { token: localStorage.getItem('token') },
            })
        );

        if (socket !== null && postData) {
            socket.on('connect', () => {
                socket.emit('join-room', { roomId: postData._id });

                socket.on('new-comment', (data) => {
                    setPostData((currState) => ({
                        ...currState,
                        comments: [
                            ...currState.comments,
                            { ...data.newComment },
                        ],
                    }));
                });

                socket.on('new-post-like', (data) => setPostData(data.post));
                socket.on('new-comment-like', (data) => setPostData(data.post));
            });
        }

        // Disconnect socket on component unmount
        return () => {
            if (socket !== null) {
                socket.emit('leave-room', { roomId: postData._id });
                socket.disconnect(true);
            }
        };
    }, [isLoading]);

    const postComment = (commentText) => {
        if (commentText !== '')
            socket.emit('new-comment', { commentText, roomId: postData._id });
        else {
            dispatch(
                handleSnackOpen({
                    message: 'Comment field is empty!',
                    variant: 'warning',
                })
            );
        }
    };

    const likePost = (e) => {
        socket.emit('like-post', { roomId: postData._id });
    };

    const likeComment = (e) => {
        socket.emit('like-comment', {
            roomId: postData._id,
            commentId: e.currentTarget.getAttribute('value'),
        });
    };

    return (
        <>
            <div className="filler-div"></div>
            <Container>
                <Left>
                    <FLeft
                        postComment={postComment}
                        postData={postData}
                        likePost={likePost}
                        userAvatar={userAvatar}
                    />
                </Left>
                <Right>
                    <FRight
                        comments={
                            postData && postData.comments
                                ? postData.comments
                                : []
                        }
                        likeComment={likeComment}
                    />
                    <br />
                    <br />
                    <br />
                </Right>
            </Container>
        </>
    );
}

export default App;
