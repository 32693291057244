import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import AdminJobDetails from './AdminJobDetails';
import '../../styles/Admin/AdminManageJobs.css';

function AdminManageJobs({
    jobIsOpen,
    currJob,
    setJobIsOpen,
    setCurrJob,
    handleJobOpen,
}) {
    const [newJobs, setNewJobs] = useState([]);
    const [ongoingJobs, setOngoingJobs] = useState([]);
    // const [jobIsOpen, setJobIsOpen] = useState(false);
    // const [currJob, setCurrJob] = useState('');

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/admin/jobs', config)
            .then((res) => {
                if (res.data.newJobs) setNewJobs(res.data.newJobs);
                if (res.data.otherJobs) setOngoingJobs(res.data.otherJobs);
            })
            .catch((err) => console.log(err));
    }, [jobIsOpen]);

    // const handleJobOpen = (e) => {
    //     setJobIsOpen(!jobIsOpen);
    //     if (e && e.currentTarget.getAttribute('value'))
    //         setCurrJob(e.currentTarget.getAttribute('value'));
    //     else setCurrJob('');
    // };

    return !jobIsOpen ? (
        <section className="AdminManageJobs">
            <div className="new-jobs-container">
                <h1>New Jobs</h1>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    {newJobs && newJobs.length > 0 ? (
                        newJobs.map((job) => (
                            <Grid item md={4} sm={6} xs={12} key={job._id}>
                                <div
                                    className="admin-job-card"
                                    onClick={handleJobOpen}
                                    value={job._id}
                                >
                                    <h1>
                                        {job.jobTitle},{' '}
                                        {moment(job.createdAt).format('YYYY')}
                                    </h1>
                                    <h2>
                                        {job?.recruiter?.company?.companyName}
                                    </h2>
                                    <p>
                                        {moment(job.createdAt).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </p>
                                </div>
                            </Grid>
                        ))
                    ) : (
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ textAlign: 'center', marginTop: '10%' }}
                        >
                            <p className="no-data-msg">No new jobs today 📅</p>
                        </Grid>
                    )}
                </Grid>
            </div>
            <div className="ongoing-jobs-container">
                <h1>Ongoing Jobs</h1>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    {ongoingJobs && ongoingJobs.length > 0 ? (
                        ongoingJobs.map(
                            (job) =>
                                job.isActive && (
                                    <Grid
                                        item
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        key={job._id}
                                    >
                                        <div
                                            className="admin-job-card"
                                            onClick={handleJobOpen}
                                            value={job._id}
                                        >
                                            <h1>
                                                {job.jobTitle},{' '}
                                                {moment(job.createdAt).format(
                                                    'YYYY'
                                                )}
                                            </h1>
                                            <h2>
                                                {
                                                    job?.recruiter?.company
                                                        ?.companyName
                                                }
                                            </h2>
                                            <p>
                                                {moment(job.createdAt).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </p>
                                        </div>
                                    </Grid>
                                )
                        )
                    ) : (
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ textAlign: 'center', marginTop: '10%' }}
                        >
                            <p className="no-data-msg">Looking for Jobs 🔍</p>
                        </Grid>
                    )}
                </Grid>
            </div>

            <div
                style={{ marginTop: '5rem' }}
                className="archived-job-container"
            >
                <h1>Archived Jobs</h1>
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    {ongoingJobs && ongoingJobs.length > 0 ? (
                        ongoingJobs.map(
                            (job) =>
                                !job.isActive && (
                                    <Grid
                                        item
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        key={job._id}
                                    >
                                        <div
                                            className="admin-job-card"
                                            onClick={handleJobOpen}
                                            value={job._id}
                                        >
                                            <h1>
                                                {job.jobTitle},{' '}
                                                {moment(job.createdAt).format(
                                                    'YYYY'
                                                )}
                                            </h1>
                                            <h2>
                                                {
                                                    job?.recruiter?.company
                                                        ?.companyName
                                                }
                                            </h2>
                                            <p>
                                                {moment(job.createdAt).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </p>
                                        </div>
                                    </Grid>
                                )
                        )
                    ) : (
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ textAlign: 'center', marginTop: '10%' }}
                        >
                            <p className="no-data-msg">Looking for Jobs 🔍</p>
                        </Grid>
                    )}
                </Grid>
            </div>
        </section>
    ) : (
        <AdminJobDetails jobId={currJob} closeJob={() => setJobIsOpen(false)} />
    );
}

export default AdminManageJobs;
