import React, { useState, useRef } from 'react';
import useInputState from '../hooks/useInputState';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import { useDispatch, useSelector } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Left,
    OLeft,
    Right,
    TLeft,
    BtnCover,
    Div1,
    Div2,
    Div3,
    Div4,
    Onediv,
    Twodiv,
    Threediv,
    RHeader,
    Wrapdiv,
    RProfilepic,
    RForm,
    RFRow,
    RFRDesc,
} from '../components/RecruiterEditProfile/RecruiterEP';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import Ipath from '../assets/illustrations/REP.png';
import '../components/RecruiterEditProfile/REP.css';
import Image from '../assets/illustrations/PPic.png';

const RecruiterEditProfile = () => {
    const [show, setShow] = useState('show');
    const [noshow, setNoshow] = useState('noshow');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector(
        (st) => st.authStatus.user.userId || st.authStatus.user.id
    );

    function nextdata() {
        setShow('noshow');
        setNoshow('show');
    }

    function prevdata() {
        setShow('show');
        setNoshow('noshow');
    }

    const [fullName, setFullName] = useInputState('');
    const [mobileNo, setMobileNo] = useInputState('');
    const [currentPosition, setCurrentPosition] = useInputState('');
    const [companyName, setCompanyName] = useInputState('');
    const [industry, setIndustry] = useInputState('');
    const [companyDesc, setCompanyDesc] = useInputState('');
    const [companyType, setCompanyType] = useInputState('');
    const [yearOfEstd, setYearOfEstd] = useInputState('');
    const [companySize, setCompanySize] = useInputState('');
    const [companyAddress, setCompanyAddress] = useInputState('');
    const [gstNumber, setGstNumber] = useInputState('');
    const [linkedIn, setLinkedIn] = useInputState('');
    const [avatarS3FileKey, setAvatarS3FileKey] = useState('');
    const [avatarS3PublicUrl, setAvatarS3PublicUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [avatarUploaded, setAvatarUploaded] = useState(false);
    const uploader = useRef();

    const uploadAvatar = (e) => {
        uploader.current.uploadFile();
    };

    const saveAvatar = (data) => {
        setAvatarS3FileKey(data.fileKey);
        setAvatarS3PublicUrl(data.publicUrl);
        setAvatarUploaded(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            (fullName &&
                mobileNo &&
                currentPosition &&
                companyName &&
                industry &&
                companyDesc &&
                companyType &&
                yearOfEstd &&
                companySize &&
                companyAddress &&
                linkedIn &&
                gstNumber) !== ''
        ) {
            const payload = {
                userType: 'recruiter',
                userId,
                fullName,
                mobileNo,
                currentPosition,
                companyName,
                industry,
                companyDesc,
                companyType,
                yearOfEstd,
                companySize,
                companyAddress,
                gstNumber,
                linkedIn
            };
            if ((avatarS3FileKey && avatarS3PublicUrl) !== '') {
                payload.avatarS3FileKey = avatarS3FileKey;
                payload.avatarS3PublicUrl = avatarS3PublicUrl;
            }
            axios
                .put('/api/user/register/profile', payload)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );
                        navigate('/rdashboard');
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <Container>
                <Left>
                    <OLeft>
                        <BtnCover>
                            <div className={show}>
                                <Div1>
                                    Recruiter Details
                                    <i>
                                        <ArrowForwardIosIcon fontSize="small" />
                                    </i>
                                </Div1>
                                <Div2 onClick={nextdata}>Company Profile</Div2>
                            </div>
                            <div className={noshow}>
                                <Div3 onClick={prevdata}>
                                    Recruiter Details
                                    <i>
                                        <DoneIcon fontSize="small" />
                                    </i>
                                </Div3>
                                <Div4>
                                    Company Profile
                                    <i>
                                        <ArrowForwardIosIcon fontSize="small" />
                                    </i>
                                </Div4>
                            </div>
                        </BtnCover>
                        <img src={Ipath} alt="imagealt" />
                    </OLeft>
                    <TLeft>
                        <div className={show}>
                            <Wrapdiv>
                                <Onediv>1</Onediv>
                                <hr />
                                <Twodiv onClick={nextdata}>2</Twodiv>
                            </Wrapdiv>
                        </div>
                        <div className={noshow}>
                            <Wrapdiv>
                                <Onediv onClick={prevdata}>1</Onediv>
                                <hr />
                                <Threediv>2</Threediv>
                            </Wrapdiv>
                        </div>
                    </TLeft>
                </Left>
                <Right style={{ paddingBottom: '20px' }}>
                    <RHeader>
                        <div className={show}>Recruiter Details</div>
                        <div className={noshow}>Company Profile</div>
                    </RHeader>
                    <div className={show}>
                        <RProfilepic>
                            <span>
                                <img
                                    src={
                                        avatarS3FileKey !== ''
                                            ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${avatarS3FileKey}`
                                            : Image
                                    }
                                    alt="Profile Picture"
                                />
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="img/*"
                                    s3path="profile_pics/recruiter/"
                                    scrubFilename={(filename) =>
                                        `profile_pic-${userId}`
                                    }
                                    onProgress={setUploadProgress}
                                    onFinish={saveAvatar}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={false}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                            </span>
                            <button onClick={uploadAvatar}>
                                {avatarUploaded
                                    ? 'Uploaded'
                                    : uploadProgress === 0
                                    ? 'Upload Profile Pic'
                                    : `${uploadProgress}%`}
                            </button>
                        </RProfilepic>
                        <p 
                            className='instruct'
                            style={{
                                fontStyle: 'italic',
                                fontSize: '14px',
                                margin: '10px 0'
                            }}
                        >
                            * Please click 'Upload' after choosing a file.
                        </p>
                        <RForm>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Full Name{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="Enter your full name"
                                        value={fullName}
                                        onChange={setFullName}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Mobile Number{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="number"
                                        value={mobileNo}
                                        onChange={setMobileNo}
                                        placeholder="Enter your phone number"
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Email{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        value={
                                            JSON.parse(
                                                localStorage.getItem('user')
                                            ).email
                                        }
                                        disabled={true}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Current Position{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        value={currentPosition}
                                        onChange={setCurrentPosition}
                                        placeholder="Enter your designation"
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                {/* <RFRDesc>
                                    <h1>
                                        Brief Description About Your Experience
                                    </h1>
                                    <textarea
                                        type="text"
                                        value={experienceDesc}
                                        onChange={setExperienceDesc}
                                        placeholder="Enter here"
                                        id="desc"
                                    />
                                </RFRDesc> */}
                                <RFRDesc>
                                    <h1>
                                        LinkedIn Profile
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input 
                                        type="text"
                                        placeholder='Add LinkedIn profile link'
                                        value={linkedIn}
                                        onChange={setLinkedIn}
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc></RFRDesc>
                                <RFRDesc>
                                    <button onClick={nextdata}>Next</button>
                                </RFRDesc>
                            </RFRow>
                        </RForm>
                    </div>
                    <div className={noshow}>
                        <RForm>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Company Name{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        value={companyName}
                                        onChange={setCompanyName}
                                        placeholder="Enter company name"
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Industry{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        value={industry}
                                        onChange={setIndustry}
                                        placeholder="Enter Industry"
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Company Type{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <select
                                        name="companyType"
                                        onChange={setCompanyType}
                                    >
                                        <option value="">
                                            Select Company Type
                                        </option>
                                        <option value="Start Up (Bootstrapped)">
                                            Start Up (Bootstrapped)
                                        </option>
                                        <option value="Start Up (Funded)">
                                            Start Up (Funded)
                                        </option>
                                        <option value="MNC">MNC</option>
                                    </select>
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Year of Establishment{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="number"
                                        value={yearOfEstd}
                                        onChange={setYearOfEstd}
                                        placeholder="Enter Year Here"
                                        id="check"
                                        min={0}
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Company Size{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <select
                                        name="companysize"
                                        placeholder=""
                                        onChange={setCompanySize}
                                    >
                                        <option value="">
                                            Select range of company employees
                                        </option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-100">11-100</option>
                                        <option value="101-1000">
                                            101-1000
                                        </option>
                                        <option value="1000+">1000+</option>
                                    </select>
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Company Head Office Address{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        value={companyAddress}
                                        onChange={setCompanyAddress}
                                        placeholder="Enter here"
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        GST Number
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input 
                                        type="text"
                                        placeholder='Enter GST Number'
                                        value={gstNumber}
                                        onChange={setGstNumber}
                                    />
                                </RFRDesc>
                                {/* <RFRDesc>
                                    <h1>Company Vision</h1>
                                    <textarea
                                        type="text"
                                        value={companyVision}
                                        onChange={setCompanyVision}
                                        placeholder="Enter Here"
                                    />
                                </RFRDesc> */}
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Description{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <textarea
                                        type="text"
                                        value={companyDesc}
                                        onChange={setCompanyDesc}
                                        placeholder="Give a description about the company"
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc></RFRDesc>
                                <RFRDesc>
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </RFRDesc>
                            </RFRow>
                        </RForm>
                    </div>
                </Right>
            </Container>
        </>
    );
};

export default RecruiterEditProfile;
