import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleSnackOpen } from '../../store/Toast/Toast';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import useInputState from '../../hooks/useInputState';
import Config from '../../config.json';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../../styles/CandidateDashboard/CandidateProfile.css';
import defaultAvatar from '../../assets/illustrations/PPic.png';

function CandidateProfile(props) {
    const [userData, setUserData] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [avatarS3FileKey, setAvatarS3FileKey] = useState('');
    const [avatarS3PublicUrl, setAvatarS3PublicUrl] = useState('');
    const [avatarUploaded, setAvatarUploaded] = useState(false);
    const [resumeUploadProgress, setResumeUploadProgress] = useState(0);
    const [resumeS3FileKey, setResumeS3FileKey] = useState('');
    const [resumeS3PublicUrl, setResumeS3PublicUrl] = useState('');
    const [resumeUploaded, setResumeUploaded] = useState(false);

    const [fullName, setFullName, resetFullName, initFullName] =
        useInputState('');
    const [mobileNo, setMobileNo, resetMobileNo, initMobileNo] =
        useInputState('');
    const [password, setPassword, resetPassword, initPassword] =
        useInputState('');
    const [state, setState, resetState, initState] = useInputState('');
    const [email, setEmail, resetEmail, initEmail] = useInputState('');
    const [college, setCollege, resetCollege, initCollege] = useInputState('');
    const [degree, setDegree, resetDegree, initDegree] = useInputState('');
    const [startingYear, setStartingYear, resetStartingYear, initStartingYear] =
        useInputState('');
    const [
        graduationYear,
        setGraduationYear,
        resetGraduationYear,
        initGraduationYear,
    ] = useInputState('');
    const [lookingFor, setLookingFor] = useState([]);
    const [jobType, setJobType] = useState([]);
    const [preferredLocations, setPreferredLocations] = useState([]);
    const [fields, setFields] = useState([]);
    const [expectedCtc, setExpectedCtc, resetExpectedCtc, initExpectedCtc] =
        useInputState('');
    const [skills, setSkills] = useState([]);
    const [userId, setUserId] = useState('');
    const [currTab, setCurrTab] = useState(0);

    const handleCurrTabChange = (event, newValue) => {
        setCurrTab(newValue);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get('/api/auth/user', config)
            .then((res) => {
                if (res.data.user) {
                    const user = res.data.user;
                    setUserId(user._id);
                    setUserData(user);
                    initFullName(user.fullName);
                    initMobileNo(user.mobileNo);
                    initState(user.state);
                    initEmail(user.email);
                    initCollege(user.college.collegeName);
                    initDegree(user.college.degree);
                    initStartingYear(user.college.startingYear);
                    initGraduationYear(user.college.graduationYear);
                    initPassword('');
                    setLookingFor(
                        user && user.preferences
                            ? user.preferences.lookingFor
                            : []
                    );
                    setJobType(
                        user && user.preferences ? user.preferences.jobType : []
                    );
                    setPreferredLocations(
                        user && user.preferences
                            ? user.preferences.preferredLocations
                            : []
                    );
                    setFields(
                        user && user.preferences ? user.preferences.fields : []
                    );
                    initExpectedCtc(
                        user && user.preferences
                            ? user.preferences.expectedCtc
                            : ''
                    );
                    setSkills(
                        user && user.experience ? user.experience.skills : []
                    );
                }
            })
            .catch((err) => console.log(err));
    }, []);

    // Avatar Update
    const uploader = useRef();

    const uploadAvatar = (e) => {
        uploader.current.uploadFile();
    };

    const saveAvatar = (data) => {
        setAvatarS3FileKey(data.fileKey);
        setAvatarS3PublicUrl(data.publicUrl);
        setAvatarUploaded(true);
    };

    const handleLookingFor = (e) => {
        setLookingFor((currSt) => {
            if (e.target.checked) {
                const foundType = currSt.find((el) => el === e.target.value);
                if (foundType) return currSt;
                else return [...currSt, e.target.value];
            } else {
                return currSt.filter((el) => el !== e.target.value);
            }
        });
    };

    const handleJobType = (e) => {
        setJobType((currSt) => {
            if (e.target.checked) {
                const foundType = currSt.find((el) => el === e.target.value);
                if (foundType) return currSt;
                else return [...currSt, e.target.value];
            } else {
                return currSt.filter((el) => el !== e.target.value);
            }
        });
    };

    const handleLocationChange = (e) => {
        const index = parseInt(e.currentTarget.name);
        setPreferredLocations((st) =>
            st.map((el, idx) => {
                if (index === idx) return e.target.value;
                else return el;
            })
        );
    };

    const handleFieldChange = (e) => {
        const index = parseInt(e.currentTarget.name);
        setFields((st) =>
            st.map((el, idx) => {
                if (index === idx) return e.target.value;
                else return el;
            })
        );
    };

    const addNewSkill = (e) => {
        setSkills([...skills, { skill: '', level: '' }]);
    };

    const handleSkillText = (e) => {
        const index = parseInt(e.target.name);
        setSkills((st) =>
            st.map((s, idx) => {
                if (index === idx) return { ...s, skill: e.target.value };
                else return s;
            })
        );
    };
    const handleSkillLevel = (e) => {
        const index = parseInt(e.target.name);
        setSkills((st) =>
            st.map((s, idx) => {
                if (index === idx) return { ...s, level: e.target.value };
                else return s;
            })
        );
    };

    const submitChanges = (e) => {
        let filteredSkills = skills.filter(
            (s) => s.skill !== '' && s.level !== ''
        );
        let filteredLocations = preferredLocations.filter(
            (location) => location !== ''
        );
        let filteredFields = fields.filter((field) => field !== '');
        if (
            (fullName ||
                mobileNo ||
                state ||
                email ||
                college ||
                degree ||
                startingYear ||
                graduationYear) === '' ||
            filteredSkills.length === 0
        ) {
            dispatch(
                handleSnackOpen({
                    message: 'Fields cannot be empty!',
                    variant: 'warning',
                })
            );
        } else {
            const payload = {
                userId,
                userType: 'user',
                fullName,
                mobileNo,
                state,
                college,
                degree,
                startingYear,
                graduationYear,
                lookingFor,
                jobType,
                preferredLocations,
                fields,
                expectedCtc,
                password,
                skills: filteredSkills,
            };
            if ((avatarS3FileKey && avatarS3PublicUrl) !== '') {
                payload.avatarS3FileKey = avatarS3FileKey;
                payload.avatarS3PublicUrl = avatarS3PublicUrl;
            }
            axios
                .put('/api/user/register/profile', payload)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: 'Profile updated successfully!',
                                variant: 'success',
                            })
                        );
                        navigate('/dashboard');
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    // Resume
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayPageNav, setDisplayPageNav] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const showPageNav = (e) => {
        setDisplayPageNav(true);
    };

    const hidePageNav = (e) => {
        setDisplayPageNav(false);
    };

    const resumeUploader = useRef();

    const uploadResume = (e) => {
        resumeUploader.current.uploadFile();
    };

    const saveResume = (data) => {
        setResumeS3FileKey(data.fileKey);
        setResumeS3PublicUrl(data.publicUrl);
        setResumeUploaded(true);
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            resumeS3FileKey: data.fileKey,
            resumeS3PublicUrl: data.publicUrl,
        };
        axios
            .put('/api/user/upload-resume', payload, config)
            .then((res) => {
                if (res.data.userId) {
                    dispatch(
                        handleSnackOpen({
                            message: 'Resume updated successfully!',
                            variant: 'success',
                        })
                    );
                    setUserData((currSt) => ({
                        ...currSt,
                        resume: {
                            ...currSt.resume,
                            resumeS3FileKey: data.fileKey,
                            resumeS3PublicUrl: data.publicUrl,
                        },
                    }));
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <section className="CandidateDashboardProfile">
            <div className="filler-div"></div>
            <h1 className="header">Profile</h1>
            <div className="profile-container">
                <Box sx={{ width: '50%', margin: '20px auto' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={currTab}
                            onChange={handleCurrTabChange}
                            aria-label="profile tabs"
                        >
                            <Tab label="Details" />
                            <Tab label="Resume" />
                        </Tabs>
                    </Box>
                </Box>
                {currTab === 0 ? (
                    <>
                        <div className="avatar-container">
                            <img
                                src={
                                    userData && userData.avatarS3FileKey
                                        ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${userData.avatarS3FileKey}`
                                        : defaultAvatar
                                }
                            />
                            <span>
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="img/*"
                                    s3path="profile_pics/candidate/"
                                    scrubFilename={(filename) =>
                                        `profile_pic-${
                                            userData && userData.userId
                                        }`
                                    }
                                    onProgress={setUploadProgress}
                                    onFinish={saveAvatar}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={false}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                                <button onClick={uploadAvatar}>
                                    {avatarUploaded
                                        ? 'Uploaded'
                                        : uploadProgress === 0
                                        ? 'Upload Profile Pic'
                                        : `${uploadProgress}%`}
                                </button>
                            </span>
                        </div>
                        <div className="field-container">
                            <div>
                                <h2>General Details</h2>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="full-name">
                                            Full Name
                                        </label>
                                        <input
                                            type="text"
                                            value={fullName}
                                            onChange={setFullName}
                                            id="full-name"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="mobileNo">
                                            Mobile No
                                        </label>
                                        <input
                                            type="text"
                                            value={mobileNo}
                                            onChange={setMobileNo}
                                            id="mobileNo"
                                        />
                                    </span>
                                </div>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            value={state}
                                            onChange={setState}
                                            id="state"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={setEmail}
                                            id="email"
                                        />
                                    </span>
                                </div>
                                {!userData.isPassword && (
                                    <div className="input-container">
                                        <span>
                                            <label htmlFor="password">
                                                Password
                                            </label>
                                            <input
                                                type="text"
                                                value={password}
                                                onChange={setPassword}
                                                id="password"
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '100%', margin: '10px auto' }}
                            />
                            <div>
                                <h2>Education Details</h2>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="college">College</label>
                                        <input
                                            type="text"
                                            value={college}
                                            onChange={setCollege}
                                            id="college"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="degree">Degree</label>
                                        <input
                                            type="degree"
                                            value={degree}
                                            onChange={setDegree}
                                            id="degree"
                                        />
                                    </span>
                                </div>
                                <div className="input-container">
                                    <span>
                                        <label htmlFor="starting-year">
                                            StartingYear
                                        </label>
                                        <input
                                            type="text"
                                            value={startingYear}
                                            onChange={setStartingYear}
                                            id="starting-year"
                                        />
                                    </span>
                                    <span>
                                        <label htmlFor="graduation-year">
                                            GraduationYear
                                        </label>
                                        <input
                                            type="graduationYear"
                                            value={graduationYear}
                                            onChange={setGraduationYear}
                                            id="graduation-year"
                                        />
                                    </span>
                                </div>
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '100%', margin: '10px auto' }}
                            />
                            <div>
                                <h2>Preferences</h2>
                                <div
                                    className="input-container"
                                    style={{ flexDirection: 'column' }}
                                >
                                    <span>
                                        <h3>Looking For</h3>
                                        <input
                                            type="checkbox"
                                            value="job"
                                            onChange={handleLookingFor}
                                            id="looking-for"
                                            name="looking-for"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={lookingFor.find(
                                                (el) => el === 'job'
                                            )}
                                        />
                                        <label
                                            htmlFor="looking-for"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            Jobs
                                        </label>
                                        <input
                                            type="checkbox"
                                            value="freelancing"
                                            onChange={handleLookingFor}
                                            id="looking-for"
                                            name="looking-for"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={lookingFor.find(
                                                (el) => el === 'freelancing'
                                            )}
                                        />
                                        <label
                                            htmlFor="looking-for"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            Freelancing
                                        </label>
                                        <input
                                            type="checkbox"
                                            value="internship"
                                            onChange={handleLookingFor}
                                            id="looking-for"
                                            name="looking-for"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={lookingFor.find(
                                                (el) => el === 'internship'
                                            )}
                                        />
                                        <label
                                            htmlFor="looking-for"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            Internships
                                        </label>
                                        <input
                                            type="checkbox"
                                            value="upskilling"
                                            onChange={handleLookingFor}
                                            id="looking-for"
                                            name="looking-for"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={lookingFor.find(
                                                (el) => el === 'upskilling'
                                            )}
                                        />
                                        <label
                                            htmlFor="looking-for"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            Upskilling
                                        </label>
                                    </span>
                                    <span>
                                        <h3>Job Types</h3>
                                        <input
                                            type="checkbox"
                                            value="remote"
                                            onChange={handleJobType}
                                            id="job-type"
                                            name="job-type"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={jobType.find(
                                                (el) => el === 'remote'
                                            )}
                                        />
                                        <label
                                            htmlFor="job-type"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            Remote
                                        </label>
                                        <input
                                            type="checkbox"
                                            value="in-office"
                                            onChange={handleJobType}
                                            id="job-type"
                                            name="job-type"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={jobType.find(
                                                (el) => el === 'in-office'
                                            )}
                                        />
                                        <label
                                            htmlFor="job-type"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            In Office
                                        </label>
                                        <input
                                            type="checkbox"
                                            value="hybrid"
                                            onChange={handleJobType}
                                            id="job-type"
                                            name="job-type"
                                            style={{
                                                display: 'inline',
                                                accentColor: '#4544A2',
                                            }}
                                            defaultChecked={jobType.find(
                                                (el) => el === 'hybrid'
                                            )}
                                        />
                                        <label
                                            htmlFor="job-type"
                                            style={{
                                                display: 'inline',
                                                margin: '0 10px',
                                            }}
                                        >
                                            Hybrid
                                        </label>
                                    </span>
                                </div>
                                <div
                                    className="input-container"
                                    style={{ flexDirection: 'column' }}
                                >
                                    <span>
                                        <h3>Preferred Locations</h3>
                                        <input
                                            type="text"
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            placeholder="Location 1"
                                            name="0"
                                            value={preferredLocations[0]}
                                            onChange={handleLocationChange}
                                        />
                                        <input
                                            type="text"
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            placeholder="Location 2"
                                            name="1"
                                            value={preferredLocations[1]}
                                            onChange={handleLocationChange}
                                        />
                                    </span>
                                    <span>
                                        <h3>Job Field Preferences</h3>
                                        <input
                                            type="text"
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            placeholder="Preference 1"
                                            name="0"
                                            value={fields[0]}
                                            onChange={handleFieldChange}
                                        />
                                        <input
                                            type="text"
                                            style={{
                                                display: 'inline',
                                                marginRight: '10px',
                                            }}
                                            placeholder="Preference 2"
                                            name="1"
                                            value={fields[1]}
                                            onChange={handleFieldChange}
                                        />
                                    </span>
                                    <span>
                                        <h3>Expected CTC</h3>
                                        <input
                                            type="text"
                                            id="expected-ctc"
                                            value={expectedCtc}
                                            onChange={setExpectedCtc}
                                        />
                                    </span>
                                </div>
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '100%', margin: '10px auto' }}
                            />
                            <div>
                                <h2>Skills</h2>
                                <div
                                    className="input-container"
                                    style={{ flexDirection: 'column' }}
                                >
                                    <span>
                                        {skills && skills.length > 0
                                            ? skills.map((s, idx) => (
                                                  <div
                                                      key={idx}
                                                      style={{
                                                          marginBottom: '10px',
                                                      }}
                                                  >
                                                      <input
                                                          type="text"
                                                          value={
                                                              skills[idx].skill
                                                          }
                                                          onChange={
                                                              handleSkillText
                                                          }
                                                          name={idx}
                                                          style={{
                                                              display:
                                                                  'inline-block',
                                                              marginRight:
                                                                  '10px',
                                                          }}
                                                      />
                                                      <select
                                                          onChange={
                                                              handleSkillLevel
                                                          }
                                                          name={idx}
                                                          style={{
                                                              display:
                                                                  'inline-block',
                                                          }}
                                                          className="profile-select-input"
                                                      >
                                                          <option value="">
                                                              Select Level
                                                          </option>
                                                          <option
                                                              value="beginner"
                                                              selected={
                                                                  s.level ===
                                                                  'beginner'
                                                              }
                                                          >
                                                              Beginner
                                                          </option>
                                                          <option
                                                              value="intermediate"
                                                              selected={
                                                                  s.level ===
                                                                  'intermediate'
                                                              }
                                                          >
                                                              Intermediate
                                                          </option>
                                                          <option
                                                              value="advance"
                                                              selected={
                                                                  s.level ===
                                                                  'advance'
                                                              }
                                                          >
                                                              Advance
                                                          </option>
                                                          <option
                                                              value="expert"
                                                              selected={
                                                                  s.level ===
                                                                  'expert'
                                                              }
                                                          >
                                                              Expert
                                                          </option>
                                                      </select>
                                                  </div>
                                              ))
                                            : 'No skills'}
                                        <button
                                            onClick={addNewSkill}
                                            className="add-btn"
                                        >
                                            Add Skill
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '100%', margin: '10px auto' }}
                            />
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <button
                                    className="save-btn"
                                    onClick={submitChanges}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="avatar-container"
                            style={{ marginBottom: '20px' }}
                        >
                            <h1>Update Resume</h1>
                            <span>
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="pdf/*"
                                    s3path="resumes/"
                                    scrubFilename={(filename) =>
                                        `resume-${userData && userData._id}.pdf`
                                    }
                                    onProgress={setResumeUploadProgress}
                                    onFinish={saveResume}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={false}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={resumeUploader}
                                />
                                <button onClick={uploadResume}>
                                    {resumeUploaded
                                        ? 'Uploaded'
                                        : resumeUploadProgress === 0
                                        ? 'Upload Resume'
                                        : `${resumeUploadProgress}%`}
                                </button>
                            </span>
                        </div>
                        {userData &&
                        userData.resume &&
                        userData.resume.resumeS3FileKey ? (
                            <div
                                className="resume-pdf-container"
                                onMouseEnter={showPageNav}
                                onMouseLeave={hidePageNav}
                                style={{ marginBottom: '20px' }}
                            >
                                <Document
                                    file={`${Config.S3_URL}/${userData.resume.resumeS3FileKey}`}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="resume-pdf"
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                <div
                                    className="page-nav"
                                    style={{
                                        display: !displayPageNav ? 'none' : '',
                                    }}
                                >
                                    <button
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                    >
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <p>
                                        Page{' '}
                                        {pageNumber || (numPages ? 1 : '--')} of{' '}
                                        {numPages || '--'}
                                    </p>
                                    <button
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <h1 className="not-found">Resume not uploaded!</h1>
                        )}
                    </>
                )}
            </div>
        </section>
    );
}

export default CandidateProfile;
