import styled from 'styled-components';
import simpleProcessBg from '../../assets/illustrations/simple_process_bg.png';
import testimonialBg from '../../assets/images/testimonials-bg.jpg';

export const HeroContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2vw;
    width: 95vw;
    @media screen and (max-width: 700px) {
        justify-content: none;
        padding-left: 0vw;
        flex-direction: column;
        padding: 0 20px;
    }

    @media screen and (max-width: 700px) {
        justify-content: none;
        padding-left: 0vw;
        flex-direction: column;
        padding: 0px;
    }
`;

export const HeroLeft = styled.div`
    padding: 10px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 55vw;
    // max-width: 500px;
    color: #263238;

    h2 {
        font-family: 'DM Sans', sans-serif;
        font-weight: 800;
        font-size: 3rem;
        line-height: 145%;
        margin-bottom: 20px;
        width: 100%;
        margin-left: 5rem;
        // text-align: center;
    }

    .highlight-1 {
        color: #5e5dbb;
    }

    .highlight-2 {
        color: #4544A2;
    }

    h3 {
        margin: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 100%;
        margin-bottom: 10px;
        color: #69A1F8;
    }

    h1 {
        margin: 0;
        font-size: 60px;
        white-space: nowrap;
        position: relative;
        font-family: Lato;
        font-style: normal;
        font-weight: 800;
        // font-size: 82px;
        line-height: 100%;
        color: #1E38C1;
    }

    // h1:after {
    //     content: '';
    //     width: 40%;
    //     height: 8px;
    //     border-radius: 10px;
    //     background-color: var(--primary);
    //     position: absolute;
    //     bottom: -19px;
    //     right: -20px;
    // }

    p {
        // margin: 2rem 0;
        font-size: 19px;
        line-height: 150%;
        width: 100%;
        margin-left: 5rem;
        margin-bottom: 2rem;
    }

    // @media screen and (max-width: 1200px) {
    //     max-width: 400px;

    //     h3 {
    //         font-size: 1.5rem;
    //     }

    //     h1 {
    //         font-size: 3rem;
    //     }
    // }

    @media screen and (max-width: 1000px) {
        max-width: 400px;

        h3 {
            font-size: 1.5rem;
        }

        h1 {
            font-size: 2.5rem;
        }
    }

    @media screen and (max-width: 700px) {
        margin-left: -15vw;
        h3 {
            font-size: 1.5rem;
        }

        h1 {
            font-size: 2rem;
        }
        h1:after {
            content: '';
            width: 40%;
            height: 8px;
            border-radius: 10px;
            background-color: var(--primary);
            position: absolute;
            bottom: -19px;
            right: -20px;
        }

        p {
            font-size: 1rem;
        }
    }

    @media screen and (max-width: 576px) {
        h2 {
            font-size: 2rem;
            // margin-left: 0;
            width: max-content;
            // margin-left: 11rem;
            // margin-bottom: 30px;
            // margin-top: 20px;
            // margin: 0 auto;
            // text-align: center;
            line-height: 150%;
        }
    }
    @media screen and (max-width: 576px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;

export const ButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-left: 5rem;
    @media screen and (max-width: 700px) {
        flex-direction: column;
        // align-items: center;
        width: 50vw;
    }
`;

export const HB1 = styled.div`
    display: inline-block;
    background-color: #4544a2;
    border-radius: 15px;
    button {
        height: 50px;
        background-color: #4544a2;
        color: white;
        width: 10vw;
        border-radius: 15px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 19.2117px;
        line-height: 100%;
        border: none;
        padding: 10px;
        cursor: pointer;
    }
    @media screen and (max-width: 700px) {
        margin-left: 1rem;
        button {
            width: 65vw;
        }
    }
    @media screen and (max-width: 500px) {
        button {
            width: 65vw;
        }
    }
`;

export const HB2 = styled.div`
    display: inline-block;
    border: 2px solid #4544a2;
    border-radius: 15px;
    button {
        background-color: transparent;
        height: 50px;
        box-sizing: border-box;
        color: #4544a2;
        width: 15vw;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 19.2117px;
        line-height: 100%;
        border: none;
        padding: 10px;
        cursor: pointer;
    }
    @media screen and (max-width: 700px) {
        margin-left: 1rem;
        button {
            width: 65vw;
        }
    }
    @media screen and (max-width: 500px) {
        button {
            width: 65vw;
        }
    }
`;

export const HeroRight = styled.div`
    width: 40vw;
    text-align: right;
    img {
        width: 90%;
    }

    @media screen and (min-width: 1200px) {
        img {
            width: 600px;
        }
    }
    @media screen and (max-width: 700px) {
        width: 90vw;
        text-align: center;
        align-items: top;
        margin-bottom: 70px;
    }
`;

// Simple process section

export const SimpleProcessContainer = styled.section`
    padding: 100px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    background-color: var(--primary);

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url(${simpleProcessBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        opacity: 0.1;
        z-index: 0;
    }

    h1 {
        color: white;
        font-weight: 800;
        font-size: 4rem;
        padding-left: 1rem;
        margin: 0;
    }

    @media screen and (max-width: 1400px) {
        flex-direction: column;
        padding-left: 0;
        gap: 1.5rem;
        h1 {
            font-size: 3rem;
        }
    }

    @media screen and (max-width: 500px) {
        h1 {
            margin-bottom: 1.5rem;
        }
    }
`;

export const ProcessCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding-left: 2rem;

    @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 1rem;
        margin-left: 1rem;
        align-items: flex-end;
    }
`;

export const ProcessCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-right: 1.5rem;
    gap: 1rem;
    background-color: white;
    border: 2px solid var(--primary);
    border-right: none;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin-left: -1rem;
    color: var(--primary);
    width: 40%;

    h2 {
        font-size: 2rem;
        font-weight: 600;
        color: var(--primary);
        margin: 0;
    }

    h1 {
        font-size: 3rem;
        font-weight: 800;
        color: var(--primary);
        margin: 0;
        padding: 0;
        flex-grow: 1;
    }

    p {
        margin: 0.5rem 0;
    }

    @media screen and (max-width: 1400px) {
        h2 {
            font-size: 1.5rem;
        }

        h1 {
            font-size: 2rem;
        }
    }

    @media screen and (max-width: 500px) {
        gap: 0.5rem;
        width: 80%;
    }
`;

// About section

export const AboutContainer = styled.section`
    min-height: calc(100vh - var(--nav-height));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        padding: 0 20px;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        padding: 0 20px;
    }
`;

export const AboutLeft = styled.div`
    width: 40vw;
    text-align: left;

    img {
        width: 90%;
    }

    @media screen and (max-width: 1000px) {
        width: 90vw;
        text-align: center;
    }

    @media screen and (max-width: 700px) {
        width: 90vw;
        text-align: center;
    }

    @media screen and (min-width: 1200px) {
        img {
            width: 600px;
        }
    }
`;

export const AboutRight = styled.div`
    width: 55vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    align-items: flex-start;
    box-sizing: border-box;

    h1 {
        font-size: 4rem;
        font-weight: 800;
        margin: 0;
        position: relative;
        color: #263238;
    }

    h1:before {
        content: 'Learn More';
        position: absolute;
        bottom: 1.5rem;
        white-space: nowrap;
        opacity: 0.05;
        font-size: 7rem;
    }

    p {
        line-height: 1.4;
        letter-spacing: 1px;
        text-align: justify;
        color: #263238;
    }

    @media screen and (max-width: 1100px) {
        width: 90vw;
        h1:before {
            font-size: 5.5rem;
            left: -1rem;
            top: -6vh;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 90vw;
        h1 {
            font-size: 8vw;
            align-self: center;
            margin-top: 2rem;
        }

        h1:before {
            font-size: 6rem;
            left: -6rem;
            top: -8vh;
        }

        p {
            font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 700px) {
        h1:before {
            font-size: 4rem;
            left: -4rem;
            top: -5vh;
        }
    }

    @media screen and (max-width: 500px) {
        h1:before {
            font-size: 4rem;
            left: -6rem;
            top: -5vh;
        }
    }
`;

export const TrustedCompanies = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #808080;
    align-self: center;
    width: 100%;

    p {
        font-size: 0.8rem;
        margin: 0;
    }

    .companies-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        opacity: 0.45;
    }

    .companies-wrap div {
        display: flex;
    }
`;

// What they say

export const WTSContainer = styled.section`
    min-height: calc(100vh - var(--nav-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 50px;
    padding-top: 80px;
    // margin: 20px;

    .heading {
        font-size: 4rem;
        font-weight: 800;
        margin-bottom: 3rem;
        position: relative;
        align-self: flex-start;
    }

    .heading:before {
        content: 'We Are Awesome';
        position: absolute;
        bottom: 1.5rem;
        white-space: nowrap;
        opacity: 0.15;
        font-size: 7rem;
    }

    .heading:after {
        content: '';
        width: 40%;
        height: 8px;
        border-radius: 10px;
        background-color: var(--primary);
        position: absolute;
        bottom: -10px;
        right: -20px;
    }

    @media screen and (max-width: 1100px) {
        .heading {
            font-size: 3rem;
        }

        .heading:before {
            font-size: 5rem;
            bottom: 1rem;
        }
    }

    @media screen and (max-width: 900px) {
        .heading {
            font-size: 3rem;
            align-self: center;
            margin-top: 5rem;
        }

        .heading:before {
            font-size: 4.5rem;
            margin-left: -20vw;
        }
    }
    @media screen and (max-width: 600px) {
        .heading {
            font-size: 3rem;
            align-self: center;
            margin-top: 8rem;
        }

        .heading:before {
            font-size: 5rem;
            bottom: 1rem;
            white-space: normal;
            text-align: center;
            left: 11vw;
        }

        padding: 20px;
    }

    @media screen and (max-width: 400px) {
        .heading {
            font-size: 2rem;
        }

        .heading:before {
            font-size: 4rem;
            left: 0%;
        }

        padding: 20px;
    }
`;

export const ReviewCardContainer = styled.li`
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(233, 236, 242, 1);
    border-radius: 1.5rem;
    padding: 20px 30px;
    max-width: 90%;
    transition: all 400ms ease-in-out;

    &:hover {
        transform: scale(1.1);
    }

    .top {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;
    }

    .top * {
        margin: 0;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.1rem;
    }

    .right h5 {
        font-weight: 800;
    }

    .right p {
        font-size: 0.8rem;
        color: rgba(38, 50, 56, 1);
    }

    .review-text {
        color: #263238;
        font-size: 0.8rem;
        color: rgba(38, 50, 56, 1);
        letter-spacing: 0.5px;
    }

    @media screen and (max-width: 1000px) {
        &:nth-child(n + 7) {
            display: none;
        }
    }

    @media screen and (max-width: 900px) {
        &:nth-child(n + 5) {
            display: none;
        }
    }

    @media screen and (max-width: 600px) {
        &:nth-child(n + 3) {
            display: none;
        }
    }

    @media screen and (max-width: 400px) {
        &:nth-child(n + 3) {
            display: none;
        }
    }
`;

// FAQs

export const FAQsContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 200px;

    img {
        width: 100%;
        max-width: 700px;
    }

    h1 {
        margin-top: 2vw;
        align-self: flex-start;
        margin: 1rem 0;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 120%;
        color: #263238;
    }
    @media screen and (max-width: 1200px) {
        padding: 100px;
    }

    @media screen and (max-width: 900px) {
        padding: 70px;
    }

    @media screen and (max-width: 600px) {
        padding: 20px;
    }
`;

export const AccordionStyle = {
    borderTop: '3px solid #D7DEF0 !important',
    '&:last-child': {
        borderBottom: '3px solid #D7DEF0 !important',
    },
    boxShadow: 'none',
    width: '100%',

    '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(45deg)',
    },

    '&::before': {
        display: 'none',
    },
};

export const AccordionSummaryStyle = {
    fontWeight: 700,
    fontSize: '1.2rem',

    '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
    },
};

export const ACprop = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 199%;
    display: flex;
    align-items: center;
    color: #263238;
`;

export const ACDprop = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: rgba(38, 50, 56, 0.85);
`;

export const AccordionDetailsStyle = {
    '@media screen and (max-width: 600px)': {
        fontSize: '0.9rem',
    },
};


export const Stcomp=styled.div`
    width: 100w;
    background-color: var(--primary);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    /* height:14rem ; */
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-image: url(${simpleProcessBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        opacity: 0.1;
        z-index: 0;
    }
   
`;


export const Sthead=styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 60px;
`;


export const Sts=styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 60px;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 200px;
        border: 1px solid #FFFFFFF;
        padding-left: 20px;
        padding-right: 20px;
    }
    img{
        margin-top: 60px;
    }
    
    @media screen and (min-width: 1000px) {
        padding-left: 100px;
        padding-right: 100px;
    }
    @media screen and (min-width: 700px) {
        padding-left: 70px;
        padding-right: 70px;
    }
`;

export const Stst=styled.div`
    margin-top:15px;
    font-size: 30px;
    font-weight: bold;
`;

export const Stdesc=styled.div`
    margin-top: 10px;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
`;

export const Chead=styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.5rem;
    font-weight: 800;
    color: #263238;
    font-weight: bold;
    padding: 0 50px;
    position: relative;
    width: fit-content;

    &:after {
        content: '';
        width: 40%;
        height: 8px;
        border-radius: 10px;
        background-color: var(--primary);
        position: absolute;
        bottom: -10px;
        right: 10px;
    }

    // @media screen and (min-width: 1000px) {
    //     font-size: 2.5rem;
    // }

    @media screen and (max-width: 700px) {
        text-align: center;
        font-size: 2.5rem;
    }
`;

export const Ccards=styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 40px 50px;
    justify-content: space-around;
    div{
        margin-top: 10px;
        margin-bottom: 10px;
        // margin-left: 10px;
        // margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 200px;
        // border: 1px solid #000000;
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 45px;
        border-radius: 15px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: all 500ms ease;
        h1{
            font-size: 15px;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 15px;
            height: fit-content;
        }
        p{
            font-size: 14px;
            font-weight: 600;
            color: #263238D9;
            line-height: 150%;
            height: 80px;
        }
        img {
            height: 40px;
        }
        i {
            font-size: 40px;
            color: #4544A2;
        }
        &:hover {
            transform: scale(1.1);
        }
    }
`;

