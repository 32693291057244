import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import eventIllus from '../../assets/illustrations/event-illustration.webp';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';

function EventInfo(props) {
    const [eventData, setEventData] = useState({});
    const dispatch = useDispatch();
    const [hasRegistered, setHasRegistered] = useState(false);
    let params = useParams();

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get(`/api/events/${params.id}`, config)
            .then((res) => {
                if (res.data) {
                    setEventData(res.data);
                    if (res.data.hasRegistered) setHasRegistered(true);
                }
            })
            .catch((err) => console.log(err));
    }, [params.id]);

    console.log("event data", eventData)

    // Register user to event on btn-click
    const handleRegister = (e) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .put(`/api/events/register/${params.id}`, {}, config)
            .then((res) => {
                if (res.data && res.data.eventId) setHasRegistered(true);
                dispatch(
                    handleSnackOpen({
                        message: 'Registered Successfully',
                        variant: 'success',
                    })
                );
            })
            .catch((err) => console.log(err));
    };

    const link = eventData.bannerS3FileKey;
    console.log(link)
    // const bannerImgStyle = {
    //     backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${
    //         eventData.bannerS3FileKey
    //             ? `entrepreneur.s3.ap-south-1.amazonaws.com/${eventData.bannerS3FileKey}`
    //             : eventIllus
    //     })`,
    // };
    const myStyle={
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url('https://preentrepreneur.s3.ap-south-1.amazonaws.com/${eventData.bannerS3FileKey}')`
       
    };
    
    return (
        <section className="EventInfo">
            <div className="filler-div"></div>
            <div className="Event-banner" style={myStyle}>
                <div>
                    <h1>{eventData.title}</h1>
                    
                    <p>
                        {moment.utc(eventData.eventDate).format('llll')} (IST)
                    </p>
                    <ul>
                        {eventData.relatedTopics &&
                        eventData.relatedTopics.length > 0
                            ? eventData.relatedTopics.map((topic, idx) => (
                                  <li key={idx}>
                                      {topic.charAt(0).toUpperCase() +
                                          topic.substring(1)}
                                  </li>
                              ))
                            : ''}
                    </ul>
                    <button onClick={handleRegister}>
                        {hasRegistered ? 'Registered' : 'Attend'}
                    </button>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '30px'}}>
                <div className="Event-info">
                    <h2>About this event</h2>
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {eventData.description}
                    </p>
                    {hasRegistered && (
                        <p style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                            <strong>Registration Link:</strong> &nbsp;
                            <a 
                                href={eventData.eventLink} 
                                style={{textDecoration: 'underline', color: '#4544a2'}}
                                target="_blank"
                            >
                                {eventData.eventLink}
                            </a>
                        </p>
                    )}
                    <p style={{ paddingTop: '30px', paddingBottom: '10px' }}>
                        🗓️{' '}
                        <strong>
                            Date:{' '}
                            {moment.utc(eventData.eventDate).format('DD MMMM YYYY')}
                        </strong>
                    </p>
                    <p>
                        🕒{' '}
                        <strong>
                            Time: {moment.utc(eventData.eventDate).format('LT')}
                        </strong>
                    </p>
                </div>
                {/* <div className='event-banner-preview'>
                    <img 
                        src={
                            eventData.bannerS3FileKey
                            ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${eventData.bannerS3FileKey}`
                            : eventIllus
                        }
                        alt="event banner"
                    />
                </div> */}
            </div>
        </section>
    );
}

export default EventInfo;
