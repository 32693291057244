import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';

const PrivateRoute = (props) => {
    const dispatch = useDispatch();
    let token = useSelector(
        (state) => state.authStatus && state.authStatus.token
    );
    if (token === '') token = localStorage.getItem('token');
    if (token === '') {
        dispatch(
            handleSnackOpen({
                message: 'Please Login/Register to proceed',
                variant: 'warning',
            })
        );
        return <Navigate to="/" />;
    }
    return props.children;
};

export default PrivateRoute;
