import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PrimaryButton from '../Common/PrimaryButton';
import { FooterContainer, LinksWrap } from './FooterComponents';
import {
    GrLinkedinOption,
    GrFacebookOption,
    GrInstagram,
} from 'react-icons/gr';
import { FaDiscord } from 'react-icons/fa';

export default function Footer() {
    return (
        <FooterContainer>
            <p className="footer-upskill">UPSKILL FOR A BETTER FUTURE</p>
            <h1>Request More Information</h1>
            <p className="footer-description">
                Reach out to experience hassle free hiring
            </p>
            <PrimaryButton>
                <Link to="/whatsapp">
                    Contact Us
                </Link>
            </PrimaryButton>
            <p className="footer-copyright">© 2022 SuperGrad (A unit of Pre Aspiring Entrepreneur Pvt Ltd)</p>
            <hr />
            <LinksWrap>
                
                <div className="NavLinks">
                    <Link to="/candidate/register">
                        <p>Register</p>
                    </Link>
                    <HashLink to="/policy/privacy">
                        <p>Privacy</p>
                    </HashLink>
                    <HashLink to="/policy/refund">
                        <p>Refund</p>
                    </HashLink>
                    <HashLink to="/policy/terms">
                        <p>Terms & Conditions</p>
                    </HashLink>
                </div>
                <div className="SocialLinks">
                    <a href="/linkedin" target="_blank" rel="noreferrer">
                        <GrLinkedinOption />
                    </a>
                    <a href="/facebook" target="_blank" rel="noreferrer">
                        <GrFacebookOption />
                    </a>
                    <a href="/instagram" target="_blank" rel="noreferrer">
                        <GrInstagram />
                    </a>
                    <a href="/discord" target="_blank" rel="noreferrer">
                        <FaDiscord />
                    </a>
                </div>
            </LinksWrap>
        </FooterContainer>
    );
}
