import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useInputState from '../hooks/useInputState';
import ReactS3Uploader from 'react-s3-uploader';
import { useDispatch, useSelector } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Left,
    OLeft,
    Right,
    TLeft,
    BtnCover,
    Div1,
    Div2,
    Div3,
    Div4,
    Onediv,
    Twodiv,
    Threediv,
    RHeader,
    Wrapdiv,
    RForm,
    RFRow,
    RFRDesc,
    HR,
    RFRDesc1,
    RFRDesc2,
    RFRDesc11,
} from '../components/ProfileEdit/ProfileEditComponents';
import { RProfilepic } from '../components/RecruiterEditProfile/RecruiterEP';
import { Box, Slider } from '@mui/material';
import { setUser, setUserType } from '../store/auth/authStatus';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import Ipath from '../assets/illustrations/REP.png';
import '../components/RecruiterEditProfile/REP.css';
import Image from '../assets/illustrations/PPic.png';

const ProfileEdit = () => {
    const [show, setShow] = useState('show');
    const [noshow, setNoshow] = useState('noshow');

    function nextdata() {
        if (fullName === '') {
            return;
        }
        setShow('noshow');
        setNoshow('show');
        window.scrollTo(0, 0);
    }

    function prevdata() {
        setShow('show');
        setNoshow('noshow');
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = useSelector((st) => st.authStatus.user._id);

    const [fullName, setFullName] = useInputState('');
    const [mobileNo, setMobileNo] = useInputState('');
    const [state, setState] = useInputState('');
    const [email, setEmail] = useInputState('');
    const [collegeName, setCollegeName] = useInputState('');
    const [degree, setDegree] = useInputState('');
    const [startingYear, setStartingYear] = useInputState('');
    const [graduationYear, setGraduationYear] = useInputState('');
    const [lookingFor, setLookingFor] = useState([]);
    const [jobType, setJobType] = useState([]);
    const [locationPref1, setLocationPref1] = useInputState('');
    const [locationPref2, setLocationPref2] = useInputState('');
    const [fieldPref1, setFieldPref1] = useInputState('');
    const [fieldPref2, setFieldPref2] = useInputState('');
    const [expectedCtc, setExpectedCtc] = useInputState('');
    const [skills, setSkills] = useState([
        { skill: '', level: 'intermediate' },
    ]);
    const [hasWorkExperience, setHasWorkExperience] = useState(false);
    const [workExperience, setWorkExperience, resetWorkExperience] =
        useInputState('');
    const [
        workExperienceYears,
        setWorkExperienceYears,
        resetWorkExperienceYears,
    ] = useInputState('');
    const [experienceDesc, setExperienceDesc] = useState([
        { companyName: '', description: '' },
    ]);
    const [workedOnProject, setWorkedOnProject] = useState(false);
    const [projects, setProjects] = useState([
        { title: '', link: '', description: '' },
    ]);

    const [numSkills, setNumSkills] = useState(1);
    const [numProjects, setNumProjects] = useState(1);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [avatarS3FileKey, setAvatarS3FileKey] = useState('');
    const [avatarS3PublicUrl, setAvatarS3PublicUrl] = useState('');
    const [avatarUploaded, setAvatarUploaded] = useState(false);
    const [resumeUploadProgress, setResumeUploadProgress] = useState(0);
    const [resumeS3FileKey, setResumeS3FileKey] = useState('');
    const [resumeS3PublicUrl, setResumeS3PublicUrl] = useState('');
    const [resumeUploaded, setResumeUploaded] = useState(false);
    const uploader = useRef();
    const resumeUploader = useRef();

    const saveAvatar = (data) => {
        setAvatarS3FileKey(data.fileKey);
        setAvatarS3PublicUrl(data.publicUrl);
        setAvatarUploaded(true);
    };
    const saveResume = (data) => {
        setResumeS3FileKey(data.fileKey);
        setResumeS3PublicUrl(data.publicUrl);
        setResumeUploaded(true);
    };

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get('/api/auth/user', config)
            .then((res) => {
                dispatch(setUser(res.data.user));
                dispatch(setUserType('candidate'));
                if (res.data.user) setEmail(res.data.user.email);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleLookingFor = (e) => {
        setLookingFor((currSt) => {
            if (e.target.checked) {
                const foundType = currSt.find((el) => el === e.target.value);
                if (foundType) return currSt;
                else return [...currSt, e.target.value];
            } else {
                return currSt.filter((el) => el !== e.target.value);
            }
        });
    };

    const handleJobType = (e) => {
        setJobType((currSt) => {
            const foundType = currSt.find((el) => el === e.target.value);
            if (foundType) return currSt;
            else return [...currSt, e.target.value];
        });
    };

    const skillLevelMap = {
        10: 'beginner',
        20: 'intermediate',
        30: 'advance',
        40: 'expert',
    };

    const handleSkillChange = (e) => {
        setSkills((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, skill: e.target.value };
                else return el;
            });
        });
    };

    const handleSkillLevelChange = (e) => {
        setSkills((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, level: skillLevelMap[e.target.value] };
                else return el;
            });
        });
    };

    const addSkill = (e) => {
        setNumSkills((st) => st + 1);
        setSkills((st) => [...st, { skill: '', level: 'intermediate' }]);
    };

    const handleHasWorkExperience = (e) => {
        setHasWorkExperience(JSON.parse(e.target.value));
        if (JSON.parse(e.target.value) === false) {
            resetWorkExperience();
            resetWorkExperienceYears();
            setExperienceDesc([{ companyName: '', description: '' }]);
        }
    };

    const handleWorkedOnProject = (e) => {
        setWorkedOnProject(JSON.parse(e.target.value));
    };

    const handleProjectTitleChange = (e) => {
        setProjects((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, title: e.target.value };
                else return el;
            });
        });
    };
    const handleProjectLinkChange = (e) => {
        setProjects((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, link: e.target.value };
                else return el;
            });
        });
    };
    const handleProjectDescriptionChange = (e) => {
        setProjects((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, description: e.target.value };
                else return el;
            });
        });
    };

    const addProject = (e) => {
        setNumProjects((st) => st + 1);
        setProjects((st) => [...st, { title: '', link: '', description: '' }]);
    };

    const handleExperienceCompanyChange = (e) => {
        setExperienceDesc((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, companyName: e.target.value };
                else return el;
            });
        });
    };

    const handleExperienceDescriptionChange = (e) => {
        setExperienceDesc((st) => {
            return st.map((el, idx) => {
                if (idx === parseInt(e.target.name))
                    return { ...el, description: e.target.value };
                else return el;
            });
        });
    };

    const addWorkExperience = (e) => {
        setExperienceDesc((st) => [
            ...st,
            { companyName: '', description: '' },
        ]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (fieldPref1 === '') {
            dispatch(
                handleSnackOpen({
                    message: 'Input all fields / Upload Avatar',
                    variant: 'warning',
                })
            );
            return;
        }
        if (
            (fullName &&
                mobileNo &&
                state &&
                collegeName &&
                degree &&
                startingYear &&
                graduationYear &&
                hasWorkExperience &&
                resumeS3FileKey &&
                fieldPref1 &&
                resumeS3PublicUrl) !== ''
        ) {
            const filteredSkills = skills.filter((s) => s.skill !== '');
            const filteredProjects = projects.filter(
                (project) => (project.title && project.description) !== ''
            );
            const filteredWorkExperienceDesc = experienceDesc.filter(
                (desc) => (desc.companyName && desc.description) !== ''
            );
            let preferredLocations = [];
            let fields = [];
            let workExToSend = workExperience;
            if (locationPref1 !== '') preferredLocations.push(locationPref1);
            if (locationPref2 !== '') preferredLocations.push(locationPref2);
            if (fieldPref1 !== '') fields.push(fieldPref1);
            if (fieldPref2 !== '') fields.push(fieldPref2);
            if (workExperience === '12+ Months') {
                if (!workExperienceYears || workExperienceYears === '') {
                    dispatch(
                        handleSnackOpen({
                            message: 'Enter Years of Experience',
                            variant: 'warning',
                        })
                    );
                    return;
                }
                workExToSend = `${workExperienceYears} ${
                    workExperienceYears > 1 ? 'years' : 'year'
                }`;
            }

            const payload = {
                userId,
                userType: 'user',
                fullName,
                mobileNo,
                state,
                collegeName,
                degree,
                startingYear,
                graduationYear,
                lookingFor,
                jobType,
                preferredLocations,
                fields,
                expectedCtc,
                skills: filteredSkills,
                workExperience: workExToSend,
                experienceDesc: filteredWorkExperienceDesc,
                workedOnProject,
                projects: filteredProjects,
                resumeS3FileKey,
                resumeS3PublicUrl,
            };
            if ((avatarS3FileKey && avatarS3PublicUrl) !== '') {
                payload.avatarS3FileKey = avatarS3FileKey;
                payload.avatarS3PublicUrl = avatarS3PublicUrl;
            }
            axios
                .put('/api/user/register/profile', payload)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );
                        navigate('/dashboard');
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Input all fields / Upload Avatar',
                    variant: 'warning',
                })
            );
        }
    };

    return (
        <>
            <br />
            <br />
            <br />
            <br />
            <Container>
                <Left>
                    <OLeft>
                        <BtnCover>
                            <div className={show}>
                                <Div1>
                                    General Details
                                    <i>
                                        <ArrowForwardIosIcon fontSize="small" />
                                    </i>
                                </Div1>
                                <Div2 onClick={nextdata}>Experiences</Div2>
                            </div>
                            <div className={noshow}>
                                <Div3 onClick={prevdata}>
                                    General Details
                                    <i>
                                        <DoneIcon fontSize="small" />
                                    </i>
                                </Div3>
                                <Div4>
                                    Experiences
                                    <i>
                                        <ArrowForwardIosIcon fontSize="small" />
                                    </i>
                                </Div4>
                            </div>
                        </BtnCover>
                        <img src={Ipath} alt="imagealt" />
                    </OLeft>
                    <TLeft>
                        <div className={show}>
                            <Wrapdiv>
                                <Onediv>1</Onediv>
                                <hr />
                                <Twodiv onClick={nextdata}>2</Twodiv>
                            </Wrapdiv>
                        </div>
                        <div className={noshow}>
                            <Wrapdiv>
                                <Onediv onClick={prevdata}>1</Onediv>
                                <hr />
                                <Threediv>2</Threediv>
                            </Wrapdiv>
                        </div>
                    </TLeft>
                </Left>
                <Right>
                    <RHeader>
                        <div className={show}>General Details</div>
                        <div className={noshow}>Experiences</div>
                    </RHeader>
                    <div className={show}>
                        <RProfilepic>
                            <span>
                                <img
                                    src={
                                        avatarS3FileKey !== ''
                                            ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${avatarS3FileKey}`
                                            : Image
                                    }
                                    style={{ borderRadius: '50%' }}
                                    alt="Profile Pic"
                                />
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="img/*"
                                    s3path="profile_pics/candidate/"
                                    scrubFilename={(filename) =>
                                        `profile_pic-${userId}`
                                    }
                                    onProgress={setUploadProgress}
                                    onFinish={saveAvatar}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={true}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                            </span>
                            {/* <button onClick={uploadAvatar}>
                                {avatarUploaded
                                    ? 'Uploaded'
                                    : uploadProgress === 0
                                    ? 'Upload Profile Pic'
                                    : `${uploadProgress}%`}
                            </button> */}
                        </RProfilepic>

                        <RForm>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Full Name{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="Enter your full name"
                                        value={fullName}
                                        onChange={setFullName}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Mobile Number{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="number"
                                        placeholder="Enter your phone number"
                                        value={mobileNo}
                                        onChange={setMobileNo}
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        State{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="Please enter the state where you currently live"
                                        value={state}
                                        onChange={setState}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Email{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="alreadyentered@gmail.com"
                                        value={
                                            JSON.parse(
                                                localStorage.getItem('user')
                                            ).email
                                        }
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <HR />
                            <RFRow>
                                <RFRDesc>
                                    <h1>Education Details</h1>
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        College{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="Please enter your college name"
                                        value={collegeName}
                                        onChange={setCollegeName}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Degree{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="text"
                                        placeholder="Enter your degree"
                                        value={degree}
                                        onChange={setDegree}
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Starting Year{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="number"
                                        placeholder="Enter Here"
                                        min={1}
                                        value={startingYear}
                                        onChange={setStartingYear}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <h1>
                                        Graduation Year{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <input
                                        type="number"
                                        placeholder="Enter Here"
                                        min={1}
                                        value={graduationYear}
                                        onChange={setGraduationYear}
                                        required
                                    />
                                </RFRDesc>
                            </RFRow>
                            <HR />
                            <RFRow>
                                <RFRDesc>
                                    <h1>Preferences</h1>
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc1>
                                    <h1>Looking For (Select all that apply)</h1>
                                    <div>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="C2"
                                                value="job"
                                                onChange={handleLookingFor}
                                            />
                                            <label htmlFor="">Jobs</label>
                                        </span>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="C2"
                                                value="freelancing"
                                                onChange={handleLookingFor}
                                            />
                                            <label htmlFor="">
                                                Freelancing
                                            </label>
                                        </span>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="C2"
                                                value="internship"
                                                onChange={handleLookingFor}
                                            />
                                            <label htmlFor="">
                                                Internships
                                            </label>
                                        </span>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="C2"
                                                value="upskilling"
                                                onChange={handleLookingFor}
                                            />
                                            <label htmlFor="">Upskilling</label>
                                        </span>
                                    </div>
                                </RFRDesc1>
                            </RFRow>
                            <RFRow>
                                <RFRDesc1>
                                    <h1>
                                        Which of the following job type(s) are
                                        you looking for?
                                    </h1>
                                    <div>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="CJ"
                                                value="remote"
                                                onChange={handleJobType}
                                            />
                                            <label htmlFor="">Remote</label>
                                        </span>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="CJ"
                                                value="in-office"
                                                onChange={handleJobType}
                                            />
                                            <label htmlFor="">In Office</label>
                                        </span>
                                        <span>
                                            <input
                                                type="checkbox"
                                                id=""
                                                name="CJ"
                                                value="hybrid"
                                                onChange={handleJobType}
                                            />
                                            <label htmlFor="">Hybrid</label>
                                        </span>
                                    </div>
                                </RFRDesc1>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>Preferred Location</h1>
                                    <input
                                        type="text"
                                        placeholder="Preference 1"
                                        value={locationPref1}
                                        onChange={setLocationPref1}
                                        required
                                    />
                                </RFRDesc>
                                <RFRDesc>
                                    <input
                                        type="text"
                                        placeholder="Preference 2"
                                        value={locationPref2}
                                        onChange={setLocationPref2}
                                    />
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Select your profile{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <select onChange={setFieldPref1}>
                                        <option value="">
                                            Select Job Preference
                                        </option>
                                        <optgroup>
                                            <option
                                                value=""
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '15px',
                                                }}
                                                disabled
                                            >
                                                Non-Technical
                                            </option>
                                            {/* <option value="" disabled> </option> */}
                                            <option value="marketing">
                                                Marketing
                                            </option>
                                            <option value="content">
                                                Content
                                            </option>
                                            <option value="management">
                                                Management
                                            </option>
                                            <option value="finance">
                                                Finance
                                            </option>
                                            <option value="customer satisfaction">
                                                Customer Satisfaction
                                            </option>
                                            <option value="business development">
                                                Business Development
                                            </option>
                                        </optgroup>
                                        <optgroup>
                                            <option
                                                value=""
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '15px',
                                                }}
                                                disabled
                                            >
                                                Technical
                                            </option>
                                            {/* <option value="" disabled> </option> */}
                                            <option value="backend">
                                                Backend
                                            </option>
                                            <option value="frontend">
                                                Frontend
                                            </option>
                                            <option value="data analyst">
                                                Data Analyst
                                            </option>
                                            <option value="python">
                                                Python
                                            </option>
                                            <option value="sde">SDE</option>
                                            <option value="java">Java</option>
                                            <option value="react">React</option>
                                        </optgroup>
                                    </select>
                                    {/* <input
                                        type="text"
                                        placeholder="Job Field Preference 1"
                                        value={fieldPref1}
                                        onChange={setFieldPref1}
                                        required
                                    /> */}
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc>
                                    <RFRDesc>
                                        <h1>Minimum Expected CTC (In INR)</h1>
                                        <input
                                            type="number"
                                            placeholder="Enter Here"
                                            value={expectedCtc}
                                            onChange={setExpectedCtc}
                                            required
                                        />
                                    </RFRDesc>
                                </RFRDesc>
                                <RFRDesc></RFRDesc>
                            </RFRow>
                            <RFRow style={{ marginBottom: '20px' }}>
                                <RFRDesc></RFRDesc>
                                <RFRDesc>
                                    <button type="submit" onClick={nextdata}>
                                        Next
                                    </button>
                                </RFRDesc>
                            </RFRow>
                        </RForm>
                    </div>
                    <div className={noshow}>
                        <RForm>
                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Upload your resume{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <ReactS3Uploader
                                        signingUrl="/s3/sign"
                                        signingUrlMethod="GET"
                                        accept="pdf/*"
                                        s3path="resumes/"
                                        scrubFilename={(filename) =>
                                            `resume-${userId}.pdf`
                                        }
                                        onProgress={setResumeUploadProgress}
                                        onFinish={saveResume}
                                        server={
                                            process.env.REACT_APP_BACKEND_URL
                                        }
                                        autoUpload={true}
                                        uploadRequestHeaders={{
                                            'x-amz-acl': 'public-read',
                                        }}
                                        contentDisposition="auto"
                                        ref={resumeUploader}
                                    />
                                    {/* <button
                                        onClick={uploadResume}
                                        className="resume-upload-btn"
                                    >
                                        {resumeUploaded
                                            ? 'Uploaded'
                                            : resumeUploadProgress === 0
                                            ? 'Upload Resume'
                                            : `${resumeUploadProgress}%`}
                                    </button> */}
                                </RFRDesc>
                            </RFRow>
                            {/* <p
                                className="instruct"
                                style={{
                                    fontStyle: 'italic',
                                    fontSize: '14px',
                                    margin: '10px 0',
                                }}
                            >
                                * Please click 'Upload' after choosing a file.
                            </p> */}
                            <p
                                className="instruct"
                                style={{
                                    fontStyle: 'italic',
                                    fontSize: '14px',
                                    margin: '10px 0',
                                }}
                            >
                                * Only PDF files are allowed.
                            </p>
                            <hr
                                className="styled-hr"
                                style={{ width: '90%' }}
                            />
                            <RFRow>
                                <RFRDesc>
                                    <h1 style={{ width: '100%' }}>
                                        Please enter your skills and rate them
                                        according to your expertise{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                </RFRDesc>
                            </RFRow>

                            {[...Array(numSkills)].map((el, idx) => (
                                <RFRow key={idx}>
                                    <RFRDesc>
                                        <input
                                            type="text"
                                            placeholder={`Enter Your skill ${
                                                idx + 1
                                            }`}
                                            value={skills[idx].skill}
                                            onChange={handleSkillChange}
                                            name={idx}
                                        />
                                    </RFRDesc>
                                    <RFRDesc2>
                                        <Box sx={{ width: 300 }}>
                                            <Slider
                                                aria-label="skill-level"
                                                defaultValue={20}
                                                step={10}
                                                marks={[
                                                    {
                                                        value: 10,
                                                        label: 'Beginner',
                                                    },
                                                    {
                                                        value: 20,
                                                        label: 'Intermediate',
                                                    },
                                                    {
                                                        value: 30,
                                                        label: 'Advance',
                                                    },
                                                    {
                                                        value: 40,
                                                        label: 'Expert',
                                                    },
                                                ]}
                                                min={10}
                                                max={40}
                                                name={idx.toString()}
                                                onChange={
                                                    handleSkillLevelChange
                                                }
                                            />
                                        </Box>
                                    </RFRDesc2>
                                </RFRow>
                            ))}
                            <RFRow>
                                <RFRDesc>
                                    <button type="submit" onClick={addSkill}>
                                        Add new Skills
                                    </button>
                                </RFRDesc>
                                <RFRDesc></RFRDesc>
                            </RFRow>
                            <hr
                                className="styled-hr"
                                style={{ width: '90%' }}
                            />
                            <RFRow>
                                <RFRDesc11>
                                    <h1>
                                        Any Work Experience{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <div>
                                        <span>
                                            <input
                                                type="radio"
                                                name="hasWorkExperience"
                                                value={true}
                                                onChange={
                                                    handleHasWorkExperience
                                                }
                                            />
                                            <label>Yes</label>
                                        </span>
                                        <span>
                                            <input
                                                type="radio"
                                                name="hasWorkExperience"
                                                value={false}
                                                onChange={
                                                    handleHasWorkExperience
                                                }
                                            />
                                            <label>No</label>
                                        </span>
                                    </div>
                                </RFRDesc11>
                            </RFRow>
                            <RFRow
                                style={{
                                    display: !hasWorkExperience ? 'none' : '',
                                }}
                            >
                                <RFRDesc11>
                                    <h1>
                                        What is your work experience{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <div>
                                        <span>
                                            <input
                                                type="radio"
                                                id=""
                                                name="R"
                                                value="0-6 Months"
                                                onChange={setWorkExperience}
                                                checked={
                                                    workExperience ===
                                                    '0-6 Months'
                                                }
                                            />
                                            <label htmlFor="">0-6 Months</label>
                                        </span>
                                        <span>
                                            <input
                                                type="radio"
                                                id=""
                                                name="R"
                                                value="6-12 Months"
                                                onChange={setWorkExperience}
                                                checked={
                                                    workExperience ===
                                                    '6-12 Months'
                                                }
                                            />
                                            <label htmlFor="">
                                                6-12 Months
                                            </label>
                                        </span>
                                        <span>
                                            <input
                                                type="radio"
                                                id=""
                                                name="R"
                                                value="12+ Months"
                                                onChange={setWorkExperience}
                                                checked={
                                                    workExperience ===
                                                    '12+ Months'
                                                }
                                            />
                                            <label htmlFor="">12+ Months</label>
                                        </span>
                                    </div>
                                </RFRDesc11>
                            </RFRow>
                            <RFRow
                                style={{
                                    display: !hasWorkExperience ? 'none' : '',
                                }}
                            >
                                <RFRDesc>
                                    {workExperience === '12+ Months' && (
                                        <input
                                            placeholder="Years of Experience"
                                            type="number"
                                            step={1}
                                            value={workExperienceYears}
                                            onChange={setWorkExperienceYears}
                                        />
                                    )}
                                </RFRDesc>
                            </RFRow>

                            <div
                                style={{
                                    display: !hasWorkExperience ? 'none' : '',
                                }}
                            >
                                {[...Array(experienceDesc.length)].map(
                                    (el, idx) => (
                                        <React.Fragment key={idx}>
                                            <h3 style={{ marginTop: '20px' }}>
                                                Experience {idx + 1}
                                            </h3>
                                            <RFRow>
                                                <RFRDesc>
                                                    <h1>Company Name</h1>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Company Name"
                                                        value={
                                                            experienceDesc[idx]
                                                                .companyName
                                                        }
                                                        onChange={
                                                            handleExperienceCompanyChange
                                                        }
                                                        name={idx}
                                                    />
                                                </RFRDesc>
                                                <RFRDesc></RFRDesc>
                                            </RFRow>
                                            <RFRow>
                                                <RFRDesc>
                                                    <h1>
                                                        Brief Description About
                                                        Your Experience
                                                    </h1>
                                                    <textarea
                                                        type="text"
                                                        placeholder="Enter here"
                                                        value={
                                                            experienceDesc[idx]
                                                                .description
                                                        }
                                                        onChange={
                                                            handleExperienceDescriptionChange
                                                        }
                                                        name={idx}
                                                    />
                                                </RFRDesc>
                                                <RFRDesc></RFRDesc>
                                            </RFRow>
                                        </React.Fragment>
                                    )
                                )}
                                <RFRow>
                                    <RFRDesc>
                                        <button onClick={addWorkExperience}>
                                            Add Experience
                                        </button>
                                    </RFRDesc>
                                    <RFRDesc></RFRDesc>
                                </RFRow>
                            </div>
                            <hr
                                className="styled-hr"
                                style={{ width: '90%' }}
                            />
                            <RFRow>
                                <RFRDesc11>
                                    <h1>
                                        Do you have any academic/personal
                                        projects or work you have done{' '}
                                        <sup className="required-field">*</sup>
                                    </h1>
                                    <div>
                                        <span>
                                            <input
                                                type="radio"
                                                id=""
                                                name="R1"
                                                value={true}
                                                onChange={handleWorkedOnProject}
                                            />
                                            <label htmlFor="">Yes</label>
                                        </span>
                                        <span>
                                            <input
                                                type="radio"
                                                id=""
                                                name="R1"
                                                value={false}
                                                onChange={handleWorkedOnProject}
                                            />
                                            <label htmlFor="">No</label>
                                        </span>
                                    </div>
                                </RFRDesc11>
                            </RFRow>

                            <RFRow>
                                <RFRDesc>
                                    <h1>
                                        Share information about your projects
                                    </h1>
                                </RFRDesc>
                                <RFRDesc></RFRDesc>
                            </RFRow>

                            {[...Array(numProjects)].map((el, idx) => (
                                <React.Fragment key={idx}>
                                    <h3>Project {idx + 1}</h3>
                                    <RFRow>
                                        <RFRDesc>
                                            <input
                                                type="text"
                                                placeholder="Project/Work Title"
                                                value={projects[idx].title}
                                                onChange={
                                                    handleProjectTitleChange
                                                }
                                                name={idx}
                                                required
                                            />
                                        </RFRDesc>
                                        <RFRDesc></RFRDesc>
                                    </RFRow>
                                    <RFRow>
                                        <RFRDesc>
                                            <input
                                                type="text"
                                                placeholder="Project/Work Link (optional)"
                                                value={projects[idx].link}
                                                onChange={
                                                    handleProjectLinkChange
                                                }
                                                name={idx}
                                            />
                                        </RFRDesc>
                                        <RFRDesc></RFRDesc>
                                    </RFRow>
                                    <RFRow>
                                        <RFRDesc>
                                            <textarea
                                                type="text"
                                                placeholder="Project/Work Description"
                                                value={
                                                    projects[idx].description
                                                }
                                                onChange={
                                                    handleProjectDescriptionChange
                                                }
                                                name={idx}
                                            />
                                        </RFRDesc>
                                        <RFRDesc></RFRDesc>
                                    </RFRow>
                                </React.Fragment>
                            ))}
                            <RFRow>
                                <RFRDesc>
                                    <button onClick={addProject}>
                                        Add new project
                                    </button>
                                </RFRDesc>
                                <RFRDesc></RFRDesc>
                            </RFRow>

                            <RFRow>
                                <RFRDesc></RFRDesc>
                                <RFRDesc>
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Done
                                    </button>
                                </RFRDesc>
                            </RFRow>
                            <RFRow>
                                <RFRDesc></RFRDesc>
                                <RFRDesc></RFRDesc>
                            </RFRow>
                        </RForm>
                    </div>
                </Right>
            </Container>
        </>
    );
};

export default ProfileEdit;
