import styled from 'styled-components';

export const NavbarContainer = styled.nav`
    box-sizing: border-box;
    height: var(--nav-height);
    width: 100%;
    padding: 0 30px;
    position: fixed;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #0000001a;
    z-index: 50;
`;

export const NavbarLogo = styled.div`
    height: 35px;

    img {
        height: 100%;
    }
`;

export const NavbarTabs = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;

    @media screen and (max-width: 700px) {
        display: none;
    }
`;

export const NavbarLink = styled.div`
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    color: rgba(21, 15, 55, 0.6);
    cursor: pointer;

    .selected {
        color: #150F37;
    }
`;

export const SidebarIconWrap = styled.div`
    font-size: 1rem;
    color: var(--primary);
    display: none;

    @media screen and (max-width: 700px) {
        display: block;
        cursor: pointer;
    }
`;

export const SidebarBoxStyle = {
    minWidth: 200,
};
