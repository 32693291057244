import { configureStore } from '@reduxjs/toolkit';
import authStatus from './auth/authStatus';
import cregister from './auth/CandidateRegister';
import login from './auth/Login';
import rregister from './auth/RecruiterRegister';
import ProfileEdit from './profile/ProfileEdit';
import Toast from './Toast/Toast';

export default configureStore({
    reducer: {
        candidateRegister: cregister,
        recruiterRegister: rregister,
        login: login,
        toast: Toast,
        profileEdit: ProfileEdit,
        authStatus: authStatus,
    },
});
