import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import useInputState from '../../hooks/useInputState';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import '../../styles/SkillTests/SkillTests.css';
import pythonIllus from '../../assets/illustrations/python-illus.jpg';

function SkillTests(props) {
    const skillTestLevels = [
        { label: 'Beginner', isChecked: false },
        { label: 'Intermediate', isChecked: false },
        { label: 'Advance', isChecked: false },
    ];

    const [skillTests, setSkillTests] = useState([]);
    const [practiceTests, setPracticeTests] = useState([]);
    const [searchQuery, setSearchQuery, resetSearchQuery] = useInputState('');
    const [roles, setRoles] = useState('');
    const [levels, setLevels] = useState(skillTestLevels);
    const [showSidebar, setShowSidebar] = useState(true);
    const [testType, setTestType] = useState(1);

    const handleTestTypeChange = (event, newValue) => {
        setTestType(newValue);
    };

    const handleRolesChange = (e) => {
        setRoles(e.target.value);
    };

    const handleLevelsChange = (e) => {
        const currName = e.currentTarget.name;
        let updateLevels = levels.map((level) => {
            if (level.label === currName)
                return { ...level, isChecked: !level.isChecked };
            else return level;
        });
        setLevels(updateLevels);

        let filters = {};
        if (searchQuery !== '') filters.title = searchQuery;
        if (roles !== '') filters.roles = [roles];
        const levelFilters = updateLevels
            .filter((level) => level.isChecked)
            .map((level) => level.label);
        if (levelFilters && levelFilters.length > 0)
            filters.difficulty = levelFilters;
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post('/api/quiz', { filters }, config)
            .then((res) => {
                if (res.data.quizzes && res.data.quizzes.length > 0) {
                    setSkillTests(res.data.quizzes);
                }
            })
            .catch((err) => console.log(err));
    };

    const resetFilters = (e) => {
        resetSearchQuery();
        setRoles('');
        setLevels(skillTestLevels);
    };

    useEffect(() => {
        let filters = {};
        if (searchQuery !== '') filters.title = searchQuery;
        if (roles !== '') filters.roles = [roles];
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post('/api/quiz', { filters }, config)
            .then((res) => {
                if (res.data.quizzes && res.data.quizzes.length > 0) {
                    setSkillTests(
                        res.data.quizzes.filter(
                            (quiz) => quiz.testType === 'skill test'
                        )
                    );
                    setPracticeTests(
                        res.data.quizzes.filter(
                            (quiz) => quiz.testType === 'practice test'
                        )
                    );
                }
            })
            .catch((err) => console.log(err));
    }, [searchQuery, roles]);

    const handleSidebar = (e) => {
        setShowSidebar(!showSidebar);
    };

    useEffect(() => {
        window
            .matchMedia('(max-width: 992px)')
            .addEventListener('change', (e) => {
                setShowSidebar(!e.matches);
            });

        document.body.style.overflowY = 'hidden';

        return () => {
            window.removeEventListener('change', (e) => {
                setShowSidebar(!e.matches);
            });
            document.body.style.overflowY = 'scroll';
        };
    }, []);

    return (
        <section className="SkillTests">
            <div className="filler-div"></div>
            <div className="SkillTests-banner">
                <h1>Practice Tests</h1>
                <p>
                    Practice tests to stay ahead of the crowd and upskill
                    yourself
                </p>
            </div>
            <div className="SkillTests-content">
                <button className="filters-btn" onClick={handleSidebar}>
                    filters{' '}
                    <i
                        className={`fas fa-chevron-${
                            showSidebar ? 'up' : 'down'
                        }`}
                    ></i>
                </button>
                <div
                    className="SkillTests-sidebar"
                    style={{ display: showSidebar ? 'block' : 'none' }}
                >
                    <div className="search-filter">
                        <div>
                            <h1>Search Tests</h1>
                            <p onClick={resetFilters}>Clear filters</p>
                        </div>
                        <input
                            type="text"
                            onChange={setSearchQuery}
                            value={searchQuery}
                            name="search"
                            placeholder="Search"
                        />
                    </div>
                    <div className="roles-filter">
                        <h1>Roles</h1>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="role-select">
                                Select your role
                            </InputLabel>
                            <Select
                                labelId="role-select"
                                id="role-select-menu"
                                value={roles}
                                label="Select your role"
                                onChange={handleRolesChange}
                                className="roles-select"
                                disableUnderline
                                style={{ marginTop: 0 }}
                            >
                                <MenuItem
                                    value="unset"
                                    selected
                                    disabled
                                    hidden
                                >
                                    Placeholder
                                </MenuItem>
                                <MenuItem value="Research Analyst">
                                    Research Analyst
                                </MenuItem>
                                <MenuItem value="Data Analyst">
                                    Data Analyst
                                </MenuItem>
                                <MenuItem value="Data Engineer">
                                    Data Engineer
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="levels-filter">
                        <h1>Levels</h1>
                        {levels.map((level, idx) => (
                            <span key={idx}>
                                <input
                                    type="checkbox"
                                    name={level.label}
                                    checked={level.isChecked}
                                    onChange={handleLevelsChange}
                                    id={level.label}
                                />
                                <label htmlFor={level.label}>
                                    {level.label}
                                </label>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="SkillTests-list">
                    {testType === 0 ? (
                        skillTests && skillTests.length > 0 ? (
                            skillTests.map((test, idx) => {
                                return (
                                    <div className="SkillTest-card" key={idx}>
                                        <div className="card-banner">
                                            <img
                                                src={pythonIllus}
                                                alt="skill test illustration"
                                            />
                                        </div>
                                        <div className="card-content">
                                            <p>
                                                {test.difficulty
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    test.difficulty.substring(
                                                        1
                                                    )}{' '}
                                                Level
                                            </p>
                                            <h1>{test.title}</h1>
                                            {/* <p>
                                                {test.description.slice(0, 200)}
                                                ...
                                            </p> */}
                                            <ul>
                                                {test.relatedRoles.map(
                                                    (role, idx) => (
                                                        <li key={idx}>
                                                            {role.replace(
                                                                /(^\w|\s\w)/g,
                                                                (m) =>
                                                                    m.toUpperCase()
                                                            )}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                            <Link
                                                to={`/skilltests/${test._id}`}
                                            >
                                                Give Test{' '}
                                                <i className="fas fa-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <h1 className="not-found">
                                {' '}
                                🔍 Looking for Skill Tests!
                            </h1>
                        )
                    ) : practiceTests && practiceTests.length > 0 ? (
                        practiceTests.map((test, idx) => {
                            return (
                                <div className="SkillTest-card" key={idx}>
                                    <div className="card-banner">
                                        <img
                                            src={pythonIllus}
                                            alt="skill test illustration"
                                        />
                                    </div>
                                    <div className="card-content">
                                        <p>
                                            {test.difficulty
                                                .charAt(0)
                                                .toUpperCase() +
                                                test.difficulty.substring(
                                                    1
                                                )}{' '}
                                            Level
                                        </p>
                                        <h1>{test.title}</h1>
                                        {/* <p>
                                            {test.description.slice(0, 200)}...
                                        </p> */}
                                        <ul>
                                            {test.relatedRoles.map(
                                                (role, idx) => (
                                                    <li key={idx}>
                                                        {role.replace(
                                                            /(^\w|\s\w)/g,
                                                            (m) =>
                                                                m.toUpperCase()
                                                        )}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                        <Link to={`/skilltests/${test._id}`}>
                                            Give Test{' '}
                                            <i className="fas fa-arrow-right"></i>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <h1 className="not-found">
                            🔍 Looking for Skill Tests!
                        </h1>
                    )}
                </div>
            </div>
        </section>
    );
}

export default SkillTests;
