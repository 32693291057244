import React, { useState } from 'react';

const Refund = ({}) => {
    return (
        <div style={{ margin: 'auto' }}>
            <div className="filler-div" />
            <h1
                style={{
                    textAlign: 'center',
                    marginBottom: '1.5rem',
                    marginTop: '2rem',
                }}
            >
                Refund Policy
            </h1>
            <p style={{ maxWidth: '80vw', margin: 'auto' }}>
                By making the payment for a course, you hereby acknowledge and
                agree that Supergrad will not offer refunds on any fees and
                charges related to your purchase of any products or Services.
                This includes any partially used or unused periods for which you
                have already paid, regardless of whether it is a subscription,
                bundled, manual subscription or one-time term payment method. We
                do not guarantee refunds for lack of usage or dissatisfaction.{' '}
            </p>
        </div>
    );
};

export default Refund;
