import styled from 'styled-components';
import BannerImg from '../../assets/images/banner-bg.svg';

export const CDMainContainer = styled.section`
    height: '100%';
`;

export const CDContainer = styled.main`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // overflow-y:auto;
    overflow-x: hidden;
    @media screen and (min-width: 1201px) {
        padding-top: 1rem;
    }
    @media screen and (max-width: 1200px) {
        margin-top: 2rem;
        flex-direction: column;
    }
`;

export const CDLeft = styled.div`
    color: rgba(38, 50, 56, 0.7);
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media screen and (min-width: 1201px) {
        // padding : 1rem;
        padding-top: 1rem;
        width: 58vw;
        padding-left: 5vw;
        padding-right: 0vw;
    }
    @media screen and (max-width: 1200px) {
        padding-top: 2rem;
        padding-left: 3rem;
        padding-right: 1rem;
        width: 80vw;
    }

    @media screen and (max-width: 1000px) {
        padding-top: 2rem;
        padding-left: 3rem;
        padding-right: 1rem;
        width: 80vw;
    }

    @media screen and (max-width: 900px) {
        margin-left: -1rem;
        width: 90vw;
    }
`;

export const CDRight = styled.div`
    color: rgba(38, 50, 56, 0.7);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: auto;

    @media screen and (min-width: 1201px) {
        // padding : 1rem;
        padding-top: 2rem;
        width: 37vw;
    }

    @media screen and (max-width: 1200px) {
        margin-left: 5vw;
        width: 90vw;
    }
    @media screen and (max-width: 1000px) {
        padding-top: 2rem;
        padding-right: 1rem;
        width: 80vw;
    }

    @media screen and (max-width: 900px) {
        padding-left: 5rem;
        margin-left: -1rem;
        width: 90vw;
    }

    @media screen and (max-width: 600px) {
        padding-left: 0rem;
        margin-left: 0rem;
        width: 90vw;
    }
`;

export const CDGreeting = styled.div``;

export const CDName = styled.h1`
    font-size: 17px;
    font-weight: 700;
    line-height: 22.2px;
    margin-left: 10px;
`;

export const CDStatus = styled.h1`
    font-size: 15px;
    font-weight: 500;
    line-height: 22.2px;
    margin-left: 10px;
    margin-bottom: 2vw;
`;

export const CDSkillTest = styled.div`
    margin-bottom: 2.5vw;
    @media screen and (max-width: 600px) {
        margin-left: -1.5rem;
    }
`;

export const CDHeading = styled.div`
    color: black;
    width: 90%;
    justify-content: space-between;
    margin-top: 1rem;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #263238;
    margin-bottom: 2rem;
    a {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #4544a2;
    }
    @media screen and (min-width: 811px) {
        // padding-top : 1rem;
    }
    @media screen and (max-width: 810px) {
        // width: 90%;
        // margin-top: 2rem;
        // justify-content: space-around;
    }
`;

export const CDSkillSuggest = styled.div`
    overflow-x: auto;

    div {
        margin-bottom: 5px;
    }

    &:: -webkit-scrollbar {
        display: none;
    }
    @media screen and (min-width: 811px) {
        // padding-top : 1rem;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 2rem;
        width: 95vw;
        // flex-direction: row;
        // justify-content: flex-start;
    }
`;

export const CDSkillSuggestBanner1 = styled.span`
    display :inline-block;
    width : 240px;
    height: 224px;
    margin-right: 20px;
    padding-left: 20px;
    padding-top:2%;
    position: relative;   
    background-image: linear-gradient(to bottom right, #FFB199, #FF6188);
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;
        color: #FFFFFF;
        z-index-1;
        border-radius: 20px;
        input{
            cursor: pointer;
            position :relative;
            margin-left: 123px;
            margin-top: 86px;
            height: 37px;
            width: 101px;
            padding-left:30px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            align-items: center;
            border:none;
            color: #FF6188;
            border-radius:7px;
            transition: margin-left 1s ease;
            &:hover{
                box-shadow:2px 3px #FFB199;
                margin-left: 110px;
            }
        }

        @media screen and (max-width: 900px) {
            padding-right: 20px;
        }
`;

export const CDSkillSuggestBanner2 = styled.span`
display :inline-block;
    width : 240px;
    height: 224px;
    margin-right: 2%;
    padding-left: 2%;
    padding-top:2%;
    position: relative;   
    background-image: linear-gradient(to bottom right, #04BEFE, #4481EB);
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;
        color: #FFFFFF;
        z-index-1;
        border-radius: 20px;
        span{
            margin-left: 60%;
            font-size:2px;
            line-height:5px;
        }
        input{
            cursor: pointer;
            position :relative;
            margin-left: 123px;
            margin-top: 86px;
            height: 37px;
            width: 101px;
            padding-left:30px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            align-items: center;
            border:none;
            color: #4481EB;
            border-radius:7px;
            transition: margin-left 1s ease;
            &:hover{
                box-shadow:2px 3px #04BEFE;
                margin-left: 110px;
            }
        }
        @media screen and (max-width: 900px) {
            padding-right: 20px;
        }
`;

export const CDSkillSuggestBanner3 = styled.span`


display :inline-block;
    width : 240px;
    height: 224px;
    margin-right: 2%;
    padding-left: 2%;
    padding-top:2%;
    position: relative;   
    background-image: linear-gradient(to bottom right, #D6FF94, #3DC24B);
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 26px;
        line-height: 32px;
        color: #FFFFFF;
        z-index-1;
        border-radius: 20px;
        span{
            margin-left: 60%;
            font-size:2px;
            line-height:5px;
        }
        input{
            cursor: pointer;
            position :relative;
            margin-left: 123px;
            margin-top: 86px;
            height: 37px;
            width: 101px;
            padding-left:30px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            display: flex;
            align-items: center;
            border:none;
            color: #3DC24B;
            border-radius:7px;
            transition: margin-left 1s ease;
            &:hover{
                box-shadow:2px 3px #D6FF94;
                margin-left: 110px;
            }
        }
        @media screen and (max-width: 900px) {
            padding-right: 20px;
        }
`;

export const CDSuggestUpskill = styled.div``;

export const CDSuggestUpskillCar = styled.div`
    @media screen and (max-width: 600px) {
        height: 300px;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        &:: -webkit-scrollbar {
            display: none;
        }
    }
`;

export const CDSuggestUpskillBanner1 = styled.div`
    cursor: pointer;
    border: 1px solid gray;
    // outline-style: solid;
    // outline-color: gray;
    border-radius: 10px;
    padding: 35px 20px;
    display: flex;
    justify-content: space-between;
    width: 50vw;
    @media screen and (max-width: 1200px) {
        width: 95%;
    }
    @media screen and (max-width: 850px) {
        justify-content: space-around;
    }
    @media screen and (max-width: 600px) {
        padding: 35px 20px;
        width: 90%;
        margin-right: 25px;
    }
    @media screen and (max-width: 500px) {
        // margin-left: -10px;
    }
    margin-bottom: 20px;
`;

export const Div1 = styled.div`
    display: inline-block;
    width: 20%;
    @media screen and (max-width: 850px) {
        display: none;
    }
`;

export const Div2 = styled.div`
    display: inline-block;
    word-wrap: break-word;

    width: 55%;
    // padding-right :  20px;

    h1 {
        color: black;
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 25px;
    }

    span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: #a5adbb;
        padding-top: 20px;
    }

    p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: #6e798c;
        margin-top: 10px;
        word-wrap: break-word;
    }

    @media screen and (max-width: 700px) {
        width: 90vw;
        p {
            width: 80vw;
        }
        padding-left: 30px;
        padding-right: 10px;
    }

    @media screen and (max-width: 600px) {
        width: 90vw;
        p {
            width: 80vw;
        }
        padding-left: 30px;
        padding-right: 10px;
    }
`;

export const Div3 = styled.div`
    display: inline-block;
    width: 20%;
    @media screen and (max-width: 700px) {
        display: none;
    }
`;

export const Layer = styled.div`
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    padding: 15px;
    padding-left: 12px;
`;

export const CDRightHelper = styled.div`
    @media screen and (min-width: 1201px) {
        margin-top: -30px;
        // position: fixed;
    }
`;

export const CDEvents = styled.div`
    height: 40%;
    @media screen and (max-width: 700px) {
        margin-left: 10vw;
    }
`;

export const CDupcoming = styled.div`
    cursor: pointer;
    overflow: scroll;
    overflow-x: hidden;
    height: 90%;
    @media screen and (min-width: 1201px) {
        padding-top: 0rem;
        width: 35vw;
    }

    @media screen and (max-width: 1200px) {
        width: 90vw;
    }
    @media screen and (max-width: 1000px) {
        padding-top: 2rem;
        width: 90vw;
    }

    @media screen and (max-width: 900px) {
        margin-left: -1rem;
        width: 90vw;
    }

    @media screen and (max-width: 600px) {
        margin-left: -1rem;
        width: 80vw;
    }
`;

export const CDDiscord = styled.div`
    cursor: pointer;
    overflow: scroll;
    @media screen and (min-width: 1201px) {
        padding-top: 0rem;
        width: 35vw;
    }

    @media screen and (max-width: 1200px) {
        width: 90vw;
    }
    @media screen and (max-width: 1000px) {
        padding-top: 2rem;
        width: 90vw;
    }

    @media screen and (max-width: 900px) {
        margin-left: -1rem;
        width: 90vw;
    }

    @media screen and (max-width: 600px) {
        width: 100vw;
        margin: 0;
    }
`;

export const CDupcomingBanner1 = styled.div`
    border: 1px solid #d7def0;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 5px;
    img {
        height: 80px;
    }
    div {
        padding: 10px 40px;
        justify-content: start;
    }

    span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 111%;
        color: rgba(38, 50, 56, 0.5);
    }
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 111%;
        color: #263238;
    }

    @media screen and (max-width: 1345px) {
        img {
            height: 60px;
        }
        div {
            padding: 7px 20px;
        }

        span {
            font-size: 10px;
            line-height: 100%;
        }
        h1 {
            font-size: 15px;
            line-height: 111%;
            color: #263238;
        }
    }
    @media screen and (min-width: 1200px) {
    }
    @media screen and (max-width: 1000px) {
    }
    @media screen and (max-width: 600px) {
    }
    @media screen and (max-width: 500px) {
    }
`;

export const SubHeaading = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0;
`;

export const CDupcomingApply = styled.div`
    margin-top: 5.5vh;
    border-radius: 10px;
    padding: 0px 0px;
    align-items: center;
    justify-content: center;
    height: 190px;
    display: flex;
    flex-direction: column;
    background: #4544a2;
    div {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        width: 65%;
    }
    button {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 111%;
        color: #4544a2;
        background-color: #fff;
        border-radius: 10px;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 15px 25px;
    }
    @media screen and (max-width: 1300px) {
        border: 1px solid gray;
        border-radius: 10px;
        padding: 0px 0px;
        align-items: center;
        justify-content: center;
        height: 190px;
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(to bottom right, #6871e9, #4544a2);
        div {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 118.5%;
            text-align: center;
            color: #ffffff;
        }
    }
    @media screen and (max-width: 1000px) {
    }
`;

export const CDCards = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

export const CDRR = styled.div`
    padding: 1.2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 12.7vw;
    border: 1px solid #e9ecf2;
    box-sizing: border-box;
    border-radius: 15px;
    margin: 10px;
    margin-top: 0;
    transition: all 200ms ease;
    //cursor: pointer;
    min-height: 10px;
    // height: fit-content;
    i {
        display: flex;
        justify-content: center;
        margin-bottom: 1vw;
    }

    span {
        display: none;
        transition: all 500ms ease;
        margin-bottom: 1vw;
    }

    button {
        // margin-top: 10px;
        background: #4544a2;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 111%;
        color: #ffffff;
        height: 40px;
        padding: 7px;
        border-radius: 10px;
        width: 90px;
        border: none;
        cursor: pointer;
        transition: all 500ms ease;
    }

    h1 {
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #4544a2;
        transition: all 500ms ease;
        height: 35px;
    }

    p {
        padding: 5px;
        margin-bottom: 10px;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 111%;
        text-align: center;
        color: rgba(69, 68, 162, 0.5);
        transition: all 500ms ease;
        height: 40px;
    }

    &:hover {
        background: #4544a2;
        span {
            display: flex;
            justify-content: center;
        }

        i {
            display: none;
        }
        h1 {
            color: #ffffff;
        }

        p {
            color: #ffffff;
        }

        button {
            background: #ffffff;
            color: #4544a2;
        }
    }

    @media screen and (max-width: 600px) {
        width: 90vw;
        margin-left: -1rem;
        i {
        }
    }
`;

export const RRB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    height: 40vh;
    align-items: center;
    cursor: pointer;

    input {
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 20px;
        width: 20vw;
    }

    @media screen and (max-width: 900px) {
        input {
            width: 30vw;
        }
    }
    @media screen and (max-width: 600px) {
        padding: 10px;
        input {
            width: 70vw;
        }
    }
`;

export const RR1t = styled.div`
    width: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between:
    align-items:center;

    span{
        disply: flex;
        justify-content: center;
        align-items: center;
        
    }

    input {
        outline: none;
        border: 1px solid #E9ECF2;
        box-sizing: border-box;
        border-radius: 12px;
        background: rgba(233, 236, 242, 0.4);
        cursor: pointer;
    }

    @media screen and (max-width: 900px){
            width: 30vw;
    }
    @media screen and (max-width: 600px){
        input{
            width: 70vw;

    }
`;

export const RR1h = styled.div`
    font-family: DM Serif Display;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 111%;
    /* or 33px */

    text-align: center;

    color: #4544a2;
`;

export const RR1d = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 111%;
    /* or 16px */

    text-align: center;

    color: rgba(69, 68, 162, 0.5);
    padding: 20px;
`;

export const RR1b = styled.button`
    text-align: center;
    background: #4544a2;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    padding: 15px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
`;

export const CDSB = styled.div`
    display: block;
    border: 1px solid #d7def0;
    border-radius: 15px;
    margin-left: 10px;
    width: 52vw;
    margin-top: 10px;
    // margin-bottom: 50px;
    background-color: #f3f4f4;
    // background-image: url(${BannerImg});
    // background-repeat: no-repeat;
    // background-size: cover;

    h1 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        padding-left: 2vw;
        padding-right: 2vw;
        font-size: 20px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #4544a2;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }

    p {
        // margin-top: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        padding-left: 2vw;
        padding-right: 2vw;
        line-height: 130%;
        text-align: justify;
        color: #8c8c8c;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .apply-button-container {
        width: 30vw;
    }

    img {
        width: 18vw;
    }

    @media screen and (max-width: 1000px) {
        width: 90vw;
        div {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90vw;
            margin: 0;
        }

        .apply-button-container {
            margin: auto;
        }

        img {
            width: 85vw;
            display: none;
        }
    }

    .test-comp-header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 100%;
        margin-bottom: 20px;
    }

    .score {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .score span {
        font-size: 25px;
    }
`;

export const Btn1 = styled.button`
    width: 20vw;
    background: #4544a2;
    border-radius: 8px;
    padding: 10px;
    margin-left: 2vw;
    margin-right: 2vw;
    // margin-top: 2vw;
    color: #ffffff;
    border: none;
    position: absolute;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    @media screen and (max-width: 1000px) {
        width: 75vw !important;
        padding: 10px !important;
        margin-top: 10px;
        position: relative !important;
        margin-bottom: 1.5rem;
        margin-left: 7vw;
    }
`;

export const BannerImage = styled.img`
    width: 52vw;
    margin-top: -3vw !important;
    visibility: hidden;

    @media screen and (min-width: 768px) {
        visibility: visible;
    }
`;

export const Btn2 = styled.button`
    width: 14vw;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    color: #ff6188;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    @media screen and (max-width: 1200px) {
        width: 75vw;
        margin-top: 30px;
        font-size: 18px;
    }
`;

export const Btn3 = styled.button`
    width: 15vw;
    background: #4544a2;
    border-radius: 8px;
    padding: 10px;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 2vw;
    color: #ffffff;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    @media screen and (max-width: 600px) {
        width: 75vw !important;
        padding: 10px !important;
        margin-top: 10px;
    }

    @media screen and (max-width: 1200px) {
        width: 15vw;
        margin-top: 20px;
        padding: 25px;
        font-size: 18px;
    }
`;

export const BtnIntern = styled.button`
    width: 20vw;
    background: #4544a2;
    border-radius: 8px;
    padding: 10px;
    margin-left: 2vw;
    margin-right: 2vw;
    // margin-top: 2vw;
    color: #ffffff;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    @media screen and (max-width: 1000px) {
        width: 75vw !important;
        padding: 10px !important;
        margin-top: 10px;
        position: relative !important;
        margin-bottom: 1.5rem;
    }
`;

export const BtnJob = styled.button`
    width: 18vw;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    color: #ff6188;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
    @media screen and (max-width: 1000px) {
        width: 75vw;
    }
`;
