import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';
import { setUserType } from '../store/auth/authStatus';

function RecruiterRoute(props) {
    const dispatch = useDispatch();
    let isRecruiter = useSelector(
        (st) => st.authStatus && st.authStatus.userType === 'recruiter'
    );
    if (!isRecruiter) {
        isRecruiter = localStorage.getItem('usertype') === 'recruiter';
    }
    if (!isRecruiter) {
        dispatch(
            handleSnackOpen({
                message: "You're unauthorized to access this route!",
                variant: 'warning',
            })
        );
        return <Navigate to="/" />;
    }
    return props.children;
}

export default RecruiterRoute;
