import { axiosInstance } from './api';

export const Register = async (data) => {
    try {
        const response = await axiosInstance.post('/api/user/register', data);
        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        return {
            success: false,
            data: error.response
                ? error.response.data
                : {
                      msg: 'Some unknown error occured. Please try again later.',
                  },
        };
    }
};

export const Login = async (data) => {
    try {
        const response = await axiosInstance.post('/api/auth/login', data);
        return {
            success: true,
            data: response.data,
        };
    } catch (error) {
        return {
            success: false,
            data: error.response
                ? error.response.data
                : {
                      msg: 'Some unknown error occured. Please try again later.',
                  },
        };
    }
};
