import React, { useState, useEffect } from 'react';
import BlogItem from '../BlogItem/BlogItem';
import './BlogList.css';
const BlogList = ({ blogs }) => {
    return (
        <ul>
            <h1 className="blog-heading">Recommended for You</h1>
            {blogs.map((b, index) => (
                <BlogItem
                    key={b._id}
                    id={b._id}
                    title={b.title}
                    datetime={b.date}
                    introduction={b.introduction}
                    blogBannerS3Path={b.blogBannerS3Path}
                    number={index}
                />
            ))}
        </ul>
    );
};

export default BlogList;
