import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setToken, setUser, setUserType } from '../../store/auth/authStatus';
import { handleSnackOpen } from '../../store/Toast/Toast';

export default function AuthParamsHandler() {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const userId = new URLSearchParams(search).get('userId');
    const userType = new URLSearchParams(search).get('userType');
    const profileCompleted = new URLSearchParams(search).get('profileCompleted');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (token && userId && userType) {
            dispatch(setToken(token));
            dispatch(
                setUser({
                    userId: userId,
                })
            );
            dispatch(setUserType(userType));
            dispatch(
                handleSnackOpen({
                    message: 'Authentication',
                    variant: 'success',
                })
            );
            if(userType === 'user' || userType === 'candidate') {
                if(JSON.parse(profileCompleted) === true) {
                    return navigate('/dashboard');
                } else {
                    return navigate('/candidate/profile/edit')
                }
            } else {
                if(JSON.parse(profileCompleted) === true) {
                    navigate('/rdashboard') 
                } else {
                    navigate('/recruiter/profile/edit')
                }
            }
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Invalid auth params. Try again later!',
                    variant: 'error',
                })
            );
            navigate('/');
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - var(--nav-height))',
                paddingTop: 'var(--nav-height)',
            }}
        >
            <h2 style={{ textALign: 'center' }}>Processing... Please wait!</h2>
        </div>
    );
}
