import React, { useState } from 'react';
import axios from 'axios';
import Config from '../../config.json';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import useInputState from '../../hooks/useInputState';
import Grid from '@mui/material/Grid';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';

function ResumeReview({userData, handleResumeOpen}) {
    const [reviewText, setReviewText] = useInputState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayPageNav, setDisplayPageNav] = useState(false);

    const dispatch = useDispatch();

    const onDocumentLoadSuccess = ({numPages}) => {

        setNumPages(numPages);
    }   
    
    const changePage = offset => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const showPageNav = e => {
        setDisplayPageNav(true);
    }

    const hidePageNav = e => {
        setDisplayPageNav(false);
    }

    const submitReview = e => {
        if(reviewText !== "") {
            const config = {
                headers: {'x-auth-token': localStorage.getItem('token')}
            };
            const payload = {
                userId: userData._id,
                reviewText
            }
            axios.post('/api/admin/resume-review', payload, config)
                .then(res => {
                    if(res.data && res.data.userId) {
                        handleResumeOpen();
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success'
                            })
                        );
                    }
                }).catch(err => console.dir(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Please enter a review before submitting.',
                    variant: 'warning'
                })
            );
        }
    }

    
    return (
        <section className='ResumeReview'>
            <Grid container spacing={2} sx={{width: '100%', height: '100%', paddingRight: '40px'}}>
                <Grid item md={7} sm={12} xs={12} sx={{height: '100%', width: '100%'}}>
                    <div 
                        className='resume-pdf-container' 
                        onMouseEnter={showPageNav} 
                        onMouseLeave={hidePageNav}
                    >
                        <Document
                            files={`${Config.S3_URL}/${userData.resume.resumeS3FileKey}`}
                            onLoadSuccess={onDocumentLoadSuccess} 
                            className='resume-pdf'
                        >
                            <Page pageNumber={pageNumber}/>
                        </Document>
                        <div className='page-nav' style={{display: !displayPageNav ? 'none' : ''}}>
                            <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                            >
                                <i className="fa-solid fa-chevron-left"></i>
                            </button>
                            <p>
                                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                            </p>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                <i className="fa-solid fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={12} xs={12} sx={{paddingLeft: '10px'}}>
                    <div className='review-text-div'>
                        <span>
                            <h1>Enter your review</h1>
                            <button onClick={submitReview}>
                                <i className="fa-solid fa-paper-plane"></i>
                            </button>
                        </span>
                        <textarea
                            value={reviewText}
                            onChange={setReviewText}
                            placeholder="Enter Here"
                            rows={10}
                            cols={40}
                        ></textarea>
                        <button onClick={submitReview} className='submit-review-btn'>Submit</button>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default ResumeReview;