import Joi from 'joi';
import { constants } from '../constants';

const registerFormSchema = Joi.object({
    email: Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .error(new Error('Invalid Email Address.')),
    password: Joi.string()
        .min(8)
        .max(50)
        .label('Password')
        .required()
        .regex(new RegExp(constants.passwordRegex))
        .messages({
            'string.min': 'Invalid Password: Too short.',
            'string.max': 'Invalid Password: Too long.',
            'string.empty': 'Password required.',
            'string.pattern.base':
                'Invalid Password: Should only contain alphabets, digits and special characters.',
        }),
});

export default registerFormSchema;
