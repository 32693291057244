import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import '../../styles/SkillTests/SkillTestInfo.css';
import pythonIllus from '../../assets/illustrations/python-course-illus.jpg';
import profilePic from '../../assets/illustrations/profile-pic.jpg';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

function SkillTestInfo(props) {
    const [skillTestData, setSkillTestData] = useState({});
    let params = useParams();
    let location = useLocation();

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get(`/api/quiz/${params.id}/view`, config)
            .then((res) => {
                if (res.data) setSkillTestData(res.data);
            })
            .catch((err) => console.dir(err));
    }, [params.id]);

    let previousScore = 'N/A';
    if (location.state) previousScore = location.state.userScore;
    else if (skillTestData.scores && skillTestData.scores.score)
        previousScore = skillTestData.scores.score;
    if (skillTestData._id)
        return <Navigate to={`/skilltests/attend/${skillTestData._id}`} />;
    return (
        <section className="SkillTestInfo">
            <div className="filler-div"></div>
            <div className="test-banner">
                <h1>Preparing Questions {skillTestData.title}</h1>
            </div>
            {/* <div className="test-info">
                <h2>Skill Test Details</h2>
                <p>{skillTestData.description}</p>
                <h2>Job Types</h2>
                <ul>
                    {skillTestData.relatedRoles &&
                    skillTestData.relatedRoles.length > 0
                        ? skillTestData.relatedRoles.map((role, idx) => (
                              <li key={idx}>
                                  {role.replace(/(^\w|\s\w)/g, (m) =>
                                      m.toUpperCase()
                                  )}
                              </li>
                          ))
                        : ''}
                </ul>
                <h2>Test Level</h2>
                <p>
                    {skillTestData.difficulty
                        ? skillTestData.difficulty.charAt(0).toUpperCase() +
                          skillTestData.difficulty.substring(1)
                        : ''}{' '}
                    Level
                </p>
            </div> */}
            {/* <div className='related-info'>
                <h2>Prepare For Test</h2>
                <div className='course-card'>
                    <img src={pythonIllus} alt="course illustration"/>
                    <div className="course-desc">
                        <h1>Learn Python Programming Masterclass</h1>
                        <p style={{fontFamily: "Open Sans", color: "#a5adbb"}}>by Tim Buchalka</p>
                        <p>This Python For Beginners Course Teaches You The Python Language Fast. Includes Python Online Training With Python 3</p>
                    </div>
                    <div className="course-info">
                        <p><i className="far fa-clock"></i> <strong>42</strong> hours</p>
                        <p><i className="fas fa-book"></i> <strong>31</strong> lessons</p>
                        <p><i className="far fa-user"></i> <strong>Medium</strong> level</p>
                    </div>
                </div>
                <h2>Testimonials</h2>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <div className='testimonial-card'>
                            <span>
                                <img src={profilePic} alt="profile pic"/>
                                <div>
                                    <h1>Sasha Perry</h1>
                                    <p>UX Designer</p>
                                </div>
                            </span>
                            <span>
                                <i className="fas fa-star selected"></i>
                                <i className="fas fa-star selected"></i>
                                <i className="fas fa-star selected"></i>
                                <i className="fas fa-star selected"></i>
                                <i className="fas fa-star"></i>
                            </span>
                            <p>
                                Nisi vivamus neque elementum, at pharetra. Cras gravida congue in tincidunt neque, ipsum egestas. Duis risus ipsum dis commodo. Enim euismod velit amet volutpat egestas urna in eget.
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div> */}
        </section>
    );
}

export default SkillTestInfo;
