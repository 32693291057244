import React from 'react';
import { FcGoogle } from 'react-icons/fc';

export default function GoogleButton({ children, userType, ...props }) {
    return (
        <a
            style={{
                backgroundColor: 'white',
                padding: 5,
                border: '1px solid rgba(234, 234, 234, 1)',
                borderRadius: 5,
                fontSize: '0.8rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
            }}
            href={`/api/auth/register/google/${userType}`}
            {...props}
        >
            <FcGoogle fontSize="1.3rem" />
            <span>{children}</span>
        </a>
    );
}
