import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './blogdetail.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import BlogsCards from './BlogCard/BlogsCards';
import { Container } from '@mui/material';
import { createEditor, Element as SlateElement } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

const Blogdetail = () => {
    const { id } = useParams();
    const [blogData, setBlogData] = useState();
    const editor = useMemo(() => withReact(createEditor()), []);
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

    const fetchdetails = async () => {
        const data = await axios
            .get(`/api/blog/getblog/${id}`)
            .then((res) => {
                setBlogData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchdetails();
    }, []);
    if (!blogData) return <div />;
    return (
        <section className="blog_detail">
            <div className="detail_image">
                <img src="/blogimg/blog.png" alt="" />
            </div>
            <Container>
                <div className="details_Container">
                    <div className="blog_info">
                        <div className="blog_cont">
                            <div className="blog_heading">
                                <h1>{blogData.title}</h1>
                            </div>
                        </div>

                        <div className="blog_images">
                            <img
                                src={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${blogData.blogBannerS3Path}`}
                                alt=""
                            />
                        </div>
                        <div className="blog_description">
                            <p>{blogData.blog}</p>
                            <br />
                            <h1>{blogData.subtitle}</h1>
                            <br />
                            <Slate editor={editor} value={blogData.content}>
                                <Editable
                                    readOnly
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                    placeholder="Enter some plain text..."
                                />
                            </Slate>
                        </div>
                        {/*<div className="share_buttons ">
                        <p>Share to</p>
                        <span>
                            <AiFillLinkedin />
                        </span>
                        <span>
                            <AiFillFacebook />
                        </span>
                        <span>
                            <AiFillTwitterSquare />
                        </span>
    </div>*/}
                    </div>
                </div>
            </Container>
            {/*<div className="more_blogs">
                blog Container 
    </div>*/}
        </section>
    );
};

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};

export default Blogdetail;
