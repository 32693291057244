import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import '../../styles/SkillTests/SkillTest.css';
import { CustomDialog, useDialog } from 'react-st-modal';

function SkillTest({ skillTestId, questions }) {
    let params = useParams();
    let navigate = useNavigate();
    const dialog = useDialog();
    const [skillTestData, setSkillTestData] = useState({});
    const [currQuestion, setCurrQuestion] = useState(0);
    const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
    const [showQuesPanel, setShowQuesPanel] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get(`/api/quiz/${params.id}/view`, config)
            .then((res) => {
                if (res.data) setSkillTestData(res.data);
            })
            .catch((err) => console.log(err));
    }, [params.id]);

    const changeQuestion = (e) => {
        if (e.currentTarget.value === 'back') {
            setCurrQuestion((currState) =>
                currState > 0 ? currState - 1 : currState
            );
        } else if (e.currentTarget.value === 'next') {
            setCurrQuestion((currState) => {
                if (currState < skillTestData.questions.length - 1)
                    return currState + 1;
                else return currState;
            });
        } else {
            setCurrQuestion(parseInt(e.currentTarget.value));
        }
    };

    const handleOptionChange = (e) => {
        const currOptionId = e.currentTarget.value;
        setSkillTestData((currState) => {
            const updateQuestions = currState.questions.map((ques, idx) => {
                if (idx === currQuestion) {
                    const updateOptions = ques.options.map((option) => {
                        if (option._id === currOptionId)
                            return { ...option, selected: true };
                        else return { ...option, selected: false };
                    });
                    return { ...ques, options: updateOptions };
                }
                return ques;
            });
            return { ...currState, questions: updateQuestions };
        });
    };

    function ScoreDialog({ totalScore, userScore }) {
        const dialog = useDialog();

        return (
            <div
                style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
                className="interview-modal"
            >
                <h1>Result</h1>
                <br />
                <h3>You Scored {`${userScore}/${totalScore}`}</h3>
                <br />
                <button
                    onClick={() => {
                        dialog.close();
                        navigate('/dashboard');
                    }}
                >
                    Okay
                </button>
            </div>
        );
    }

    const openDialog = (e) => {
        setOpenSubmitDialog(true);
    };
    const closeDialog = (e) => {
        setOpenSubmitDialog(false);
    };

    const submitTest = (e) => {
        setIsSubmitting(true);
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const userResponse = skillTestData.questions.map((ques) => {
            const selectedOption = ques.options.find(
                (option) => option.selected
            );
            return {
                questionId: ques._id,
                answerId: selectedOption ? selectedOption._id : '',
            };
        });
        axios
            .post(
                '/api/quiz/submit',
                { userResponse, quizId: skillTestData._id },
                config
            )
            .then(async (res) => {
                closeDialog();
                //if(res.data) navigate(`/skilltests/${skillTestData._id}`, {state: {...res.data}});
                await CustomDialog(
                    <ScoreDialog
                        totalScore={res.data.totalScore}
                        userScore={res.data.userScore}
                    />,
                    {}
                );
                if (res.data) navigate('/dashboard');
            })
            .catch((err) => console.log(err));
    };

    const handleQuesPanel = (e) => {
        setShowQuesPanel(!showQuesPanel);
    };

    useEffect(() => {
        window
            .matchMedia('(max-width: 992px)')
            .addEventListener('change', (e) => {
                setShowQuesPanel(!e.matches);
            });

        return () => {
            window.removeEventListener('change', (e) => {
                setShowQuesPanel(!e.matches);
            });
        };
    }, []);

    const isLastQuestion =
        skillTestData.questions &&
        currQuestion < skillTestData.questions.length - 1;
    return (
        <section className="SkillTest">
            <div className="filler-div"></div>
            <div className="SkillTest-content">
                <button className="ques-panel-btn" onClick={handleQuesPanel}>
                    Question Panel{' '}
                    <i
                        className={`fas fa-chevron-${
                            showQuesPanel ? 'up' : 'down'
                        }`}
                    ></i>
                </button>
                <div
                    className="question-panel"
                    style={{ display: showQuesPanel ? 'block' : 'none' }}
                >
                    <h1>Question Panel</h1>
                    {skillTestData.questions &&
                    skillTestData.questions.length > 0
                        ? skillTestData.questions.map((ques, idx) => (
                              <button
                                  key={idx}
                                  value={idx}
                                  className={
                                      currQuestion === idx ||
                                      ques.options.find(
                                          (option) => option.selected
                                      )
                                          ? 'selected'
                                          : ''
                                  }
                                  onClick={changeQuestion}
                              >
                                  {idx + 1}
                              </button>
                          ))
                        : ''}
                </div>
                <div className="question-container">
                    <div className="question-info">
                        <h1>Question No. {currQuestion + 1}</h1>
                        <h1 style={{ paddingRight: '80px' }}>Max Marks: 1</h1>
                    </div>
                    <p>
                        Multiple Choice (Select 1 out of 4 options, for the
                        question below)
                    </p>
                    <hr className="styled-hr" />
                    <div className="question">
                        <p style={{ width: '80%' }}>
                            {skillTestData.questions &&
                            skillTestData.questions.length > 0
                                ? skillTestData.questions[currQuestion].question
                                : ''}
                        </p>
                        {skillTestData.questions &&
                        skillTestData.questions.length > 0
                            ? skillTestData.questions[currQuestion].options.map(
                                  (option) => (
                                      <span key={option._id}>
                                          <input
                                              type="radio"
                                              id={option._id}
                                              value={option._id}
                                              name="option"
                                              onChange={handleOptionChange}
                                              checked={
                                                  option.selected
                                                      ? option.selected
                                                      : false
                                              }
                                          />
                                          <label htmlFor={option._id}>
                                              {option.text}
                                          </label>
                                      </span>
                                  )
                              )
                            : ''}
                    </div>
                    <div className="question-nav-btns">
                        <button onClick={changeQuestion} value="back">
                            Back
                        </button>
                        <button
                            onClick={
                                isLastQuestion ? changeQuestion : openDialog
                            }
                            value="next"
                        >
                            {isLastQuestion ? 'Next' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
            <Dialog open={openSubmitDialog} aria-labelledby="submit-test">
                <DialogTitle id="submit-test" className="dialog-title">
                    Are you sure you want to submit the test?
                </DialogTitle>
                <DialogActions className="dialog-btns">
                    {isSubmitting ? (
                        <p>Submitting..</p>
                    ) : (
                        <>
                            <button onClick={submitTest}>Yes</button>
                            <button onClick={closeDialog}>No</button>{' '}
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </section>
    );
}

export default SkillTest;
