import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import PrimaryButton from '../Common/PrimaryButton';
import { RegisterFormContainer } from './AuthComponents';
import { BiHide, BiShowAlt } from 'react-icons/bi';

function LoginForm(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <RegisterFormContainer onSubmit={handleSubmit}>
            <TextField
                variant="standard"
                label="Email Address"
                type="email"
                name="email"
                required
                fullWidth
                value={props.email}
                onChange={(e) => props.setEmail(e.target.value)}
            />
            <TextField
                variant="standard"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                required
                fullWidth
                value={props.password}
                onChange={(e) => props.setPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <BiShowAlt /> : <BiHide />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {props.isLoading ? (
                <PrimaryButton type="submit" disabled>
                    Loading...
                </PrimaryButton>
            ) : (
                <PrimaryButton type="submit">Log In</PrimaryButton>
            )}
        </RegisterFormContainer>
    );
}

export default LoginForm;
