import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import profilePic from '../../assets/illustrations/profile-pic.jpg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Config from '../../config.json';

function CandidateProfile({ userData, handleProfileOpen }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayPageNav, setDisplayPageNav] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const showPageNav = (e) => {
        setDisplayPageNav(true);
    };

    const hidePageNav = (e) => {
        setDisplayPageNav(false);
    };
    console.log(userData);
    return (
        <section className="CandidateProfile">
            <button className="close-profile" onClick={handleProfileOpen}>
                <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="profile-overview">
                <img
                    src={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${userData.avatarS3FileKey}`}
                    alt="No Profile Photo"
                />
                <span>
                    <h1>{userData.fullName}</h1>
                    <p>{userData.email}</p>
                    <p>
                        {userData.mobileNo}, {userData.city}
                    </p>
                </span>
            </div>
            <div className="candidate-details">
                <div className="details-section-1">
                    <span className="edu-details">
                        <h2>EDUCATION</h2>
                        <p>{userData.college.collegeName}</p>
                        <p>
                            {userData.college.degree},{' '}
                            {userData.college.graduationYear}
                        </p>
                    </span>
                    <span className="preference-details">
                        <h2>PREFERENCES</h2>
                        <p>
                            <span>Looking For : </span>{' '}
                            {userData.preferences &&
                            userData.preferences.lookingFor &&
                            userData.preferences.lookingFor.length > 0
                                ? userData.preferences.lookingFor.map(
                                      (type, idx) =>
                                          idx ===
                                          userData.preferences.lookingFor
                                              .length -
                                              1
                                              ? `${type}`
                                              : `${type}, `
                                  )
                                : 'N/A'}
                        </p>
                        <p>
                            <span>Location : </span>{' '}
                            {userData.preferences &&
                            userData.preferences.jobType &&
                            userData.preferences.jobType.length > 0
                                ? userData.preferences.jobType.map(
                                      (type, idx) =>
                                          idx ===
                                          userData.preferences.jobType.length -
                                              1
                                              ? `${type}`
                                              : `${type}, `
                                  )
                                : 'N/A'}
                        </p>
                        {userData.preferences && (
                            <p>
                                <span>Minimum Expected CTC : </span>
                                <i
                                    className="fa-solid fa-indian-rupee-sign"
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.9)',
                                        paddingLeft: '5px',
                                        paddingRight: '2px',
                                    }}
                                ></i>
                                {userData.preferences.expectedCtc}
                            </p>
                        )}
                        <p>
                            <span>Work Preferences : </span>{' '}
                            {userData.preferences &&
                            userData.preferences.fields &&
                            userData.preferences.fields.length > 0
                                ? userData.preferences.fields.map(
                                      (field, idx) =>
                                          idx ===
                                          userData.preferences.fields.length - 1
                                              ? `${field}`
                                              : `${field}, `
                                  )
                                : 'N/A'}
                        </p>
                    </span>
                    {userData.experience && (
                        <span className="experience-details">
                            <h2>EXPERIENCE</h2>
                            <p>
                                {userData.experience.workExperience} of
                                experience
                            </p>
                            {/* <p>{userData.experience.experienceDesc}</p> */}
                        </span>
                    )}
                </div>
                <div className="details-section-2">
                    <span className="skill-details">
                        <h2>SKILLS</h2>
                        <ul>
                            {userData.experience &&
                            userData.experience.skills &&
                            userData.experience.skills.length > 0 ? (
                                userData.experience.skills.map((s, idx) => (
                                    <li key={idx}>
                                        <span>{s.skill}</span> : {s.level}
                                    </li>
                                ))
                            ) : (
                                <li>N/A</li>
                            )}
                        </ul>
                    </span>
                    <span className="project-details">
                        <h2>WORK</h2>
                        <Grid container spacing={2} sx={{ width: '100%' }}>
                            {userData.projects && userData.projects.length > 0
                                ? userData.projects.map((project, idx) => (
                                      <Grid item md={4} sm={6} xs={12}>
                                          <div className="project-card">
                                              <span>
                                                  <h3>{project.title}</h3>
                                                  <a
                                                      href={project.link}
                                                      target="_blank"
                                                  >
                                                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                                  </a>
                                              </span>
                                              <p>{project.description}</p>
                                          </div>
                                      </Grid>
                                  ))
                                : 'N/A'}
                        </Grid>
                    </span>
                    <span className="resume">
                        <br /> <br />
                        <h2>
                            {' '}
                            <a
                                href={`${Config.S3_URL}/${userData.resume.resumeS3FileKey}`}
                                target="_blank"
                                download
                            >
                                {' '}
                                Download Resume 
                            </a>
                        </h2>
                        <h2>RESUME</h2>
                        {userData &&
                        userData.resume &&
                        userData.resume.resumeS3FileKey ? (
                            <div
                                className="resume-pdf-container"
                                onMouseEnter={showPageNav}
                                onMouseLeave={hidePageNav}
                                style={{ marginBottom: '20px' }}
                            >
                                <Document
                                    file={`${Config.S3_URL}/${userData.resume.resumeS3FileKey}`}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="resume-pdf"
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                <div
                                    className="page-nav"
                                    style={{
                                        display: !displayPageNav ? 'none' : '',
                                    }}
                                >
                                    <button
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                    >
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <p>
                                        Page{' '}
                                        {pageNumber || (numPages ? 1 : '--')} of{' '}
                                        {numPages || '--'}
                                    </p>
                                    <button
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <h1 className="not-found">Resume not uploaded!</h1>
                        )}
                    </span>
                </div>
            </div>
        </section>
    );
}

export default CandidateProfile;
