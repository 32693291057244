import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReduxStore from './store/ReduxStore';
import { Provider } from 'react-redux';
import 'swiper/swiper-bundle.css';
import { Helmet } from 'react-helmet';

ReactDOM.render(
    <Provider store={ReduxStore}>
        <Helmet>
            <title>
                SuperGrad | Enabling Freshers to Land Their First Job/Internship
            </title>
            <meta
                name="description"
                content="Supergrad is India`s only affordable upskilling platform. Become a part of our exclusive community and build a career of your choice."
            />
        </Helmet>
        <App />
    </Provider>,
    document.getElementById('root')
);
