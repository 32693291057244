import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import moment from 'moment';
import useInputState from '../../hooks/useInputState';
import { Desc1, Foot1, Set1, Set2, Title1 } from './Forum';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import './Forum.css';
import Python from '../../assets/illustrations/Py.png';
import WPython from '../../assets/illustrations/python-illus-2.jpg';

const FLeft = ({ postComment, postData, likePost, userAvatar }) => {
    const [comment, setComment, resetComment] = useInputState('');

    let dispatch = useDispatch();
    const handlePostComment = (e) => {
        if (comment !== '') {
            postComment(comment);
            resetComment();
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Comment field is empty!',
                    variant: 'warning',
                })
            );
        }
    };

    return (
        <>
            <Set1>
                <Title1>
                    <h1>Today's post</h1>
                    <p>
                        {' '}
                        {postData && postData.title
                            ? postData.title
                            : 'N/A'} -{' '}
                        {postData && postData.createdAt
                            ? moment(postData.createdAt).fromNow()
                            : ''}
                    </p>
                </Title1>
                <Desc1>
                    {postData && postData.description
                        ? postData.description
                        : 'No description available'}
                </Desc1>
                <img
                    src={
                        postData && postData.posterS3FileKey
                            ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${postData.posterS3FileKey}`
                            : WPython
                    }
                />
                <br />
                <Foot1>
                    <button
                        onClick={likePost}
                        disabled={!postData}
                        style={{
                            cursor: !postData ? 'not-allowed' : 'pointer',
                        }}
                    >
                        <i className="fa-solid fa-heart"></i>
                    </button>
                    <span>
                        {postData && postData.likes ? postData.likes.length : 0}
                    </span>
                </Foot1>
            </Set1>
            <Set2>
                <div>
                    <img
                        src={
                            userAvatar
                                ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${userAvatar}`
                                : WPython
                        }
                    />
                    <input
                        type="text"
                        placeholder="Comment"
                        value={comment}
                        onChange={setComment}
                        disabled={!postData}
                    />
                    <i>
                        <SendOutlinedIcon />
                    </i>
                </div>
                <div style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
                    <button
                        onClick={handlePostComment}
                        disabled={!postData}
                        style={{
                            cursor: !postData ? 'not-allowed' : 'pointer',
                        }}
                    >
                        Post
                    </button>
                </div>
            </Set2>
        </>
    );
};

export default FLeft;
