import { Modal, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ModalStyle,
    TabPanelStyle,
    TabsStyle,
    TabStyle,
} from '../components/auth/AuthComponents';
import {
    CandidateLogin,
    RecruiterLogin,
    TabPanel,
} from '../components/auth/LoginTabs';
import {
    closeLoginModal,
    openCandidateTab,
    openRecruiterTab,
} from '../store/auth/Login';

export default function Login() {
    const { modalOpen, isCandidateTab } = useSelector((state) => state.login);
    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        newValue === 0
            ? dispatch(openCandidateTab())
            : dispatch(openRecruiterTab());
    };

    return (
        <Modal
            open={modalOpen}
            onClose={() => dispatch(closeLoginModal())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={ModalStyle}>
                <Tabs
                    value={isCandidateTab ? 0 : 1}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={TabsStyle}
                >
                    <Tab
                        label="Candidate"
                        id="login-tab-0"
                        aria-controls="login-tab-0"
                        sx={TabStyle}
                    />
                    <Tab
                        label="Recruiter"
                        id="login-tab-1"
                        aria-controls="login-tab-1"
                        sx={TabStyle}
                    />
                </Tabs>
                <TabPanel
                    sx={TabPanelStyle}
                    value={isCandidateTab ? 0 : 1}
                    index={0}
                >
                    <CandidateLogin />
                </TabPanel>
                <TabPanel
                    sx={TabPanelStyle}
                    value={isCandidateTab ? 0 : 1}
                    index={1}
                >
                    <RecruiterLogin />
                </TabPanel>
            </Box>
        </Modal>
    );
}
