import React from 'react';
import BlogsCards from '../BlogCard/BlogsCards';
import { AiOutlineArrowRight } from 'react-icons/ai';
import './BlogItem.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
const BlogItem = ({
    title,
    datetime,
    id,
    introduction,
    number,
    blogBannerS3Path,
}) => {
    // console.log("ref",id)

    const intro = introduction.substring(0, 80);

    return (
        <div className={number % 2 == 0 ? 'blogs-out-even' : 'blog-out-odd'}>
            <BlogsCards className={number % 2 == 0 ? 'blog-item' : 'blog-item'}>
                <div
                    className={
                        number % 2 == 0
                            ? 'blog_container'
                            : 'blog_container-odd'
                    }
                >
                    <div
                        className={
                            number % 2 == 0
                                ? 'blog-item__img-container'
                                : 'blog-item__img-container-odd'
                        }
                    >
                        <img
                            src={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${blogBannerS3Path}`}
                            alt="blog"
                        />
                    </div>
                    <div
                        className={
                            number % 2 == 0
                                ? 'blog-item__blog-container'
                                : 'blog-item__blog-container-odd'
                        }
                    >
                        <div className="blog-item__title">
                            <h1>{title}</h1>
                            <p>{moment(Date(datetime)).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="blog-item__blog">
                        {
                            window.innerWidth > 500 ? (
                                <p>{introduction}</p>
                            ):( <p>{intro}</p>)
                        }
                           
                        </div>
                        <div className="blog-item__actions">
                            <Link to={`/blogs/${id}`}>
                                <button>
                                    View <AiOutlineArrowRight />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </BlogsCards>

            <div className={number % 2 == 0 ? 'image' : 'image-odd'}>
                <img src="" alt="" />
            </div>
        </div>
    );
};

export default BlogItem;
