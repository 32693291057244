import { Button } from '@mui/material';
import React from 'react';

function SecondaryButton({ children, ...props }) {
    return (
        <Button
            variant="outlined"
            color="primary"
            sx={{
                borderRadius: 2.5,
                textTransform: 'none',
                border: '2px solid #150F37 !important',
                transition: 'all 0.25s ease-in-out',
                '&:hover': {
                    backgroundColor: '#150F37',
                    color: 'white',
                    borderColor: '#150F37 !important',
                },
            }}
            {...props}
        >
            {children}
        </Button>
    );
}

export default SecondaryButton;
