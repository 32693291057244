import React from 'react';
import {
    AccordionDetailsStyle,
    AccordionStyle,
    AccordionSummaryStyle,
    FAQsContainer,
    ACprop,
    ACDprop,
} from './HomeComponents';
import FAQsImage from '../../assets/illustrations/FAQ_home.svg';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { AiOutlinePlus } from 'react-icons/ai';

 const FAQs = ( {
     allFAQs
 } ) => {
    return (
        <FAQsContainer id="faqs">
            <img src={FAQsImage} alt="faq" />
            <h1>Frequently Asked Questions</h1>
            <br />
            <br />
            {allFAQs.map((faq, index) => (
                <Accordion sx={AccordionStyle} square>
                    <AccordionSummary
                        expandIcon={<AiOutlinePlus />}
                        sx={AccordionSummaryStyle}
                    >
                        {' '}
                        <ACprop>{faq.question}</ACprop>
                    </AccordionSummary>
                    <AccordionDetails sx={AccordionDetailsStyle}>
                        <ACDprop>{faq.answer}</ACDprop>
                    </AccordionDetails>
                </Accordion>
            ))}
        </FAQsContainer>
    );
}


export default FAQs;