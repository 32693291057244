import {useState} from 'react';

const useInputState = initialVal => {
    const [state, setState] = useState(initialVal);
    const handleChange = e => {
        setState(e.target.value);
    };
    const setInitialVal = val => {
        setState(val);
    }
    const reset = () => {
        setState("");
    };
    return [state, handleChange, reset, setInitialVal];
};

export default useInputState;