import React from 'react';
import { useEffect } from 'react';
import Footer from '../components/Footer/Footer';
import { Cards } from '../components/Home/Cards';
import CommunityBanner from '../components/Home/CommunityBanner';
import FAQs from '../components/Home/FAQs';
import HeroSection from '../components/Home/HeroSection';
import SimpleProcess from '../components/Home/SimpleProcess';
import Stats from '../components/Home/Stats';
import WhatTheySay from '../components/Home/WhatTheySay';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const navigate = useNavigate();
    const allFAQs = [
        {
            question: 'Why I should join SuperGrad?',
            answer: 'As the business landscape becomes more innovative and technologically driven,theoretical knowledge is not enough. We help you in building a strong network and learning all required practical skills. Not only this you can also get several Jobs and internships through our platform.',
        },
        {
            question: 'How to join SuperGrad?',
            answer: 'You can register through our website and give one skill test. After that, we will be giving access to our community on Discord, which will open doors to weekly activities, group discussions, masterclasses, and opportunities.',
        },
        {
            question: 'How to get a job through SuperGrad?',
            answer: 'Join our community and give one skill test, Yes, it is that simple.',
        },
        {
            question:
                'Will I be charged for getting jobs through the platform?',
            answer: 'NO.',
        },
    ];

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const userType = localStorage.getItem('usertype');
            if (userType === 'candidate' || userType === 'user')
                navigate('/dashboard');
            else if (userType === 'recruiter') {
                navigate('/rdashboard');
            }
        }
    }, []);

    return (
        <>
            <main style={{ paddingTop: 'var(--nav-height)' }}>
                
                <HeroSection />
                <SimpleProcess />
                <Cards />
                <CommunityBanner />
                <WhatTheySay />
                <Stats />
                <FAQs allFAQs={allFAQs} />
            </main>
            <Footer />
        </>
    );
}
