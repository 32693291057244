import React from 'react';
import { Stcomp, Stdesc, Sthead, Sts, Stst } from './HomeComponents';
import Icon1 from '../../assets/illustrations/recruitment_icon.png';
import Icon2 from '../../assets/illustrations/ctc_icon.png';
import Icon3 from '../../assets/illustrations/fact_icon03.png';
import Icon4 from '../../assets/illustrations/fact_icon04.png';
import bgBanner from '../../assets/illustrations/stats-banner.jpg';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';

const Counter = ({ number, prefix, suffix }) => (
    <CountUp
        end={number}
        redraw={true}
        prefix={prefix ? prefix : ''}
        suffix={suffix}
        separator=","
        formattingFn={(num) =>
            `${prefix ?? ''} ${num.toLocaleString('en-IN')}${suffix}`
        }
    >
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
    </CountUp>
);

const Stats = () => {
    var onChange = () => {};
    return (
        <>
            <Stcomp>
            <Sthead>Our Pride</Sthead>

                <Sts>

                    <div>
                        <img src={Icon1} alt="Icon" />
                        <Stst>
                            <Counter number={100} suffix="+" />
                        </Stst>
                        <Stdesc>On-boarded recruiters</Stdesc>
                    </div>
                    <div>
                        <img src={Icon2} alt="Icon" />
                        <Stst>
                            <Counter number={50000000} prefix="₹" suffix="+" />
                        </Stst>
                        <Stdesc>Worth of CTC's already served</Stdesc>
                    </div>
                    <div>
                        <img src={Icon3} alt="Icon" />
                        <Stst>
                            <Counter number={150} suffix="+" />
                        </Stst>
                        <Stdesc>Exciting events & competitions hosted</Stdesc>
                    </div>
                    <div>
                        <img src={Icon4} alt="Icon" />
                        <Stst>100%</Stst>
                        <Stdesc>
                            Of our pre evaluated candidates get placed
                        </Stdesc>
                    </div>
                </Sts>
            </Stcomp>
        </>
    );
};

export default Stats;
