import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    RegistrationContainer,
    RegistrationLeft,
    RegistrationRight,
} from '../components/auth/AuthComponents';
import RegisterForm from '../components/auth/RegisterForm';
import {
    candidateRegistrationFail,
    candidateRegistrationPending,
    candidateRegistrationSuccess,
    updateEmail,
    updatePassword,
} from '../store/auth/CandidateRegister';
import CRegisterImage from '../assets/illustrations/candidate_register.svg';
import { openLoginModal } from '../store/auth/Login';
import registerFormSchema from '../lib/validation/registerationSchema';
import { handleSnackOpen } from '../store/Toast/Toast';
import { Register } from '../api/requests';
import { setToken, setUser, setUserType } from '../store/auth/authStatus';
import { useNavigate } from 'react-router-dom';
import FacebookButton from '../components/Common/FacebookButton';
import GoogleButton from '../components/Common/GoogleButton';
import Rdashboard from '../components/CustomerDashboard/DashboardFooter';
import Footer from '../components/Footer/Footer';

function CandidateRegistration() {
    const navigate = useNavigate();
    const { email, password, isLoading } = useSelector(
        (state) => state.candidateRegister
    );
    const dispatch = useDispatch();

    const onSubmit = async (e) => {
        const data = {
            email: email,
            password: password,
        };

        const { error } = registerFormSchema.validate(data);

        if (error) {
            dispatch(candidateRegistrationFail(error.message));
            dispatch(
                handleSnackOpen({
                    message: error.message,
                    variant: 'error',
                })
            );
            return;
        }

        dispatch(candidateRegistrationPending());

        const response = await Register({
            email: email,
            password: password,
            userType: 'candidate',
        });

        if (response.success) {
            dispatch(setToken(response.data.token));
            dispatch(setUser(response.data.user));
            dispatch(setUserType('candidate'));
            dispatch(candidateRegistrationSuccess());
            dispatch(
                handleSnackOpen({
                    message: 'You have been successfully registered!',
                    variant: 'success',
                    title: 'Candidate registration successful',
                })
            );
            navigate('/candidate/profile/edit');
        } else {
            dispatch(candidateRegistrationFail(response.data.msg));
            dispatch(
                handleSnackOpen({
                    message: response.data.msg,
                    variant: 'error',
                    title: 'Candidate registration failed',
                })
            );
        }
    };

    return (
        <>
            <RegistrationContainer>
                <RegistrationLeft image={CRegisterImage} />
                <RegistrationRight>
                    <h1 className="r-heading">Candidate Registration</h1>
                    <div className="fg-registration">
                        <GoogleButton userType="user">
                            Sign up with Google
                        </GoogleButton>
                        {/* <FacebookButton userType="user">Sign up with Facebook</FacebookButton> */}
                    </div>
                    <div className="or-symbol">- OR -</div>
                    <RegisterForm
                        email={email}
                        password={password}
                        setEmail={(email) => dispatch(updateEmail(email))}
                        setPassword={(password) =>
                            dispatch(updatePassword(password))
                        }
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                    />
                    {/* <p>
                    <span className="inline-button">Sign up</span> with Phone
                    Number
                </p> */}
                    <hr />
                    <p>
                        Already have an account?{' '}
                        <span
                            onClick={() => dispatch(openLoginModal())}
                            className="inline-button"
                        >
                            Login
                        </span>
                    </p>
                </RegistrationRight>
            </RegistrationContainer>
            <Footer />
        </>
    );
}

export default CandidateRegistration;
