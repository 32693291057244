import React from 'react';
import Masonry from '@mui/lab/Masonry';
import { Avatar, Rating } from '@mui/material';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { ReviewCardContainer, WTSContainer } from './HomeComponents';
import namanPic from '../../assets/profile-pics/naman.jpeg';
import nehaPic from '../../assets/profile-pics/neha.jpeg';
// import nehaPic2 from '../../assets/profile-pics/neha-2.jpeg';
import sakshamPic from '../../assets/profile-pics/saksham.jpeg';
import sanaPic from '../../assets/profile-pics/sana.jpeg';
import manaswitaPic from '../../assets/profile-pics/manaswita.jpeg';
import deepanshuPic from '../../assets/profile-pics/deepanshu.jpeg';

const ReviewCard = ({ review, key }) => {
    return (
        <ReviewCardContainer key={key} style={{backgroundColor: '#dcdcff'}}>
            <div className="top">
                <div className="left">
                    <Avatar alt={review.name} src={review.profilePic} />
                </div>
                <div className="right">
                    <h5>{review.name}</h5>
                    <p>{review.designation}</p>
                    <Rating
                        name="half-rating"
                        defaultValue={review.rating}
                        readOnly
                        icon={<AiFillStar />}
                        emptyIcon={<AiOutlineStar />}
                        sx={{
                            color: '#FFDF00',
                        }}
                        size="small"
                    />
                </div>
            </div>
            <p className="review-text">{review.text}</p>
        </ReviewCardContainer>
    );
};

export default function WhatTheySay() {
    return (
        <WTSContainer>
            <h1 className="heading">What they say</h1>
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 3 }} spacing={3}>
                {reviews.map((review, index) => (
                    <ReviewCard review={review} key={index} />
                ))}
            </Masonry>
        </WTSContainer>
    );
}

const reviews = [
    {
        profilePic: namanPic,
        name: 'Naman',
        designation: '',
        rating: 5,
        text: 'SuperGrad helped me out as an elder brother , The community groomed me up and helped me land a job in a flash . I hope this community expands its wings to each and every Deserving student out in India',
        width: '400px',
        background: 'linear-gradient(to right, #37234F, #6A4198)'
    },
    {
        profilePic: manaswitaPic,
        name: 'Manaswita',
        designation: '',
        rating: 5,
        text: 'The first and foremost thing that SuperGrad has taught me is the value of working in a start-up. The kind of work,  opportunities, and most importantly the supportive and learning environment that I have gotten here, is something that I had never imagined to get in a formal working area. I have truly learnt first hand how start-ups provide a higher range of experiences and a better level of growth.',
        width: '700px',
        background: 'linear-gradient(to right, #3e747b, #508fa7)'
    },
    {
        profilePic: nehaPic,
        name: 'Neha',
        designation: '',
        rating: 5,
        text: 'I graduated from IITM noida and i was all set for higher studies because very few companies came for placements. One friend told me about “Supergrad” and in next 30 days i was working for a fintech firm. Mentors are really helpful and and it was fun engaging on the platform.Highly recommend it to all undergrads.',
        width: '900px',
        background: 'linear-gradient(to right, #c84d6a, #e76683)'
    },
    {
        profilePic: deepanshuPic,
        name: 'Deepanshu',
        designation: '',
        rating: 5,
        text: 'I met several like minded people in “SuperGrad’s” community. Now i am doing several projects with them.After graduation i was planning to go abroad for masters, my mentors helped in that as well.Highly recommended for folks who wants to do something big in their life.',
        width: '300px',
        background: 'linear-gradient(to left, #76b852, #8dc26f)'
    },
    {
        profilePic: sakshamPic,
        name: 'Saksham',
        designation: '',
        rating: 5,
        text: 'SuperGrad is boon for the fresh graduates.My experience with them was really great along the way i learnt various skills which is required in real corporate world and SuperGrad helped me  to secure a internship with a really great  organization where i am being valued and contributing in its growth . I request all the fresher who really wants to give direction to their career there is no platform better than SuperGrad to give it a try.',
        width: '500px',
        background: 'linear-gradient(to left, #f85032, #e73827)'
    },
    {
        profilePic: sanaPic,
        name: 'Sana',
        designation: '',
        rating: 5,
        text: "If you're looking for opportunities and you want a very friendly helping hand throughout your journey of finding jobs/Internships,  SuperGrad is THE BEST platform to associate with. As far as my personal experience, I've been in touch with them from past few months and I'm more than happy to share that these people actually show what they stand for. They are super supportive and can really help you in connecting with a myriad of opportunities and guide you through out. Personally, I feel lucky to have their back in all aspects and I would love to be in touch with them in the future as well.",
        width: '400px',
        background: 'linear-gradient(to right, #da22ff, #9733ee)'
    },
];
