import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminPanelSidebar from './AdminPanelSidebar';
import AdminDashboard from './AdminDashboard';
import AdminSkillTests from './AdminSkillTests';
import AdminManageJobs from './AdminManageJobs';
import AdminMentorRequests from './AdminMentorRequests';
import AdminResumeReview from './AdminResumeReview';
import AdminInterviewRequests from './AdminInterviewRequests';
import AdminProjects from './AdminProjects';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircleIcon from '@mui/icons-material/Circle';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { ReactComponent as SidebarIcon } from '../../assets/icons/sidebar_logo.svg';
import '../../styles/Admin/AdminPanel.css';
import logo from '../../assets/supergrad_logo-2.svg';
// import logo from '../../assets/logo_light.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../../store/auth/authStatus';
import { handleSnackOpen } from '../../store/Toast/Toast';
import AdminCandidateList from './AdminCandidateList';
import AdminEventManager from './AdminEventManager';
import AdminCandidateProfiles from './AdminCandidateProfiles';
import AdminBlogManager from './AdminBlogManager';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
};

function AdminPanel(props) {
    const [currTab, setCurrTab] = useState('Dashboard');
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const adminName = useSelector((state) => state.authStatus.admin.name);
    const tabs = [
        { text: 'Dashboard', icon: 'fas fa-square-poll-horizontal' },
        { text: 'Skill Tests', icon: 'fa-solid fa-book' },
        { text: 'Manage Jobs', icon: 'fas fa-sitemap' },
        { text: 'Events', icon: 'fa-solid fa-calendar' },
        { text: 'Mentor Requests', icon: 'fas fa-list-ul' },
        { text: 'Resume Review', icon: 'fa-solid fa-book-open' },
        { text: 'Interview Requests', icon: 'fas fa-user-group' },
        { text: 'Project Requests', icon: 'fa-solid fa-bars-progress' },
        { text: 'Candidates', icon: 'fas fa-user-group' },
        { text: 'Profile Editor', icon: 'fas fa-user-group' },
        { text: 'Blogs', icon: 'fa fa-rss-square' },
    ];

    // Notification Menu
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [currNotifData, setCurrNotifData] = useState({});
    const handleModalOpen = (notifId, notifData) => {
        setCurrNotifData(notifData);
        setModalOpen(true);

        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            action: 'read',
            notifId,
        };
        axios
            .put('/api/admin/notifications', payload, config)
            .then((res) => {
                if (res.data.notifications)
                    setNotifications(res.data.notifications);
            })
            .catch((err) => console.log(err));
    };
    const handleModalClose = () => {
        setCurrNotifData({});
        setModalOpen(false);
    };

    const handleTabClick = (e) => {
        setCurrTab(
            e.currentTarget.name || e.currentTarget.getAttribute('name')
        );
    };

    const handleSidebarOpen = (e) => {
        setSidebarIsOpen(!sidebarIsOpen);
    };

    const handleLogout = (e) => {
        dispatch(logout());
        localStorage.clear();
        navigate('/');
    };

    const loadNotifications = () => {
        // Load notifications
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/admin/notifications', config)
            .then((res) => {
                if (res.data.notifications) {
                    setNotifications(res.data.notifications);
                }
            })
            .catch((err) => console.log(err));
    };

    const clearNotifications = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            action: 'clear',
        };
        axios
            .put('/api/admin/notifications', payload, config)
            .then((res) => {
                if (res.data.notifications) {
                    setNotifications(res.data.notifications);
                    dispatch(
                        handleSnackOpen({
                            message: res.data.msg,
                            variant: 'success',
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        // Load notifications
        loadNotifications();

        return () => (document.body.style.overflowY = 'scroll');
    }, []);

    // Manage Jobs State
    const [jobIsOpen, setJobIsOpen] = useState(false);
    const [currJob, setCurrJob] = useState('');

    const handleJobOpen = (e) => {
        setJobIsOpen(!jobIsOpen);
        if (e && e.currentTarget.getAttribute('value'))
            setCurrJob(e.currentTarget.getAttribute('value'));
        else setCurrJob('');
    };

    const previewJob = (jobId) => {
        setCurrTab('Manage Jobs');
        setTimeout(() => {
            setJobIsOpen(true);
            setCurrJob(jobId);
        }, 200);
    };

    let tabContent;
    if (currTab === 'Dashboard')
        tabContent = <AdminDashboard handleTabClick={handleTabClick} />;
    else if (currTab === 'Skill Tests') tabContent = <AdminSkillTests />;
    else if (currTab === 'Manage Jobs')
        tabContent = (
            <AdminManageJobs
                jobIsOpen={jobIsOpen}
                setJobIsOpen={setJobIsOpen}
                currJob={currJob}
                setCurrJob={setCurrJob}
                handleJobOpen={handleJobOpen}
            />
        );
    else if (currTab === 'Mentor Requests')
        tabContent = <AdminMentorRequests />;
    else if (currTab === 'Resume Review') tabContent = <AdminResumeReview />;
    else if (currTab === 'Interview Requests')
        tabContent = <AdminInterviewRequests />;
    else if (currTab === 'Project Requests') tabContent = <AdminProjects />;
    else if (currTab === 'Candidates') tabContent = <AdminCandidateList />;
    else if (currTab === 'Events') tabContent = <AdminEventManager />;
    else if (currTab === 'Profile Editor')
        tabContent = <AdminCandidateProfiles />;
    else if (currTab === 'Blogs') tabContent = <AdminBlogManager />;

    return (
        <>
            <main className="AdminPanel">
                <section className="Admin-sidebar">
                    <div className="sidebar-logo">
                        <Link to="/">
                            <img src={logo} alt="SuperGrad logo" />
                        </Link>
                        <SidebarIcon
                            className="sidebar-menu-icon"
                            onClick={handleSidebarOpen}
                        />
                    </div>
                    <div className="admin-sidebar-btns">
                        {tabs.map((tab, idx) => (
                            <button
                                key={idx}
                                className={
                                    currTab === tab.text ? 'btn-selected' : ''
                                }
                                onClick={handleTabClick}
                                name={tab.text}
                            >
                                <i className={tab.icon}></i>
                                {tab.text}
                            </button>
                        ))}
                    </div>
                    <AdminPanelSidebar
                        tabs={tabs}
                        handleTabClick={handleTabClick}
                        currTab={currTab}
                        sidebarIsOpen={sidebarIsOpen}
                        handleSidebarOpen={handleSidebarOpen}
                    />
                </section>
                <section className="Admin-main">
                    <div className="content-nav">
                        <IconButton
                            onClick={handleClick}
                            id="notifications-button"
                            aria-controls={
                                open ? 'notifications-menu' : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <NotificationsIcon />
                        </IconButton>
                        <Menu
                            id="notifications-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'notifications-button',
                            }}
                            // anchorOrigin={{
                            //     horizontal: 'center',
                            // }}
                            transformOrigin={{
                                horizontal: 'center',
                                vertical: 'top',
                            }}
                            sx={{ width: '100%' }}
                        >
                            {notifications && notifications.length > 0 ? (
                                notifications.map((notif) => (
                                    <MenuItem
                                        key={notif._id}
                                        sx={{
                                            backgroundColor:
                                                notif.status === 'unread'
                                                    ? '#F5F5F5'
                                                    : '',
                                            width: 'fit-content',
                                        }}
                                    >
                                        <div className="notification">
                                            {notif.text}
                                            {notif.status === 'unread' ? (
                                                <CircleIcon
                                                    fontSize="small"
                                                    sx={{
                                                        fontSize: '10px',
                                                        marginLeft: '10px',
                                                        color: '#D0342C',
                                                    }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <span
                                            className="open-notif"
                                            onClick={() =>
                                                handleModalOpen(
                                                    notif._id,
                                                    notif.data
                                                )
                                            }
                                        >
                                            Open{' '}
                                            <i class="fa-solid fa-arrow-right"></i>
                                        </span>
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem>No Notifications.</MenuItem>
                            )}
                            {/* <Divider /> */}
                            <Button
                                variant="contained"
                                sx={{
                                    marginLeft: '15px',
                                    fontSize: '11px',
                                    marginTop: '15px',
                                }}
                                onClick={loadNotifications}
                            >
                                Refresh{' '}
                                <RefreshIcon sx={{ fontSize: '20px' }} />
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    marginLeft: '15px',
                                    fontSize: '11px',
                                    marginTop: '15px',
                                    marginRight: '15px',
                                }}
                                color="warning"
                                onClick={clearNotifications}
                            >
                                Clear Notifications
                            </Button>
                        </Menu>
                        <button
                            onClick={handleLogout}
                            className="notifications"
                        >
                            <i className="fa fa-sign-out"></i>
                            <span>
                                <p>Sign Out</p>
                            </span>
                        </button>
                        <div>
                            <i className="fa-solid fa-circle-user"></i>
                            <span>
                                <p>
                                    <strong>{adminName}</strong>
                                </p>
                                <p>Admin Account</p>
                            </span>
                        </div>
                    </div>
                    {tabContent}
                </section>
            </main>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="Notification modal"
                aria-describedby="notification details"
            >
                <Box sx={style}>
                    <div className="notif-modal">
                        <h1>Job Details:</h1>
                        <hr
                            className="styled-hr"
                            style={{ margin: '5px 0', marginBottom: '15px' }}
                        />
                        <p>
                            <span>Job Title: </span>
                            {currNotifData.jobTitle}
                        </p>
                        <p>
                            <span>Job ID: </span>
                            {currNotifData.jobId}
                        </p>
                        <p>
                            <span>Recruiter ID: </span>
                            {currNotifData.recruiterId}
                        </p>
                        <h1>Candidate Details:</h1>
                        <hr
                            className="styled-hr"
                            style={{ margin: '5px 0', marginBottom: '15px' }}
                        />
                        <p>
                            <span>Candidate Name: </span>
                            {currNotifData.user}
                        </p>
                        <p>
                            <span>Candidate ID: </span>
                            {currNotifData.userId}
                        </p>
                        <button onClick={() => previewJob(currNotifData.jobId)}>
                            Open Job
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default AdminPanel;
