import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { handleSnackClose } from '../store/Toast/Toast';

const Toast = () => {
    const { snackOpen, snackData } = useSelector((state) => state.toast);
    const dispatch = useDispatch();

    return (
        <Snackbar
            anchorOrigin={snackData.position}
            open={snackOpen}
            autoHideDuration={snackData.duration}
            onClose={(event, reason) =>
                dispatch(handleSnackClose({ event, reason }))
            }
        >
            <Alert
                onClose={(event, reason) =>
                    dispatch(handleSnackClose({ event, reason }))
                }
                severity={snackData.variant}
            >
                {snackData.title && <AlertTitle>{snackData.title}</AlertTitle>}
                {snackData.message}
            </Alert>
        </Snackbar>
    );
};

export default Toast;
