import styled from 'styled-components';

export const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 50px 20px 50px;
    text-align: center;
    overflow: hidden;
    height: 100%;

    .footer-upskill {
        color: #263238;
        font-size: 1rem;
        font-weight: 600;
        opacity: 0.6;
    }

    h1 {
        font-weight: 800;
        position: relative;
    }

    // h1:before {
    //     content: 'Do You Want To Contact Us';
    //     position: absolute;
    //     bottom: 2rem;
    //     white-space: nowrap;
    //     opacity: 0.13;
    //     font-size: 5rem;
    //     left: -90%;
    // }

    .footer-description {
        color: #263238;
        font-size: 1rem;
        max-width: 350px;
        opacity: 0.8;
        margin-bottom: 30px;
    }

    .footer-copyright {
        color: #263238;:w
        
        font-size: 0.8rem;
        max-width: 350px;
        opacity: 0.8;
        margin-top: 30px;
    }

    hr {
        height: 1px;
        width: 80%;
        background-color: black;
        opacity: 0.06;
    }

    @media screen and (max-width: 700px) {
        padding: 100px 30px 20px 40px;

        h1:before {
            white-space: normal;
            font-size: 4rem;
            left: -25%;
            width: 150%;
        }
    }

    @media screen and (max-width: 576px) {
        padding: 100px 10px 20px 10px;

        h1 {
            font-size: 28px;
            margin-bottom: 5px;
        }

        h1:before {
            font-size: 3rem;
            bottom: 0.9rem;
        }

        .footer-upskill {
            margin-bottom: 10px;
        }
    }
`;

export const LinksWrap = styled.div`
    display: grid;
    grid-template-columns:  1fr 1fr;
    align-items: center;
    justify-items: center;
    /* width: 80%; */

    .NavLinks {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        color: #263238;
    }

    .SocialLinks {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }

    .SocialLinks a {
        padding: 7px;
        border: 3px solid #4544a21a;
        border-radius: 50%;
        line-height: 70%;
        color: var(--primary);
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 576px) {
        margin-top: 10px
    }
    @media screen and (max-width: 1600px) {
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items:center;
    }
    @media screen and (max-width: 576px) {
        .NavLinks {
            width: 100%;
            margin-bottom: 20px;
            // justify-content: space-evenly;
            align-items: center;
            margin-left: 5px;
        }
        .NavLinks a {
            padding: 0 8px;
        }
    }
`;
