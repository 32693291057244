import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import '../../styles/Admin/AdminProjects.css';

function AdminProjects(props) {
    const [allRequests, setAllRequests] = useState([]);

    useEffect(() => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };

        axios.get('/api/admin/project-requests', config)
            .then(res => {
                if(res.data.candidates) setAllRequests(res.data.candidates);
            }).catch(err => console.log(err));
    }, []);

    return (
        <section className='AdminProjects'>
            <h1>Project Requests</h1>
            <Grid container spacing={2} sx={{width: '100%'}}>
                {
                    allRequests && allRequests.length > 0
                    ? (
                        allRequests.map(user => (
                            <Grid item md={4} sm={6} xs={12} key={user._id}>
                                <div className='review-card'>
                                    <span>
                                        <h2>{user.fullName}</h2>
                                        <p>{user.mobileNo}</p>
                                    </span>
                                    <button>
                                        <a href={`mailto:${user.email}`}>
                                            <i className="fa-solid fa-envelope" style={{paddingRight: '5px'}}></i> 
                                            Email
                                        </a>
                                    </button>
                                </div>
                            </Grid>
                        ))
                    )
                    : <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center', marginTop: '10%'}}>
                        <p className='no-data-msg'>No project requests 🔍</p>
                    </Grid>
                }
            </Grid>
        </section>
    );
}

export default AdminProjects;