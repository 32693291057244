import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser, setUserType } from '../store/auth/authStatus';
import {
    CDMainContainer,
    CDContainer,
    CDGreeting,
    CDHeading,
    CDLeft,
    CDRight,
    CDSkillTest,
    CDName,
    CDStatus,
    CDSkillSuggest,
    CDupcoming,
    CDupcomingApply,
    CDRightHelper,
    CDCards,
    CDEvents,
    CDDiscord,
} from '../components/CustomerDashboard/CDComponents';
import CDSkillSuggestBanners from '../components/CustomerDashboard/CDSkillSuggestBanners';
import CDupcomingBanners from '../components/CustomerDashboard/CDupcomingBanners';
import CDCardsVal from '../components/CustomerDashboard/CDCardsVal';
import DashboardFooter from '../components/CustomerDashboard/DashboardFooter';

export default function CandidateDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [candidateData, setCandidateData] = useState({});
    const [suggestedSkillTests, setSuggestedSkillTests] = useState([]);

    useEffect(() => {
        userRefresh();
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get('/api/quiz/user', config)
            .then((res) => {
                if (res.data.quizes) setSuggestedSkillTests(res.data.quizes);
            })
            .catch((err) => console.log(err));
    }, []);

    const userRefresh = () => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get('/api/auth/user', config)
            .then((res) => {
                if (res.data.user) {
                    setCandidateData(res.data.user);
                    dispatch(setUser(res.data.user));
                    dispatch(setUserType('candidate'));
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <CDMainContainer>
            <div className="filler-div"></div>
            <CDContainer>
                <CDLeft>
                    <CDGreeting>
                        <CDName style={{marginBottom: '5px'}}>
                            Welcome{' '}
                            {candidateData && candidateData.fullName
                                ? candidateData.fullName.split(' ')[0]
                                : ''}
                            ,
                        </CDName>
                        <CDName>
                            {candidateData.testsTaken &&
                            candidateData.testsTaken.length > 0
                                ? 'Congrats, your journey to a great career begins.'
                                : 'Complete atleast one skill tests to unlock a great career.'}
                        </CDName>
                    </CDGreeting>
                    <CDSkillTest>
                        <CDSkillSuggest>
                            <CDSkillSuggestBanners
                                isEligible={
                                    candidateData.testsTaken &&
                                    candidateData.testsTaken.length > 0
                                }
                                suggestedSkillTests={suggestedSkillTests}
                                skillTestScore={
                                    candidateData.testsTaken &&
                                    candidateData.testsTaken.length > 0
                                        ? candidateData.testsTaken[0].score
                                        : 'N/A'
                                }
                                testTakenAt={
                                    candidateData.testsTaken &&
                                    candidateData.testsTaken.length > 0
                                        ? candidateData.testsTaken[0]
                                              .testTakenAt
                                        : 'N/A'
                                }
                                totalScore={
                                    candidateData.testsTaken &&
                                    candidateData.testsTaken.length > 0
                                        ? candidateData.testsTaken[0].answers
                                              .length
                                        : 'N/A'
                                }
                                appliedInternship={
                                    candidateData.isInterestedInIntern
                                }
                                appliedJob={candidateData.isInterestedInJob}
                                userRefresh={userRefresh}
                            />
                        </CDSkillSuggest>
                    </CDSkillTest>
                    <CDCards>
                        <CDCardsVal
                            candidateData={candidateData}   
                            setCandidateData={setCandidateData}
                        />
                    </CDCards>
                </CDLeft>
                <CDRight>
                    <CDRightHelper>
                        <CDEvents>
                            <CDHeading>
                                <h3>Upcoming Events</h3>
                                <Link to="/events">View More</Link>
                            </CDHeading>
                            <CDupcoming>
                                <CDupcomingBanners />
                            </CDupcoming>
                        </CDEvents>

                        <CDDiscord>
                            <CDupcomingApply
                                onClick={() => navigate('/discord')}
                            >
                                <div>
                                    Join our exclusive community to learn and
                                    grow with top 1%
                                </div>
                                <br />
                                <button>
                                    <i className="fa-brands fa-discord"></i>{' '}
                                    Join
                                </button>
                            </CDupcomingApply>
                        </CDDiscord>
                    </CDRightHelper>
                </CDRight>
            </CDContainer>
            <DashboardFooter />
        </CDMainContainer>
    );
}
