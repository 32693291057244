import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CreateSkillTest from './CreateSkillTest';
import '../../styles/Admin/AdminSkillTests.css';

function AdminSkillTests(props) {
    const [skillTests, setSkillTests] = useState([]);
    const [practiceTests, setPracticeTests] = useState([]);
    const [skillTestFormOpen, setSkillTestFormOpen] = useState(false);
    const [testType, setTestType] = useState(0);
    const [editSkillTestId, setEditSkillTestId] = useState('');

    const handleTestTypeChange = (event, newValue) => {
        setTestType(newValue);
    };

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post('/api/quiz', {}, config)
            .then((res) => {
                if (res.data.quizzes && res.data.quizzes.length > 0) {
                    setSkillTests(
                        res.data.quizzes.filter(
                            (quiz) => quiz.testType === 'skill test'
                        )
                    );
                    setPracticeTests(
                        res.data.quizzes.filter(
                            (quiz) => quiz.testType === 'practice test'
                        )
                    );
                }
            })
            .catch((err) => console.log(err));
    }, [skillTestFormOpen]);

    const handleSkillTestFormOpen = (testId) => {
        setSkillTestFormOpen(!skillTestFormOpen);
        if (testId && testId !== '') {
            setEditSkillTestId(testId);
        }
    };

    return (
        <section className="AdminSkillTests">
            {!skillTestFormOpen ? (
                <>
                    <div className="admin-tests-header">
                        <h1>Skill Tests</h1>
                        <button onClick={() => handleSkillTestFormOpen()}>
                            Create New Skill Test
                        </button>
                    </div>
                    <Box sx={{ width: '100%', marginBottom: '20px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={testType}
                                onChange={handleTestTypeChange}
                                aria-label="test type tabs"
                            >
                                <Tab label="Skill Test" />
                                <Tab label="Practice Test" />
                            </Tabs>
                        </Box>
                    </Box>
                    <Grid container spacing={2} sx={{ width: '100%' }}>
                        {testType === 0 ? (
                            skillTests && skillTests.length > 0 ? (
                                skillTests.map((test, idx) => (
                                    <Grid item md={6} sm={12} xs={12} key={idx}>
                                        <div className="SkillTest-card">
                                            <div className="card-content">
                                                <p>
                                                    {test.difficulty
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        test.difficulty.substring(
                                                            1
                                                        )}{' '}
                                                    Level
                                                </p>
                                                <h1>{test.title}</h1>
                                                <p>
                                                    {test.description.slice(
                                                        0,
                                                        200
                                                    )}
                                                    ...
                                                </p>
                                                <ul>
                                                    {test.relatedRoles.map(
                                                        (role, idx) => (
                                                            <li key={idx}>
                                                                {role.replace(
                                                                    /(^\w|\s\w)/g,
                                                                    (m) =>
                                                                        m.toUpperCase()
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                                <a
                                                    onClick={() =>
                                                        handleSkillTestFormOpen(
                                                            test._id
                                                        )
                                                    }
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    View{' '}
                                                    <i className="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        textAlign: 'center',
                                        marginTop: '10%',
                                    }}
                                >
                                    <p className="no-data-msg">
                                        No skill tests found 🔍
                                    </p>
                                </Grid>
                            )
                        ) : practiceTests && practiceTests.length > 0 ? (
                            practiceTests.map((test, idx) => (
                                <Grid item md={6} sm={12} xs={12} key={idx}>
                                    <div className="SkillTest-card">
                                        <div className="card-content">
                                            <p>
                                                {test.difficulty
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    test.difficulty.substring(
                                                        1
                                                    )}{' '}
                                                Level
                                            </p>
                                            <h1>{test.title}</h1>
                                            <p>
                                                {test.description.slice(0, 200)}
                                                ...
                                            </p>
                                            <ul>
                                                {test.relatedRoles.map(
                                                    (role, idx) => (
                                                        <li key={idx}>
                                                            {role.replace(
                                                                /(^\w|\s\w)/g,
                                                                (m) =>
                                                                    m.toUpperCase()
                                                            )}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                            <a
                                                onClick={() =>
                                                    handleSkillTestFormOpen(
                                                        test._id
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                View{' '}
                                                <i className="fas fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </Grid>
                            ))
                        ) : (
                            <Grid
                                item
                                md={12}
                                sm={12}
                                xs={12}
                                sx={{ textAlign: 'center', marginTop: '10%' }}
                            >
                                <p className="no-data-msg">
                                    No skill tests found 🔍
                                </p>
                            </Grid>
                        )}
                    </Grid>
                </>
            ) : (
                <CreateSkillTest
                    handleFormOpen={handleSkillTestFormOpen}
                    testId={
                        editSkillTestId && editSkillTestId !== ''
                            ? editSkillTestId
                            : ''
                    }
                    isEditTest={editSkillTestId && editSkillTestId !== ''}
                />
            )}
        </section>
    );
}

export default AdminSkillTests;
