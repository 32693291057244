import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { FaTimes } from 'react-icons/fa';
import {
    NavLink as LinkRouter,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import PrimaryButton from '../Common/PrimaryButton';
import { Box } from '@mui/system';
import { SidebarBoxStyle } from './NavbarComponents';
import { openLoginModal } from '../../store/auth/Login';
import { logout } from '../../store/auth/authStatus';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SecondaryButton from '../Common/SecondaryButton';

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let authToken = useSelector((st) => st.authStatus.token);
    let userType = useSelector((st) => st.authStatus.userType);
    if (!userType) {
        userType = localStorage.getItem('usertype');
    }
    if (!authToken) {
        authToken = localStorage.getItem('token');
    }

    let location = useLocation();
    const isProfileEditPage = location.pathname.indexOf('/profile/edit') !== -1;

    let navigate = useNavigate();

    const handleLogout = (e) => {
        dispatch(logout());
        localStorage.clear();
        navigate('/');
    };

    return (
        <Drawer
            anchor="right"
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
        >
            <Box
                sx={SidebarBoxStyle}
                onClick={(e) => {
                    if (e.target.id === 'register-button') return;
                    setSidebarOpen(false);
                }}
                onKeyDown={() => setSidebarOpen(false)}
            >
                {!authToken || authToken === '' ? (
                    <List>
                        <ListItem button>
                            <ListItemIcon>
                                <FaTimes />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem button>
                            <LinkRouter to="/community">
                                <ListItemText primary="Community" />
                            </LinkRouter>
                        </ListItem>
                        <ListItem button>
                            <LinkRouter to="/events">
                                <ListItemText primary="Events" />
                            </LinkRouter>
                        </ListItem>
                        <ListItem button>
                            <LinkRouter to="/bootcamp">
                                <ListItemText primary="Career Accelerator Program" />
                            </LinkRouter>
                        </ListItem>
                        <ListItem button>
                            <LinkRouter to="/blogs">
                                <ListItemText primary="Blogs" />
                            </LinkRouter>
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => dispatch(openLoginModal())}
                        >
                            <ListItemText primary="Log in" />
                        </ListItem>
                        <ListItem>
                            <Button
                                id="register-button"
                                aria-controls={
                                    open ? 'register-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                sx={{
                                    borderRadius: 2.5,
                                    textTransform: 'none',
                                    border: '2px solid var(--primary)',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: '#263238',
                                        border: '2px solid #263238',
                                    },
                                    height: 'fit-content',
                                }}
                            >
                                Register
                            </Button>
                            <Menu
                                id="register-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'register-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <LinkRouter to="/candidate/register">
                                        As a Student
                                    </LinkRouter>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <LinkRouter to="/recruiter/register">
                                        As a Recruiter
                                    </LinkRouter>
                                </MenuItem>
                            </Menu>
                        </ListItem>
                    </List>
                ) : isProfileEditPage ? (
                    <List>
                        <ListItem onClick={handleLogout}>
                            <SecondaryButton>Logout</SecondaryButton>
                        </ListItem>
                    </List>
                ) : userType === 'user' || userType === 'candidate' ? (
                    <List>
                        <LinkRouter to="/dashboard">
                            <ListItem
                                style={{
                                    color: location.pathname.match('/dashboard')
                                        ? '#150F37'
                                        : 'rgba(21, 15, 55, 0.6)',
                                }}
                            >
                                Dashboard
                            </ListItem>
                        </LinkRouter>
                        <LinkRouter to="/skilltests">
                            <ListItem
                                style={{
                                    color: location.pathname.match(
                                        '/skilltests'
                                    )
                                        ? '#150F37'
                                        : 'rgba(21, 15, 55, 0.6)',
                                }}
                            >
                                Workstation
                            </ListItem>
                        </LinkRouter>
                        <LinkRouter to="/events">
                            <ListItem
                                style={{
                                    color: location.pathname.match('/events')
                                        ? '#150F37'
                                        : 'rgba(21, 15, 55, 0.6)',
                                }}
                            >
                                Events
                            </ListItem>
                        </LinkRouter>
                        <LinkRouter to="/forum/latest-post">
                            <ListItem
                                style={{
                                    color: location.pathname.match('/forum')
                                        ? '#150F37'
                                        : 'rgba(21, 15, 55, 0.6)',
                                }}
                            >
                                Forum
                            </ListItem>
                        </LinkRouter>
                        {/* <LinkRouter to="/companies">
                                            <ListItem
                                                style={{color: location.pathname.match('/companies') ? '#150F37' : 'rgba(21, 15, 55, 0.6)'}}
                                            >
                                                Companies
                                            </ListItem>    
                                        </LinkRouter> */}
                        <ListItem onClick={handleLogout}>
                            <SecondaryButton>Logout</SecondaryButton>
                        </ListItem>
                    </List>
                ) : (
                    <List>
                        <LinkRouter to="/rdashboard">
                            <ListItem
                                style={{
                                    color: location.pathname.match(
                                        '/rdashboard'
                                    )
                                        ? '#150F37'
                                        : 'rgba(21, 15, 55, 0.6)',
                                }}
                            >
                                Dashboard
                            </ListItem>
                        </LinkRouter>
                        <LinkRouter to="/hiringpanel">
                            <ListItem
                                style={{
                                    color: location.pathname.match(
                                        '/hiringpanel'
                                    )
                                        ? '#150F37'
                                        : 'rgba(21, 15, 55, 0.6)',
                                }}
                            >
                                Hiring Panel
                            </ListItem>
                        </LinkRouter>
                        <ListItem onClick={handleLogout}>
                            <SecondaryButton>Logout</SecondaryButton>
                        </ListItem>
                    </List>
                )}
            </Box>
        </Drawer>
    );
}
