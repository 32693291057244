import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Events from './components/Events/Events';
import EventInfo from './components/Events/EventInfo';
import SkillTests from './components/SkillTests/SkillTests';
import SkillTestInfo from './components/SkillTests/SkillTestInfo';
import SkillTest from './components/SkillTests/SkillTest';
import Community from './components/Community/Community';
import PrivateRoute from './util/PrivateRoute';

import CandidateRegistration from './containers/CandidateRegistration';
import Home from './containers/Home';
import Login from './containers/Login';
import ProfileEdit from './containers/ProfileEdit';
import RecruiterRegistration from './containers/RecruiterRegister';
import Redirect from './util/Redirect';
import ScrollToTop from './util/ScrollToTop';
import CandidateDashboard from './containers/CandidateDashboard';
import AuthParamsHandler from './components/auth/AuthParamsHandler';
import CandidateRoute from './util/CandidateRoute';
import CandidateProfile from './components/CustomerDashboard/CandidateProfile';
import RecruiterProfile from './components/RecruiterDashboard/RecruiterProfile';

import RecruiterDashboard from './containers/RecruiterDashboard';
import RecruiterEditProfile from './containers/RecruiterEditProfile';
import HiringPanelList from './components/RecruiterDashboard/HiringPanelList';
import HiringPanel from './containers/HiringPanel';
import Job from './components/Job Description/Job';
import Forum from './containers/Forum';
import RecruiterRoute from './util/RecruiterRoute';

import AdminPanel from './components/Admin/AdminPanel';
import AdminRoute from './util/AdminRoute';
import AdminLogin from './components/Admin/AdminLogin';
import Bootcamps from './components/Bootcamp/Bootcamp';
import BootcampDetails from './components/Bootcamp/BootcampDetails';
import Privacy from './components/PolicyPages/Privacy';
import Refund from './components/PolicyPages/Refund';
import Terms from './components/PolicyPages/Terms';
import Blog from './components/Blog/Blog';
import Blogdetail from './components/Blog/Blogs/Blogdetail';


export default function RoutesProvider() {
    return (
        <Router>
            <ScrollToTop>
                <Login />
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<Home />} />

                    {/* Candidate */}
                    <Route
                        exact
                        path="/candidate/register"
                        element={<CandidateRegistration />}
                    />
                    <Route
                        exact
                        path="/candidate/profile/edit"
                        element={
                            <PrivateRoute>
                                <ProfileEdit />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/candidate/profile"
                        element={
                            <PrivateRoute>
                                <CandidateProfile />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/recruiter/profile"
                        element={
                            <PrivateRoute>
                                <RecruiterProfile />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <CandidateRoute>
                                    <CandidateDashboard />
                                </CandidateRoute>
                            </PrivateRoute>
                        }
                    />

                    <Route exact path="/events" element={<Events />} />
                    <Route
                        exact
                        path="/events/:id"
                        element={
                            <PrivateRoute>
                                <CandidateRoute>
                                    <EventInfo />
                                </CandidateRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/skilltests"
                        element={
                            <PrivateRoute>
                                <CandidateRoute>
                                    <SkillTests />
                                </CandidateRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/skilltests/:id"
                        element={
                            <PrivateRoute>
                                <CandidateRoute>
                                    <SkillTestInfo />
                                </CandidateRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/skilltests/attend/:id"
                        element={
                            <PrivateRoute>
                                <CandidateRoute>
                                    <SkillTest />
                                </CandidateRoute>
                            </PrivateRoute>
                        }
                    />
                    <Route exact path="/blogs" element={<Blog />} />    
                    <Route exact path="/blogs/:id" element={<Blogdetail />} />    
                   

                    <Route exact path="/community" element={<Community />} />
                    <Route exact path="/bootcamp" element={<Bootcamps />} />
                    <Route
                        exact
                        path="/bootcamp/:bootcampId"
                        element={<BootcampDetails />}
                    />
                    {/* Recruiter */}
                    <Route
                        exact
                        path="/recruiter/register"
                        element={<RecruiterRegistration />}
                    />

                    <Route
                        exact
                        path="/recruiter/profile/edit"
                        element={
                            <PrivateRoute>
                                <RecruiterEditProfile />
                            </PrivateRoute>
                        }
                    />

                    <Route exact path="/auth" element={<AuthParamsHandler />} />

                    {/* Admin */}

                    <Route
                        exact
                        path="/admin"
                        element={
                            <AdminRoute>
                                <AdminPanel />
                            </AdminRoute>
                        }
                    />
                    <Route exact path="/admin/login" element={<AdminLogin />} />

                    <Route
                        exact
                        path="/rdashboard"
                        element={
                            <PrivateRoute>
                                <RecruiterRoute>
                                    <RecruiterDashboard />
                                </RecruiterRoute>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        exact
                        path="/hiringpanel"
                        element={
                            <PrivateRoute>
                                <RecruiterRoute>
                                    <HiringPanelList />
                                </RecruiterRoute>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        exact
                        path="/hiringpanel/:id"
                        element={
                            <PrivateRoute>
                                <RecruiterRoute>
                                    <HiringPanel />
                                </RecruiterRoute>
                            </PrivateRoute>
                        }
                    />

                    <Route exact path="/job/:id" element={<Job />} />

                    {/* Forum */}
                    <Route
                        exact
                        path="/forum/:slug"
                        element={
                            <PrivateRoute>
                                <Forum />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        exact
                        path="/forum/latest-post"
                        element={
                            <PrivateRoute>
                                <Forum />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/forum"
                        element={
                            <PrivateRoute>
                                <Forum />
                            </PrivateRoute>
                        }
                    />

                    {/*Policy Pages */}
                    <Route exact path="/policy/privacy" element={<Privacy />} />
                    <Route exact path="/policy/refund" element={<Refund />} />
                    <Route exact path="/policy/terms" element={<Terms />} />

                    {/* Social Redirects */}
                    <Route
                        exact
                        path="/linkedin"
                        element={
                            <Redirect url="https://www.linkedin.com/company/pre-entrepreneur" />
                        }
                    />
                    <Route
                        exact
                        path="/facebook"
                        element={
                            <Redirect url="https://www.facebook.com/Supergrad-107210311972002" />
                        }
                    />
                    <Route
                        exact
                        path="/instagram"
                        element={
                            <Redirect url="https://www.instagram.com/supergrad.co.in/" />
                        }
                    />
                    <Route
                        exact
                        path="/discord"
                        element={
                            <Redirect url="https://discord.gg/bJPXJ95U4A" />
                        }
                    />
                    <Route
                        exact
                        path="/whatsapp"
                        element={<Redirect url="https://wa.me/919810831351" />}
                    />
                </Routes>
            </ScrollToTop>
        </Router>
    );
}
