import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
    phoneNumber: '',
    password: '',
    isLoading: false,
    isAuth: false,
    error: '',
};

export const register = createSlice({
    name: 'register',
    initialState,
    reducers: {
        updateEmail: (state, action) => {
            state.email = action.payload;
        },
        updatePassword: (state, action) => {
            state.password = action.payload;
        },
        updatePhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
        },
        candidateRegistrationPending: (state) => {
            state.isLoading = true;
        },
        candidateRegistrationSuccess: (state) => {
            state.isLoading = false;
            state.isAuth = true;
            state.error = '';
            state.email = '';
            state.password = '';
            state.phoneNumber = '';
        },
        candidateRegistrationFail: (state, { payload }) => {
            state.isLoading = false;
            state.isAuth = false;
            state.error = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateEmail,
    updatePassword,
    updatePhoneNumber,
    candidateRegistrationPending,
    candidateRegistrationSuccess,
    candidateRegistrationFail,
} = register.actions;

export default register.reducer;
