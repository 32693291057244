import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow-y: hidden;
    z-index: 0;
    &:-webkit-scrollbar{
        display:none;
    }

    @media screen and (max-width: 900px){
        overflow-y: scroll;
        justify-content: space-around;
        text-align:center;
        flex-direction: column;
    }
`;

export const OLeft = styled.div`
    display: flex;
    height:88vh;
    flex-direction: column;
    border-right: 1px solid #E9ECF2;
    justify-content: space-around;
    width:90%;
    @media screen and (max-width: 900px){
        display: none;
    }
`;

export const BtnCover= styled.div`
    margin-left: 2rem;
`;

export const Div1 = styled.div`
    padding: 17px 20px;
    width: 75%;
    background-color: #4544A2;
    color: white;
    display:flex;
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    align-items: center;
    border-radius: 15px;
    i{
        margin-top:0.1rem;
    }
`;

export const Div3 = styled.div`
    padding: 17px 20px;
    width: 75%;
    background-color: #D7DEF0; 
    color: rgba(69, 68, 162, 0.5);
    display:flex;
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    align-items: center;
    border-radius: 15px;

`;

export const Div2 = styled.div`
    margin-top: 15px;
    padding: 17px 20px;
    width: 75%;
    background-color: #D7DEF0; 
    color: rgba(69, 68, 162, 0.5);
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    align-items: center;
    border-radius: 15px;
`;

export const Div4 = styled.div`
    margin-top: 15px;
    padding: 17px 20px;
    width: 75%;
    background-color: #4544A2;
    color: white;
    display:flex;
    justify-content: space-between;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    align-items: center;
    border-radius: 15px;
    i{
        margin-top:0.1rem;
    }
`;

export const TLeft = styled.div`
    display: none;
    @media screen and (max-width: 900px){
        display:flex;
        flex-direction:row;
        justify-content: center;
        margin-top:40px;
        margin-bottom: 40px;
    }
`;

export const Wrapdiv=styled.div`
        display:flex;
        flex-direction:row;
        justify-content: center;
`;

export const Onediv = styled.div`
    height:40px;
    width:40px;
    border-radius:20px;
    background-color: #4544A2;
    border: 1px solid #4544A2;
    display:flex;
    justify-content: center;
    align-items:center;
    color: #FFFFFF;
    margin-left: 30px;
`;

export const Twodiv = styled.div`
    height:40px;
    width:40px;
    border-radius:20px;
    border: 1px solid #D7DEF0;
    display:flex;
    justify-content: center;
    align-items:center;
    color: #D7DEF0;
    margin-left: -0px;
`;


export const Threediv = styled.div`
    height:40px;
    width:40px;
    border-radius:20px;
    background-color: #4544A2;
    border: 1px solid #4544A2;
    display:flex;
    justify-content: center;
    align-items:center;
    color: #FFFFFF;

`;

export const Left = styled.div`
    width: 30vw;
    @media screen and (max-width: 900px){
        width: 95vw;
    }
`;

export const Right = styled.div`
    width: 70vw;
    margin-top: 50px;
    @media screen and (max-width: 900px){
        width: 95vw;
    }
`;

export const RHeader=styled.div`
    display: none;
    @media screen and (max-width: 900px){
        display: block;
        width: 100vw;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 100%;
        text-align: center;
        color: #2E353A;
        margin-bottom: 40px;
    } 
`;

export const RProfilepic=styled.div`
    width: fit-content;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    button{
        cursor: pointer;
        padding: 10px 15px;
        background: #4544A2;
        border-radius: 15px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #FFFFFF;
        border: none;
        outline: none;
    }
    @media screen and (max-width: 900px){
        width: 100vw;
        flex-direction: column;

        button{
            margin-top:30px;
            margin-left: 0vw;
        }
    } 
`;

export const RForm=styled.div`
    margin-top:20px;
`;

export const RFRow=styled.div`
    width: 67vw;
    margin-top: 20px;
    @media screen and (max-width: 900px){
        width: 95vw;
        margin-left:2.5vw;
    } 
`;

export const RFRDesc=styled.div`
    display:inline-block;
    width: 33vw;
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        // display: flex;
        // align-items: center;
        color: #000000;
        margin-bottom: 10px;
        margin-left:5px;
    }
    input{
        width: 30vw;
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #E9ECF2;
        box-sizing: border-box;
        border-radius: 12px;
        height: 52px;
        padding-left: 20px;
    }
    input[type="file"]{
        background: none;
        border: none;
        height: fit-content;
        width: fit-content;
        margin-bottom: 15px;
    }

    select{
        width: 30vw;
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #E9ECF2;
        box-sizing: border-box;
        border-radius: 12px;
        height: 52px;
        padding-left: 20px;
    }

    #cps{
        width: 30vw;
        background: rgba(233, 236, 242, 0.4);
        height: 52px;
        padding-left: 20px;
        padding-top: 10px;
        
    }

    textarea{
        width: 28vw;
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #E9ECF2;
        box-sizing: border-box;
        border-radius: 12px;
        height: 52px;
        padding-left: 20px;
        max-width: 30vw;
        min-width: 30vw;
        min-height: 130px;
        max-height: 130px;
        padding: 20px;
    }

    button{
        cursor: pointer;
        margin-top:auto;
        margin-left:auto;
        margin-right:3vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 36px;
        background: #4544A2;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #FFFFFF;
        border:none;
    }

    .resume-upload-btn {
        margin-left: 0;
        padding: 10px 15px;
        font-size: 15px;
        box-sizing: border-box;
        margin-left: 15px;
    }

    // #check{
    //     background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="52" color="rgba(69, 68, 162, 0.3)" ><text x="10" y="30" style="color: rgba(69, 68, 162, 0.3); margin: auto 5px;">Rs.</text></svg>') no-repeat;
    //     padding-left: 40px;
    // }

    @media screen and (max-width: 900px){
        display: flex;
        flex-direction: column;
        width: 95%;
        margin-top:30px;

        h1{
            margin-left: 2.2vw;
        }

        input{
            margin-left:2vw;
            width: 90vw;
        }

        textarea{
            margin-left:2vw;
            width: 90vw;
            background: rgba(233, 236, 242, 0.4);
            border: 1px solid #E9ECF2;
            box-sizing: border-box;
            border-radius: 12px;
            height: 52px;
            padding-left: 20px;
            max-width: 90vw;
            min-width:90vw;
            max-height: 150px;
            min-height: 150px;
        }

        button{
            margin-left:auto;
            margin-right:auto;
            margin-bottom: 30px;
        }

        select{
            margin-left:2vw;
            width: 90vw;
            height: 52px;
            padding-left: 20px;
        }
    } 
`;



























export const RFRDesc1=styled.div`
    display:inline-block;
    width: 60vw;
    margin-top:10px;
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 10px;
        margin-left:5px;
    }
    
    div{
        margin-top: 15px;
        display: flex;
        align-items: center;
        // justify-content: space-between;


        span{
            display: flex;
            justify-content: flex-start;
            margin-left: -4vw;
            margin-right: 5vw;
        }
        input{
            width: 10vw;
            background: rgba(233, 236, 242, 0.4);
            border: 1px solid #E9ECF2;
            box-sizing: border-box;
            border-radius: 12px;
            height: 25px;
            padding-left: 20px;
        }    
    }


    @media screen and (max-width: 900px){
        display: flex;
        flex-direction: column;
        width: 95%;
        margin-top:30px;

        h1{
            margin-left: 2.2vw;
        }

        div{
            flex-direction: column;
            span{
                margin-left: -40vw;
                width: 90vw;
                label{
                    margin-left: -30vw;
                }
            }

            input{
                width: 70vw;
                background: rgba(233, 236, 242, 0.4);
                border: 1px solid #E9ECF2;
                box-sizing: border-box;
                border-radius: 12px;
                height: 25px;
                padding-left: 20px;
            }    

            
        }
    } 
`;






export const RFRDesc11=styled.div`
    display:inline-block;
    width: 60vw;
    margin-top:10px;
    h1{
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 10px;
        margin-left:5px;
    }
    
    div{
        margin-top: 15px;
        display: flex;
        align-items: center;
        // justify-content: space-between;


        span{
            display: flex;
            justify-content: flex-start;
            margin-left: -4vw;
            margin-right: 5vw;

            label{
                margin-left: -3vw;
            }
        }
        input{
            width: 10vw;
            background: rgba(233, 236, 242, 0.4);
            border: 1px solid #E9ECF2;
            box-sizing: border-box;
            border-radius: 12px;
            height: 25px;
            padding-left: 20px;
        }    
    }


    @media screen and (max-width: 900px){
        display: flex;
        flex-direction: column;
        width: 95%;
        margin-top:30px;

        h1{
            margin-left: 2.2vw;
        }

        div{
            flex-direction: column;
            span{
                margin-top: 7px;
                margin-left: -40vw;
                width: 90vw;
                label{
                    margin-left: -30vw;
                }
            }

            input{
                width: 70vw;
                background: rgba(233, 236, 242, 0.4);
                border: 1px solid #E9ECF2;
                box-sizing: border-box;
                border-radius: 12px;
                height: 25px;
                padding-left: 20px;
            }    

            
        }
    } 
`;


























































export const RFRDesc2=styled.div`
    display:inline-block;
    width: 30vw;
    margin-left: 2vw;

        input{
            width: 10vw;
            background: rgba(233, 236, 242, 0.4);
            border: 1px solid #E9ECF2;
            box-sizing: border-box;
            border-radius: 12px;
            height: 25px;
            padding-left: 20px;
        }    


    @media screen and (max-width: 900px){
        display: flex;
        flex-direction: column;
        width: 93%;
        margin-top:30px;
        margin-left: 7%;
    } 
`;





export const HR=styled.div`
    margin-top: 30px;
    width: 68vw;
    border: 1px solid #E9ECF2;
    margin-bottom: 20px;

    @media screen and (max-width: 900px){
        width: 90vw;
        margin-left: 5vw;
    }
`;



