import styled from 'styled-components';

export const RDContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 1201px) {
        padding-top: 1rem;
    }
    @media screen and (max-width: 1200px) {
        margin-top: 2rem;
        flex-direction: column;
    }
`;

export const RDLeft = styled.div`
    // display: flex;
    color: rgba(38, 50, 56, 0.7);
    gap: 1rem;
    @media screen and (min-width: 1201px) {
        padding-top: 1rem;
        width: 65vw;
        padding-right: 0vw;
    }
    @media screen and (max-width: 1200px) {
        padding-top: 2rem;
        width: 96vw;
    }

    @media screen and (max-width: 1000px) {
        padding-top: 2rem;
        width: 98vw;
    }

    @media screen and (max-width: 900px) {
        width: 100vw;
    }
`;

export const RDRight = styled.div`
    color: rgba(38, 50, 56, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 40px;
    @media screen and (min-width: 1201px) {
        padding-top: 2rem;
        width: 37vw;
    }

    @media screen and (max-width: 1200px) {
        padding-top: 2rem;
        padding-right: 1rem;
        width: 90vw;
    }
    @media screen and (max-width: 1000px) {
        width: 100vw;
    }

    @media screen and (max-width: 900px) {
        width: 100vw;
    }

    @media screen and (max-width: 600px) {
        width: 100vw;
    }
`;

export const RDCard1 = styled.div`
    cursor: default;
    display: inline-block;
    color: #ffffff;
    height: 221px;
    width: 23%;
    // background-image: linear-gradient(to bottom right, #ffb199, #ff6188);
    background-color: #2f5596;
    margin-left: 1rem;
    border-radius: 20px;
    margin-top: 1rem;

    h1 {
        padding: 20px 60px 10px 20px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
    }
    div {
        padding: 80px 20px 10px 20px;
        display: flex;
        justify-content: space-between;
    }
    span {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 32px;
        text-align: right;
    }

    @media screen and (max-width: 900px) {
        & {
            width: 44vw;
            display: inline-block;
            margin-left: 0.5rem;
        }
        height: 110px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        h1 {
            padding: 20px 2px 10px 10px;
            font-family: DM Serif Display;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
            display: inline-block;
            width: 50%;
        }
        div {
            padding: 10px 10px 5px 10px;
            display: flex;
            justify-content: space-between;
            display: inline-block;
        }
        i {
            display: none;
        }
        span {
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 25px;
            line-height: 32px;
            text-align: right;
        }
    }
`;

export const RDCard2 = styled.div`
    cursor: default;

    display: inline-block;
    color: #ffffff;
    height: 221px;
    width: 23%;
    // background-image: linear-gradient(to bottom right, #04befe, #4481eb);
    background-color: #90aadb;
    margin-left: 1rem;
    border-radius: 20px;
    margin-top: 1rem;

    h1 {
        padding: 20px 60px 10px 20px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
    }
    div {
        padding: 80px 20px 10px 20px;
        display: flex;
        justify-content: space-between;
    }
    span {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 32px;
        text-align: right;
    }

    @media screen and (max-width: 900px) {
        & {
            width: 44vw;
            display: inline-block;
            margin-left: 0.5rem;
        }
        height: 110px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        h1 {
            padding: 20px 2px 10px 10px;
            font-family: DM Serif Display;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
            display: inline-block;
            width: 50%;
        }
        div {
            padding: 10px 10px 5px 10px;
            display: flex;
            justify-content: space-between;
            display: inline-block;
        }
        i {
            display: none;
        }
        span {
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 25px;
            line-height: 32px;
            text-align: right;
        }
    }
`;

export const RDCard3 = styled.div`
    cursor: default;

    display: inline-block;
    color: #ffffff;
    height: 221px;
    width: 23%;
    // background-image: linear-gradient(to bottom right, #b0e559, #3dc24b);
    background-color: #002060;
    margin-left: 1rem;
    border-radius: 20px;
    margin-top: 1rem;

    h1 {
        padding: 20px 60px 10px 20px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
    }
    div {
        padding: 80px 20px 10px 20px;
        display: flex;
        justify-content: space-between;
    }
    span {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 32px;
        text-align: right;
    }

    @media screen and (max-width: 900px) {
        & {
            width: 44vw;
            display: inline-block;
            margin-left: 0.5rem;
        }
        height: 110px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        h1 {
            padding: 20px 2px 10px 10px;
            font-family: DM Serif Display;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
            display: inline-block;
            width: 50%;
        }
        div {
            padding: 10px 10px 5px 10px;
            display: flex;
            justify-content: space-between;
            display: inline-block;
        }
        i {
            display: none;
        }
        span {
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 25px;
            line-height: 32px;
            text-align: right;
        }
    }
`;

export const RDCard4 = styled.div`
    cursor: default;
    display: inline-block;
    color: #ffffff;
    height: 221px;
    width: 23%;
    // background-image: linear-gradient(to bottom right, #ffc164, #f08302);
    background-color: #a5a5a5;
    margin-left: 1rem;
    border-radius: 20px;
    margin-top: 1rem;

    h1 {
        padding: 20px 60px 10px 20px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
    }
    div {
        padding: 80px 20px 10px 20px;
        display: flex;
        justify-content: space-between;
    }
    span {
        font-family: Lato;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 32px;
        text-align: right;
    }

    @media screen and (max-width: 900px) {
        & {
            width: 44vw;
            display: inline-block;
            margin-left: 0.5rem;
        }
        height: 110px;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        h1 {
            padding: 20px 2px 10px 10px;
            font-family: DM Serif Display;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
            display: inline-block;
            width: 50%;
        }
        div {
            padding: 10px 10px 5px 10px;
            display: flex;
            justify-content: space-between;
            display: inline-block;
        }
        i {
            display: none;
        }
        span {
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 25px;
            line-height: 32px;
            text-align: right;
        }
    }
`;

export const RDupcomingCreate = styled.div`
    border-radius: 10px;
    width: 60%;
    padding: 0px 40px;
    align-items: center;
    justify-content: center;
    height: 221px;
    display: flex;
    flex-direction: column;
    // background-image: linear-gradient(to bottom right, #6871e9, #4544a2);
    background-color: #4544a2;
    div {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 118.5%;
        text-align: center;
        color: #ffffff;
    }
    button {
        width: 177px;
        height: 60px;
        border-radius: 20px;
        color: #4544a2;
        font-size: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #fff;
    }

    @media screen and (max-width: 1200px) {
        // margin-left: -1rem;
        padding-left: 3rem;
        padding-right: 1rem;
        width: 100vw;
        margin: auto;
        border-radius: 0;
    }
    @media screen and (max-width: 1000px) {
        // margin-left: -1rem;
        padding-top: 2rem;
        padding-right: 1rem;
    }

    @media screen and (max-width: 900px) {
        margin-left: -1rem;
    }

    @media screen and (max-width: 600px) {
        padding-left: 0;
        margin-left: 0rem;
        width: 100vw;
        border-radius: 0;
        padding: 0px 0px;
    }
`;

export const RDContainerjobs = styled.div`
    @media screen and (min-width: 1201px) {
    }
    @media screen and (max-width: 1200px) {
    }
    @media screen and (max-width: 900px) {
    }
`;

export const RDOpenjobs = styled.div`
    display: inline-block;
    border: 1px solid #d7def0;
    border-radius: 10px;
    width: 28vw;
    margin-left: 2vw;
    margin-top: 2rem;
    height: 80px;
    cursor: pointer;

    div {
        height: 10px;
        width: 10px;
        border-radius: 5px;
        background-color: #4544a2;
        float: right;
    }

    h1 {
        font-family: DM Serif Display;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 100%;
        color: #2e353a;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    span {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 100%;
        color: #2e353a;
    }

    p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 100%;
        text-align: right;
        color: rgba(38, 50, 56, 0.5);
    }

    @media screen and (min-width: 1201px) {
        padding-top: 10px;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    @media screen and (max-width: 1200px) {
        // padding-top: 2rem;
        padding-top: 10px;
        padding-left: 3rem;
        padding-right: 2rem;
        margin-right: 0vw;
        width: 37vw;
    }

    @media screen and (max-width: 900px) {
        // margin-left: -1rem;
        margin-top: 2rem;
        width: 80%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 2rem;
        padding-top: 10px;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-right: 0vw;
        width: 87vw;
    }
`;

export const RDPagenumber = styled.div`
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    color: #263238;
    cursor: pointer;

    span {
        cursor: default;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #2e353a;
    }
`;

export const Box = styled.div`
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    height: '85vh',
    bgcolor: 'white',
    border: '2px solid #000',`;

export const MTitle = styled.div`
    text-align: center;
    font-family: 'DM Serif Display', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 100%;
    color: #2e353a;
    padding-top: 20px;
`;
export const Mcontainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    @media screen and (max-width: 900px) {
        display: block;
    }
`;

export const Mleft = styled.div`
    width: 50%;
    padding-left: 10px;
    @media screen and (max-width: 900px) {
        width: 70vw;
    }
`;

export const Mright = styled.div`
    width: 50%;
    padding-left: 10px;
    @media screen and (max-width: 900px) {
        width: 70vw;
    }
`;

export const MSet = styled.div`
    h1 {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 5px;
        margin-left: 10%;
    }
    .location-names {
        margin-left: 15%;
        margin-top: 0.5rem;
    }

    button {
        margin-left: 75%;
        background: #4544a2;
        color: #ffffff;
    }

    button:hover {
        color: #4544a2;
    }

    input {
        margin-top: 10px;
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #e9ecf2;
        box-sizing: border-box;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: rgba(69, 68, 162, 0.3);
        height: 40px;
        width: 80%;
        padding-left: 4%;
        margin-left: 10%;
    }
    .selector {
        margin-top: 10px;
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #e9ecf2;
        box-sizing: border-box;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: rgba(69, 68, 162, 0.3);
        height: 40px;
        width: 80%;
        padding-left: 4%;
        margin-left: 10%;
    }
    margin-bottom: 19px;
`;

export const MSet1 = styled.div`
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 5px;
        margin-left: 10%;
    }
    div {
        display: flex;
        align-items: center;
        color: black;
    }

    input {
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #e9ecf2;
        box-sizing: border-box;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        color: rgba(69, 68, 162, 0.3);
        height: 40px;
        width: 1.5rem;
        margin-left: 14%;
        margin-right: 2%;
        border: 2px solid #e9ecf2;
    }
    margin-bottom: 19px;
    @media screen and (max-width: 900px) {
        input {
            margin: 0;
            padding: 0;
        }
        #input-container {
            justify-content: space-between;
        }
    }
`;

export const MSet2 = styled.div`
    h1 {
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        margin-bottom: 5px;
        margin-left: 10%;
    }

    textarea {
        margin-top: 10px;
        padding: 10px;
        background: rgba(233, 236, 242, 0.4);
        border: 1px solid #e9ecf2;
        box-sizing: border-box;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: rgba(69, 68, 162, 0.3);
        height: 140px;
        min-height: 140px;
        max-height: 140px;
        // height: 90%;
        width: 80%;
        min-width: 80%;
        max-width: 80%;

        padding-left: 4%;
        margin-left: 10%;
    }
    button {
        border: none;
        outline: none;
        background-color: #4544a2;
        color: #fff;
        border-radius: 15px;
        padding: 10px 15px;
        cursor: pointer;
    }
    margin-bottom: 19px;
`;

export const MCreate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 2rem;
    height: 3.5rem;
    button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 19px 36px;
        position: absolute;
        width: 159px;
        height: 56px;
        background: #4544a2;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #ffffff;
    }
`;
