import React from 'react';
import { Link } from 'react-router-dom';
import HeroImage from '../../assets/illustrations/herosection.svg';
// import HeroImage from '../../assets/illustrations/landing-svg.svg';
// import PrimaryButton from '../Common/PrimaryButton';
// import SecondaryButton from '../Common/SecondaryButton';
import {
    ButtonsWrap,
    HeroContainer,
    HeroLeft,
    HeroRight,
    HB1,
    HB2,
} from './HomeComponents';

export default function HeroSection() {
    return (
        <HeroContainer id="home">
            <HeroLeft>
                <h2>
                    Enabling <span className='highlight-1'>freshers</span> <br/> to land their <br /> <span className='highlight-2'>First Job / Internship</span>
                </h2>
                {/* <h3>Enabling freshers to land their</h3>
                <h1>First Job / Internship </h1> */}
                {/* <p>
                    Be a part of India’s Smartest community, where you can find
                    like minded people, career accelerator programs, Industry
                    leaders and several exciting opportunities.
                </p> */}
                <ButtonsWrap>
                    <Link to="/candidate/register">
                        <HB1>
                            <button type="submit">Get Hired</button>
                        </HB1>
                        {/* <PrimaryButton>Get Hired</PrimaryButton> */}
                    </Link>
                    <Link to="/recruiter/register">
                        <HB2>
                            <button type="submit">Hire Candidates</button>
                        </HB2>
                        {/* <SecondaryButton>Hire Candidates</SecondaryButton> */}
                    </Link>
                </ButtonsWrap>
            </HeroLeft>
            <HeroRight>
                <img src={HeroImage} alt="Connecting you to the right place - A fulfilling opportunity | SuperGrad" />
            </HeroRight>
        </HeroContainer>
    );
}
