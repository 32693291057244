import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    snackOpen: false,
    snackData: {
        variant: 'success',
        message: 'Sample',
        title: '',
        position: { vertical: 'top', horizontal: 'center' },
        duration: 2000,
    },
};

export const toast = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        handleSnackClose: (state, action) => {
            if (action.payload.reason === 'clickaway') {
                return;
            }
            state.snackOpen = false;
        },
        handleSnackOpen: (state, action) => {
            // Closes the current open snackbar
            if (state.snackOpen) {
                state.snackOpen = false;
            }

            const {
                message = 'Sample',
                variant = 'success',
                title = '',
                position = { vertical: 'top', horizontal: 'center' },
                duration = 2000,
            } = action.payload;

            state.snackData = { message, variant, title, position, duration };

            state.snackOpen = true;
        },
    },
});

// Action creators are generated for each case reducer function
export const { handleSnackClose, handleSnackOpen } = toast.actions;

export default toast.reducer;
