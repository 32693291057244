import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import '../../styles/Admin/DetailsModal.css';

const style = {
    position: 'absolute',
    // top: '50%',
    left: '50%',
    transform: 'translate(-50%)',
    width: 500,
    background: 'linear-gradient(#6871E9 0%, #4544A2 73.54%), #4544A2',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
    marginTop: '10px',
};

function DetailsModal({ open, handleModalOpen, content, title }) {
    // COMPONENT USAGE
    /* content = [
        {title: "", text: "" (or) list: []}
    ] */

    return (
        <Modal
            open={open}
            onClose={handleModalOpen}
            aria-labelledby={title}
            aria-describedby="Job/Company Details"
            style={{ overflow: 'auto', height: '100%' }}
        >
            <Box sx={style}>
                <h1 className="details-modal-header">{title}</h1>
                <section className="details-modal-contents">
                    {content && content.length > 0
                        ? content.map((el, idx) =>
                              el.text ? (
                                  <div key={idx}>
                                      <h2>{el.title}</h2>
                                      <hr
                                          className="styled-hr"
                                          style={{
                                              marginTop: 0,
                                              marginBottom: '10px',
                                          }}
                                      />
                                      <p>
                                          {el.type && el.type === 'date'
                                              ? moment(el.text).format(
                                                    'MMMM Do, YYYY'
                                                )
                                              : el.text}
                                      </p>
                                  </div>
                              ) : (
                                  <div key={idx}>
                                      <h2>{el.title}</h2>
                                      <hr
                                          className="styled-hr"
                                          style={{
                                              marginTop: 0,
                                              marginBottom: '10px',
                                          }}
                                      />
                                      <ul>
                                          {el?.list?.map((element, index) => (
                                              <li key={index}>{element}</li>
                                          ))}
                                      </ul>
                                  </div>
                              )
                          )
                        : 'Nothing to show!'}
                </section>
            </Box>
        </Modal>
    );
}

export default DetailsModal;
