import { Button } from '@mui/material';
import React from 'react';

function PrimaryButton({ children, ...props }) {
    return (
        <Button
            variant="contained"
            color="primary"
            sx={{
                borderRadius: 2.5,
                textTransform: 'none',
                border: '2px solid var(--primary)',
                '&:hover': {
                    backgroundColor: 'white',
                    color: '#263238',
                    border: '2px solid #263238',
                },
                height: 'fit-content',
            }}
            {...props}
        >
            {children}
        </Button>
    );
}

export default PrimaryButton;
