import React from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import Box from '@mui/system/Box';
import { FaTimes } from 'react-icons/fa';

function AdminPanelSidebar({tabs, currTab, handleTabClick, sidebarIsOpen, handleSidebarOpen}) {
    return (
        <Drawer
            anchor="right" 
            open={sidebarIsOpen}
            onClose={handleSidebarOpen}
        >
            <Box
                onClick={handleSidebarOpen}
                onKeyDown={handleSidebarOpen}
            >
                <List>
                    <ListItem button>
                        <ListItemIcon>
                            <FaTimes />
                        </ListItemIcon>
                    </ListItem>
                    {
                        tabs.map((tab, idx) => (
                            <ListItem button key={idx} onClick={handleTabClick} name={tab.text}>
                                <ListItemIcon><i className={tab.icon}></i></ListItemIcon>
                                <ListItemText primary={tab.text}/>
                            </ListItem>
                        ))
                    }
                </List>
            </Box>
        </Drawer>
    );
}

export default AdminPanelSidebar;