import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { CDSB, Btn1, BannerImage, BtnIntern, BtnJob } from './CDComponents';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import illus1 from '../../assets/illustrations/dashboard-illus-1.svg';
import { padding } from '@mui/system';
import BannerImg from '../../assets/images/banner-bg.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const CDSkillSuggestBanners = ({
    isEligible,
    suggestedSkillTests,
    skillTestScore,
    testTakenAt,
    totalScore,
    appliedInternship,
    appliedJob,
    userRefresh,
}) => {
    const dispatch = useDispatch();
    const [currModalData, setCurrModalData] = useState({
        modalText: '',
        link: '',
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [allSkillTests, setAllSkillTests] = useState([]);

    const handleModalOpen = () => {
        setModalOpen(!modalOpen);
    };

    const handleApply = (e) => {
        if (e.target.name === 'jobs' && appliedJob) {
            dispatch(
                handleSnackOpen({
                    message: `Your job application is already under process`,
                    variant: 'warning',
                })
            );
            return;
        } else if (e.target.name === 'internships' && appliedInternship) {
            dispatch(
                handleSnackOpen({
                    message: `Your internship application is already under process`,
                    variant: 'warning',
                })
            );
            return;
        }
        if (isEligible) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const payload = {
                interestType: e.target.name,
            };

            axios
                .post('/api/user/apply', payload, config)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: `Successfully Applied for ${e.target.name}`,
                                variant: 'success',
                            })
                        );
                        userRefresh();
                    }
                })
                .catch((err) => console.log(err));
            setCurrModalData({
                link: '/discord',
            });
            handleModalOpen();
        }
        if (!isEligible) {
            setCurrModalData({
                modalText: 'Please submit one skill test to proceed',
                link:
                    suggestedSkillTests.length > 0
                        ? `/skilltests/${suggestedSkillTests[0]._id}`
                        : allSkillTests.length > 0
                        ? `/skilltests/${allSkillTests[0]._id}`
                        : '/skilltests',
            });
            handleModalOpen();
        }
    };

    useEffect(() => {
        if (suggestedSkillTests.length === 0) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            axios
                .post('/api/quiz', {}, config)
                .then((res) => {
                    if (res.data.quizzes) setAllSkillTests(res.data.quizzes);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        <>
            <CDSB
                style={{
                    background: isEligible
                        ? 'linear-gradient(321.69deg, #375595 0%, #0D1F5F 100%)'
                        : '',
                }}
            >
                {!isEligible ? (
                    <>
                        <h1>Skill Test</h1>
                        <p style={{ marginBottom: '1.5rem' }}>
                            Take this 20 minutes test to be eligible for various
                            opportunities and other community exclusive perks.
                        </p>

                        <Link
                            style={{ zIndex: 99 }}
                            to={
                                suggestedSkillTests &&
                                suggestedSkillTests.length > 0
                                    ? `/skilltests/${suggestedSkillTests[0]._id}`
                                    : ''
                            }
                        >
                            <Btn1>Take Test</Btn1>
                        </Link>

                        <div>
                            <BannerImage
                                style={{ width: '52vw', marginTop: '-5vw' }}
                                src={BannerImg}
                                alt="skill test completed"
                            />
                        </div>
                    </>
                ) : (
                    <div style={{ display: 'flex' }}>
                        <span>
                            <h1
                                className="test-comp-header"
                                style={{ color: '#fff' }}
                            >
                                Skill Test Completed
                                <i
                                    style={{ paddingLeft: '10px' }}
                                    className="fa-solid fa-circle-check"
                                ></i>
                            </h1>
                            <p style={{ fontSize: '19px', color: '#fff' }}>
                                Score:
                            </p>
                            <p className="score" style={{ color: '#fff' }}>
                                <span>{skillTestScore}</span> / {totalScore}
                            </p>
                            {/* <p>Join us on discord and stay updated.</p> */}
                            {/* <p
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    color: '#fff',
                                }}
                            >
                                You are currently above 90% of the candidates.
                            </p> */}
                            <div className="apply-button-container">
                                <BtnIntern
                                    onClick={handleApply}
                                    name="internships"
                                    style={{
                                        color: '#1D3373',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {appliedInternship
                                        ? 'Internship Applied'
                                        : 'Apply For Internships'}
                                </BtnIntern>
                                <BtnJob
                                    onClick={handleApply}
                                    name="jobs"
                                    style={{ color: '#1D3373' }}
                                >
                                    {appliedJob
                                        ? 'Job Applied'
                                        : 'Apply For Jobs'}
                                </BtnJob>
                            </div>
                        </span>
                        <div style={{ marginLeft: '50px', paddingTop: '20px' }}>
                            <img
                                className="skill-img"
                                src={illus1}
                                alt="skill test completed"
                            />
                        </div>
                    </div>
                )}
            </CDSB>
            {/* <CDSB>
                {
                    !isEligible
                    ? (
                        <>
                            <h1>Skill Test</h1>
                            <p>
                                Take this 20 min test to apply for various opportunities
                                and mentorship programs. After successful submission, your
                                profile will be verified which will help recruiter to sort
                                list you quickly. Verified members can also join
                                “SuperGrad” community for free.
                            </p>
                            <div>
                                <Btn1>
                                    <Link 
                                        to={
                                            suggestedSkillTests && suggestedSkillTests.length > 0
                                            ? `/skilltests/${suggestedSkillTests[0]._id}`
                                            : ''
                                        } 
                                    >
                                        Take Test
                                    </Link>
                                </Btn1>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <h1>Your Skill Test Score: {skillTestScore}</h1>
                            <p>Last Taken At: {testTakenAt !== 'N/A' ? moment(testTakenAt).format('DD/MM/YYYY') : 'N/A'}</p>
                            <p>You are currently above 90% of the candidates.</p>
                        </>
                    )
                }
            </CDSB>
            <CDSB 
                style={{
                    background: 'linear-gradient(321.69deg, #4481EB 0%, #04BEFE 100%), url(.png), linear-gradient(321.69deg, #FF6188 0%, #FFB199 100%)'
                }}
            >
                <h1>Apply With SuperGrad</h1>
                <p>
                    Get your dream internship/job in 15 days
                </p>
                <div>
                    <Btn1 onClick={handleApply} name="internships" style={{color: '#4481EB'}}>
                        Apply For Internships
                    </Btn1>
                    <Btn2 onClick={handleApply} name="jobs" style={{color: '#4481EB'}}>
                        Apply For Jobs
                    </Btn2>
                </div>
            </CDSB> */}
            <Modal open={modalOpen} onClose={handleModalOpen}>
                <Box sx={{ ...style, width: 600 }}>
                    <h2>
                        {isEligible ? (
                            <>
                                You have applied successfully! <br />
                                <br /> For further process, join our discord
                                community.
                                <br />
                            </>
                        ) : (
                            currModalData.modalText
                        )}
                    </h2>
                    <Button onClick={isEligible ? handleModalOpen : null}>
                        {isEligible ? (
                            'Close'
                        ) : (
                            <Link to={currModalData.link}>Go to SkillTest</Link>
                        )}
                    </Button>
                    {isEligible ? (
                        <Button>
                            <Link to={currModalData.link}>Join Discord</Link>
                        </Button>
                    ) : (
                        ''
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default CDSkillSuggestBanners;
