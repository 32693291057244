import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import useInputState from '../../hooks/useInputState';
import {
    RDupcomingCreate,
    MTitle,
    Mcontainer,
    Mleft,
    Mright,
    MSet,
    MSet1,
    MSet2,
    MCreate,
} from './RDComponents';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReactS3Uploader from 'react-s3-uploader';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    maxHeight: '90%',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 5,
};

const RDCreate = ({ setJobUpdate, updateNumJobs }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();

    const stipendItems = [
        '10,000-15,000',
        '15,001-20,000',
        '20,001-30,000',
        '30,001-40,000',
        '40,000+',
    ];
    const salaryItems = [
        '6LPA-8LPA',
        '8LPA-10LPA',
        '10LPA-15LPA',
        '15LPA-20LPA',
        '20LPA-30LPA',
        '30LPA+',
    ];

    const [jobTitle, setJobTitle, resetJobTitle] = useInputState('');
    const [jobRole, setJobRole, resetJobRole] = useInputState('');
    const [jobType, setJobType, resetJobType] = useInputState('');
    const [numOpenings, setNumOpenings, resetNumOpenings] = useInputState('');
    const [jobLocation, setJobLocation, resetJobLocation] = useInputState('');
    const [jobLocations, setJobLocations] = useState([]);
    const [jobDescription, setJobDescription] = useState('');
    const [qualifications, setQualifications, resetQualifications] =
        useInputState('');
    const [salary, setSalary, resetSalary] = useInputState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [jdUploaded, setJdUploaded] = useState(false);

    const handleJobLocations = (e) => {
        if (jobLocation !== '') {
            setJobLocations((currState) => {
                const existingLocation = currState.find(
                    (location) =>
                        location.toLowerCase() === jobLocation.toLowerCase()
                );
                if (existingLocation) return currState;
                else return [...currState, jobLocation];
            });
            resetJobLocation();
        }
    };

    const handleJobLocationsDelete = (locationName) => {
        if (locationName !== '') {
            setJobLocations((currState) => {
                const existingLocation = currState.findIndex(
                    (location) =>
                        location.toLowerCase() === locationName.toLowerCase()
                );
                if (existingLocation === -1) return currState;
                let duplicateLocationState = [...currState];
                duplicateLocationState.splice(existingLocation, 1);
                return [...duplicateLocationState];
            });
            resetJobLocation();
        }
    };

    const handleSubmit = (e) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        if (
            (jobTitle &&
                jobRole &&
                jobType &&
                numOpenings &&
                jobDescription &&
                qualifications &&
                salary) !== '' &&
            jobLocations.length > 0
        ) {
            const payload = {
                jobTitle,
                description: jobDescription,
                qualifications,
                salary,
                jobRole,
                jobType,
                jobLocations,
                numOpenings,
            };
            axios
                .post('/api/rdashboard/post', payload, config)
                .then((res) => {
                    if (res.data.id) {
                        resetJobTitle();
                        resetJobRole();
                        resetJobType();
                        resetNumOpenings();
                        resetJobLocation();
                        setJobLocations([]);
                        resetQualifications();
                        resetSalary();
                        handleClose();
                        updateNumJobs();
                        setJdUploaded(false);
                        setJobDescription('');
                        setUploadProgress(0);
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );
                        setJobUpdate(true);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const saveJd = (data) => {
        setJobDescription(data.filename);
        setJdUploaded(true);
    };

    const uploader = useRef();

    const uploadBanner = (e) => {
        uploader.current.uploadFile();
    };

    return (
        <>
            <RDupcomingCreate>
                <div>Create a new job post and hire seamlessly</div>
                <br />
                <button type="submit" onClick={handleOpen}>
                    <strong>Start Hiring</strong>
                </button>
            </RDupcomingCreate>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflow: 'scroll' }}
            >
                <Box sx={style}>
                    <MTitle>Create New Job</MTitle>
                    <Mcontainer>
                        <Mleft>
                            <MSet>
                                <h1>Job Title</h1>
                                <input
                                    type="text"
                                    value={jobTitle}
                                    onChange={setJobTitle}
                                    placeholder="Enter Here"
                                />
                            </MSet>
                            <MSet1>
                                <h1>Job Type</h1>
                                <div id="input-container">
                                    <input
                                        type="radio"
                                        id="c11"
                                        name="c1"
                                        value="remote"
                                        onChange={setJobType}
                                        checked={jobType === 'remote'}
                                    />
                                    <label>Remote</label>
                                    <input
                                        type="radio"
                                        id="c12"
                                        name="c1"
                                        value="in-office"
                                        onChange={setJobType}
                                        checked={jobType === 'in-office'}
                                    />
                                    <label>In-Office</label>
                                    <input
                                        type="radio"
                                        id="c12"
                                        name="c1"
                                        value="hybrid"
                                        onChange={setJobType}
                                        checked={jobType === 'hybrid'}
                                    />
                                    <label>Hybrid</label>
                                </div>
                            </MSet1>
                            <MSet>
                                <h1>Enter Locations</h1>
                                <input
                                    type="text"
                                    value={jobLocation}
                                    onChange={setJobLocation}
                                    placeholder="Enter Here"
                                    onBlur={handleJobLocations}
                                />
                                <div className="location-names">
                                    <Stack direction="row" spacing={1}>
                                        {jobLocations &&
                                        jobLocations.length > 0 ? (
                                            jobLocations.map(
                                                (location, idx) => (
                                                    <Chip
                                                        label={location}
                                                        onDelete={() =>
                                                            handleJobLocationsDelete(
                                                                location
                                                            )
                                                        }
                                                        key={idx}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <Chip label={'Add Locations'} />
                                        )}
                                    </Stack>
                                </div>
                                <br />
                                <Button onClick={handleJobLocations}>
                                    Add
                                </Button>
                            </MSet>
                            <MSet2>
                                <h1>Initial Skills Required</h1>
                                <textarea
                                    type="text"
                                    value={qualifications}
                                    onChange={setQualifications}
                                    placeholder="Enter Here"
                                />
                            </MSet2>
                            <MSet1>
                                <h1>Job Role</h1>
                                <div id="input-container">
                                    <input
                                        type="radio"
                                        id="c21"
                                        name="c2"
                                        value="internship"
                                        onChange={setJobRole}
                                        checked={jobRole === 'internship'}
                                    />
                                    <label>Internship</label>
                                    <input
                                        type="radio"
                                        id="c22"
                                        name="c2"
                                        value="job(full-time)"
                                        onChange={setJobRole}
                                        checked={jobRole === 'job(full-time)'}
                                    />
                                    <label>Full Time</label>
                                    <input
                                        type="radio"
                                        id="c22"
                                        name="c2"
                                        value="job(part-time)"
                                        onChange={setJobRole}
                                        checked={jobRole === 'job(part-time)'}
                                    />
                                    <label>Free Lancing</label>
                                </div>
                            </MSet1>
                        </Mleft>
                        <Mright>
                            <MSet>
                                <h1>
                                    {jobRole === 'job(full-time)'
                                        ? 'Salary'
                                        : 'Stipend'}
                                </h1>

                                <Select
                                    value={salary}
                                    onChange={setSalary}
                                    displayEmpty
                                    className="selector"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                borderRadius: '10px',
                                                '& .MuiMenuItem-root': {
                                                    paddingBottom: '8px',
                                                    paddingTop: '12px',
                                                    paddingLeft: '14px',
                                                    borderBottom:
                                                        '1px solid #D7DEF0',
                                                    fontFamily:
                                                        "'Lato', sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: '16px',
                                                    color: 'rgba(38, 50, 56, 0.5)',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {jobRole !== 'job(full-time)' &&
                                        stipendItems.map((stipend) => (
                                            <MenuItem value={stipend}>
                                                {stipend}
                                            </MenuItem>
                                        ))}
                                    {jobRole === 'job(full-time)' &&
                                        salaryItems.map((salary) => (
                                            <MenuItem value={salary}>
                                                {salary}
                                            </MenuItem>
                                        ))}
                                    <MenuItem
                                        value="Undisclosed"
                                        style={{ borderBottom: 'none' }}
                                    >
                                        Undisclosed
                                    </MenuItem>
                                </Select>
                            </MSet>
                            <MSet>
                                <h1>Number of Openings</h1>
                                <input
                                    type="number"
                                    placeholder="Enter Here"
                                    value={numOpenings}
                                    onChange={setNumOpenings}
                                />
                            </MSet>
                            <MSet2>
                                <h1>Job Description</h1>
                                <ReactS3Uploader
                                    signingUrl="/s3/sign"
                                    signingUrlMethod="GET"
                                    accept="img/*"
                                    s3path="jds/"
                                    scrubFilename={(filename) =>
                                        `jd_${filename}`
                                    }
                                    onProgress={setUploadProgress}
                                    onFinish={saveJd}
                                    server={process.env.REACT_APP_BACKEND_URL}
                                    autoUpload={false}
                                    uploadRequestHeaders={{
                                        'x-amz-acl': 'public-read',
                                    }}
                                    contentDisposition="auto"
                                    ref={uploader}
                                />
                                <button
                                    onClick={uploadBanner}
                                    disabled={jdUploaded}
                                >
                                    {jdUploaded
                                        ? 'Uploaded'
                                        : uploadProgress === 0
                                        ? 'Upload'
                                        : `${uploadProgress}%`}
                                </button>
                            </MSet2>
                        </Mright>
                    </Mcontainer>
                    <MCreate>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            style={{ border: 'none', outline: 'none' }}
                        >
                            Create
                        </button>
                    </MCreate>
                </Box>
            </Modal>
        </>
    );
};

export default RDCreate;
