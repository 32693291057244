import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { RDOpenjobs } from './RDComponents';
import { useNavigate } from 'react-router-dom';

const RDJobs = ({ updateJob, setJobUpdate, jobs }) => {
    // const [jobs, setJobs] = useState([]);
    // const [jobs, setJobs] = useState([]);
    const navigate = useNavigate();

    // useEffect(() => {
    //     const config = {
    //         headers: { 'x-auth-token': localStorage.getItem('token') },
    //     };

    //     axios
    //         .get('/api/rdashboard/jobs', config)
    //         .then((res) => {
    //             if (res.data.postedJobs) setJobs(res.data.postedJobs);
    //         })
    //         .catch((err) => console.log(err));
    // }, []);

    return (
        <>
            <Grid container spacing={2} sx={{ width: '100%' }}>
                {jobs && jobs.length > 0 ? (
                    jobs.map((job) => (
                        <Grid
                            onClick={() => navigate(`/hiringpanel/${job._id}`)}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            sx={{ width: '100%' }}
                            key={job._id}
                        >
                            <RDOpenjobs
                                style={{
                                    background: !job.isActive && '#ADADAD',
                                }}
                            >
                                <div></div>
                                <h1>
                                    {job.jobTitle},{' '}
                                    {!job.isActive && '(Completed)'}
                                    {job.isActive &&
                                        moment(job.createdAt).format('YYYY')}
                                </h1>
                                <span>
                                    {job.jobType
                                        .split('-')
                                        .map(
                                            (el) =>
                                                `${
                                                    el.charAt(0).toUpperCase() +
                                                    el.substring(1)
                                                } `
                                        )}
                                    : {job.jobLocations[0]}
                                </span>
                                <p>
                                    {moment(job.createdAt).format('DD/MM/YYYY')}
                                </p>
                            </RDOpenjobs>
                        </Grid>
                    ))
                ) : (
                    <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ textAlign: 'center', marginTop: '10%' }}
                    >
                {/*<p className="no-data-msg"> Looking for Jobs 🔍</p>*/}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default RDJobs;
