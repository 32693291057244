import axios from 'axios';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import EditEventModal from './EditEventModal';
import Blogcreate from '../Blog/Blogs/Blogcreate/Blogcreate';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';

function AdminBlogManager(props) {
    const [blogList, setBlogList] = useState([]);
    const dispatch = useDispatch();
    const [selectedBlog, setSelectedBlog] = useState({});
    const [blogModalOpen, setblogModalOpen] = useState(false);
    const handleblogModalToggle = (e) => {
        setblogModalOpen(!blogModalOpen);
    };
    const getBlogs = async () => {
        try {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const res = await axios.get('/api/blog/admin', config);
            if (res.data.msg) {
                return;
            }
            setBlogList(res.data);
        } catch (err) {
            setBlogList([]);
        }
    };

    useEffect(() => {
        getBlogs();
    }, []);

    const handleEdit = (index) => {
        setSelectedBlog(blogList[index]);
        setblogModalOpen(true);
    };
    const handleDelete = async (id) => {
        try {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const res = await axios.delete(`/api/blog/${id}`, config);
            dispatch(
                handleSnackOpen({
                    message: 'Blog Deleted Successfully!',
                    variant: 'warning',
                })
            );
        } catch (err) {
            setBlogList([]);
        }
        getBlogs();
    };
    return (
        <div style={{ padding: '2rem', height: '80%', overflow: 'auto' }}>
            <h1 style={{ padding: '2rem 0' }}>Blogs</h1>
            <Grid container spacing={2} sx={{ width: '100%' }}>
                {blogList.length > 0 &&
                    blogList.map((blogData, idx) => (
                        <Grid item md={4} sm={6} xs={12} key={idx}>
                            <div className="admin-card">
                                <div className="admin-card-info">
                                    <h2>{blogData.title}</h2>
                                    <p
                                        style={{
                                            color: 'rgba(46, 53, 58, 0.3)',
                                            marginBottom: '10px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {blogData.introduction}
                                    </p>
                                    <button onClick={() => handleEdit(idx)}>
                                        Edit
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleDelete(blogData._id)
                                        }
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    ))}
                {blogList.length === 0 && (
                    <p className="no-data-msg">Looking for Blogs 🔍</p>
                )}
            </Grid>
            <Blogcreate
                open={blogModalOpen}
                handleOpen={handleblogModalToggle}
                blogDataEdit={selectedBlog}
                trigger={getBlogs}
            />
        </div>
    );
}

export default AdminBlogManager;
