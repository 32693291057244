import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import '../../styles/Admin/AdminInterviewRequests.css';
import CandidateProfile from './CandidateProfile';

function AdminInterviewRequests(props) {
    const [candidates, setCandidates] = useState([]);
    const [currCandidateProfile, setCurrCandidateProfile] = useState('');
    const [candidateProfileIsOpen, setCandidateProfileIsOpen] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const [currCandidateActionId, setCurrCandidateActionId] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };

        axios.get('/api/admin/interview-requests', config)
            .then(res => {
                if(res.data.users && res.data.users.length > 0) setCandidates(res.data.users);
            }).catch(err => console.log(err));
    }, [dialogIsOpen]);

    const openCandidateProfile = e => {
        setCandidateProfileIsOpen(!candidateProfileIsOpen);
        if(e && e.currentTarget.value) setCurrCandidateProfile(parseInt(e.currentTarget.value));
    }

    const handleDialogOpen = (userId, dialogType) => {
        if(!userId || userId === '') setCurrCandidateActionId('');
        else setCurrCandidateActionId(userId);

        if(!dialogType || userId === '') setDialogType('');
        else setDialogType(dialogType);

        setDialogIsOpen(!dialogIsOpen);
    }

    const markAsScheduled = e => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };
        const payload = {
            userId: currCandidateActionId,
            status: 'scheduled'
        };

        axios.post('/api/admin/interview-requests', payload, config)
            .then(res => {
                if(res.data.userId) {
                    handleDialogOpen();
                    dispatch(
                        handleSnackOpen({
                            message: 'Marked as scheduled',
                            variant: 'success'
                        })
                    );
                }
            }).catch(err => console.log(err));
    }

    const closeRequest = e => {
        const config = {
            headers: {'x-auth-token': localStorage.getItem('token')}
        };
        const payload = {
            userId: currCandidateActionId,
            status: 'closed'
        };

        axios.post('/api/admin/interview-requests', payload, config)
            .then(res => {
                if(res.data.userId) {
                    handleDialogOpen();
                    dispatch(
                        handleSnackOpen({
                            message: 'Marked as closed',
                            variant: 'success'
                        })
                    );
                }
            }).catch(err => console.log(err));
    }

    return (
        !candidateProfileIsOpen
        ? (
            <section className='AdminInterviewRequests'>
                <h1>Mock Interview Requests</h1>
                <div className='interview-requests'>
                    <Grid container spacing={1} sx={{width: '100%'}}>
                        {
                            candidates && candidates.length > 0
                            ? (
                                candidates.map((candidate, idx) => {
                                    const foundInterviewRequest = candidate.interviewRequests.find(request => (
                                        request.interviewType === 'mock'
                                    ));
                                    return(
                                        <Grid item md={3} sm={6} xs={12} key={idx}>
                                            <div className='interview-request-card'>
                                                <div className='request-card-info'>
                                                    <h2>{candidate.fullName}</h2>
                                                    <p 
                                                        style={{
                                                            color: 'rgba(46, 53, 58, 0.3)', 
                                                            marginBottom: '10px',
                                                            fontWeight: 400
                                                        }}
                                                    >
                                                        {candidate.mobileNo}
                                                    </p>
                                                    <p>
                                                        Date: {moment(candidate.interviewRequests.requestedInterviewDate).format('LL')}
                                                    </p>
                                                    <p>
                                                        Time: {moment(candidate.interviewRequests.requestedInterviewDate).format('LT')}
                                                    </p>
                                                    <button 
                                                        onClick={openCandidateProfile}
                                                        value={idx}
                                                    >
                                                        View Profile
                                                    </button>
                                                </div>
                                                <div className='request-card-btns'>
                                                    <span>
                                                        {
                                                            foundInterviewRequest.status !== 'scheduled'
                                                            ? <i 
                                                                onClick={() => handleDialogOpen(candidate._id, 'schedule')} 
                                                                className="fa-solid fa-check"
                                                            ></i>
                                                            : <i 
                                                                style={{
                                                                    cursor: 'default',
                                                                    fontSize: '14px'
                                                                }}
                                                            >
                                                                Scheduled
                                                            </i>
                                                        }
                                                        <i 
                                                            onClick={() => handleDialogOpen(candidate._id, 'close')} 
                                                            className="fa-solid fa-xmark"
                                                        ></i>
                                                    </span>
                                                    <button>
                                                        <a href={`mailto:${candidate.email}`}>
                                                            <i className="fa-solid fa-envelope"></i>
                                                            Mail
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })
                            )
                            : <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center', marginTop: '10%'}}>
                                <p className='no-data-msg'>No mock interview requests found 🔍</p>
                            </Grid>
                        }
                    </Grid>
                </div>
                <Dialog
                    open={dialogIsOpen}
                    onClose={() => handleDialogOpen('', '')}
                    fullWidth
                >
                    <DialogTitle>
                        {
                            dialogType === 'schedule'
                            ? 'Mark request as scheduled?'
                            : 'Close request?'
                        }
                    </DialogTitle>
                    <DialogActions>
                        <div className='dialog-btns'>
                            <button onClick={() => handleDialogOpen('', '')}>Cancel</button>
                            <button 
                                onClick={dialogType === 'schedule' ? markAsScheduled : closeRequest}
                            >
                                Confirm
                            </button>
                        </div>
                    </DialogActions>
                </Dialog>
            </section>
        ) : (
            <CandidateProfile 
                userData={candidates[currCandidateProfile]} 
                handleProfileOpen={openCandidateProfile}
            />
        )
    )
}

export default AdminInterviewRequests;