import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../../store/Toast/Toast';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import '../../styles/Admin/AdminInterviewRequests.css';
import CandidateProfile from './CandidateProfile';

function AdminCandidateList(props) {
    const [candidates, setCandidates] = useState([]);
    const [currCandidateProfile, setCurrCandidateProfile] = useState('');
    const [candidateProfileIsOpen, setCandidateProfileIsOpen] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const [currCandidateActionId, setCurrCandidateActionId] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/admin/candidates', config)
            .then((res) => {
                if (res.data.users && res.data.users.length > 0)
                    setCandidates(res.data.users);
            })
            .catch((err) => console.log(err));
    }, [dialogIsOpen]);

    const openCandidateProfile = (e) => {
        setCandidateProfileIsOpen(!candidateProfileIsOpen);
        if (e && e.currentTarget.value)
            setCurrCandidateProfile(parseInt(e.currentTarget.value));
    };

    const handleDialogOpen = (userId, dialogType) => {
        if (!userId || userId === '') setCurrCandidateActionId('');
        else setCurrCandidateActionId(userId);

        if (!dialogType || userId === '') setDialogType('');
        else setDialogType(dialogType);

        setDialogIsOpen(!dialogIsOpen);
    };

    return !candidateProfileIsOpen ? (
        <section className="AdminCandidateList">
            <h1>Candidate list</h1>
            <div className="interview-requests">
                <Grid container spacing={2} sx={{ width: '100%' }}>
                    {candidates && candidates.length > 0 ? (
                        candidates.map((candidate, idx) => {
                            return (
                                <Grid item md={4} sm={6} xs={12} key={idx}>
                                    <div
                                        style={{
                                            background:
                                                candidate.adminCreated &&
                                                '#6871e9',
                                        }}
                                        className="interview-request-card"
                                    >
                                        <div className="request-card-info">
                                            <h2>
                                                {candidate.fullName
                                                    ? candidate.fullName
                                                    : candidate.email}
                                            </h2>
                                            <p
                                                style={{
                                                    color: 'rgba(46, 53, 58, 0.3)',
                                                    marginBottom: '10px',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {candidate.mobileNo
                                                    ? candidate.mobileNo
                                                    : 'Profile Not created'}
                                            </p>

                                            {candidate.profileCompleted && (
                                                <button
                                                    onClick={
                                                        openCandidateProfile
                                                    }
                                                    value={idx}
                                                >
                                                    View Profile
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })
                    ) : (
                        <Grid
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{ textAlign: 'center', marginTop: '10%' }}
                        >
                            <p className="no-data-msg">
                                Looking for Candidates 🔍
                            </p>
                        </Grid>
                    )}
                </Grid>
            </div>
        </section>
    ) : (
        <CandidateProfile
            userData={candidates[currCandidateProfile]}
            handleProfileOpen={openCandidateProfile}
        />
    );
}

export default AdminCandidateList;
