import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactS3Uploader from 'react-s3-uploader';
import useInputState from '../../hooks/useInputState';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleSnackOpen } from '../../store/Toast/Toast';
import '../../styles/CandidateDashboard/CandidateProfile.css';
import defaultAvatar from '../../assets/illustrations/PPic.png';

function RecruiterProfile(props) {
    const [userData, setUserData] = useState({});
    const [userId, setUserId] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [avatarS3FileKey, setAvatarS3FileKey] = useState('');
    const [avatarS3PublicUrl, setAvatarS3PublicUrl] = useState('');
    const [avatarUploaded, setAvatarUploaded] = useState(false);
    const [fullName, setFullName, resetFullName, initFullName] =
        useInputState('');
    const [mobileNo, setMobileNo, resetMobileNo, initMobileNo] =
        useInputState('');
    const [email, setEmail, resetEmail, initEmail] = useInputState('');
    const [
        currentPosition,
        setCurrentPosition,
        resetCurrentPosition,
        initCurrentPosition,
    ] = useInputState('');
    const [companyName, setCompanyName, resetCompanyName, initCompanyName] =
        useInputState('');
    const [industry, setIndustry, resetIndustry, initIndustry] =
        useInputState('');
    const [companyDesc, setCompanyDesc, resetCompanyDesc, initCompanyDesc] =
        useInputState('');
    const [companyType, setCompanyType, resetCompanyType, initCompanyType] =
        useInputState('');
    const [yearOfEstd, setYearOfEstd, resetYearOfEstd, initYearOfEstd] =
        useInputState('');
    const [companySize, setCompanySize, resetCompanySize, initCompanySize] =
        useInputState('');
    const [
        companyAddress,
        setCompanyAddress,
        resetCompanyAddress,
        initCompanyAddress,
    ] = useInputState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .get('/api/auth/recruiter', config)
            .then((res) => {
                const user = res.data.user;
                setUserId(user._id);
                setUserData(user);
                initFullName(user.fullName);
                initMobileNo(user.mobileNo);
                initEmail(user.email);
                initCurrentPosition(user.currentPosition);
                initCompanyName(user.company.companyName);
                initIndustry(user.company.industry);
                initCompanyDesc(user.company.companyDesc);
                initCompanyType(user.company.companyType);
                initYearOfEstd(user.company.yearOfEstd);
                initCompanySize(user.company.companySize);
                initCompanyAddress(user.company.companyAddress);
            })
            .catch((err) => console.log(err));
    }, []);

    // Avatar Update
    const uploader = useRef();

    const uploadAvatar = (e) => {
        uploader.current.uploadFile();
    };

    const saveAvatar = (data) => {
        setAvatarS3FileKey(data.fileKey);
        setAvatarS3PublicUrl(data.publicUrl);
        setAvatarUploaded(true);
    };

    const submitChanges = (e) => {
        if (
            (fullName ||
                mobileNo ||
                currentPosition ||
                email ||
                companyName ||
                industry ||
                companyType ||
                yearOfEstd ||
                companySize ||
                companyAddress ||
                companyDesc) === ''
        ) {
            dispatch(
                handleSnackOpen({
                    message: 'Fields cannot be empty!',
                    variant: 'warning',
                })
            );
        } else {
            const payload = {
                userId,
                userType: 'recruiter',
                fullName,
                mobileNo,
                currentPosition,
                email,
                companyName,
                industry,
                companyType,
                yearOfEstd,
                companySize,
                companyAddress,
                companyDesc,
            };
            if ((avatarS3FileKey && avatarS3PublicUrl) !== '') {
                payload.avatarS3FileKey = avatarS3FileKey;
                payload.avatarS3PublicUrl = avatarS3PublicUrl;
            }
            axios
                .put('/api/user/register/profile', payload)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: 'Profile updated successfully!',
                                variant: 'success',
                            })
                        );
                        navigate('/rdashboard');
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <section className="CandidateDashboardProfile">
            <div className="filler-div"></div>
            <h1 className="header">Profile</h1>
            <div className="profile-container">
                <div className="avatar-container">
                    <img
                        src={
                            userData && userData.avatarS3FileKey
                                ? `https://preentrepreneur.s3.ap-south-1.amazonaws.com/${userData.avatarS3FileKey}`
                                : defaultAvatar
                        }
                    />
                    <span>
                        <ReactS3Uploader
                            signingUrl="/s3/sign"
                            signingUrlMethod="GET"
                            accept="img/*"
                            s3path="profile_pics/recruiter/"
                            scrubFilename={(filename) =>
                                `profile_pic-${userData && userData.userId}`
                            }
                            onProgress={setUploadProgress}
                            onFinish={saveAvatar}
                            server={process.env.REACT_APP_BACKEND_URL}
                            autoUpload={false}
                            uploadRequestHeaders={{
                                'x-amz-acl': 'public-read',
                            }}
                            contentDisposition="auto"
                            ref={uploader}
                        />
                        <button onClick={uploadAvatar}>
                            {avatarUploaded
                                ? 'Uploaded'
                                : uploadProgress === 0
                                ? 'Upload Profile Pic'
                                : `${uploadProgress}%`}
                        </button>
                    </span>
                </div>
                <div className="field-container">
                    <div>
                        <h2>General Details</h2>
                        <div className="input-container">
                            <span>
                                <label htmlFor="full-name">Full Name</label>
                                <input
                                    type="text"
                                    value={fullName}
                                    onChange={setFullName}
                                    id="full-name"
                                />
                            </span>
                            <span>
                                <label htmlFor="mobileNo">Mobile No</label>
                                <input
                                    type="text"
                                    value={mobileNo}
                                    onChange={setMobileNo}
                                    id="mobileNo"
                                />
                            </span>
                        </div>
                        <div className="input-container">
                            <span>
                                <label htmlFor="current-position">
                                    Current Position
                                </label>
                                <input
                                    type="text"
                                    value={currentPosition}
                                    onChange={setCurrentPosition}
                                    id="current-position"
                                />
                            </span>
                            <span>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={setEmail}
                                    id="email"
                                />
                            </span>
                        </div>
                    </div>
                    <hr
                        className="styled-hr"
                        style={{ width: '100%', margin: '10px auto' }}
                    />
                    <div>
                        <h2>Company Profile</h2>
                        <div className="input-container">
                            <span>
                                <label htmlFor="company-name">
                                    Company Name
                                </label>
                                <input
                                    type="text"
                                    value={companyName}
                                    onChange={setCompanyName}
                                    id="company-name"
                                />
                            </span>
                            <span>
                                <label htmlFor="industry">Industry</label>
                                <input
                                    type="text"
                                    value={industry}
                                    onChange={setIndustry}
                                    id="industry"
                                />
                            </span>
                        </div>
                        <div className="input-container">
                            <span>
                                <label htmlFor="company-type">
                                    Company Type
                                </label>
                                <select
                                    onChange={setCompanyType}
                                    name="companyType"
                                >
                                    <option value="">
                                        Select Company Type
                                    </option>
                                    <option value="Start Up (Bootstrapped)">
                                        Start Up (Bootstrapped)
                                    </option>
                                    <option value="Start Up (Funded)">
                                        Start Up (Funded)
                                    </option>
                                    <option value="MNC">MNC</option>
                                </select>
                            </span>
                            <span>
                                <label htmlFor="yearOfEstd">
                                    Year of Establishment
                                </label>
                                <input
                                    type="number"
                                    value={yearOfEstd}
                                    onChange={setYearOfEstd}
                                    id="yearOfEstd"
                                    min={1}
                                />
                            </span>
                        </div>
                        <div className="input-container">
                            <span>
                                <label htmlFor="company-size">
                                    Company Size
                                </label>
                                <select onChange={setCompanySize}>
                                    <option value="">
                                        Select range of employees
                                    </option>
                                    <option value="1-10">1-10</option>
                                    <option value="11-100">11-100</option>
                                    <option value="101-1000">101-1000</option>
                                    <option value="1000+">1000+</option>
                                </select>
                            </span>
                            <span>
                                <label htmlFor="company-address">
                                    Company Head Office Address
                                </label>
                                <input
                                    type="text"
                                    value={companyAddress}
                                    onChange={setCompanyAddress}
                                    id="company-address"
                                />
                            </span>
                        </div>
                        <div className="input-container">
                            <span>
                                <label htmlFor="company-description">
                                    Company Description
                                </label>
                                <input
                                    type="text"
                                    value={companyDesc}
                                    onChange={setCompanyDesc}
                                    id="company-description"
                                />
                            </span>
                        </div>
                    </div>
                    <hr
                        className="styled-hr"
                        style={{ width: '100%', margin: '10px auto' }}
                    />
                    <button className="save-btn" onClick={submitChanges}>
                        Save Changes
                    </button>
                </div>
            </div>
        </section>
    );
}

export default RecruiterProfile;
