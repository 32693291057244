import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RDCard1, RDCard2, RDCard3, RDCard4 } from './RDComponents';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const RDStats = () => {
    const [allStats, setAllStats] = useState({});

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/rdashboard/stats', config)
            .then((res) => {
                if (res.data.recruiterStats)
                    setAllStats(res.data.recruiterStats);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div style={{ paddingLeft: '10px' }}>
            <h1
                style={{
                    fontSize: '20px',
                    paddingLeft: '10px',
                    marginBottom: '20px',
                }}
            >
                Welcome {JSON.parse(localStorage.getItem('user')).fullName},
            </h1>
            <RDCard1>
                <h1>
                    Total Jobs
                    <br />
                    Posted
                </h1>
                <div>
                    <i>
                        <DescriptionOutlinedIcon fontSize="large" />
                    </i>
                    <span>
                        {allStats && allStats.totalJobs
                            ? allStats.totalJobs
                            : 0}
                    </span>
                </div>
            </RDCard1>
            <RDCard2>
                <h1>
                    Ongoing <br /> Hiring
                </h1>
                <div>
                    <i>
                        <MessageOutlinedIcon fontSize="large" />
                    </i>
                    <span>
                        {allStats && allStats.ongoingJobHirings
                            ? allStats.ongoingJobHirings
                            : 0}
                    </span>
                </div>
            </RDCard2>
            <RDCard3>
                <h1>
                    Completed <br /> Hiring
                </h1>
                <div>
                    <i>
                        <TaskAltOutlinedIcon fontSize="large" />
                    </i>
                    <span>
                        {allStats && allStats.completedHirings
                            ? allStats.completedHirings
                            : 0}
                    </span>
                </div>
            </RDCard3>
            <RDCard4>
                <h1>
                    Candidates
                    <br />
                    Hired
                </h1>
                <div>
                    <i>
                        <GroupOutlinedIcon fontSize="large" />
                    </i>
                    <span>
                        {allStats && allStats.candidatesHired
                            ? allStats.candidatesHired
                            : 0}
                    </span>
                </div>
            </RDCard4>
        </div>
    );
};

export default RDStats;
