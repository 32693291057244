import React, { useEffect } from 'react';

const Redirect = ({ url }) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - var(--nav-height))',
                paddingTop: 'var(--nav-height)',
            }}
        >
            <h2 style={{ textALign: 'center' }}>Redirecting... Please wait!</h2>
        </div>
    );
};

export default Redirect;
