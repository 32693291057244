import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Row } from '@mui/material';
import '../../styles/Bootcamps.css';
import Footer from '../Footer/Footer';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Autoplay } from 'swiper';
import { HashLink } from 'react-router-hash-link';

import 'swiper/swiper.scss';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

function Bootcamps(props) {
    const [whichRaised, setWhichRaised] = useState(0);
    return (
        <div className="Bootcamp">
            <div className="filler-div" />
            <section
                style={{ background: 'rgba(199, 199, 227, 0.24)' }}
                className="bootcamp-hero"
            >
                {window.screen.width >= 992 ? (
                    <Grid
                        alignContent={'center'}
                        justifyContent="center"
                        container
                        style={{ height: '100%' }}
                    >
                        <Grid className="hero-content" md={6} item>
                            <h1>Career Accelerator Program</h1>
                            <p>
                                Intricated tailor-made programs co-created with
                                corporate leaders to make you industry ready so
                                that nothing can stop you from building a great
                                career.
                            </p>
                            <h3 style={{ color: '#4544a2' }}>
                                Why Should I Join
                            </h3>
                            <Grid
                                className="why-options"
                                alignContent="center"
                                justifyContent="center"
                                container
                                item
                                direction="row"
                                spacing={2}
                            >
                                <HeroWhyOptions
                                    icon="/assets/images/bootcamp-why-1.svg"
                                    heading={
                                        '100% assured placement assistance'
                                    }
                                    iconAlt="Guaranteed Internship"
                                />
                                <HeroWhyOptions
                                    icon="/assets/images/bootcamp-why-2.svg"
                                    heading={
                                        'Become industry ready and build strong network'
                                    }
                                    iconAlt="Expert Mentorship"
                                />
                                <HeroWhyOptions
                                    icon="/assets/images/bootcamp-why-3.svg"
                                    heading={
                                        'Work on several live projects with vibrant community'
                                    }
                                    iconAlt="Expert Mentorship"
                                />
                            </Grid>
                            <HashLink smooth to="#bootcamp-details">
                                <button>Explore Bootcamps</button>
                            </HashLink>
                        </Grid>
                        <Grid className="hero-img" md={5} item>
                            <img
                                src="/assets/images/bootcamp-hero.png"
                                alt="Career Accelerator Program"
                                className="bootcamp-hero-img"
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        alignContent={'center'}
                        justifyContent="center"
                        container
                        style={{ height: '100%' }}
                    >
                        <Grid className="hero-img" md={5} item>
                            <img
                                src="/assets/images/bootcamp-hero.png"
                                alt="Career Accelerator Program"
                                className="bootcamp-hero-img"
                            />
                        </Grid>
                        <Grid className="hero-content" md={6} item>
                            <h1>Career Accelerator Program</h1>
                            <p>
                                Intricated tailor-made programs co-created with
                                corporate leaders to make you industry ready so
                                that nothing can stop you from building a great
                                career.
                            </p>
                            <h3 style={{ color: '#4544a2' }}>
                                Why Should I Join
                            </h3>
                            <Grid
                                className="why-options"
                                alignContent="center"
                                justifyContent="center"
                                container
                                item
                                direction="row"
                                spacing={2}
                            >
                                <HeroWhyOptions
                                    icon="/assets/images/bootcamp-why-1.svg"
                                    heading={
                                        '100% placement assistance and guaranteed Internship'
                                    }
                                    iconAlt="Guaranteed Internship"
                                />
                                <HeroWhyOptions
                                    icon="/assets/images/bootcamp-why-2.svg"
                                    heading={
                                        'Become industry ready and build strong network.'
                                    }
                                    iconAlt="Expert Mentorship"
                                />
                                <HeroWhyOptions
                                    icon="/assets/images/bootcamp-why-3.svg"
                                    heading={
                                        'Work on several live projects with vibrant community.'
                                    }
                                    iconAlt="Expert Mentorship"
                                />
                            </Grid>
                            <button>Explore Bootcamps</button>
                        </Grid>
                    </Grid>
                )}
            </section>
            <section className="bootcamp-details" id="bootcamp-details">
                <h1 style={{ color: '#4544a2', textAlign: 'center' }}>
                    Find a bootcamp that works for you
                </h1>
                <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                    style={{ height: '100%', justifyContent: 'center' }}
                >
                    <BootcampCard
                        headerImg="/assets/images/bootcamp/dataanalytics.png"
                        setWhichRaised={setWhichRaised}
                        whichRaised={whichRaised}
                        cardPosition={1}
                        hours={45}
                        title="Data Analytics Bootcamp"
                        bootcampId={'data-analytics-bootcamp'}
                    />
                    <BootcampCard
                        headerImg="/assets/images/bootcamp/fullstack.png"
                        setWhichRaised={setWhichRaised}
                        whichRaised={whichRaised}
                        cardPosition={2}
                        hours={60}
                        title="Web Development Bootcamp"
                        bootcampId={'web-development-bootcamp'}
                    />
                    <BootcampCard
                        headerImg="/assets/images/internBootcamp.jpeg"
                        setWhichRaised={setWhichRaised}
                        whichRaised={whichRaised}
                        cardPosition={3}
                        hours={30}
                        title="Summer Intership Bootcamp"
                        bootcampId={'summer-internship-bootcamp'}
                    />
                    <BootcampCard
                        headerImg="/assets/images/productBootcamp.png"
                        setWhichRaised={setWhichRaised}
                        whichRaised={whichRaised}
                        cardPosition={4}
                        hours={30}
                        title="Product Bootcamp"
                        bootcampId={'product-management-bootcamp'}
                    />
                </Grid>
            </section>
            <section
                className="bootcamp-brands"
                style={{ background: 'rgba(199, 199, 227, 0.24)' }}
            >
                <h1 style={{ color: '#4544a2', textAlign: 'center' }}>
                    Learn From The Best
                </h1>

                <Swiper
                    slidesPerView={5}
                    spaceBetween={20}
                    autoplay={{ delay: 1500, pauseOnMouseEnter: true }}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                    loop
                >
                    <SwiperSlide>
                        <img src="/assets/images/comp_logo/1.svg"></img>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/images/comp_logo/2.png"></img>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/images/comp_logo/3.png"></img>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/images/comp_logo/4.svg"></img>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/assets/images/comp_logo/5.png"></img>
                    </SwiperSlide>
                </Swiper>
            </section>
            <section className="marketing-partner">
                <h4>Marketed By</h4>
                <img src="/assets/images/bootcamp/market.jpeg"></img>
            </section>
            <Footer />
        </div>
    );
}

const HeroWhyOptions = ({ icon, heading, iconAlt = '' }) => (
    <Grid
        item
        direction="column"
        className="hero-why-options"
        style={{ justifyContent: 'center', textAlign: 'center' }}
    >
        <img style={{ height: '3rem' }} src={icon} alt={iconAlt} />
        <h5 style={{ padding: '0.5vw' }}>{heading}</h5>
    </Grid>
);

const BootcampCard = ({
    whichRaised,
    setWhichRaised,
    cardPosition,
    headerImg,
    title,
    body,
    bootcampId,
    hours,
}) => (
    <Grid md={6} item>
        <a style={{ cursor: 'default' }} href={`/bootcamp/${bootcampId}`}>
            <Card
                onMouseEnter={() => setWhichRaised(cardPosition)}
                onMouseLeave={() => setWhichRaised(0)}
                raised={whichRaised === cardPosition}
                className="bootcamp-cards"
                sx={{
                    maxWidth: 350,
                    margin: 'auto',
                    background: 'rgba(199, 199, 227, 0.22)',
                }}
            >
                <CardMedia
                    component="img"
                    className="image"
                    image={headerImg}
                    alt="Summer Internship Bootcamp"
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="title"
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                    ></Typography>
                </CardContent>
                <CardContent>
                    <Grid container className="benefits">
                        <Grid item md={12} className="benefits-item">
                            <img src="/assets/images/icons/youtube.png" />
                            <span>{hours}+ Hours Live Sessions</span>
                        </Grid>
                        <Grid item md={12} className="benefits-item">
                            <img src="/assets/images/icons/puzzle.png" />
                            <span>5+ Hands on Projects</span>
                        </Grid>
                        <Grid item md={12} className="benefits-item">
                            <img src="/assets/images/icons/assistant.png" />
                            <span>Placement Assistance</span>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className="action-btn" action>
                    {bootcampId === 'summer-internship-bootcamp' ? (
                        <Button className="btn" size="small" href="/" bootcamp>
                            Enrollment Closed
                        </Button>
                    ) : (
                        <Button
                            className="btn"
                            size="small"
                            href={`/bootcamp/${bootcampId}`}
                        >
                            Join
                        </Button>
                    )}
                </CardActions>
            </Card>
        </a>
    </Grid>
);

export default Bootcamps;
