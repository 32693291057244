import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    /* overflow-y:scroll; */
    scroll-behaviour:smoooth;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;

export const Left = styled.div`
    /* position: fixed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60vw;
    @media screen and (max-width: 900px) {
        width: 98vw;
        // padding: 10px;
        position: relative;
    }
`;

export const Set2 = styled.div`
    width: 40vw;
    margin-top: 20px;
    div {
        display: flex;
        align-items: center;
    }

    img {
        height: 40px;
        margin-right: 5px;
        border-radius: 20px;
    }
    input {
        margin-left: 15px;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #6871e9;
        padding: 18px 10px;
        height: 40px;
        width: calc(40vw);
        border: 1px solid #d7def0;
        border-radius: 15px;
        box-sizing: border-box;
        outline: none;
    }

    button {
        background: #4544a2;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
        border-radius: 100px;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 12px 20px;
        color: #fff;
        display: block;
    }

    i {
        display: none;
    }

    @media screen and (max-width: 900px) {
        width: 98vw;
        padding-bottom: 50px;
        border-bottom: 1px solid #d7def0;
        input {
            width: calc(88vw - 80px);
            margin-right: 10px;
        }
        i {
            display: block;
            transform: rotate(-20deg);
            color: #4544a2;
        }
    }
`;

export const Set1 = styled.div`
    width: 40vw;
    margin-top: 30px;

    img {
        height: 40vh;
        border-radius: 12px;
    }

    @media screen and (max-width: 900px) {
        width: 95vw;
        padding: 10px;
        // padding-left: 30px;

        img {
            width: 93vw;
            padding-top: 2vw;
        }
    }
`;

export const Title1 = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    h1 {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        margin-right: 5px;
    }
    p {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #828282;
    }
`;

export const Desc1 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 10px;

    span {
        color: #52a2f3;
    }
`;

export const Foot1 = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
    align-items: center;
    margin-top: 20px;
    width: 40vw;

    button {
        border: none;
        outline: none;
        background: none;
        color: #6871e9;
        font-size: 16px;
        cursor: pointer;
    }

    span {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #6871e9;
    }

    @media screen and (max-width: 900px) {
        width: 92vw;
        padding-right: 0px;
    }
`;

// FRight Styles
export const Right = styled.div`
    /* margin-left: 60vw; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow:scroll ; */
    width: 40vw;
    height: calc(100vh - var(--nav-height));
    border-left: 1px solid #d7def0;
    .no-comments {
        font-size: 15px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
    }
    @media screen and (max-width: 900px) {
        width: 95vw;
        padding: 10px;
        padding-left: 10px;
        margin-left: 0px;
    }
`;

export const Set = styled.div`
    width: 34vw;
    margin-top: 40px;
    margin-bottom: 10px;
    @media screen and (max-width: 900px) {
        width: 95vw;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    margin-bottom: 5px;

    span {
        img {
            height: 35px;
            width: 35px;
            margin-right: 10px;
            border-radius: 50%;
        }
    }

    h1 {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000;
        display: flex;
        margin-right: 10px;
    }
    p {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #b4bbc6;
    }

    @media screen and (max-width: 900px) {
        width: 92vw;
    }
`;

export const TImg = styled.div``;

export const Desc = styled.div`
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 15px;
`;

export const Foot = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;

    p {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 12px;
        color: #8991a0;
    }

    i {
        color: #8991a0;
        font-size: 15px;
        cursor: pointer;
    }
`;
