import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CreateEventModal from './CreateEventModal';
import '../../styles/Admin/AdminDashboard.css';
import CreateForumModal from './CreateForumModal';
import AdminJobDetails from './AdminJobDetails';
import Blogcreate from '../Blog/Blogs/Blogcreate/Blogcreate';

function AdminDashboard({ handleTabClick }) {
    const [currStats, setCurrStats] = useState('weekly');
    const [statsData, setStatsData] = useState({});
    const [allJobs, setAllJobs] = useState([]);
    const [eventModalOpen, setEventModalOpen] = useState(false);
    const [blogModalOpen, setblogModalOpen] = useState(false);
    const [forumModalOpen, setForumModalOpen] = useState(false);
    const [jobIsOpen, setJobIsOpen] = useState(false);
    const [currJob, setCurrJob] = useState('');
    // Retreive all posted jobs
    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get('/api/admin/jobs', config)
            .then((res) => {
                setAllJobs(res.data.jobs);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleStatsChange = (e) => {
        setCurrStats(e.target.value);
    };

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };

        axios
            .get(`/api/admin/stats/${currStats}`, config)
            .then((res) => {
                setStatsData(res.data);
            })
            .catch((err) => console.log(err));
    }, [currStats]);

    const handleEventModalOpen = (e) => {
        setEventModalOpen(!eventModalOpen);
    };
    const toggleBlogModal = (e) => {
        setblogModalOpen(!blogModalOpen);
    };

    const handleForumModalOpen = (e) => {
        setForumModalOpen(!forumModalOpen);
    };

    const handleJobOpen = (e) => {
        setJobIsOpen(!jobIsOpen);
        if (e && e.currentTarget.getAttribute('value'))
            setCurrJob(e.currentTarget.getAttribute('value'));
        else setCurrJob('');
    };

    return !jobIsOpen ? (
        <section className="AdminDashboard">
            <div className="admin-dash-content">
                <FormControl style={{ marginBottom: '12px' }}>
                    <Select
                        value={currStats}
                        onChange={handleStatsChange}
                        displayEmpty
                        style={{
                            height: '36px',
                            fontFamily: "'Lato', sans-serif",
                            color: '#2E353A',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    borderRadius: '10px',
                                    '& .MuiMenuItem-root': {
                                        paddingBottom: '8px',
                                        paddingTop: '12px',
                                        paddingLeft: '14px',
                                        borderBottom: '1px solid #D7DEF0',
                                        fontFamily: "'Lato', sans-serif",
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        color: 'rgba(38, 50, 56, 0.5)',
                                    },
                                },
                            },
                        }}
                    >
                        <MenuItem value="weekly">Weekly Statistics</MenuItem>
                        <MenuItem value="daily">Daily Statistics</MenuItem>
                        <MenuItem value="monthly">Monthly Statistics</MenuItem>
                        <MenuItem
                            value="yearly"
                            style={{ borderBottom: 'none' }}
                        >
                            Yearly Statistics
                        </MenuItem>
                    </Select>
                </FormControl>
                <div className="weekly-stats">
                    <span id="new-users">
                        <h1>New Users</h1>
                        <p>{statsData.newUsers ? statsData.newUsers : 'N/A'}</p>
                    </span>
                    <span id="job-candidates">
                        <h1>Job Candidates</h1>
                        <p>
                            {statsData.jobCandidates
                                ? statsData.jobCandidates
                                : 'N/A'}
                        </p>
                    </span>
                    <span id="job-candidates">
                        <h1>Intern Candidates</h1>
                        <p>
                            {statsData.internCandidates
                                ? statsData.internCandidates
                                : 'N/A'}
                        </p>
                    </span>
                    <span id="candidates-hired">
                        <h1>Candidates Hired</h1>
                        <p>
                            {statsData.candidatesHired
                                ? statsData.candidatesHired
                                : 'N/A'}
                        </p>
                    </span>
                    <span id="tests-taken">
                        <h1>Tests Taken</h1>
                        <p>
                            {statsData.testTaken ? statsData.testTaken : 'N/A'}
                        </p>
                    </span>
                    <span id="events-attended">
                        <h1>Events Attended</h1>
                        <p>
                            {statsData.eventsAttended
                                ? statsData.eventsAttended
                                : 'N/A'}
                        </p>
                    </span>
                </div>
                <div className="dashboard-main">
                    <div className="new-jobs">
                        <span>
                            <h2>New Jobs</h2>
                            <h3 onClick={handleTabClick} name="Manage Jobs">
                                View More
                            </h3>
                        </span>
                        <Grid container spacing={2}>
                            {allJobs && allJobs.length > 0 ? (
                                allJobs.slice(0, 4).map((job, idx) => (
                                    <Grid item key={idx} md={6} sm={12} xs={12}>
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            className="new-jobs-card"
                                            onClick={handleJobOpen}
                                            value={job._id}
                                        >
                                            <h3>
                                                {job.jobTitle},{' '}
                                                {moment(job.createdAt).format(
                                                    'YYYY'
                                                )}
                                            </h3>
                                            <p>
                                                {job?.recruiter?.company?.companyName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    job?.recruiter?.company?.companyName.substring(
                                                        1
                                                    )}
                                            </p>
                                            <p>
                                                {moment(job.createdAt).format(
                                                    'l'
                                                )}
                                            </p>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <div className="admin-dash-not-found">
                                    <h1>No Jobs Found 🔍</h1>
                                </div>
                            )}
                        </Grid>
                    </div>
                    <div className="create-btns">
                        <div className="create-test">
                            <h1>Skill Test</h1>
                            <p>
                                Get guidance related to your career and personal
                                growth
                            </p>
                            <button onClick={handleTabClick} name="Skill Tests">
                                Create
                            </button>
                        </div>
                        <div className="create-event">
                            <h1>Create Event</h1>
                            <p>
                                Get guidance related to your career and personal
                                growth
                            </p>
                            <button onClick={handleEventModalOpen} name="event">
                                Create
                            </button>
                        </div>
                        <div className="create-event">
                            <h1>Create Blog</h1>
                            <p></p>
                            <button onClick={toggleBlogModal} name="event">
                                Create
                            </button>
                        </div>
                        <div className="create-forum">
                            <h1>Create Forum</h1>
                            <p>Create a forum for engagement</p>
                            <button onClick={handleForumModalOpen} name="event">
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <CreateEventModal
                open={eventModalOpen}
                handleOpen={handleEventModalOpen}
            />
            <Blogcreate
                open={blogModalOpen}
                handleOpen={toggleBlogModal}
                trigger={toggleBlogModal}
            />
            <CreateForumModal
                open={forumModalOpen}
                handleOpen={handleForumModalOpen}
            />
        </section>
    ) : (
        <AdminJobDetails jobId={currJob} closeJob={() => setJobIsOpen(false)} />
    );
}

export default AdminDashboard;
