import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userType: '',
    user: {},
    token: '',
};

export const authStatus = createSlice({
    name: 'authStatus',
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            localStorage.setItem('token', action.payload);
            state.token = action.payload;
        },
        setUser: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload));
            state.user = action.payload;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
            localStorage.setItem('usertype', state.userType);
        },
        logout: (state) => {
            state.token = '';
            state.user = {};
            state.userType = '';
            state.admin = {};
        },
        adminLoginPending: (state) => {
            state.loading = true;
        },
        adminLoginSuccess: (state, action) => {
            state.loading = false;
            localStorage.setItem('token', action.payload.token);
            state.token = action.payload.token;
            state.admin = action.payload.user;
            state.isAdmin = action.payload.user.isAdmin;
        },
        adminLoginFail: (state) => {
            state.loading = false;
            state.admin = {};
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setToken,
    setUser,
    setUserType,
    adminLoginPending,
    adminLoginSuccess,
    adminLoginFail,
    logout,
} = authStatus.actions;

export default authStatus.reducer;
