import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    profilePic: '',
    fullName: '',
    mobileNumber: '',
    state: '',
    city: '',
    college: '',
    email: '',
    lookingFor: [],
    jobTypes: [],
    preferredLocations: ['', ''],
    fields: ['', ''],
    minimumCTC: '',
    skills: [],
    workExperince: '0-2',
    pastExperiences: '',
    academicProjects: null,
    projects: [],
    tabValue: 0,
};

export const profileEdit = createSlice({
    name: 'profileEdit',
    initialState,
    reducers: {
        updateTab: (state, action) => {
            state.tabValue = action.payload;
        },
        updateValue: (state, action) => {
            state[action.payload.field] = action.payload.newValue;
        },
        sectionComplete: (state, action) => {
            switch (action.payload) {
                case 0:
                    return state.profilePic &&
                        state.fullName &&
                        state.mobileNumber &&
                        state.state &&
                        state.city &&
                        state.college &&
                        state.email
                        ? true
                        : false;

                case 1:
                    return state.profilePic &&
                        state.fullName &&
                        state.mobileNumber &&
                        state.state &&
                        state.city &&
                        state.college &&
                        state.email
                        ? true
                        : false;

                case 2:
                    return state.skills &&
                        state.workExperince &&
                        state.pastExperiences
                        ? true
                        : false;
                case 3:
                    return state.academicProjects && state.projects
                        ? true
                        : false;
                default:
                    return false;
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { updateTab, updateValue, sectionComplete } = profileEdit.actions;

export default profileEdit.reducer;
