export const constants = {
    passwordRegex: /^[a-zA-Z0-9`!@#$%^&*()-/:'.,{}_"~]{8,50}$/, // 8-50 characters,
};

export const listOfLocations = [
    'Location Option 1',
    'Location Option 2',
    'Location Option 3',
    'Location Option 4',
    'Location Option 5',
    'Location Option 6',
    'Location Option 7',
    'Location Option 8',
    'Location Option 9',
    'Location Option 10',
];

export const listOfFields = [
    'Field Option 1',
    'Field Option 2',
    'Field Option 3',
    'Field Option 4',
    'Field Option 5',
    'Field Option 6',
    'Field Option 7',
    'Field Option 8',
    'Field Option 9',
    'Field Option 10',
];
