import React from 'react';
import { Ccards, Chead } from './HomeComponents';
// import Icon1 from '../../assets/illustrations/upskill_icon.jpeg'
import Icon2 from '../../assets/illustrations/mentorship_icon.png';
import Icon3 from '../../assets/illustrations/job-opportunity_icon.png';
import Icon4 from '../../assets/illustrations/live-project_icon.jpeg';
import categoryIcon from '../../assets/icons/category-2.svg';
import bullseyeIcon from '../../assets/icons/bullseye-2.svg';

export const Cards = () => {
    return (
        <>
            <Chead>Join our community to experience ...</Chead>
            <Ccards>
                <div>
                    <i className="fa-solid fa-share-nodes"></i>
                    {/* <img src={Icon1} alt="Icon"/> */}
                    <h1>Networking</h1>
                    <p>
                        Build a strong network of industry experts and like
                        minded people.
                    </p>
                </div>
                <div>
                    <img src={categoryIcon} alt="category" />
                    {/* <img src={Icon2} alt="Icon"/> */}
                    <h1>Events and Competitions</h1>
                    <p>
                        Become a part of exclusive events. Participate in
                        various competitions to win exciting prizes.
                    </p>
                </div>
                <div>
                    <img src={bullseyeIcon} alt="bullseye" />
                    {/* <img src={Icon3} alt="Icon"/> */}
                    <h1>2 Year Headstart of career</h1>
                    <p>
                        Learn all practical skills and standout of the crowd
                        through participating in various live projects and
                        events.
                    </p>
                </div>
                <div>
                    <i className="fa-solid fa-users"></i>
                    {/* <img src={Icon4} alt="Icon"/> */}
                    <h1>Community-based learning</h1>
                    <p>
                        Go beyond traditional learning with peer led engagement
                        to help each other grow.
                    </p>
                </div>
            </Ccards>
        </>
    );
};
