import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';
import axios from 'axios';
import moment from 'moment';
import {
    Ribbon,
    Left,
    Right,
    Title,
    Date,
    HiringP,
    AFRibbon,
    AFRLeft,
    AFRRight,
    LeftMost,
    Middle,
    RightMost,
    RMHead,
    RMHL,
    RMHR,
    RMState1,
    Desc,
    Container,
    DefineButton,
    DB1,
    DB2,
    DB4,
    DB3,
    Openfilter,
    OPHead,
    OPSet,
    OPClear,
    MSet1,
    MSTitle,
    MSTL,
    MSBody,
    MSBT,
    MSBB,
    BRemove,
    BShortlist,
    BResume,
} from '../components/Hiring Panel/HiringP';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DescriptionIcon from '@mui/icons-material/Description';
import GridViewIcon from '@mui/icons-material/GridView';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useInputState from '../hooks/useInputState';
import '../components/Hiring Panel/Hiring.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Config from '../config.json';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none',
    borderRadius: '15px',
    outline: 'none',
};

const HiringPanel = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState('show');
    const [noshow, setNoshow] = useState('noshow');
    const [card1, setCard1] = useState('nohaze');
    const [card2, setCard2] = useState('haze');
    const [card3, setCard3] = useState('haze');
    const [card4, setCard4] = useState('haze');
    const [currentTab, setCurrentTab] = useState('applicants');
    const [filter, setFilter] = useState('noshow');
    const [container, setContainer] = useState('show');
    const [candidateList, setCandidateList] = useState([]);
    const [interviewChoosed, setInterviewChoosed] = useState(false);
    const [interviewModalOpen, setInterviewModalOpen] = useState(false);
    const [candidateActionData, setCandidateActionData] = useState([]);
    const [interviewDate, setInterviewDate, resetInterviewDate] =
        useInputState('');
    const [interviewTime, setInterviewTime, resetInterviewTime] =
        useInputState('');
    const [interviewDuration, setInterviewDuration, resetInterviewDuration] =
        useInputState('');
    const [
        interviewDurationType,
        setInterviewDurationType,
        resetInterviewDurationType,
    ] = useInputState('');

    const handleInterviewClose = () => {
        resetInterviewDate();
        resetInterviewTime();
    };

    const [userInterviewModalOpen, setUserInterviewModalOpen] = useState(false);
    const [userInterviewDate, setUserInterviewDate, resetUserInterviewDate] =
        useInputState('');
    const [userInterviewTime, setUserInterviewTime, resetUserInterviewTime] =
        useInputState('');
    const [
        userInterviewDuration,
        setUserInterviewDuration,
        resetUserInterviewDuration,
    ] = useInputState('');
    const [
        userInterviewDurationType,
        setUserInterviewDurationType,
        resetUserInterviewDurationType,
    ] = useInputState('');
    const [userInterviewEmail, setUserInterviewEmail, resetUserInterviewEmail] =
        useInputState('');
    const [currUserInterviewId, setCurrUserInterviewId] = useState('');
    const [currInterviewRound, setCurrInterviewRound] = useState(1);
    const [resumeModalOpen, setResumeModalOpen] = useState(false);
    const [currResume, setCurrResume] = useState('');

    const handleUserInterviewOpen = (e) => {
        if (e.currentTarget.getAttribute('name') === 'yes') {
            dispatch(
                handleSnackOpen({
                    message: 'Interview Already scheduled',
                    variant: 'warning',
                })
            );
            return;
        }
        setCurrUserInterviewId(e.currentTarget.getAttribute('value'));
        if (e.currentTarget.getAttribute('name') === 'round-2') {
            setCurrInterviewRound(2);
        }
        setUserInterviewModalOpen(true);
    };
    const handleUserInterviewClose = () => {
        resetUserInterviewDate();
        resetUserInterviewTime();
        setCurrUserInterviewId('');
        setCurrUserInterviewId(1);
        setUserInterviewModalOpen(false);
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post(
                `/api/rdashboard/jobs/${params.id}`,
                { candidateType: 'all' },
                config
            )
            .then((res) => {
                if (res.data.jobData) {
                    setJobData(res.data.jobData);
                    updateUsers(res.data.jobData);
                }
            })
            .catch((err) => console.log(err));
    };

    const updateUsers = (data) => {
        switch (currentTab) {
            case 'applicants':
                return setCandidateList(data.applicants);
            case 'shortlisted':
                return setCandidateList(data.shortlisted);
            case 'hired':
                return setCandidateList(data.hired);
            case 'waitlisted':
                return setCandidateList(data.waitlisted);
        }
    };

    const handleWaitlist = (e) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            jobId: jobData._id,
            action: 'waitlist',
            applicantId: e.currentTarget.getAttribute('value'),
        };
        axios
            .put('/api/rdashboard/jobs/actions', payload, config)
            .then((res) => {
                dispatch(
                    handleSnackOpen({
                        message: res.data.msg,
                        variant: 'success',
                    })
                );
                setJobData(res.data.job);
                updateUsers(res.data.job);
            })
            .catch((err) => console.log(err));
    };

    const handleInterviewSubmit = (e) => {
        if (
            (interviewDate &&
                interviewTime &&
                interviewDuration &&
                interviewDurationType) !== ''
        ) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };

            const payload = {
                jobId: jobData._id,
                interviewDate,
                interviewTime,
                interviewDuration: `${interviewDuration} ${interviewDurationType}`,
            };

            axios
                .put('/api/rdashboard/set-interview', payload, config)
                .then((res) => {
                    if (res.data.jobId) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );
                        setJobData(res.data.job);
                        handleInterviewClose();
                        const config = {
                            headers: {
                                'x-auth-token': localStorage.getItem('token'),
                            },
                        };
                        const payload = candidateActionData;
                        axios
                            .put(
                                '/api/rdashboard/jobs/actions',
                                payload,
                                config
                            )
                            .then((res) => {
                                if (res.data.job) {
                                    setJobData(res.data.job);
                                    switch (currentTab) {
                                        case 'applicants':
                                            return setCandidateList(
                                                res.data.job.applicants
                                            );
                                        case 'shortlisted':
                                            return setCandidateList(
                                                res.data.job.shortlisted
                                            );
                                        case 'hired':
                                            return setCandidateList(
                                                res.data.job.hired
                                            );
                                        case 'waitlisted':
                                            return setCandidateList(
                                                res.data.job.waitlisted
                                            );
                                    }
                                    setCandidateActionData({});
                                    dispatch(
                                        handleSnackOpen({
                                            message: res.data.msg,
                                            variant: 'success',
                                        })
                                    );
                                }
                            })
                            .catch((err) => console.log(err));
                    } else {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'error',
                            })
                        );
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter/Choose all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [displayPageNav, setDisplayPageNav] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const changePage = (offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    const showPageNav = (e) => {
        setDisplayPageNav(true);
    };

    const hidePageNav = (e) => {
        setDisplayPageNav(false);
    };

    const handleUserInterviewSubmit = (e) => {
        if (
            (userInterviewDate &&
                userInterviewTime &&
                userInterviewDuration &&
                userInterviewEmail &&
                userInterviewDurationType) !== ''
        ) {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };

            const payload = {
                jobId: jobData._id,
                action: 'interview',
                applicantId: currUserInterviewId,
                interviewDate: userInterviewDate,
                interviewTime: userInterviewTime,
                interviewEmail: userInterviewEmail,
                interviewDuration: `${userInterviewDuration} ${userInterviewDurationType}`,
            };
            if (currInterviewRound === 2) payload.round = 2;
            axios
                .put('/api/rdashboard/jobs/actions', payload, config)
                .then((res) => {
                    if (res.data.userId) {
                        dispatch(
                            handleSnackOpen({
                                message: res.data.msg,
                                variant: 'success',
                            })
                        );

                        handleUserInterviewClose();
                    }
                })
                .catch((err) => console.log(err));
        } else {
            dispatch(
                handleSnackOpen({
                    message: 'Enter/Choose all fields!',
                    variant: 'warning',
                })
            );
        }
    };

    function oncard1() {
        setCard1('nohaze');
        setCard2('haze');
        setCard3('haze');
        setCard4('haze');
        setCandidateList(jobData.applicants);
        setCurrentTab('applicants');
    }

    function oncard2() {
        setCard1('haze');
        setCard2('nohaze');
        setCard3('haze');
        setCard4('haze');
        setCandidateList(jobData.shortlisted);
        setCurrentTab('shortlisted');
    }

    function oncard3() {
        setCard1('haze');
        setCard2('haze');
        setCard3('nohaze');
        setCard4('haze');
        setCandidateList(jobData.hired);
        setCurrentTab('hired');
    }

    function oncard4() {
        setCard1('haze');
        setCard2('haze');
        setCard3('haze');
        setCard4('nohaze');
        setCandidateList(jobData.waitlisted);
        setCurrentTab('waitlisted');
    }

    function handlePhoneTabChange(e) {
        //console.log(e.currentTarget.getAttribute('name'));
        switch (e.target.value) {
            case 'applicants':
                oncard1();
                return;
            case 'shortlisted':
                oncard2(e);
                return;
            case 'hired':
                oncard3(e);
                return;
            case 'waitlisted':
                oncard4(e);
                return;
        }
    }

    function nextdata() {
        setShow('noshow');
        setNoshow('show');
    }

    function prevdata() {
        setShow('show');
        setNoshow('noshow');
    }

    function changefilter1() {
        setFilter('show');
        setContainer('noshow');
    }
    function changefilter2() {
        setFilter('noshow');
        setContainer('show');
    }

    const handleResumeModalOpen = (e) => {
        setResumeModalOpen(true);
        setCurrResume(e.target.getAttribute('value'));
    };
    const handleResumeModalClose = (e) => {
        setResumeModalOpen(false);
        setCurrResume('');
    };

    const handleActions = (e) => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        const payload = {
            applicantId: e.currentTarget.getAttribute('value'),
            jobId: jobData._id,
            action: e.currentTarget.getAttribute('name'),
        };
        axios
            .put('/api/rdashboard/jobs/actions', payload, config)
            .then((res) => {
                if (res.data.job) {
                    setJobData(res.data.job);
                    switch (currentTab) {
                        case 'applicants':
                            return setCandidateList(res.data.job.applicants);
                        case 'shortlisted':
                            return setCandidateList(res.data.job.shortlisted);
                        case 'hired':
                            return setCandidateList(res.data.job.hired);
                        case 'waitlisted':
                            return setCandidateList(res.data.job.waitlisted);
                    }

                    dispatch(
                        handleSnackOpen({
                            message: res.data.msg,
                            variant: 'success',
                        })
                    );
                }
            })
            .catch((err) => console.log(err));
    };

    const [jobData, setJobData] = useState({});

    useEffect(() => {
        const config = {
            headers: { 'x-auth-token': localStorage.getItem('token') },
        };
        axios
            .post(
                `/api/rdashboard/jobs/${params.id}`,
                { candidateType: 'all' },
                config
            )
            .then((res) => {
                if (res.data.jobData) {
                    setJobData(res.data.jobData);
                    setCandidateList(res.data.jobData.applicants);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        setInterviewChoosed(
            jobData && jobData.interviewDate && jobData.interviewDate !== ''
        );
    }, [jobData]);

    return (
        <>
            <div className="filler-div"></div>
            <Ribbon>
                <Left>
                    <Title>
                        {jobData && jobData.jobTitle ? jobData.jobTitle : 'N/A'}
                        ,{' '}
                        {jobData && jobData.createdAt
                            ? moment(jobData.createdAt).format('YYYY')
                            : '-'}
                    </Title>
                    <Date>
                        {jobData && jobData.createdAt
                            ? moment(jobData.createdAt).format('DD/MM/YYYY')
                            : '-/-/-'}
                    </Date>
                    {/* <Date>
                        Interview Request:{' '}
                        {jobData && !jobData.interviewDate ? (
                            'Not Requested'
                        ) : jobData &&
                          jobData.interviewDate &&
                          !jobData.interviewLink ? (
                            'Processing...'
                        ) : (
                            <a
                                href={jobData.interviewLink}
                                target="_blank"
                                className="link"
                            >
                                {jobData.interviewLink}
                            </a>
                        )}
                    </Date> */}
                </Left>
                <Right>
                    <HiringP>
                        <button onClick={() => navigate(`/job/${jobData._id}`)}>
                            <DescriptionIcon />
                            Hiring Panel
                        </button>
                    </HiringP>
                </Right>
            </Ribbon>

            <AFRibbon>
                <AFRLeft>
                    <select onChange={handlePhoneTabChange}>
                        <option value={'applicants'}>
                            Application Received (
                            {jobData && jobData.applicants
                                ? jobData.applicants.length
                                : 0}
                            )
                        </option>
                        <option value={'shortlisted'}>
                            Round 2 Candidates (
                            {jobData && jobData.shortlisted
                                ? jobData.shortlisted.length
                                : 0}
                            )
                        </option>
                        <option value={'hired'}>
                            Hired Candidates (
                            {jobData && jobData.hired
                                ? jobData.hired.length
                                : 0}
                            )
                        </option>
                        <option value={'waitlisted'}>
                            Waitlisted Candidates (
                            {jobData && jobData.waitlisted
                                ? jobData.waitlisted.length
                                : 0}
                            )
                        </option>
                    </select>
                </AFRLeft>
                {/* <AFRRight onClick={changefilter1}>Filter</AFRRight> */}
            </AFRibbon>

            {/* <Openfilter className={filter}>
                <OPHead>
                    <h1>Filter Candidates</h1>
                    <i onClick={changefilter2}>
                        <CloseIcon />
                    </i>
                </OPHead>
                <OPSet>
                    <h1>Degree</h1>
                    <input type="text" placeholder="Eg: MBA" />
                </OPSet>
                <OPSet>
                    <h1>Skill Test Score</h1>
                    <input type="text" placeholder="Eg: 350" />
                </OPSet>
                <OPSet>
                    <h1>Skills</h1>
                    <input type="text" placeholder="Eg: Java" />
                </OPSet>
                <OPSet>
                    <h1>Graduation Year</h1>
                    <input type="text" placeholder="Eg: 2022" />
                </OPSet>
                <OPClear>Clear All</OPClear>
            </Openfilter> */}

            <Container>
                <LeftMost className={container}>
                    <Desc className={card1} onClick={oncard1}>
                        Applications Received{' '}
                        <span>
                            (
                            {jobData && jobData.applicants
                                ? jobData.applicants.length
                                : 0}
                            )
                        </span>
                    </Desc>
                    <Desc className={card2} onClick={oncard2}>
                        Round 2 Candidates{' '}
                        <span>
                            (
                            {jobData && jobData.shortlisted
                                ? jobData.shortlisted.length
                                : 0}
                            )
                        </span>
                    </Desc>
                    <Desc className={card3} onClick={oncard3}>
                        Hired Candidates{' '}
                        <span>
                            (
                            {jobData && jobData.hired
                                ? jobData.hired.length
                                : 0}
                            )
                        </span>
                    </Desc>
                    <Desc className={card4} onClick={oncard4}>
                        Waitlisted Candidates{' '}
                        <span>
                            (
                            {jobData && jobData.waitlisted
                                ? jobData.waitlisted.length
                                : 0}
                            )
                        </span>
                    </Desc>
                </LeftMost>

                <Middle className={container}>
                    <div className={show}>
                        <DefineButton>
                            <DB1>
                                <DescriptionOutlinedIcon />
                            </DB1>
                            <DB4 onClick={nextdata}>
                                <GridViewIcon />
                            </DB4>
                        </DefineButton>
                    </div>
                    <div className={noshow}>
                        <DefineButton>
                            <DB3 onClick={prevdata}>
                                <DescriptionOutlinedIcon />
                            </DB3>
                            <DB2>
                                <GridViewIcon />
                            </DB2>
                        </DefineButton>
                    </div>

                    <div className={show}>
                        {candidateList && candidateList.length > 0 ? (
                            candidateList.map((user) => {
                                let isHired =
                                    currentTab !== 'hired' &&
                                    jobData.hired.find(
                                        (candidate) =>
                                            candidate._id === user._id
                                    );
                                let interviewScheduled =
                                    user && user.interviews
                                        ? user.interviews.find(
                                              (interview) =>
                                                  interview.job === jobData._id
                                          )
                                        : false;
                                let interviewSet =
                                    user && user.interviews
                                        ? user.interviews.find((interview) => {
                                              if (
                                                  interview.job === jobData._id
                                              ) {
                                                  return (
                                                      interview.interviewLink &&
                                                      interview.interviewLink !==
                                                          ''
                                                  );
                                              }
                                          })
                                        : false;

                                // let interviewLink = user && user.interviews ? user.interviews.find(interview => interview.job === jobData._id).interviewLink : '';
                                let foundInterview =
                                    user && user.interviews
                                        ? user.interviews.find(
                                              (interview) =>
                                                  interview.job === jobData._id
                                          )
                                        : { interviewRound: 1 };
                                return (
                                    <MSet1 key={user._id}>
                                        <MSTitle>
                                            <MSTL>
                                                <h1>
                                                    {user.fullName
                                                        ? user.fullName
                                                        : 'N/A'}
                                                </h1>
                                                <span>
                                                    {user.state
                                                        ? user.state
                                                        : 'N/A'}
                                                </span>
                                            </MSTL>
                                            {/* <MSTR>
                                                Skill Test Score : <span>50</span>
                                            </MSTR> */}
                                        </MSTitle>
                                        <MSBody>
                                            <MSBT>Education</MSBT>
                                            <MSBB>
                                                {user.college &&
                                                user.college.degree
                                                    ? user.college.degree
                                                    : 'N/A'}
                                                , (
                                                {user.college &&
                                                user.college.startingYear
                                                    ? user.college.startingYear
                                                    : ''}
                                                -
                                                {user.college &&
                                                user.college.graduationYear
                                                    ? user.college
                                                          .graduationYear
                                                    : ''}
                                                )
                                            </MSBB>
                                        </MSBody>
                                        <MSBody>
                                            <MSBT>
                                                Experience (
                                                {user.experience &&
                                                user.experience.workExperience
                                                    ? user.experience
                                                          .workExperience
                                                    : '0'}{' '}
                                                )
                                            </MSBT>
                                        </MSBody>
                                        <MSBody>
                                            <MSBT>Skills</MSBT>
                                            <MSBB>
                                                {user.experience &&
                                                user.experience.skills.length >
                                                    0
                                                    ? user.experience.skills.map(
                                                          (s, idx) => (
                                                              <React.Fragment
                                                                  key={idx}
                                                              >
                                                                  {s.skill} (
                                                                  {s.level})
                                                                  <br />
                                                              </React.Fragment>
                                                          )
                                                      )
                                                    : 'N/A'}
                                            </MSBB>
                                        </MSBody>
                                        <MSBody>
                                            <MSBT>Projects</MSBT>
                                            <MSBB>
                                                {user.projects &&
                                                user.projects.length > 0
                                                    ? user.projects.map(
                                                          (project) => (
                                                              <React.Fragment
                                                                  key={
                                                                      project._id
                                                                  }
                                                              >
                                                                  <a
                                                                      href={
                                                                          project.link
                                                                      }
                                                                      target="_blank"
                                                                  >
                                                                      {
                                                                          project.title
                                                                      }
                                                                  </a>
                                                                  <br />
                                                              </React.Fragment>
                                                          )
                                                      )
                                                    : 'No projects'}
                                            </MSBB>
                                        </MSBody>
                                        <MSBody>
                                            <MSBT>Interview Link</MSBT>
                                            <MSBB>
                                                {interviewSet ? (
                                                    <a
                                                        href={
                                                            foundInterview.interviewLink
                                                        }
                                                    >
                                                        {
                                                            foundInterview.interviewLink
                                                        }
                                                    </a>
                                                ) : interviewScheduled ? (
                                                    'Processing...'
                                                ) : (
                                                    'Not Requested'
                                                )}
                                            </MSBB>
                                        </MSBody>
                                        <MSBody>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                {currentTab === 'applicants' ? (
                                                    <BShortlist
                                                        onClick={handleActions}
                                                        value={user._id}
                                                        name="shortlist"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Shortlist
                                                    </BShortlist>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab !== 'hired' &&
                                                currentTab !== 'applicants' &&
                                                currentTab !== 'waitlisted' &&
                                                interviewSet ? (
                                                    <BShortlist
                                                        onClick={handleActions}
                                                        value={user._id}
                                                        name="hire"
                                                        disabled={isHired}
                                                        style={{
                                                            cursor: isHired
                                                                ? 'not-allowed'
                                                                : 'pointer',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        {isHired
                                                            ? 'Hired'
                                                            : 'Hire'}
                                                    </BShortlist>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab === 'waitlisted' ? (
                                                    <BShortlist
                                                        onClick={handleActions}
                                                        value={user._id}
                                                        name="hire"
                                                        disabled={isHired}
                                                        style={{
                                                            cursor: isHired
                                                                ? 'not-allowed'
                                                                : 'pointer',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        {isHired
                                                            ? 'Hired'
                                                            : 'Hire'}
                                                    </BShortlist>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab === 'applicants' ? (
                                                    <>
                                                        <BShortlist
                                                            onClick={
                                                                handleUserInterviewOpen
                                                            }
                                                            value={user._id}
                                                            disabled={
                                                                interviewScheduled
                                                            }
                                                            name={
                                                                interviewScheduled
                                                                    ? 'yes'
                                                                    : 'no'
                                                            }
                                                            style={{
                                                                cursor: interviewScheduled
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                                marginRight:
                                                                    '10px',
                                                                // marginLeft: '15px'
                                                            }}
                                                        >
                                                            {interviewScheduled &&
                                                            foundInterview.interviewLink &&
                                                            foundInterview.interviewLink !==
                                                                ''
                                                                ? 'Interview Scheduled'
                                                                : interviewScheduled &&
                                                                  (!foundInterview.interviewLink ||
                                                                      foundInterview.interviewLink ===
                                                                          '')
                                                                ? 'Interview Requested'
                                                                : 'Schedule Interview'}
                                                        </BShortlist>
                                                        <BShortlist
                                                            onClick={
                                                                handleWaitlist
                                                            }
                                                            value={user._id}
                                                            //   disabled={
                                                            //       interviewScheduled
                                                            //   }
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginRight:
                                                                    '10px',
                                                                background:
                                                                    '#F08302',
                                                                // marginLeft: '15px'
                                                            }}
                                                        >
                                                            Waitlist
                                                        </BShortlist>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab === 'waitlisted' ? (
                                                    <>
                                                        <BShortlist
                                                            onClick={
                                                                handleUserInterviewOpen
                                                            }
                                                            value={user._id}
                                                            disabled={
                                                                interviewScheduled
                                                            }
                                                            name={
                                                                interviewScheduled
                                                                    ? 'yes'
                                                                    : 'no'
                                                            }
                                                            style={{
                                                                cursor: interviewScheduled
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                                marginRight:
                                                                    '10px',
                                                                // marginLeft: '15px'
                                                            }}
                                                        >
                                                            {interviewScheduled &&
                                                            foundInterview.interviewLink &&
                                                            foundInterview.interviewLink !==
                                                                ''
                                                                ? 'Interview Scheduled'
                                                                : interviewScheduled &&
                                                                  (!foundInterview.interviewLink ||
                                                                      foundInterview.interviewLink ===
                                                                          '')
                                                                ? 'Interview Requested'
                                                                : 'Schedule Interview'}
                                                        </BShortlist>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab ===
                                                'shortlisted' ? (
                                                    <>
                                                        <BShortlist
                                                            onClick={
                                                                handleUserInterviewOpen
                                                            }
                                                            value={user._id}
                                                            disabled={
                                                                interviewScheduled
                                                            }
                                                            name={
                                                                interviewScheduled
                                                                    ? 'yes'
                                                                    : 'no'
                                                            }
                                                            style={{
                                                                cursor: interviewScheduled
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                                marginRight:
                                                                    '10px',
                                                                // marginLeft: '15px'
                                                            }}
                                                        >
                                                            {interviewScheduled &&
                                                            foundInterview.interviewLink &&
                                                            foundInterview.interviewLink !==
                                                                ''
                                                                ? foundInterview &&
                                                                  foundInterview.interviewRound ===
                                                                      2
                                                                    ? 'Round 2 Interview Scheduled'
                                                                    : 'Interview Scheduled'
                                                                : interviewScheduled &&
                                                                  (!foundInterview.interviewLink ||
                                                                      foundInterview.interviewLink ===
                                                                          '')
                                                                ? foundInterview &&
                                                                  foundInterview.interviewRound ===
                                                                      2
                                                                    ? 'Round 2 Interview Requested'
                                                                    : 'Interview requested'
                                                                : 'Schedule Interview'}
                                                        </BShortlist>
                                                        <BShortlist
                                                            onClick={
                                                                handleWaitlist
                                                            }
                                                            value={user._id}
                                                            //   disabled={
                                                            //       interviewScheduled
                                                            //   }
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginRight:
                                                                    '10px',
                                                                background:
                                                                    '#F08302',
                                                                // marginLeft: '15px'
                                                            }}
                                                        >
                                                            Waitlist
                                                        </BShortlist>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {/* {
                                                      currentTab === 'shortlisted' && interviewSet && foundInterview ? (
                                                          <BShortlist
                                                            disabled={foundInterview && foundInterview.interviewRound === 2}
                                                            onClick={handleUserInterviewOpen}
                                                            value={user._id}
                                                            name="round-2"
                                                            style={{
                                                                cursor: foundInterview && foundInterview.interviewRound === 2
                                                                    ? 'not-allowed'
                                                                    : 'pointer',
                                                                    marginRight: '10px'
                                                                // marginLeft: '15px'
                                                            }}
                                                          >
                                                              {
                                                                  foundInterview && foundInterview.interviewRound === 1
                                                                  ? 'Schedule Round 2'
                                                                  : 'Round 2 requested'
                                                              }
                                                          </BShortlist>
                                                      )
                                                      : ('')
                                                  } */}
                                            </div>
                                        </MSBody>
                                    </MSet1>
                                );
                            })
                        ) : (
                            <p style={{ textAlign: 'center' }}>
                                No applicants for this job post.
                            </p>
                        )}
                    </div>
                    <div className={noshow}>
                        <table>
                            <tr className="mainheadd">
                                <th>
                                    <input
                                        type="checkbox"
                                        name="listview"
                                        className="chbx"
                                    />
                                </th>
                                <th>Name</th>
                                <th>Mobile No/Unique ID</th>
                                {/* <th>Passing Year</th> */}
                                <th>Resume</th>
                                <th>Remark</th>
                                <th>Rating</th>
                                {currentTab !== 'waitlisted' && (
                                    <th>Shortlist</th>
                                )}
                                {currentTab === 'shortlisted' ||
                                currentTab === 'applicants' ? (
                                    <th>Interview</th>
                                ) : (
                                    ''
                                )}
                                {currentTab === 'shortlisted' ||
                                currentTab === 'applicants' ? (
                                    <th>Waitlist</th>
                                ) : (
                                    ''
                                )}

                                {currentTab === 'waitlisted' ? (
                                    <th>Hire</th>
                                ) : (
                                    ''
                                )}
                                {currentTab === 'waitlisted' ? (
                                    <th>Interview</th>
                                ) : (
                                    ''
                                )}
                                {currentTab === 'applicants' ? (
                                    <th>Reject</th>
                                ) : (
                                    ''
                                )}
                            </tr>
                            {candidateList && candidateList.length > 0 ? (
                                candidateList.map((user) => {
                                    let isHired =
                                        currentTab !== 'hired' &&
                                        jobData.hired.find(
                                            (candidate) =>
                                                candidate._id === user._id
                                        );
                                    let interviewScheduled =
                                        user && user.interviews
                                            ? user.interviews.find(
                                                  (interview) =>
                                                      interview.job ===
                                                      jobData._id
                                              )
                                            : true;
                                    if (!user.interviews)
                                        interviewScheduled = false;
                                    let foundRating =
                                        user.ratings && user.ratings.length > 0
                                            ? user.ratings.find(
                                                  (r) => r.job === jobData._id
                                              )
                                            : null;
                                    return (
                                        <tr key={user._id}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name="listview"
                                                    className="chbx"
                                                />
                                            </td>
                                            <td>
                                                <div>
                                                    {user.fullName
                                                        ? user.fullName
                                                        : 'N/A'}
                                                    {/* <span>
                                                          {user.fullName
                                                              ? user.fullName
                                                              : 'N/A'}
                                                      </span>
                                                      <p>
                                                          {user.mobileNo
                                                              ? user.mobileNo
                                                              : 'N/A'}
                                                      </p> */}
                                                </div>
                                            </td>
                                            <td>
                                                {user.mobileNo
                                                    ? user.mobileNo
                                                    : 'N/A'}
                                            </td>
                                            {/* <td>
                                                  {user.college &&
                                                  user.college.graduationYear
                                                      ? user.college
                                                            .graduationYear
                                                      : 'N/A'}
                                              </td> */}
                                            <td>
                                                <BResume>
                                                    {user.resume &&
                                                    user.resume
                                                        .resumeS3FileKey ? (
                                                        <a
                                                            onClick={
                                                                handleResumeModalOpen
                                                            }
                                                            value={
                                                                user.resume
                                                                    .resumeS3FileKey
                                                            }
                                                            target="_blank"
                                                        >
                                                            Resume
                                                        </a>
                                                    ) : (
                                                        'No Resume'
                                                    )}
                                                </BResume>
                                            </td>
                                            <td>
                                                {foundRating
                                                    ? foundRating.comment
                                                          .length > 50
                                                        ? `${foundRating.comment.substring(
                                                              0,
                                                              50
                                                          )}...`
                                                        : foundRating.comment
                                                    : 'No Remarks'}
                                            </td>
                                            <td>
                                                {foundRating
                                                    ? `${foundRating.rating}/5`
                                                    : '0/5'}
                                            </td>
                                            <td>
                                                {/* <BShortlist
                                                  onClick={handleActions}
                                                  value={user._id}
                                                  name="shortlist"
                                              >
                                                  Shortlist
                                              </BShortlist> */}
                                                {currentTab === 'applicants' ? (
                                                    <BShortlist
                                                        onClick={handleActions}
                                                        value={user._id}
                                                        name="shortlist"
                                                        style={{
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Shortlist
                                                    </BShortlist>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab ===
                                                'shortlisted' ? (
                                                    <BShortlist
                                                        onClick={handleActions}
                                                        value={user._id}
                                                        name="hire"
                                                        disabled={isHired}
                                                        style={{
                                                            cursor: isHired
                                                                ? 'not-allowed'
                                                                : 'pointer',
                                                        }}
                                                    >
                                                        {isHired
                                                            ? 'Hired'
                                                            : 'Hire'}
                                                    </BShortlist>
                                                ) : (
                                                    ''
                                                )}
                                                {currentTab === 'waitlisted' ? (
                                                    <BShortlist
                                                        onClick={handleActions}
                                                        value={user._id}
                                                        name="hire"
                                                        disabled={isHired}
                                                        style={{
                                                            cursor: isHired
                                                                ? 'not-allowed'
                                                                : 'pointer',
                                                        }}
                                                    >
                                                        {isHired
                                                            ? 'Hired'
                                                            : 'Hire'}
                                                    </BShortlist>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            {currentTab === 'shortlisted' ||
                                            currentTab === 'waitlisted' ||
                                            currentTab === 'applicants' ? (
                                                <td>
                                                    <BShortlist
                                                        onClick={
                                                            handleUserInterviewOpen
                                                        }
                                                        value={user._id}
                                                        disabled={
                                                            interviewScheduled
                                                        }
                                                        name={
                                                            interviewScheduled
                                                                ? 'yes'
                                                                : 'no'
                                                        }
                                                        style={{
                                                            cursor: interviewScheduled
                                                                ? 'not-allowed'
                                                                : 'pointer',
                                                            marginLeft: '15px',
                                                        }}
                                                    >
                                                        {interviewScheduled
                                                            ? 'Interview requested'
                                                            : 'Schedule Interview'}
                                                    </BShortlist>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            {currentTab === 'shortlisted' ||
                                            currentTab === 'applicants' ? (
                                                <td>
                                                    <BShortlist
                                                        onClick={handleWaitlist}
                                                        value={user._id}
                                                        //   disabled={
                                                        //       interviewScheduled
                                                        //   }
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginLeft: '15px',
                                                            background:
                                                                '#F08302',
                                                        }}
                                                    >
                                                        Waitlist
                                                    </BShortlist>
                                                </td>
                                            ) : (
                                                <></>
                                            )}
                                            <td>
                                                <BRemove
                                                    onClick={handleActions}
                                                    value={user._id}
                                                    name="remove"
                                                >
                                                    Reject
                                                </BRemove>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <p style={{ textAlign: 'center' }}>
                                    No applicants for this job post.
                                </p>
                            )}
                        </table>
                    </div>
                </Middle>
                {/* <RightMost>
                    <RMHead>
                        <RMHL>Filter Candidates</RMHL>
                        <RMHR>Clear All</RMHR>
                    </RMHead>
                    <RMState1>
                        <h1>Degree</h1>
                        <input type="text" placeholder="Eg: MBA" />
                    </RMState1>
                    <RMState1>
                        <h1>Skill Test Score</h1>
                        <input type="text" placeholder="Eg: 350" />
                    </RMState1>
                    <RMState1>
                        <h1>Skills</h1>
                        <input type="text" placeholder="Eg: Java" />
                    </RMState1>
                    <RMState1>
                        <h1>Graduation Year</h1>
                        <input type="text" placeholder="Eg: 2022" />
                    </RMState1>
                </RightMost> */}
            </Container>
            {/* <Modal
                open={interviewModalOpen}
                onClose={handleInterviewClose}
                aria-labelledby="interview-choose"
                aria-describedby="choose-interview-date"
            >
                <Box sx={style}>
                    <div className="interview-modal">
                        <h1>Choose Interview</h1>
                        <p>
                            Please choose an interview date and time before
                            shortlisting candidates for this job post:
                        </p>
                        <input
                            type="date"
                            value={interviewDate}
                            onChange={setInterviewDate}
                            id="interview-date"
                        />
                        <input
                            type="time"
                            value={interviewTime}
                            onChange={setInterviewTime}
                            id="interview-time"
                        />
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <input
                                type="number"
                                value={interviewDuration}
                                onChange={setInterviewDuration}
                                style={{
                                    display: 'inline-block',
                                    width: '145px',
                                    margin: 0,
                                    marginRight: '5px',
                                }}
                                placeholder="Enter duration"
                                min={1}
                            />
                            <select
                                onChange={setInterviewDurationType}
                                style={{
                                    display: 'inline',
                                    width: '145px',
                                    margin: 0,
                                }}
                            >
                                <option value="">Choose Type</option>
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                            </select>
                        </span>
                        <button onClick={handleInterviewSubmit}>Submit</button>
                    </div>
                </Box>
            </Modal> */}
            <Modal
                open={userInterviewModalOpen}
                onClose={handleUserInterviewClose}
                aria-labelledby="interview-choose"
                aria-describedby="choose-interview-date"
            >
                <Box sx={style}>
                    <div className="interview-modal">
                        <h1>Choose Interview</h1>
                        <p>
                            Please choose an interview date and time before
                            shortlisting candidates for this job post:
                        </p>
                        <input
                            type="date"
                            value={userInterviewDate}
                            onChange={setUserInterviewDate}
                            id="interview-date"
                        />
                        <input
                            type="time"
                            value={userInterviewTime}
                            onChange={setUserInterviewTime}
                            id="interview-time"
                        />
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '10px',
                            }}
                        >
                            <input
                                type="number"
                                value={userInterviewDuration}
                                onChange={setUserInterviewDuration}
                                style={{
                                    display: 'inline-block',
                                    width: '145px',
                                    margin: 0,
                                    marginRight: '5px',
                                }}
                                placeholder="Enter duration"
                                min={1}
                            />
                            <select
                                onChange={setUserInterviewDurationType}
                                style={{
                                    display: 'inline',
                                    width: '145px',
                                    margin: 0,
                                }}
                            >
                                <option value="">Choose Type</option>
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                            </select>
                        </span>
                        <input
                            type="email"
                            value={userInterviewEmail}
                            onChange={setUserInterviewEmail}
                            placeholder="Enter email Id"
                        />
                        <button onClick={handleUserInterviewSubmit}>
                            Submit
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={resumeModalOpen}
                onClose={handleResumeModalClose}
                aria-labelledby="Resume"
                aria-describedby="View Resume"
                style={{ overflow: 'auto', height: '100%', width: '80vw' }}
            >
                <Box sx={style} style={{ width: '80vw', margin: 'auto' }}>
                    <div className="interview-modal" style={{ width: '75vw' }}>
                        <h1>Resume</h1>
                        <a
                            href={`https://preentrepreneur.s3.ap-south-1.amazonaws.com/${currResume}`}
                        >
                            Download File
                        </a>

                        <div
                            className="resume-pdf-container"
                            onMouseEnter={showPageNav}
                            onMouseLeave={hidePageNav}
                            style={{ marginBottom: '20px', marginTop: '20px' }}
                        >
                            <Document
                                file={`${Config.S3_URL}/${currResume}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                className="resume-pdf"
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            <div
                                className="page-nav"
                                style={{
                                    display: !displayPageNav ? 'none' : '',
                                }}
                            >
                                <button
                                    type="button"
                                    disabled={pageNumber <= 1}
                                    onClick={previousPage}
                                >
                                    <i className="fa-solid fa-chevron-left"></i>
                                </button>
                                <p>
                                    Page {pageNumber || (numPages ? 1 : '--')}{' '}
                                    of {numPages || '--'}
                                </p>
                                <button
                                    type="button"
                                    disabled={pageNumber >= numPages}
                                    onClick={nextPage}
                                >
                                    <i className="fa-solid fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default HiringPanel;
