import React, { useEffect, useState } from 'react';
import './blog.css';
import BlogList from './Blogs/BlogList/BlogList';
import axios from 'axios';

const Blog = () => {
    const config = {
        headers: { 'x-auth-token': localStorage.getItem('token') },
    };

    const [blog, setBlogs] = useState([]);
    const fetchblogs = async () => {
        const { data } = await axios.get('/api/blog/');
        setBlogs(data);
    };
    useEffect(() => {
        fetchblogs();
    });

    // console.log("blog", blog)
    return (
        <section className="blogs">
            <BlogList blogs={blog} />
        </section>
    );
};

export default Blog;
