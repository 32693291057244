import React from 'react';
import './App.css';
import MUIThemeProvider from './context/MuiThemeProvider';

import RoutesProvider from './Routes';
import Toast from './util/Toast';

function App() {
    return (
        <MUIThemeProvider>
            <Toast />
            <RoutesProvider />
        </MUIThemeProvider>
    );
}

export default App;
