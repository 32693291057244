import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { handleSnackOpen } from '../store/Toast/Toast';

function CandidateRoute(props) {
    const dispatch = useDispatch();
    let isCandidate = useSelector(
        (st) =>
            st.authStatus &&
            (st.authStatus.userType === 'user' ||
                st.authStatus.userType === 'candidate')
    );
    if (!isCandidate) {
        isCandidate = localStorage.getItem('usertype') === 'candidate';
    }
    if (!isCandidate) {
        dispatch(
            handleSnackOpen({
                message: "You're unauthorized to access this route!",
                variant: 'warning',
            })
        );
        return <Navigate to="/" />;
    }
    return props.children;
}

export default CandidateRoute;
