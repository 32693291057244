import axios from 'axios';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import EditEventModal from './EditEventModal';

function AdminEventManager(props) {
    const [eventList, setEventList] = useState([]);
    const [editModalOpen, setEditEventModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});

    const getEvents = async () => {
        try {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            const res = await axios.post('/api/events/', {}, config);
            if (res.data.msg) {
                return;
            }
            setEventList(res.data.events);
        } catch (err) {
            setEventList([]);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    const handleEventModalOpen = (e) => {
        setEditEventModal((editState) => !editState);
    };

    const handleEdit = (index) => {
        setEditEventModal(true);
        setSelectedEvent(eventList[index]);
    };

    const handleHide = async (eventId) => {
        try {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            await axios.delete(`/api/events/${eventId}`, config);
            getEvents();
        } catch (err) {
            setEventList([]);
        }
    };

    const handleUnHide = async (eventId) => {
        try {
            const config = {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            };
            await axios.get(`/api/events/unhide/${eventId}`, config);
            getEvents();
        } catch (err) {
            setEventList([]);
        }
    };

    return (
        <div style={{ padding: '2rem', height: '80%', overflow: 'auto' }}>
            <h1 style={{ padding: '2rem 0' }}>Events</h1>
            <Grid container spacing={2} sx={{ width: '100%' }}>
                {eventList.length > 0 &&
                    eventList.map(
                        (eventData, idx) =>
                            eventData.isActive && (
                                <Grid item md={4} sm={6} xs={12} key={idx}>
                                    <div className="admin-card">
                                        <div className="admin-card-info">
                                            <h2>{eventData.title}</h2>
                                            <p
                                                style={{
                                                    color: 'rgba(46, 53, 58, 0.3)',
                                                    marginBottom: '10px',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {moment
                                                    .utc(eventData.eventDate)
                                                    .format('llll')}
                                            </p>
                                            <button
                                                onClick={() => handleEdit(idx)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() =>
                                                    handleHide(eventData._id)
                                                }
                                            >
                                                Hide
                                            </button>
                                        </div>
                                    </div>
                                </Grid>
                            )
                    )}
                {eventList.length === 0 && (
                    <p className="no-data-msg">Looking for Events 🔍</p>
                )}
            </Grid>
            <h1 style={{ padding: '2rem 0' }}>Hidden Events</h1>
            <Grid container spacing={2} sx={{ width: '100%' }}>
                {eventList.length > 0 &&
                    eventList.map(
                        (eventData, idx) =>
                            !eventData.isActive && (
                                <Grid item md={4} sm={6} xs={12} key={idx}>
                                    <div className="admin-card">
                                        <div className="admin-card-info">
                                            <h2>{eventData.title}</h2>
                                            <p
                                                style={{
                                                    color: 'rgba(46, 53, 58, 0.3)',
                                                    marginBottom: '10px',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {moment
                                                    .utc(eventData.eventDate)
                                                    .format('llll')}
                                            </p>
                                            <button
                                                onClick={() =>
                                                    handleUnHide(eventData._id)
                                                }
                                            >
                                                Unhide
                                            </button>
                                        </div>
                                    </div>
                                </Grid>
                            )
                    )}
                {eventList.length === 0 && (
                    <p className="no-data-msg">Looking for Events 🔍</p>
                )}
            </Grid>
            <EditEventModal
                open={editModalOpen}
                handleOpen={handleEventModalOpen}
                eventData={selectedEvent}
                trigger={getEvents}
            />
        </div>
    );
}

export default AdminEventManager;
